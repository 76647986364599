// THIS FILE IS GENERATED, DO NOT EDIT!

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Cursor for paging through collections */
  ConnectionCursor: string;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
  /** The `Upload` scalar type represents a file upload. */
  Upload: unknown;
};

export type AddAttendeesToEventInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddEventsToCalendarInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddExperienceTagsToExperienceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddExperienceTagsToTagInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddExperiencesToUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddFeedbacksToUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddImagesToExperienceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddImagesToProfileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddInterestsToProfileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMeetingsToZoomUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMessageFilesToMessageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMessageFlaggingsToMessageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMessagesToMessageFlaggingInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMessagesToMessageThreadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddStrengthsToProfileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddThreadParticipantsToMessageThreadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddTimeslotsToScheduleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddUsersToThreadParticipantsInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type Address = {
  __typename?: 'Address';
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type AddressObjectFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<AddressInput>;
  neq?: Maybe<AddressInput>;
  gt?: Maybe<AddressInput>;
  gte?: Maybe<AddressInput>;
  lt?: Maybe<AddressInput>;
  lte?: Maybe<AddressInput>;
  like?: Maybe<AddressInput>;
  notLike?: Maybe<AddressInput>;
  iLike?: Maybe<AddressInput>;
  notILike?: Maybe<AddressInput>;
  in?: Maybe<Array<AddressInput>>;
  notIn?: Maybe<Array<AddressInput>>;
};

export type Attendee = {
  __typename?: 'Attendee';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<AttendeeStatus>;
  role?: Maybe<AttendeeRole>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  event?: Maybe<Event>;
};

export type AttendeeAggregateGroupBy = {
  __typename?: 'AttendeeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<AttendeeStatus>;
  role?: Maybe<AttendeeRole>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
};

export type AttendeeConnection = {
  __typename?: 'AttendeeConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<AttendeeEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type AttendeeCountAggregate = {
  __typename?: 'AttendeeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['Int']>;
  cancelReason?: Maybe<Scalars['Int']>;
  cancelledAt?: Maybe<Scalars['Int']>;
};

export type AttendeeDeleteFilter = {
  and?: Maybe<Array<AttendeeDeleteFilter>>;
  or?: Maybe<Array<AttendeeDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  status?: Maybe<AttendeeStatusFilterComparison>;
  role?: Maybe<AttendeeRoleFilterComparison>;
  cancelReason?: Maybe<StringFieldComparison>;
  cancelledAt?: Maybe<DateFieldComparison>;
};

export type AttendeeDeleteResponse = {
  __typename?: 'AttendeeDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<AttendeeStatus>;
  role?: Maybe<AttendeeRole>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
};

export type AttendeeEdge = {
  __typename?: 'AttendeeEdge';
  /** The node containing the Attendee */
  node: Attendee;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type AttendeeFilter = {
  and?: Maybe<Array<AttendeeFilter>>;
  or?: Maybe<Array<AttendeeFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  status?: Maybe<AttendeeStatusFilterComparison>;
  role?: Maybe<AttendeeRoleFilterComparison>;
  cancelReason?: Maybe<StringFieldComparison>;
  cancelledAt?: Maybe<DateFieldComparison>;
  event?: Maybe<AttendeeFilterEventFilter>;
  user?: Maybe<AttendeeFilterUserFilter>;
};

export type AttendeeFilterEventFilter = {
  and?: Maybe<Array<AttendeeFilterEventFilter>>;
  or?: Maybe<Array<AttendeeFilterEventFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  calendarId?: Maybe<StringFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  start?: Maybe<DateFieldComparison>;
  end?: Maybe<DateFieldComparison>;
  private?: Maybe<BooleanFieldComparison>;
  transparency?: Maybe<EventTransparencyFilterComparison>;
  status?: Maybe<EventStatusFilterComparison>;
  overrideLink?: Maybe<StringFieldComparison>;
};

export type AttendeeFilterUserFilter = {
  and?: Maybe<Array<AttendeeFilterUserFilter>>;
  or?: Maybe<Array<AttendeeFilterUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  emailVerified?: Maybe<BooleanFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  phoneVerified?: Maybe<BooleanFieldComparison>;
  username?: Maybe<StringFieldComparison>;
  firstName?: Maybe<StringFieldComparison>;
  lastName?: Maybe<StringFieldComparison>;
  nickname?: Maybe<StringFieldComparison>;
  avatar?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  continent?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  welcomeComplete?: Maybe<DateFieldComparison>;
  welcomeVetted?: Maybe<BooleanFieldComparison>;
  criminalRecordCheckDate?: Maybe<DateFieldComparison>;
  criminalRecordCheckValid?: Maybe<BooleanFieldComparison>;
  criminalRecordCheck?: Maybe<StringFieldComparison>;
  location?: Maybe<PointObjectFilterComparison>;
  dob?: Maybe<StringFieldComparison>;
  address?: Maybe<AddressObjectFilterComparison>;
};

export type AttendeeMaxAggregate = {
  __typename?: 'AttendeeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<AttendeeStatus>;
  role?: Maybe<AttendeeRole>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
};

export type AttendeeMinAggregate = {
  __typename?: 'AttendeeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<AttendeeStatus>;
  role?: Maybe<AttendeeRole>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
};

export enum AttendeeRole {
  Host = 'host',
  Participant = 'participant',
}

export type AttendeeRoleFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<AttendeeRole>;
  neq?: Maybe<AttendeeRole>;
  gt?: Maybe<AttendeeRole>;
  gte?: Maybe<AttendeeRole>;
  lt?: Maybe<AttendeeRole>;
  lte?: Maybe<AttendeeRole>;
  like?: Maybe<AttendeeRole>;
  notLike?: Maybe<AttendeeRole>;
  iLike?: Maybe<AttendeeRole>;
  notILike?: Maybe<AttendeeRole>;
  in?: Maybe<Array<AttendeeRole>>;
  notIn?: Maybe<Array<AttendeeRole>>;
};

export type AttendeeSort = {
  field: AttendeeSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum AttendeeSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  UserId = 'userId',
  Name = 'name',
  Status = 'status',
  Role = 'role',
  CancelReason = 'cancelReason',
  CancelledAt = 'cancelledAt',
}

export enum AttendeeStatus {
  Pending = 'pending',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
}

export type AttendeeStatusFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<AttendeeStatus>;
  neq?: Maybe<AttendeeStatus>;
  gt?: Maybe<AttendeeStatus>;
  gte?: Maybe<AttendeeStatus>;
  lt?: Maybe<AttendeeStatus>;
  lte?: Maybe<AttendeeStatus>;
  like?: Maybe<AttendeeStatus>;
  notLike?: Maybe<AttendeeStatus>;
  iLike?: Maybe<AttendeeStatus>;
  notILike?: Maybe<AttendeeStatus>;
  in?: Maybe<Array<AttendeeStatus>>;
  notIn?: Maybe<Array<AttendeeStatus>>;
};

export type AttendeeUpdateFilter = {
  and?: Maybe<Array<AttendeeUpdateFilter>>;
  or?: Maybe<Array<AttendeeUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  status?: Maybe<AttendeeStatusFilterComparison>;
  role?: Maybe<AttendeeRoleFilterComparison>;
  cancelReason?: Maybe<StringFieldComparison>;
  cancelledAt?: Maybe<DateFieldComparison>;
};

export type Auth = {
  __typename?: 'Auth';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  issuer: Scalars['String'];
  externalId: Scalars['String'];
  user: User;
};

export type AuthAggregateGroupBy = {
  __typename?: 'AuthAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  issuer?: Maybe<Scalars['String']>;
};

export type AuthConnection = {
  __typename?: 'AuthConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<AuthEdge>;
};

export type AuthCountAggregate = {
  __typename?: 'AuthCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  issuer?: Maybe<Scalars['Int']>;
};

export type AuthDeleteFilter = {
  and?: Maybe<Array<AuthDeleteFilter>>;
  or?: Maybe<Array<AuthDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  issuer?: Maybe<StringFieldComparison>;
};

export type AuthDeleteResponse = {
  __typename?: 'AuthDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  issuer?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type AuthEdge = {
  __typename?: 'AuthEdge';
  /** The node containing the Auth */
  node: Auth;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type AuthFilter = {
  and?: Maybe<Array<AuthFilter>>;
  or?: Maybe<Array<AuthFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  issuer?: Maybe<StringFieldComparison>;
};

export type AuthMaxAggregate = {
  __typename?: 'AuthMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  issuer?: Maybe<Scalars['String']>;
};

export type AuthMinAggregate = {
  __typename?: 'AuthMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  issuer?: Maybe<Scalars['String']>;
};

export type AuthSort = {
  field: AuthSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum AuthSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Issuer = 'issuer',
}

export type AuthUpdateFilter = {
  and?: Maybe<Array<AuthUpdateFilter>>;
  or?: Maybe<Array<AuthUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  issuer?: Maybe<StringFieldComparison>;
};

export type AvailableTimeslot = {
  __typename?: 'AvailableTimeslot';
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
};

export type BooleanFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
};

export type Calendar = {
  __typename?: 'Calendar';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  schedule?: Maybe<Schedule>;
  events: CalendarEventsConnection;
  experience?: Maybe<Experience>;
};

export type CalendarEventsArgs = {
  paging?: CursorPaging;
  filter?: EventFilter;
  sorting?: Array<EventSort>;
};

export type CalendarAggregateGroupBy = {
  __typename?: 'CalendarAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type CalendarConnection = {
  __typename?: 'CalendarConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<CalendarEdge>;
};

export type CalendarCountAggregate = {
  __typename?: 'CalendarCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type CalendarDeleteFilter = {
  and?: Maybe<Array<CalendarDeleteFilter>>;
  or?: Maybe<Array<CalendarDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type CalendarDeleteResponse = {
  __typename?: 'CalendarDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  schedule?: Maybe<Schedule>;
  events?: Maybe<Array<Event>>;
};

export type CalendarEdge = {
  __typename?: 'CalendarEdge';
  /** The node containing the Calendar */
  node: Calendar;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type CalendarEventsConnection = {
  __typename?: 'CalendarEventsConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<EventEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type CalendarFilter = {
  and?: Maybe<Array<CalendarFilter>>;
  or?: Maybe<Array<CalendarFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  experience?: Maybe<CalendarFilterExperienceFilter>;
  events?: Maybe<CalendarFilterEventFilter>;
};

export type CalendarFilterEventFilter = {
  and?: Maybe<Array<CalendarFilterEventFilter>>;
  or?: Maybe<Array<CalendarFilterEventFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  calendarId?: Maybe<StringFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  start?: Maybe<DateFieldComparison>;
  end?: Maybe<DateFieldComparison>;
  private?: Maybe<BooleanFieldComparison>;
  transparency?: Maybe<EventTransparencyFilterComparison>;
  status?: Maybe<EventStatusFilterComparison>;
  overrideLink?: Maybe<StringFieldComparison>;
};

export type CalendarFilterExperienceFilter = {
  and?: Maybe<Array<CalendarFilterExperienceFilter>>;
  or?: Maybe<Array<CalendarFilterExperienceFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  meetingScheduled?: Maybe<BooleanFieldComparison>;
  progressStep?: Maybe<ExperienceProgressStepFilterComparison>;
  hostId?: Maybe<StringFieldComparison>;
  video?: Maybe<StringFieldComparison>;
  featured?: Maybe<NumberFieldComparison>;
  category?: Maybe<ExperienceCategoryFilterComparison>;
  publishedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<ExperienceStatusFilterComparison>;
  nextAvailability?: Maybe<DateFieldComparison>;
  nextEvent?: Maybe<DateFieldComparison>;
  next?: Maybe<DateFieldComparison>;
  type?: Maybe<ExperienceTypeFilterComparison>;
  mode?: Maybe<ExperienceModeFilterComparison>;
  locationType?: Maybe<ExperienceLocationTypeFilterComparison>;
  approximateLocation?: Maybe<PointObjectFilterComparison>;
  coordinates?: Maybe<PointObjectFilterComparison>;
  streetNumber?: Maybe<StringFieldComparison>;
  route?: Maybe<StringFieldComparison>;
  locality?: Maybe<StringFieldComparison>;
  administrativeAreaLevel2?: Maybe<StringFieldComparison>;
  administrativeAreaLevel1?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  postalCode?: Maybe<StringFieldComparison>;
};

export type CalendarMaxAggregate = {
  __typename?: 'CalendarMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type CalendarMinAggregate = {
  __typename?: 'CalendarMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type CalendarSort = {
  field: CalendarSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum CalendarSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Name = 'name',
}

export type CalendarUpdateFilter = {
  and?: Maybe<Array<CalendarUpdateFilter>>;
  or?: Maybe<Array<CalendarUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type CancelBookingInput = {
  eventId: Scalars['ID'];
  reason: Scalars['String'];
};

export type ConfirmBookingInput = {
  eventId: Scalars['ID'];
};

export type CreateAttendeeInput = {
  user?: Maybe<RelationInput>;
  event?: Maybe<RelationInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<AttendeeStatus>;
  role?: Maybe<AttendeeRole>;
  cancelReason?: Maybe<Scalars['String']>;
};

export type CreateAuthInput = {
  issuer: Scalars['String'];
  externalId: Scalars['String'];
  user?: Maybe<CreateUserInput>;
};

export type CreateCalendarInput = {
  name?: Maybe<Scalars['String']>;
};

export type CreateEventInput = {
  calendar: RelationInput;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  timezone?: Maybe<Timezone>;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  private?: Maybe<Scalars['Boolean']>;
  transparency?: Maybe<EventTransparency>;
  status?: Maybe<EventStatus>;
  overrideLink?: Maybe<Scalars['String']>;
};

export type CreateExperienceInput = {
  title?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  content?: Maybe<ExperienceContentInput>;
  brainstorm?: Maybe<ExperienceBrainstormInput>;
  progressStep?: Maybe<ExperienceProgressStep>;
  meetingScheduled?: Maybe<Scalars['Boolean']>;
  category: ExperienceCategory;
  status?: Maybe<ExperienceStatus>;
  type?: Maybe<ExperienceType>;
  host?: Maybe<RelationInput>;
  mode?: Maybe<ExperienceMode>;
  locationType?: Maybe<ExperienceLocationType>;
  coordinates?: Maybe<PointInput>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']>;
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type CreateExperienceTagInput = {
  experience?: Maybe<RelationInput>;
  tag?: Maybe<RelationInput>;
};

export type CreateFeedbackInput = {
  status?: Maybe<FeedbackStatus>;
  content: FeedbackContentInput;
  createdBy: RelationInput;
  user: RelationInput;
  experience?: Maybe<RelationInput>;
  event?: Maybe<RelationInput>;
};

export type CreateFileInput = {
  key: Scalars['String'];
  mimetype: Scalars['String'];
};

export type CreateImageInput = {
  order?: Maybe<Scalars['Float']>;
  experience?: Maybe<RelationInput>;
  file?: Maybe<RelationInput>;
};

export type CreateManyAttendeesInput = {
  /** Array of records to create */
  attendees: Array<CreateAttendeeInput>;
};

export type CreateManyAuthsInput = {
  /** Array of records to create */
  auths: Array<CreateAuthInput>;
};

export type CreateManyCalendarsInput = {
  /** Array of records to create */
  calendars: Array<CreateCalendarInput>;
};

export type CreateManyEventsInput = {
  /** Array of records to create */
  events: Array<CreateEventInput>;
};

export type CreateManyExperienceTagsInput = {
  /** Array of records to create */
  experienceTags: Array<CreateExperienceTagInput>;
};

export type CreateManyExperiencesInput = {
  /** Array of records to create */
  experiences: Array<CreateExperienceInput>;
};

export type CreateManyFeedbacksInput = {
  /** Array of records to create */
  feedbacks: Array<CreateFeedbackInput>;
};

export type CreateManyFilesInput = {
  /** Array of records to create */
  files: Array<CreateFileInput>;
};

export type CreateManyImagesInput = {
  /** Array of records to create */
  images: Array<CreateImageInput>;
};

export type CreateManyMessageFilesInput = {
  /** Array of records to create */
  messageFiles: Array<CreateMessageFileInput>;
};

export type CreateManyMessageFlaggingsInput = {
  /** Array of records to create */
  messageFlaggings: Array<CreateMessageFlagInput>;
};

export type CreateManyMessageThreadsInput = {
  /** Array of records to create */
  messageThreads: Array<CreateMessageThreadInput>;
};

export type CreateManyNotificationsInput = {
  /** Array of records to create */
  notifications: Array<CreateNotificationInput>;
};

export type CreateManyOEmbedsInput = {
  /** Array of records to create */
  oEmbeds: Array<CreateOEmbedInput>;
};

export type CreateManyOrdersInput = {
  /** Array of records to create */
  orders: Array<CreateOrderInput>;
};

export type CreateManyProfileImagesInput = {
  /** Array of records to create */
  profileImages: Array<CreateProfileImageInput>;
};

export type CreateManyProfileInterestsInput = {
  /** Array of records to create */
  profileInterests: Array<CreateProfileInterestInput>;
};

export type CreateManyProfileStrengthsInput = {
  /** Array of records to create */
  profileStrengths: Array<CreateProfileStrengthInput>;
};

export type CreateManyProfilesInput = {
  /** Array of records to create */
  profiles: Array<CreateProfileInput>;
};

export type CreateManySchedulesInput = {
  /** Array of records to create */
  schedules: Array<CreateScheduleInput>;
};

export type CreateManyStripeCustomersInput = {
  /** Array of records to create */
  stripeCustomers: Array<CreateStripeCustomerInput>;
};

export type CreateManyStripeProductsInput = {
  /** Array of records to create */
  stripeProducts: Array<CreateStripeProductInput>;
};

export type CreateManyThreadParticipantsInput = {
  /** Array of records to create */
  threadParticipants: Array<CreateThreadParticipantsInput>;
};

export type CreateManyTimeslotsInput = {
  /** Array of records to create */
  timeslots: Array<CreateTimeslotInput>;
};

export type CreateManyUsersInput = {
  /** Array of records to create */
  users: Array<CreateUserInput>;
};

export type CreateManyZoomMeetingsInput = {
  /** Array of records to create */
  zoomMeetings: Array<CreateZoomMeetingInput>;
};

export type CreateManyZoomUsersInput = {
  /** Array of records to create */
  zoomUsers: Array<CreateZoomUserInput>;
};

export type CreateMessageFileInput = {
  file?: Maybe<RelationInput>;
  message?: Maybe<RelationInput>;
};

export type CreateMessageFlagInput = {
  reason?: Maybe<Scalars['String']>;
  message: RelationInput;
  user: RelationInput;
  status: MessageFlaggingStatus;
};

export type CreateMessageInput = {
  messageThread: RelationInput;
  user: RelationInput;
  content?: Maybe<Scalars['String']>;
};

export type CreateMessageSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: MessageSubscriptionFilter;
};

export type CreateMessageThreadInput = {
  name?: Maybe<Scalars['String']>;
};

export type CreateMessageThreadSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: MessageThreadSubscriptionFilter;
};

export type CreateNotificationInput = {
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: NotificationStatus;
  user: RelationInput;
};

export type CreateNotificationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: NotificationSubscriptionFilter;
};

export type CreateOEmbedInput = {
  url: Scalars['String'];
};

export type CreateOneAttendeeInput = {
  /** The record to create */
  attendee: CreateAttendeeInput;
};

export type CreateOneAuthInput = {
  /** The record to create */
  auth: CreateAuthInput;
};

export type CreateOneCalendarInput = {
  /** The record to create */
  calendar: CreateCalendarInput;
};

export type CreateOneEventInput = {
  /** The record to create */
  event: CreateEventInput;
};

export type CreateOneExperienceInput = {
  /** The record to create */
  experience: CreateExperienceInput;
};

export type CreateOneExperienceTagInput = {
  /** The record to create */
  experienceTag: CreateExperienceTagInput;
};

export type CreateOneFeedbackInput = {
  /** The record to create */
  feedback: CreateFeedbackInput;
};

export type CreateOneFileInput = {
  /** The record to create */
  file: CreateFileInput;
};

export type CreateOneImageInput = {
  /** The record to create */
  image: CreateImageInput;
};

export type CreateOneMessageFileInput = {
  /** The record to create */
  messageFile: CreateMessageFileInput;
};

export type CreateOneMessageFlaggingInput = {
  /** The record to create */
  messageFlagging: CreateMessageFlagInput;
};

export type CreateOneMessageInput = {
  /** The record to create */
  message: CreateMessageInput;
};

export type CreateOneMessageThreadInput = {
  /** The record to create */
  messageThread: CreateMessageThreadInput;
};

export type CreateOneNotificationInput = {
  /** The record to create */
  notification: CreateNotificationInput;
};

export type CreateOneOEmbedInput = {
  /** The record to create */
  oEmbed: CreateOEmbedInput;
};

export type CreateOneOrderInput = {
  /** The record to create */
  order: CreateOrderInput;
};

export type CreateOneProfileImageInput = {
  /** The record to create */
  profileImage: CreateProfileImageInput;
};

export type CreateOneProfileInput = {
  /** The record to create */
  profile: CreateProfileInput;
};

export type CreateOneProfileInterestInput = {
  /** The record to create */
  profileInterest: CreateProfileInterestInput;
};

export type CreateOneProfileStrengthInput = {
  /** The record to create */
  profileStrength: CreateProfileStrengthInput;
};

export type CreateOneScheduleInput = {
  /** The record to create */
  schedule: CreateScheduleInput;
};

export type CreateOneStripeCustomerInput = {
  /** The record to create */
  stripeCustomer: CreateStripeCustomerInput;
};

export type CreateOneStripeProductInput = {
  /** The record to create */
  stripeProduct: CreateStripeProductInput;
};

export type CreateOneTagInput = {
  /** The record to create */
  tag: CreateTagInput;
};

export type CreateOneThreadParticipantsInput = {
  /** The record to create */
  threadParticipants: CreateThreadParticipantsInput;
};

export type CreateOneTimeslotInput = {
  /** The record to create */
  timeslot: CreateTimeslotInput;
};

export type CreateOneUserInput = {
  /** The record to create */
  user: CreateUserInput;
};

export type CreateOneZoomMeetingInput = {
  /** The record to create */
  zoomMeeting: CreateZoomMeetingInput;
};

export type CreateOneZoomUserInput = {
  /** The record to create */
  zoomUser: CreateZoomUserInput;
};

export type CreateOrderInput = {
  experience: RelationInput;
  user: RelationInput;
  status: OrderStatus;
  stripeId: Scalars['String'];
  stripeLivemode?: Scalars['Boolean'];
  shippedAt?: Maybe<Scalars['DateTime']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
};

export type CreateProfileImageInput = {
  order?: Maybe<Scalars['Float']>;
  profile?: Maybe<RelationInput>;
  file?: Maybe<RelationInput>;
};

export type CreateProfileInput = {
  nickname?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
};

export type CreateProfileInterestInput = {
  name: Scalars['String'];
};

export type CreateProfileStrengthInput = {
  name: Scalars['String'];
};

export type CreateScheduleInput = {
  duration?: Maybe<Scalars['Float']>;
  durationMetric?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['Float']>;
  frequencyMetric?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['Float']>;
  rangeMetric?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['Float']>;
  delayMetric?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['Float']>;
  capRange?: Maybe<Scalars['Float']>;
  capRangeMetric?: Maybe<Scalars['String']>;
};

export type CreateStripeCheckoutSessionInput = {
  experienceId: Scalars['ID'];
  cancelUrl?: Maybe<Scalars['String']>;
  successUrl?: Maybe<Scalars['String']>;
};

export type CreateStripeCustomerInput = {
  stripeId: Scalars['String'];
  stripeLivemode?: Scalars['Boolean'];
  user: RelationInput;
};

export type CreateStripeProductInput = {
  stripeId: Scalars['String'];
  stripeLivemode?: Scalars['Boolean'];
  priceId: Scalars['String'];
  experience: RelationInput;
};

export type CreateTagInput = {
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<RelationInput>;
  category?: Maybe<TagCategory>;
  public?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type CreateThreadParticipantsInput = {
  messageThread: RelationInput;
  user: RelationInput;
  unread?: Scalars['Boolean'];
};

export type CreateThreadParticipantsSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ThreadParticipantsSubscriptionFilter;
};

export type CreateTimeslotInput = {
  schedule?: Maybe<RelationInput>;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  free?: Maybe<Scalars['Boolean']>;
  weekly?: Maybe<Scalars['Boolean']>;
};

export type CreateUserInput = {
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  timezone?: Maybe<Timezone>;
  city?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  location?: Maybe<PointInput>;
  dob?: Maybe<Scalars['String']>;
  welcomeComplete?: Maybe<Scalars['DateTime']>;
  welcomeVetted?: Maybe<Scalars['Boolean']>;
  criminalRecordCheckDate?: Maybe<Scalars['DateTime']>;
  criminalRecordCheckValid?: Maybe<Scalars['Boolean']>;
  criminalRecordCheck?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
};

export type CreateZoomMeetingInput = {
  zoomUser: RelationInput;
  code: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  joinUrl: Scalars['String'];
};

export type CreateZoomUserInput = {
  zoomId: Scalars['String'];
  email: Scalars['String'];
  user: RelationInput;
};

export type CursorPaging = {
  /** Paginate before opaque cursor */
  before?: Maybe<Scalars['ConnectionCursor']>;
  /** Paginate after opaque cursor */
  after?: Maybe<Scalars['ConnectionCursor']>;
  /** Paginate first */
  first?: Maybe<Scalars['Int']>;
  /** Paginate last */
  last?: Maybe<Scalars['Int']>;
};

export type DateFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  between?: Maybe<DateFieldComparisonBetween>;
  notBetween?: Maybe<DateFieldComparisonBetween>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime'];
  upper: Scalars['DateTime'];
};

export type DeleteManyAttendeesInput = {
  /** Filter to find records to delete */
  filter: AttendeeDeleteFilter;
};

export type DeleteManyAuthsInput = {
  /** Filter to find records to delete */
  filter: AuthDeleteFilter;
};

export type DeleteManyCalendarsInput = {
  /** Filter to find records to delete */
  filter: CalendarDeleteFilter;
};

export type DeleteManyEventsInput = {
  /** Filter to find records to delete */
  filter: EventDeleteFilter;
};

export type DeleteManyExperienceTagsInput = {
  /** Filter to find records to delete */
  filter: ExperienceTagDeleteFilter;
};

export type DeleteManyExperiencesInput = {
  /** Filter to find records to delete */
  filter: ExperienceDeleteFilter;
};

export type DeleteManyFeedbacksInput = {
  /** Filter to find records to delete */
  filter: FeedbackDeleteFilter;
};

export type DeleteManyFilesInput = {
  /** Filter to find records to delete */
  filter: FileDeleteFilter;
};

export type DeleteManyImagesInput = {
  /** Filter to find records to delete */
  filter: ImageDeleteFilter;
};

export type DeleteManyMessageFilesInput = {
  /** Filter to find records to delete */
  filter: MessageFileDeleteFilter;
};

export type DeleteManyMessageFlaggingsInput = {
  /** Filter to find records to delete */
  filter: MessageFlaggingDeleteFilter;
};

export type DeleteManyMessageThreadsInput = {
  /** Filter to find records to delete */
  filter: MessageThreadDeleteFilter;
};

export type DeleteManyMessagesInput = {
  /** Filter to find records to delete */
  filter: MessageDeleteFilter;
};

export type DeleteManyNotificationsInput = {
  /** Filter to find records to delete */
  filter: NotificationDeleteFilter;
};

export type DeleteManyOEmbedsInput = {
  /** Filter to find records to delete */
  filter: OEmbedDeleteFilter;
};

export type DeleteManyOrdersInput = {
  /** Filter to find records to delete */
  filter: OrderDeleteFilter;
};

export type DeleteManyProfileImagesInput = {
  /** Filter to find records to delete */
  filter: ProfileImageDeleteFilter;
};

export type DeleteManyProfileInterestsInput = {
  /** Filter to find records to delete */
  filter: ProfileInterestDeleteFilter;
};

export type DeleteManyProfileStrengthsInput = {
  /** Filter to find records to delete */
  filter: ProfileStrengthDeleteFilter;
};

export type DeleteManyProfilesInput = {
  /** Filter to find records to delete */
  filter: ProfileDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int'];
};

export type DeleteManySchedulesInput = {
  /** Filter to find records to delete */
  filter: ScheduleDeleteFilter;
};

export type DeleteManyStripeCustomersInput = {
  /** Filter to find records to delete */
  filter: StripeCustomerDeleteFilter;
};

export type DeleteManyStripeProductsInput = {
  /** Filter to find records to delete */
  filter: StripeProductDeleteFilter;
};

export type DeleteManyTagsInput = {
  /** Filter to find records to delete */
  filter: TagDeleteFilter;
};

export type DeleteManyThreadParticipantsInput = {
  /** Filter to find records to delete */
  filter: ThreadParticipantsDeleteFilter;
};

export type DeleteManyTimeslotsInput = {
  /** Filter to find records to delete */
  filter: TimeslotDeleteFilter;
};

export type DeleteManyUsersInput = {
  /** Filter to find records to delete */
  filter: UserDeleteFilter;
};

export type DeleteManyZoomMeetingsInput = {
  /** Filter to find records to delete */
  filter: ZoomMeetingDeleteFilter;
};

export type DeleteManyZoomUsersInput = {
  /** Filter to find records to delete */
  filter: ZoomUserDeleteFilter;
};

export type DeleteOneAttendeeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneAuthInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneCalendarInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneEventInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneExperienceInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneExperienceTagInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneFeedbackInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneFileInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneImageInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneMessageFileInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneMessageFlaggingInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneMessageInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneMessageSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: MessageSubscriptionFilter;
};

export type DeleteOneMessageThreadInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneMessageThreadSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: MessageThreadSubscriptionFilter;
};

export type DeleteOneNotificationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneNotificationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: NotificationSubscriptionFilter;
};

export type DeleteOneOEmbedInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneOrderInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneProfileImageInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneProfileInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneProfileInterestInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneProfileStrengthInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneScheduleInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneStripeCustomerInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneStripeProductInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneTagInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneThreadParticipantsInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneThreadParticipantsSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ThreadParticipantsSubscriptionFilter;
};

export type DeleteOneTimeslotInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneUserInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneZoomMeetingInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneZoomUserInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  calendarId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  timezone?: Maybe<Timezone>;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  private?: Maybe<Scalars['Boolean']>;
  transparency?: Maybe<EventTransparency>;
  status?: Maybe<EventStatus>;
  overrideLink?: Maybe<Scalars['String']>;
  location?: Maybe<EventLocation>;
  experienceId?: Maybe<Scalars['String']>;
  calendar: Calendar;
  zoomMeeting?: Maybe<ZoomMeeting>;
  attendees?: Maybe<EventAttendeesConnection>;
};

export type EventAttendeesArgs = {
  paging?: CursorPaging;
  filter?: AttendeeFilter;
  sorting?: Array<AttendeeSort>;
};

export type EventAggregateFilter = {
  and?: Maybe<Array<EventAggregateFilter>>;
  or?: Maybe<Array<EventAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  calendarId?: Maybe<StringFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  start?: Maybe<DateFieldComparison>;
  end?: Maybe<DateFieldComparison>;
  private?: Maybe<BooleanFieldComparison>;
  transparency?: Maybe<EventTransparencyFilterComparison>;
  status?: Maybe<EventStatusFilterComparison>;
  overrideLink?: Maybe<StringFieldComparison>;
};

export type EventAggregateGroupBy = {
  __typename?: 'EventAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  calendarId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  private?: Maybe<Scalars['Boolean']>;
  transparency?: Maybe<EventTransparency>;
  status?: Maybe<EventStatus>;
  overrideLink?: Maybe<Scalars['String']>;
};

export type EventAttendeesConnection = {
  __typename?: 'EventAttendeesConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<AttendeeEdge>;
};

export type EventConnection = {
  __typename?: 'EventConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<EventEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type EventCountAggregate = {
  __typename?: 'EventCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  calendarId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  private?: Maybe<Scalars['Int']>;
  transparency?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  overrideLink?: Maybe<Scalars['Int']>;
};

export type EventDeleteFilter = {
  and?: Maybe<Array<EventDeleteFilter>>;
  or?: Maybe<Array<EventDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  calendarId?: Maybe<StringFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  start?: Maybe<DateFieldComparison>;
  end?: Maybe<DateFieldComparison>;
  private?: Maybe<BooleanFieldComparison>;
  transparency?: Maybe<EventTransparencyFilterComparison>;
  status?: Maybe<EventStatusFilterComparison>;
  overrideLink?: Maybe<StringFieldComparison>;
};

export type EventDeleteResponse = {
  __typename?: 'EventDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  calendarId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  timezone?: Maybe<Timezone>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  private?: Maybe<Scalars['Boolean']>;
  transparency?: Maybe<EventTransparency>;
  status?: Maybe<EventStatus>;
  overrideLink?: Maybe<Scalars['String']>;
  location?: Maybe<EventLocation>;
  experienceId?: Maybe<Scalars['String']>;
};

export type EventEdge = {
  __typename?: 'EventEdge';
  /** The node containing the Event */
  node: Event;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type EventFilter = {
  and?: Maybe<Array<EventFilter>>;
  or?: Maybe<Array<EventFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  calendarId?: Maybe<StringFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  start?: Maybe<DateFieldComparison>;
  end?: Maybe<DateFieldComparison>;
  private?: Maybe<BooleanFieldComparison>;
  transparency?: Maybe<EventTransparencyFilterComparison>;
  status?: Maybe<EventStatusFilterComparison>;
  overrideLink?: Maybe<StringFieldComparison>;
  zoomMeeting?: Maybe<EventFilterZoomMeetingFilter>;
  experience?: Maybe<EventFilterExperienceFilter>;
  calendar?: Maybe<EventFilterCalendarFilter>;
  attendees?: Maybe<EventFilterAttendeeFilter>;
};

export type EventFilterAttendeeFilter = {
  and?: Maybe<Array<EventFilterAttendeeFilter>>;
  or?: Maybe<Array<EventFilterAttendeeFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  status?: Maybe<AttendeeStatusFilterComparison>;
  role?: Maybe<AttendeeRoleFilterComparison>;
  cancelReason?: Maybe<StringFieldComparison>;
  cancelledAt?: Maybe<DateFieldComparison>;
};

export type EventFilterCalendarFilter = {
  and?: Maybe<Array<EventFilterCalendarFilter>>;
  or?: Maybe<Array<EventFilterCalendarFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type EventFilterExperienceFilter = {
  and?: Maybe<Array<EventFilterExperienceFilter>>;
  or?: Maybe<Array<EventFilterExperienceFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  meetingScheduled?: Maybe<BooleanFieldComparison>;
  progressStep?: Maybe<ExperienceProgressStepFilterComparison>;
  hostId?: Maybe<StringFieldComparison>;
  video?: Maybe<StringFieldComparison>;
  featured?: Maybe<NumberFieldComparison>;
  category?: Maybe<ExperienceCategoryFilterComparison>;
  publishedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<ExperienceStatusFilterComparison>;
  nextAvailability?: Maybe<DateFieldComparison>;
  nextEvent?: Maybe<DateFieldComparison>;
  next?: Maybe<DateFieldComparison>;
  type?: Maybe<ExperienceTypeFilterComparison>;
  mode?: Maybe<ExperienceModeFilterComparison>;
  locationType?: Maybe<ExperienceLocationTypeFilterComparison>;
  approximateLocation?: Maybe<PointObjectFilterComparison>;
  coordinates?: Maybe<PointObjectFilterComparison>;
  streetNumber?: Maybe<StringFieldComparison>;
  route?: Maybe<StringFieldComparison>;
  locality?: Maybe<StringFieldComparison>;
  administrativeAreaLevel2?: Maybe<StringFieldComparison>;
  administrativeAreaLevel1?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  postalCode?: Maybe<StringFieldComparison>;
};

export type EventFilterZoomMeetingFilter = {
  and?: Maybe<Array<EventFilterZoomMeetingFilter>>;
  or?: Maybe<Array<EventFilterZoomMeetingFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  joinUrl?: Maybe<StringFieldComparison>;
  zoomUserId?: Maybe<StringFieldComparison>;
};

export type EventLocation = {
  __typename?: 'EventLocation';
  approximateLocation?: Maybe<Point>;
  coordinates?: Maybe<Point>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']>;
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type EventMaxAggregate = {
  __typename?: 'EventMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  calendarId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  transparency?: Maybe<EventTransparency>;
  status?: Maybe<EventStatus>;
  overrideLink?: Maybe<Scalars['String']>;
};

export type EventMinAggregate = {
  __typename?: 'EventMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  calendarId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  transparency?: Maybe<EventTransparency>;
  status?: Maybe<EventStatus>;
  overrideLink?: Maybe<Scalars['String']>;
};

export type EventSort = {
  field: EventSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum EventSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  CalendarId = 'calendarId',
  Title = 'title',
  Description = 'description',
  Start = 'start',
  End = 'end',
  Private = 'private',
  Transparency = 'transparency',
  Status = 'status',
  OverrideLink = 'overrideLink',
}

export enum EventStatus {
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
}

export type EventStatusFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<EventStatus>;
  neq?: Maybe<EventStatus>;
  gt?: Maybe<EventStatus>;
  gte?: Maybe<EventStatus>;
  lt?: Maybe<EventStatus>;
  lte?: Maybe<EventStatus>;
  like?: Maybe<EventStatus>;
  notLike?: Maybe<EventStatus>;
  iLike?: Maybe<EventStatus>;
  notILike?: Maybe<EventStatus>;
  in?: Maybe<Array<EventStatus>>;
  notIn?: Maybe<Array<EventStatus>>;
};

export enum EventTransparency {
  Opaque = 'opaque',
  Transparent = 'transparent',
}

export type EventTransparencyFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<EventTransparency>;
  neq?: Maybe<EventTransparency>;
  gt?: Maybe<EventTransparency>;
  gte?: Maybe<EventTransparency>;
  lt?: Maybe<EventTransparency>;
  lte?: Maybe<EventTransparency>;
  like?: Maybe<EventTransparency>;
  notLike?: Maybe<EventTransparency>;
  iLike?: Maybe<EventTransparency>;
  notILike?: Maybe<EventTransparency>;
  in?: Maybe<Array<EventTransparency>>;
  notIn?: Maybe<Array<EventTransparency>>;
};

export type EventUpdateFilter = {
  and?: Maybe<Array<EventUpdateFilter>>;
  or?: Maybe<Array<EventUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  calendarId?: Maybe<StringFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  start?: Maybe<DateFieldComparison>;
  end?: Maybe<DateFieldComparison>;
  private?: Maybe<BooleanFieldComparison>;
  transparency?: Maybe<EventTransparencyFilterComparison>;
  status?: Maybe<EventStatusFilterComparison>;
  overrideLink?: Maybe<StringFieldComparison>;
};

export type Experience = {
  __typename?: 'Experience';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  meetingScheduled?: Maybe<Scalars['Boolean']>;
  progressStep?: Maybe<ExperienceProgressStep>;
  content?: Maybe<ExperienceContent>;
  brainstorm?: Maybe<ExperienceBrainstorm>;
  images: ExperienceImagesConnection;
  host: User;
  hostId: Scalars['String'];
  featured?: Maybe<Scalars['Float']>;
  category: ExperienceCategory;
  publishedAt?: Maybe<Scalars['DateTime']>;
  status: ExperienceStatus;
  calendar?: Maybe<Calendar>;
  nextAvailability?: Maybe<Scalars['DateTime']>;
  nextEvent?: Maybe<Scalars['DateTime']>;
  next?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ExperienceType>;
  mode?: Maybe<ExperienceMode>;
  locationType?: Maybe<ExperienceLocationType>;
  approximateLocation?: Maybe<Point>;
  coordinates?: Maybe<Point>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']>;
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  events: ExperienceEventsConnection;
  experienceTags: ExperienceExperienceTagsConnection;
  video?: Maybe<OEmbed>;
};

export type ExperienceImagesArgs = {
  paging?: CursorPaging;
  filter?: ImageFilter;
  sorting?: Array<ImageSort>;
};

export type ExperienceEventsArgs = {
  paging?: CursorPaging;
  filter?: EventFilter;
  sorting?: Array<EventSort>;
};

export type ExperienceExperienceTagsArgs = {
  paging?: CursorPaging;
  filter?: ExperienceTagFilter;
  sorting?: Array<ExperienceTagSort>;
};

export type ExperienceAggregateFilter = {
  and?: Maybe<Array<ExperienceAggregateFilter>>;
  or?: Maybe<Array<ExperienceAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  meetingScheduled?: Maybe<BooleanFieldComparison>;
  progressStep?: Maybe<ExperienceProgressStepFilterComparison>;
  hostId?: Maybe<StringFieldComparison>;
  video?: Maybe<StringFieldComparison>;
  featured?: Maybe<NumberFieldComparison>;
  category?: Maybe<ExperienceCategoryFilterComparison>;
  publishedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<ExperienceStatusFilterComparison>;
  nextAvailability?: Maybe<DateFieldComparison>;
  nextEvent?: Maybe<DateFieldComparison>;
  next?: Maybe<DateFieldComparison>;
  type?: Maybe<ExperienceTypeFilterComparison>;
  mode?: Maybe<ExperienceModeFilterComparison>;
  locationType?: Maybe<ExperienceLocationTypeFilterComparison>;
  approximateLocation?: Maybe<PointObjectFilterComparison>;
  coordinates?: Maybe<PointObjectFilterComparison>;
  streetNumber?: Maybe<StringFieldComparison>;
  route?: Maybe<StringFieldComparison>;
  locality?: Maybe<StringFieldComparison>;
  administrativeAreaLevel2?: Maybe<StringFieldComparison>;
  administrativeAreaLevel1?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  postalCode?: Maybe<StringFieldComparison>;
};

export type ExperienceAggregateGroupBy = {
  __typename?: 'ExperienceAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  meetingScheduled?: Maybe<Scalars['Boolean']>;
  progressStep?: Maybe<ExperienceProgressStep>;
  hostId?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Float']>;
  category?: Maybe<ExperienceCategory>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ExperienceStatus>;
  nextAvailability?: Maybe<Scalars['DateTime']>;
  nextEvent?: Maybe<Scalars['DateTime']>;
  next?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ExperienceType>;
  mode?: Maybe<ExperienceMode>;
  locationType?: Maybe<ExperienceLocationType>;
  approximateLocation?: Maybe<Point>;
  coordinates?: Maybe<Point>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']>;
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type ExperienceAvailabilityInput = {
  experience: Scalars['ID'];
  users?: Maybe<Array<Scalars['ID']>>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
};

export type ExperienceAvgAggregate = {
  __typename?: 'ExperienceAvgAggregate';
  featured?: Maybe<Scalars['Float']>;
};

export type ExperienceBrainstorm = {
  __typename?: 'ExperienceBrainstorm';
  inspiration?: Maybe<Scalars['String']>;
  hobby?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['String']>;
  second?: Maybe<Scalars['String']>;
  third?: Maybe<Scalars['String']>;
  interest?: Maybe<Scalars['String']>;
  secondInterest?: Maybe<Scalars['String']>;
  partOfDay?: Maybe<Scalars['String']>;
};

export type ExperienceBrainstormInput = {
  inspiration?: Maybe<Scalars['String']>;
  hobby?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['String']>;
  second?: Maybe<Scalars['String']>;
  third?: Maybe<Scalars['String']>;
  interest?: Maybe<Scalars['String']>;
  secondInterest?: Maybe<Scalars['String']>;
  partOfDay?: Maybe<Scalars['String']>;
};

export enum ExperienceCategory {
  Taste = 'Taste',
  Make = 'Make',
  Move = 'Move',
  Connect = 'Connect',
  Explore = 'Explore',
  Advocate = 'Advocate',
}

export type ExperienceCategoryFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<ExperienceCategory>;
  neq?: Maybe<ExperienceCategory>;
  gt?: Maybe<ExperienceCategory>;
  gte?: Maybe<ExperienceCategory>;
  lt?: Maybe<ExperienceCategory>;
  lte?: Maybe<ExperienceCategory>;
  like?: Maybe<ExperienceCategory>;
  notLike?: Maybe<ExperienceCategory>;
  iLike?: Maybe<ExperienceCategory>;
  notILike?: Maybe<ExperienceCategory>;
  in?: Maybe<Array<ExperienceCategory>>;
  notIn?: Maybe<Array<ExperienceCategory>>;
};

export type ExperienceConnection = {
  __typename?: 'ExperienceConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ExperienceEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ExperienceContent = {
  __typename?: 'ExperienceContent';
  description?: Maybe<Scalars['String']>;
  what?: Maybe<Scalars['String']>;
  bring?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  accessibility?: Maybe<Scalars['String']>;
};

export type ExperienceContentInput = {
  description?: Maybe<Scalars['String']>;
  what?: Maybe<Scalars['String']>;
  bring?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  accessibility?: Maybe<Scalars['String']>;
};

export type ExperienceCountAggregate = {
  __typename?: 'ExperienceCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['Int']>;
  meetingScheduled?: Maybe<Scalars['Int']>;
  progressStep?: Maybe<Scalars['Int']>;
  hostId?: Maybe<Scalars['Int']>;
  video?: Maybe<Scalars['Int']>;
  featured?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  nextAvailability?: Maybe<Scalars['Int']>;
  nextEvent?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['Int']>;
  locationType?: Maybe<Scalars['Int']>;
  approximateLocation?: Maybe<Scalars['Int']>;
  coordinates?: Maybe<Scalars['Int']>;
  streetNumber?: Maybe<Scalars['Int']>;
  route?: Maybe<Scalars['Int']>;
  locality?: Maybe<Scalars['Int']>;
  administrativeAreaLevel2?: Maybe<Scalars['Int']>;
  administrativeAreaLevel1?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['Int']>;
  postalCode?: Maybe<Scalars['Int']>;
};

export type ExperienceDeleteFilter = {
  and?: Maybe<Array<ExperienceDeleteFilter>>;
  or?: Maybe<Array<ExperienceDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  meetingScheduled?: Maybe<BooleanFieldComparison>;
  progressStep?: Maybe<ExperienceProgressStepFilterComparison>;
  hostId?: Maybe<StringFieldComparison>;
  video?: Maybe<StringFieldComparison>;
  featured?: Maybe<NumberFieldComparison>;
  category?: Maybe<ExperienceCategoryFilterComparison>;
  publishedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<ExperienceStatusFilterComparison>;
  nextAvailability?: Maybe<DateFieldComparison>;
  nextEvent?: Maybe<DateFieldComparison>;
  next?: Maybe<DateFieldComparison>;
  type?: Maybe<ExperienceTypeFilterComparison>;
  mode?: Maybe<ExperienceModeFilterComparison>;
  locationType?: Maybe<ExperienceLocationTypeFilterComparison>;
  approximateLocation?: Maybe<PointObjectFilterComparison>;
  coordinates?: Maybe<PointObjectFilterComparison>;
  streetNumber?: Maybe<StringFieldComparison>;
  route?: Maybe<StringFieldComparison>;
  locality?: Maybe<StringFieldComparison>;
  administrativeAreaLevel2?: Maybe<StringFieldComparison>;
  administrativeAreaLevel1?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  postalCode?: Maybe<StringFieldComparison>;
};

export type ExperienceDeleteResponse = {
  __typename?: 'ExperienceDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  meetingScheduled?: Maybe<Scalars['Boolean']>;
  progressStep?: Maybe<ExperienceProgressStep>;
  content?: Maybe<ExperienceContent>;
  brainstorm?: Maybe<ExperienceBrainstorm>;
  images?: Maybe<Array<Image>>;
  host?: Maybe<User>;
  hostId?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Float']>;
  category?: Maybe<ExperienceCategory>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ExperienceStatus>;
  calendar?: Maybe<Calendar>;
  nextAvailability?: Maybe<Scalars['DateTime']>;
  nextEvent?: Maybe<Scalars['DateTime']>;
  next?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ExperienceType>;
  mode?: Maybe<ExperienceMode>;
  locationType?: Maybe<ExperienceLocationType>;
  approximateLocation?: Maybe<Point>;
  coordinates?: Maybe<Point>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']>;
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type ExperienceEdge = {
  __typename?: 'ExperienceEdge';
  /** The node containing the Experience */
  node: Experience;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ExperienceEventsConnection = {
  __typename?: 'ExperienceEventsConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<EventEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ExperienceExperienceTagsConnection = {
  __typename?: 'ExperienceExperienceTagsConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ExperienceTagEdge>;
};

export type ExperienceFilter = {
  and?: Maybe<Array<ExperienceFilter>>;
  or?: Maybe<Array<ExperienceFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  meetingScheduled?: Maybe<BooleanFieldComparison>;
  progressStep?: Maybe<ExperienceProgressStepFilterComparison>;
  hostId?: Maybe<StringFieldComparison>;
  video?: Maybe<StringFieldComparison>;
  featured?: Maybe<NumberFieldComparison>;
  category?: Maybe<ExperienceCategoryFilterComparison>;
  publishedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<ExperienceStatusFilterComparison>;
  nextAvailability?: Maybe<DateFieldComparison>;
  nextEvent?: Maybe<DateFieldComparison>;
  next?: Maybe<DateFieldComparison>;
  type?: Maybe<ExperienceTypeFilterComparison>;
  mode?: Maybe<ExperienceModeFilterComparison>;
  locationType?: Maybe<ExperienceLocationTypeFilterComparison>;
  approximateLocation?: Maybe<PointObjectFilterComparison>;
  coordinates?: Maybe<PointObjectFilterComparison>;
  streetNumber?: Maybe<StringFieldComparison>;
  route?: Maybe<StringFieldComparison>;
  locality?: Maybe<StringFieldComparison>;
  administrativeAreaLevel2?: Maybe<StringFieldComparison>;
  administrativeAreaLevel1?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  postalCode?: Maybe<StringFieldComparison>;
  events?: Maybe<ExperienceFilterEventFilter>;
  images?: Maybe<ExperienceFilterImageFilter>;
};

export type ExperienceFilterEventFilter = {
  and?: Maybe<Array<ExperienceFilterEventFilter>>;
  or?: Maybe<Array<ExperienceFilterEventFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  calendarId?: Maybe<StringFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  start?: Maybe<DateFieldComparison>;
  end?: Maybe<DateFieldComparison>;
  private?: Maybe<BooleanFieldComparison>;
  transparency?: Maybe<EventTransparencyFilterComparison>;
  status?: Maybe<EventStatusFilterComparison>;
  overrideLink?: Maybe<StringFieldComparison>;
};

export type ExperienceFilterImageFilter = {
  and?: Maybe<Array<ExperienceFilterImageFilter>>;
  or?: Maybe<Array<ExperienceFilterImageFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  order?: Maybe<NumberFieldComparison>;
};

export type ExperienceImagesConnection = {
  __typename?: 'ExperienceImagesConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ImageEdge>;
};

export type ExperienceLocationReportInput = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type ExperienceLocationReportObject = {
  __typename?: 'ExperienceLocationReportObject';
  locations: Array<ReportLocationObject>;
};

export enum ExperienceLocationType {
  Public = 'Public',
  Home = 'Home',
}

export type ExperienceLocationTypeFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<ExperienceLocationType>;
  neq?: Maybe<ExperienceLocationType>;
  gt?: Maybe<ExperienceLocationType>;
  gte?: Maybe<ExperienceLocationType>;
  lt?: Maybe<ExperienceLocationType>;
  lte?: Maybe<ExperienceLocationType>;
  like?: Maybe<ExperienceLocationType>;
  notLike?: Maybe<ExperienceLocationType>;
  iLike?: Maybe<ExperienceLocationType>;
  notILike?: Maybe<ExperienceLocationType>;
  in?: Maybe<Array<ExperienceLocationType>>;
  notIn?: Maybe<Array<ExperienceLocationType>>;
};

export type ExperienceMaxAggregate = {
  __typename?: 'ExperienceMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  progressStep?: Maybe<ExperienceProgressStep>;
  hostId?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Float']>;
  category?: Maybe<ExperienceCategory>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ExperienceStatus>;
  nextAvailability?: Maybe<Scalars['DateTime']>;
  nextEvent?: Maybe<Scalars['DateTime']>;
  next?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ExperienceType>;
  mode?: Maybe<ExperienceMode>;
  locationType?: Maybe<ExperienceLocationType>;
  approximateLocation?: Maybe<Point>;
  coordinates?: Maybe<Point>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']>;
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type ExperienceMinAggregate = {
  __typename?: 'ExperienceMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  progressStep?: Maybe<ExperienceProgressStep>;
  hostId?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Float']>;
  category?: Maybe<ExperienceCategory>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ExperienceStatus>;
  nextAvailability?: Maybe<Scalars['DateTime']>;
  nextEvent?: Maybe<Scalars['DateTime']>;
  next?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ExperienceType>;
  mode?: Maybe<ExperienceMode>;
  locationType?: Maybe<ExperienceLocationType>;
  approximateLocation?: Maybe<Point>;
  coordinates?: Maybe<Point>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']>;
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export enum ExperienceMode {
  Online = 'Online',
  InPerson = 'InPerson',
}

export type ExperienceModeFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<ExperienceMode>;
  neq?: Maybe<ExperienceMode>;
  gt?: Maybe<ExperienceMode>;
  gte?: Maybe<ExperienceMode>;
  lt?: Maybe<ExperienceMode>;
  lte?: Maybe<ExperienceMode>;
  like?: Maybe<ExperienceMode>;
  notLike?: Maybe<ExperienceMode>;
  iLike?: Maybe<ExperienceMode>;
  notILike?: Maybe<ExperienceMode>;
  in?: Maybe<Array<ExperienceMode>>;
  notIn?: Maybe<Array<ExperienceMode>>;
};

export enum ExperienceProgressStep {
  Intro = 'Intro',
  Edit = 'Edit',
  Booking = 'Booking',
  Brainstorm = 'Brainstorm',
  Inspiration = 'Inspiration',
  ThreeThings = 'ThreeThings',
  Interests = 'Interests',
  PartOfDay = 'PartOfDay',
  Hobby = 'Hobby',
  Final = 'Final',
  BrainstormIntro = 'BrainstormIntro',
}

export type ExperienceProgressStepFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<ExperienceProgressStep>;
  neq?: Maybe<ExperienceProgressStep>;
  gt?: Maybe<ExperienceProgressStep>;
  gte?: Maybe<ExperienceProgressStep>;
  lt?: Maybe<ExperienceProgressStep>;
  lte?: Maybe<ExperienceProgressStep>;
  like?: Maybe<ExperienceProgressStep>;
  notLike?: Maybe<ExperienceProgressStep>;
  iLike?: Maybe<ExperienceProgressStep>;
  notILike?: Maybe<ExperienceProgressStep>;
  in?: Maybe<Array<ExperienceProgressStep>>;
  notIn?: Maybe<Array<ExperienceProgressStep>>;
};

export type ExperienceSort = {
  field: ExperienceSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ExperienceSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Title = 'title',
  Slug = 'slug',
  MeetingScheduled = 'meetingScheduled',
  ProgressStep = 'progressStep',
  HostId = 'hostId',
  Video = 'video',
  Featured = 'featured',
  Category = 'category',
  PublishedAt = 'publishedAt',
  Status = 'status',
  NextAvailability = 'nextAvailability',
  NextEvent = 'nextEvent',
  Next = 'next',
  Type = 'type',
  Mode = 'mode',
  LocationType = 'locationType',
  ApproximateLocation = 'approximateLocation',
  Coordinates = 'coordinates',
  StreetNumber = 'streetNumber',
  Route = 'route',
  Locality = 'locality',
  AdministrativeAreaLevel2 = 'administrativeAreaLevel2',
  AdministrativeAreaLevel1 = 'administrativeAreaLevel1',
  Country = 'country',
  PostalCode = 'postalCode',
}

export enum ExperienceStatus {
  Publish = 'Publish',
  Private = 'Private',
  Draft = 'Draft',
  Trash = 'Trash',
  Unlisted = 'Unlisted',
  PendingReview = 'PendingReview',
}

export type ExperienceStatusFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<ExperienceStatus>;
  neq?: Maybe<ExperienceStatus>;
  gt?: Maybe<ExperienceStatus>;
  gte?: Maybe<ExperienceStatus>;
  lt?: Maybe<ExperienceStatus>;
  lte?: Maybe<ExperienceStatus>;
  like?: Maybe<ExperienceStatus>;
  notLike?: Maybe<ExperienceStatus>;
  iLike?: Maybe<ExperienceStatus>;
  notILike?: Maybe<ExperienceStatus>;
  in?: Maybe<Array<ExperienceStatus>>;
  notIn?: Maybe<Array<ExperienceStatus>>;
};

export type ExperienceSumAggregate = {
  __typename?: 'ExperienceSumAggregate';
  featured?: Maybe<Scalars['Float']>;
};

export type ExperienceTag = {
  __typename?: 'ExperienceTag';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  experience: Experience;
  tag: Tag;
};

export type ExperienceTagAggregateGroupBy = {
  __typename?: 'ExperienceTagAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExperienceTagConnection = {
  __typename?: 'ExperienceTagConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ExperienceTagEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ExperienceTagCountAggregate = {
  __typename?: 'ExperienceTagCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type ExperienceTagDeleteFilter = {
  and?: Maybe<Array<ExperienceTagDeleteFilter>>;
  or?: Maybe<Array<ExperienceTagDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type ExperienceTagDeleteResponse = {
  __typename?: 'ExperienceTagDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExperienceTagEdge = {
  __typename?: 'ExperienceTagEdge';
  /** The node containing the ExperienceTag */
  node: ExperienceTag;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ExperienceTagFilter = {
  and?: Maybe<Array<ExperienceTagFilter>>;
  or?: Maybe<Array<ExperienceTagFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  tag?: Maybe<ExperienceTagFilterTagFilter>;
  experience?: Maybe<ExperienceTagFilterExperienceFilter>;
};

export type ExperienceTagFilterExperienceFilter = {
  and?: Maybe<Array<ExperienceTagFilterExperienceFilter>>;
  or?: Maybe<Array<ExperienceTagFilterExperienceFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  meetingScheduled?: Maybe<BooleanFieldComparison>;
  progressStep?: Maybe<ExperienceProgressStepFilterComparison>;
  hostId?: Maybe<StringFieldComparison>;
  video?: Maybe<StringFieldComparison>;
  featured?: Maybe<NumberFieldComparison>;
  category?: Maybe<ExperienceCategoryFilterComparison>;
  publishedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<ExperienceStatusFilterComparison>;
  nextAvailability?: Maybe<DateFieldComparison>;
  nextEvent?: Maybe<DateFieldComparison>;
  next?: Maybe<DateFieldComparison>;
  type?: Maybe<ExperienceTypeFilterComparison>;
  mode?: Maybe<ExperienceModeFilterComparison>;
  locationType?: Maybe<ExperienceLocationTypeFilterComparison>;
  approximateLocation?: Maybe<PointObjectFilterComparison>;
  coordinates?: Maybe<PointObjectFilterComparison>;
  streetNumber?: Maybe<StringFieldComparison>;
  route?: Maybe<StringFieldComparison>;
  locality?: Maybe<StringFieldComparison>;
  administrativeAreaLevel2?: Maybe<StringFieldComparison>;
  administrativeAreaLevel1?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  postalCode?: Maybe<StringFieldComparison>;
};

export type ExperienceTagFilterTagFilter = {
  and?: Maybe<Array<ExperienceTagFilterTagFilter>>;
  or?: Maybe<Array<ExperienceTagFilterTagFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  category?: Maybe<TagCategoryFilterComparison>;
  public?: Maybe<BooleanFieldComparison>;
  description?: Maybe<StringFieldComparison>;
};

export type ExperienceTagMaxAggregate = {
  __typename?: 'ExperienceTagMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExperienceTagMinAggregate = {
  __typename?: 'ExperienceTagMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExperienceTagSort = {
  field: ExperienceTagSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ExperienceTagSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export type ExperienceTagUpdateFilter = {
  and?: Maybe<Array<ExperienceTagUpdateFilter>>;
  or?: Maybe<Array<ExperienceTagUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export enum ExperienceType {
  Event = 'Event',
  Video = 'Video',
  Schedule = 'Schedule',
  Box = 'Box',
  Undefined = 'Undefined',
}

export type ExperienceTypeFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<ExperienceType>;
  neq?: Maybe<ExperienceType>;
  gt?: Maybe<ExperienceType>;
  gte?: Maybe<ExperienceType>;
  lt?: Maybe<ExperienceType>;
  lte?: Maybe<ExperienceType>;
  like?: Maybe<ExperienceType>;
  notLike?: Maybe<ExperienceType>;
  iLike?: Maybe<ExperienceType>;
  notILike?: Maybe<ExperienceType>;
  in?: Maybe<Array<ExperienceType>>;
  notIn?: Maybe<Array<ExperienceType>>;
};

export type ExperienceUpdateFilter = {
  and?: Maybe<Array<ExperienceUpdateFilter>>;
  or?: Maybe<Array<ExperienceUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  meetingScheduled?: Maybe<BooleanFieldComparison>;
  progressStep?: Maybe<ExperienceProgressStepFilterComparison>;
  hostId?: Maybe<StringFieldComparison>;
  video?: Maybe<StringFieldComparison>;
  featured?: Maybe<NumberFieldComparison>;
  category?: Maybe<ExperienceCategoryFilterComparison>;
  publishedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<ExperienceStatusFilterComparison>;
  nextAvailability?: Maybe<DateFieldComparison>;
  nextEvent?: Maybe<DateFieldComparison>;
  next?: Maybe<DateFieldComparison>;
  type?: Maybe<ExperienceTypeFilterComparison>;
  mode?: Maybe<ExperienceModeFilterComparison>;
  locationType?: Maybe<ExperienceLocationTypeFilterComparison>;
  approximateLocation?: Maybe<PointObjectFilterComparison>;
  coordinates?: Maybe<PointObjectFilterComparison>;
  streetNumber?: Maybe<StringFieldComparison>;
  route?: Maybe<StringFieldComparison>;
  locality?: Maybe<StringFieldComparison>;
  administrativeAreaLevel2?: Maybe<StringFieldComparison>;
  administrativeAreaLevel1?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  postalCode?: Maybe<StringFieldComparison>;
};

export type Feedback = {
  __typename?: 'Feedback';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<FeedbackStatus>;
  content: FeedbackContent;
  createdBy: User;
  user: User;
  experience?: Maybe<Experience>;
  event?: Maybe<Event>;
};

export type FeedbackAggregateFilter = {
  and?: Maybe<Array<FeedbackAggregateFilter>>;
  or?: Maybe<Array<FeedbackAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<FeedbackStatusFilterComparison>;
  content?: Maybe<FeedbackContentObjectFilterComparison>;
};

export type FeedbackAggregateGroupBy = {
  __typename?: 'FeedbackAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<FeedbackStatus>;
  content?: Maybe<FeedbackContent>;
};

export type FeedbackConnection = {
  __typename?: 'FeedbackConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<FeedbackEdge>;
};

export type FeedbackContent = {
  __typename?: 'FeedbackContent';
  public?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['String']>;
};

export type FeedbackContentInput = {
  public?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['String']>;
};

export type FeedbackContentObjectFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<FeedbackContentInput>;
  neq?: Maybe<FeedbackContentInput>;
  gt?: Maybe<FeedbackContentInput>;
  gte?: Maybe<FeedbackContentInput>;
  lt?: Maybe<FeedbackContentInput>;
  lte?: Maybe<FeedbackContentInput>;
  like?: Maybe<FeedbackContentInput>;
  notLike?: Maybe<FeedbackContentInput>;
  iLike?: Maybe<FeedbackContentInput>;
  notILike?: Maybe<FeedbackContentInput>;
  in?: Maybe<Array<FeedbackContentInput>>;
  notIn?: Maybe<Array<FeedbackContentInput>>;
};

export type FeedbackCountAggregate = {
  __typename?: 'FeedbackCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['Int']>;
};

export type FeedbackDeleteFilter = {
  and?: Maybe<Array<FeedbackDeleteFilter>>;
  or?: Maybe<Array<FeedbackDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<FeedbackStatusFilterComparison>;
  content?: Maybe<FeedbackContentObjectFilterComparison>;
};

export type FeedbackDeleteResponse = {
  __typename?: 'FeedbackDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<FeedbackStatus>;
  content?: Maybe<FeedbackContent>;
};

export type FeedbackEdge = {
  __typename?: 'FeedbackEdge';
  /** The node containing the Feedback */
  node: Feedback;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type FeedbackFilter = {
  and?: Maybe<Array<FeedbackFilter>>;
  or?: Maybe<Array<FeedbackFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<FeedbackStatusFilterComparison>;
  content?: Maybe<FeedbackContentObjectFilterComparison>;
  event?: Maybe<FeedbackFilterEventFilter>;
  experience?: Maybe<FeedbackFilterExperienceFilter>;
  user?: Maybe<FeedbackFilterUserFilter>;
  createdBy?: Maybe<FeedbackFilterUserFilter>;
};

export type FeedbackFilterEventFilter = {
  and?: Maybe<Array<FeedbackFilterEventFilter>>;
  or?: Maybe<Array<FeedbackFilterEventFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  calendarId?: Maybe<StringFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  start?: Maybe<DateFieldComparison>;
  end?: Maybe<DateFieldComparison>;
  private?: Maybe<BooleanFieldComparison>;
  transparency?: Maybe<EventTransparencyFilterComparison>;
  status?: Maybe<EventStatusFilterComparison>;
  overrideLink?: Maybe<StringFieldComparison>;
};

export type FeedbackFilterExperienceFilter = {
  and?: Maybe<Array<FeedbackFilterExperienceFilter>>;
  or?: Maybe<Array<FeedbackFilterExperienceFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  meetingScheduled?: Maybe<BooleanFieldComparison>;
  progressStep?: Maybe<ExperienceProgressStepFilterComparison>;
  hostId?: Maybe<StringFieldComparison>;
  video?: Maybe<StringFieldComparison>;
  featured?: Maybe<NumberFieldComparison>;
  category?: Maybe<ExperienceCategoryFilterComparison>;
  publishedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<ExperienceStatusFilterComparison>;
  nextAvailability?: Maybe<DateFieldComparison>;
  nextEvent?: Maybe<DateFieldComparison>;
  next?: Maybe<DateFieldComparison>;
  type?: Maybe<ExperienceTypeFilterComparison>;
  mode?: Maybe<ExperienceModeFilterComparison>;
  locationType?: Maybe<ExperienceLocationTypeFilterComparison>;
  approximateLocation?: Maybe<PointObjectFilterComparison>;
  coordinates?: Maybe<PointObjectFilterComparison>;
  streetNumber?: Maybe<StringFieldComparison>;
  route?: Maybe<StringFieldComparison>;
  locality?: Maybe<StringFieldComparison>;
  administrativeAreaLevel2?: Maybe<StringFieldComparison>;
  administrativeAreaLevel1?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  postalCode?: Maybe<StringFieldComparison>;
};

export type FeedbackFilterUserFilter = {
  and?: Maybe<Array<FeedbackFilterUserFilter>>;
  or?: Maybe<Array<FeedbackFilterUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  emailVerified?: Maybe<BooleanFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  phoneVerified?: Maybe<BooleanFieldComparison>;
  username?: Maybe<StringFieldComparison>;
  firstName?: Maybe<StringFieldComparison>;
  lastName?: Maybe<StringFieldComparison>;
  nickname?: Maybe<StringFieldComparison>;
  avatar?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  continent?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  welcomeComplete?: Maybe<DateFieldComparison>;
  welcomeVetted?: Maybe<BooleanFieldComparison>;
  criminalRecordCheckDate?: Maybe<DateFieldComparison>;
  criminalRecordCheckValid?: Maybe<BooleanFieldComparison>;
  criminalRecordCheck?: Maybe<StringFieldComparison>;
  location?: Maybe<PointObjectFilterComparison>;
  dob?: Maybe<StringFieldComparison>;
  address?: Maybe<AddressObjectFilterComparison>;
};

export type FeedbackMaxAggregate = {
  __typename?: 'FeedbackMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<FeedbackStatus>;
  content?: Maybe<FeedbackContent>;
};

export type FeedbackMinAggregate = {
  __typename?: 'FeedbackMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<FeedbackStatus>;
  content?: Maybe<FeedbackContent>;
};

export type FeedbackSort = {
  field: FeedbackSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum FeedbackSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Status = 'status',
  Content = 'content',
}

export enum FeedbackStatus {
  Publish = 'Publish',
  Private = 'Private',
}

export type FeedbackStatusFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<FeedbackStatus>;
  neq?: Maybe<FeedbackStatus>;
  gt?: Maybe<FeedbackStatus>;
  gte?: Maybe<FeedbackStatus>;
  lt?: Maybe<FeedbackStatus>;
  lte?: Maybe<FeedbackStatus>;
  like?: Maybe<FeedbackStatus>;
  notLike?: Maybe<FeedbackStatus>;
  iLike?: Maybe<FeedbackStatus>;
  notILike?: Maybe<FeedbackStatus>;
  in?: Maybe<Array<FeedbackStatus>>;
  notIn?: Maybe<Array<FeedbackStatus>>;
};

export type FeedbackUpdateFilter = {
  and?: Maybe<Array<FeedbackUpdateFilter>>;
  or?: Maybe<Array<FeedbackUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<FeedbackStatusFilterComparison>;
  content?: Maybe<FeedbackContentObjectFilterComparison>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  mimetype: Scalars['String'];
  /** Only returned when performing upload via signed URL. */
  uploadUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  thumbnail: Scalars['String'];
  sources?: Maybe<Array<Source>>;
};

export type FileSourcesArgs = {
  sizes?: Maybe<Array<SourceSizeInput>>;
};

export type FileAggregateGroupBy = {
  __typename?: 'FileAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
};

export type FileConnection = {
  __typename?: 'FileConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<FileEdge>;
};

export type FileCountAggregate = {
  __typename?: 'FileCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Int']>;
};

export type FileDeleteFilter = {
  and?: Maybe<Array<FileDeleteFilter>>;
  or?: Maybe<Array<FileDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  key?: Maybe<StringFieldComparison>;
};

export type FileDeleteResponse = {
  __typename?: 'FileDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  /** Only returned when performing upload via signed URL. */
  uploadUrl?: Maybe<Scalars['String']>;
};

export type FileEdge = {
  __typename?: 'FileEdge';
  /** The node containing the File */
  node: File;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type FileFilter = {
  and?: Maybe<Array<FileFilter>>;
  or?: Maybe<Array<FileFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  key?: Maybe<StringFieldComparison>;
};

export type FileMaxAggregate = {
  __typename?: 'FileMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
};

export type FileMinAggregate = {
  __typename?: 'FileMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
};

export type FileSort = {
  field: FileSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum FileSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Key = 'key',
}

export type FileUpdateFilter = {
  and?: Maybe<Array<FileUpdateFilter>>;
  or?: Maybe<Array<FileUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  key?: Maybe<StringFieldComparison>;
};

export type GeneralReportInput = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type GeneralReportObject = {
  __typename?: 'GeneralReportObject';
  experiences: Array<Experience>;
  events: Array<Event>;
  attendees: Array<Attendee>;
  users: Array<User>;
  tags: Array<Tag>;
};

export type IdFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['ID']>;
  neq?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  like?: Maybe<Scalars['ID']>;
  notLike?: Maybe<Scalars['ID']>;
  iLike?: Maybe<Scalars['ID']>;
  notILike?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  notIn?: Maybe<Array<Scalars['ID']>>;
};

export type IoEmbedData = {
  title?: Maybe<Scalars['String']>;
  author_name?: Maybe<Scalars['String']>;
  author_url?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  provider_url?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  thumbnail_width?: Maybe<Scalars['Int']>;
  thumbnail_height?: Maybe<Scalars['Int']>;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  order: Scalars['Float'];
  experience?: Maybe<Experience>;
  file?: Maybe<File>;
};

export type ImageAggregateGroupBy = {
  __typename?: 'ImageAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Float']>;
};

export type ImageAvgAggregate = {
  __typename?: 'ImageAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type ImageConnection = {
  __typename?: 'ImageConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ImageEdge>;
};

export type ImageCountAggregate = {
  __typename?: 'ImageCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

export type ImageDeleteFilter = {
  and?: Maybe<Array<ImageDeleteFilter>>;
  or?: Maybe<Array<ImageDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  order?: Maybe<NumberFieldComparison>;
};

export type ImageDeleteResponse = {
  __typename?: 'ImageDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Float']>;
  experience?: Maybe<Experience>;
};

export type ImageEdge = {
  __typename?: 'ImageEdge';
  /** The node containing the Image */
  node: Image;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ImageFilter = {
  and?: Maybe<Array<ImageFilter>>;
  or?: Maybe<Array<ImageFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  order?: Maybe<NumberFieldComparison>;
  experience?: Maybe<ImageFilterExperienceFilter>;
  file?: Maybe<ImageFilterFileFilter>;
};

export type ImageFilterExperienceFilter = {
  and?: Maybe<Array<ImageFilterExperienceFilter>>;
  or?: Maybe<Array<ImageFilterExperienceFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  meetingScheduled?: Maybe<BooleanFieldComparison>;
  progressStep?: Maybe<ExperienceProgressStepFilterComparison>;
  hostId?: Maybe<StringFieldComparison>;
  video?: Maybe<StringFieldComparison>;
  featured?: Maybe<NumberFieldComparison>;
  category?: Maybe<ExperienceCategoryFilterComparison>;
  publishedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<ExperienceStatusFilterComparison>;
  nextAvailability?: Maybe<DateFieldComparison>;
  nextEvent?: Maybe<DateFieldComparison>;
  next?: Maybe<DateFieldComparison>;
  type?: Maybe<ExperienceTypeFilterComparison>;
  mode?: Maybe<ExperienceModeFilterComparison>;
  locationType?: Maybe<ExperienceLocationTypeFilterComparison>;
  approximateLocation?: Maybe<PointObjectFilterComparison>;
  coordinates?: Maybe<PointObjectFilterComparison>;
  streetNumber?: Maybe<StringFieldComparison>;
  route?: Maybe<StringFieldComparison>;
  locality?: Maybe<StringFieldComparison>;
  administrativeAreaLevel2?: Maybe<StringFieldComparison>;
  administrativeAreaLevel1?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  postalCode?: Maybe<StringFieldComparison>;
};

export type ImageFilterFileFilter = {
  and?: Maybe<Array<ImageFilterFileFilter>>;
  or?: Maybe<Array<ImageFilterFileFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  key?: Maybe<StringFieldComparison>;
};

export type ImageMaxAggregate = {
  __typename?: 'ImageMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Float']>;
};

export type ImageMinAggregate = {
  __typename?: 'ImageMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Float']>;
};

export type ImageSort = {
  field: ImageSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ImageSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Order = 'order',
}

export type ImageSumAggregate = {
  __typename?: 'ImageSumAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type ImageUpdateFilter = {
  and?: Maybe<Array<ImageUpdateFilter>>;
  or?: Maybe<Array<ImageUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  order?: Maybe<NumberFieldComparison>;
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  messageThreadId: Scalars['String'];
  userId: Scalars['String'];
  user: User;
  messageThread?: Maybe<MessageThread>;
  messageFlagging?: Maybe<MessageFlagging>;
  messageFlaggings?: Maybe<MessageMessageFlaggingsConnection>;
  messageFiles?: Maybe<MessageMessageFilesConnection>;
};

export type MessageMessageFlaggingsArgs = {
  paging?: CursorPaging;
  filter?: MessageFlaggingFilter;
  sorting?: Array<MessageFlaggingSort>;
};

export type MessageMessageFilesArgs = {
  paging?: CursorPaging;
  filter?: MessageFileFilter;
  sorting?: Array<MessageFileSort>;
};

export type MessageAggregateFilter = {
  and?: Maybe<Array<MessageAggregateFilter>>;
  or?: Maybe<Array<MessageAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  content?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  messageThreadId?: Maybe<StringFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
};

export type MessageAggregateGroupBy = {
  __typename?: 'MessageAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  messageThreadId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type MessageConnection = {
  __typename?: 'MessageConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<MessageEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type MessageCountAggregate = {
  __typename?: 'MessageCountAggregate';
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  messageThreadId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

export type MessageDeleteFilter = {
  and?: Maybe<Array<MessageDeleteFilter>>;
  or?: Maybe<Array<MessageDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  content?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  messageThreadId?: Maybe<StringFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
};

export type MessageDeleteResponse = {
  __typename?: 'MessageDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  messageThreadId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  /** The node containing the Message */
  node: Message;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type MessageFile = {
  __typename?: 'MessageFile';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  file?: Maybe<File>;
  message?: Maybe<Message>;
};

export type MessageFileAggregateGroupBy = {
  __typename?: 'MessageFileAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MessageFileConnection = {
  __typename?: 'MessageFileConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<MessageFileEdge>;
};

export type MessageFileCountAggregate = {
  __typename?: 'MessageFileCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type MessageFileDeleteFilter = {
  and?: Maybe<Array<MessageFileDeleteFilter>>;
  or?: Maybe<Array<MessageFileDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type MessageFileDeleteResponse = {
  __typename?: 'MessageFileDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MessageFileEdge = {
  __typename?: 'MessageFileEdge';
  /** The node containing the MessageFile */
  node: MessageFile;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type MessageFileFilter = {
  and?: Maybe<Array<MessageFileFilter>>;
  or?: Maybe<Array<MessageFileFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  message?: Maybe<MessageFileFilterMessageFilter>;
  file?: Maybe<MessageFileFilterFileFilter>;
};

export type MessageFileFilterFileFilter = {
  and?: Maybe<Array<MessageFileFilterFileFilter>>;
  or?: Maybe<Array<MessageFileFilterFileFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  key?: Maybe<StringFieldComparison>;
};

export type MessageFileFilterMessageFilter = {
  and?: Maybe<Array<MessageFileFilterMessageFilter>>;
  or?: Maybe<Array<MessageFileFilterMessageFilter>>;
  id?: Maybe<IdFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  content?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  messageThreadId?: Maybe<StringFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
};

export type MessageFileMaxAggregate = {
  __typename?: 'MessageFileMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MessageFileMinAggregate = {
  __typename?: 'MessageFileMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MessageFileSort = {
  field: MessageFileSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum MessageFileSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
}

export type MessageFileUpdateFilter = {
  and?: Maybe<Array<MessageFileUpdateFilter>>;
  or?: Maybe<Array<MessageFileUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type MessageFilter = {
  and?: Maybe<Array<MessageFilter>>;
  or?: Maybe<Array<MessageFilter>>;
  id?: Maybe<IdFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  content?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  messageThreadId?: Maybe<StringFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  messageFlagging?: Maybe<MessageFilterMessageFlaggingFilter>;
  messageThread?: Maybe<MessageFilterMessageThreadFilter>;
  user?: Maybe<MessageFilterUserFilter>;
  messageFiles?: Maybe<MessageFilterMessageFileFilter>;
};

export type MessageFilterMessageFileFilter = {
  and?: Maybe<Array<MessageFilterMessageFileFilter>>;
  or?: Maybe<Array<MessageFilterMessageFileFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type MessageFilterMessageFlaggingFilter = {
  and?: Maybe<Array<MessageFilterMessageFlaggingFilter>>;
  or?: Maybe<Array<MessageFilterMessageFlaggingFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<MessageFlaggingStatusFilterComparison>;
  userId?: Maybe<StringFieldComparison>;
  messageId?: Maybe<StringFieldComparison>;
};

export type MessageFilterMessageThreadFilter = {
  and?: Maybe<Array<MessageFilterMessageThreadFilter>>;
  or?: Maybe<Array<MessageFilterMessageThreadFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  lastMessageAt?: Maybe<DateFieldComparison>;
};

export type MessageFilterUserFilter = {
  and?: Maybe<Array<MessageFilterUserFilter>>;
  or?: Maybe<Array<MessageFilterUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  emailVerified?: Maybe<BooleanFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  phoneVerified?: Maybe<BooleanFieldComparison>;
  username?: Maybe<StringFieldComparison>;
  firstName?: Maybe<StringFieldComparison>;
  lastName?: Maybe<StringFieldComparison>;
  nickname?: Maybe<StringFieldComparison>;
  avatar?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  continent?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  welcomeComplete?: Maybe<DateFieldComparison>;
  welcomeVetted?: Maybe<BooleanFieldComparison>;
  criminalRecordCheckDate?: Maybe<DateFieldComparison>;
  criminalRecordCheckValid?: Maybe<BooleanFieldComparison>;
  criminalRecordCheck?: Maybe<StringFieldComparison>;
  location?: Maybe<PointObjectFilterComparison>;
  dob?: Maybe<StringFieldComparison>;
  address?: Maybe<AddressObjectFilterComparison>;
};

export type MessageFlagging = {
  __typename?: 'MessageFlagging';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: MessageFlaggingStatus;
  userId: Scalars['String'];
  messageId: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  messages: MessageFlaggingMessagesConnection;
  users: MessageFlaggingUsersConnection;
};

export type MessageFlaggingMessagesArgs = {
  paging?: CursorPaging;
  filter?: MessageFilter;
  sorting?: Array<MessageSort>;
};

export type MessageFlaggingUsersArgs = {
  paging?: CursorPaging;
  filter?: UserFilter;
  sorting?: Array<UserSort>;
};

export type MessageFlaggingAggregateGroupBy = {
  __typename?: 'MessageFlaggingAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MessageFlaggingStatus>;
  userId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
};

export type MessageFlaggingConnection = {
  __typename?: 'MessageFlaggingConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<MessageFlaggingEdge>;
};

export type MessageFlaggingCountAggregate = {
  __typename?: 'MessageFlaggingCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  messageId?: Maybe<Scalars['Int']>;
};

export type MessageFlaggingDeleteFilter = {
  and?: Maybe<Array<MessageFlaggingDeleteFilter>>;
  or?: Maybe<Array<MessageFlaggingDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<MessageFlaggingStatusFilterComparison>;
  userId?: Maybe<StringFieldComparison>;
  messageId?: Maybe<StringFieldComparison>;
};

export type MessageFlaggingDeleteResponse = {
  __typename?: 'MessageFlaggingDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MessageFlaggingStatus>;
  userId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export type MessageFlaggingEdge = {
  __typename?: 'MessageFlaggingEdge';
  /** The node containing the MessageFlagging */
  node: MessageFlagging;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type MessageFlaggingFilter = {
  and?: Maybe<Array<MessageFlaggingFilter>>;
  or?: Maybe<Array<MessageFlaggingFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<MessageFlaggingStatusFilterComparison>;
  userId?: Maybe<StringFieldComparison>;
  messageId?: Maybe<StringFieldComparison>;
  user?: Maybe<MessageFlaggingFilterUserFilter>;
  messages?: Maybe<MessageFlaggingFilterMessageFilter>;
};

export type MessageFlaggingFilterMessageFilter = {
  and?: Maybe<Array<MessageFlaggingFilterMessageFilter>>;
  or?: Maybe<Array<MessageFlaggingFilterMessageFilter>>;
  id?: Maybe<IdFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  content?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  messageThreadId?: Maybe<StringFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
};

export type MessageFlaggingFilterUserFilter = {
  and?: Maybe<Array<MessageFlaggingFilterUserFilter>>;
  or?: Maybe<Array<MessageFlaggingFilterUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  emailVerified?: Maybe<BooleanFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  phoneVerified?: Maybe<BooleanFieldComparison>;
  username?: Maybe<StringFieldComparison>;
  firstName?: Maybe<StringFieldComparison>;
  lastName?: Maybe<StringFieldComparison>;
  nickname?: Maybe<StringFieldComparison>;
  avatar?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  continent?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  welcomeComplete?: Maybe<DateFieldComparison>;
  welcomeVetted?: Maybe<BooleanFieldComparison>;
  criminalRecordCheckDate?: Maybe<DateFieldComparison>;
  criminalRecordCheckValid?: Maybe<BooleanFieldComparison>;
  criminalRecordCheck?: Maybe<StringFieldComparison>;
  location?: Maybe<PointObjectFilterComparison>;
  dob?: Maybe<StringFieldComparison>;
  address?: Maybe<AddressObjectFilterComparison>;
};

export type MessageFlaggingMaxAggregate = {
  __typename?: 'MessageFlaggingMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MessageFlaggingStatus>;
  userId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
};

export type MessageFlaggingMessagesConnection = {
  __typename?: 'MessageFlaggingMessagesConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<MessageEdge>;
};

export type MessageFlaggingMinAggregate = {
  __typename?: 'MessageFlaggingMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MessageFlaggingStatus>;
  userId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
};

export type MessageFlaggingSort = {
  field: MessageFlaggingSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum MessageFlaggingSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  UserId = 'userId',
  MessageId = 'messageId',
}

/** Status for flagged message */
export enum MessageFlaggingStatus {
  Flagged = 'flagged',
  Resolved = 'resolved',
}

export type MessageFlaggingStatusFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<MessageFlaggingStatus>;
  neq?: Maybe<MessageFlaggingStatus>;
  gt?: Maybe<MessageFlaggingStatus>;
  gte?: Maybe<MessageFlaggingStatus>;
  lt?: Maybe<MessageFlaggingStatus>;
  lte?: Maybe<MessageFlaggingStatus>;
  like?: Maybe<MessageFlaggingStatus>;
  notLike?: Maybe<MessageFlaggingStatus>;
  iLike?: Maybe<MessageFlaggingStatus>;
  notILike?: Maybe<MessageFlaggingStatus>;
  in?: Maybe<Array<MessageFlaggingStatus>>;
  notIn?: Maybe<Array<MessageFlaggingStatus>>;
};

export type MessageFlaggingUpdateFilter = {
  and?: Maybe<Array<MessageFlaggingUpdateFilter>>;
  or?: Maybe<Array<MessageFlaggingUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<MessageFlaggingStatusFilterComparison>;
  userId?: Maybe<StringFieldComparison>;
  messageId?: Maybe<StringFieldComparison>;
};

export type MessageFlaggingUsersConnection = {
  __typename?: 'MessageFlaggingUsersConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<UserEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type MessageMaxAggregate = {
  __typename?: 'MessageMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  messageThreadId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type MessageMessageFilesConnection = {
  __typename?: 'MessageMessageFilesConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<MessageFileEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type MessageMessageFlaggingsConnection = {
  __typename?: 'MessageMessageFlaggingsConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<MessageFlaggingEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type MessageMinAggregate = {
  __typename?: 'MessageMinAggregate';
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  messageThreadId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type MessageSort = {
  field: MessageSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum MessageSortFields {
  Id = 'id',
  UpdatedAt = 'updatedAt',
  Content = 'content',
  CreatedAt = 'createdAt',
  MessageThreadId = 'messageThreadId',
  UserId = 'userId',
}

export type MessageSubscriptionFilter = {
  and?: Maybe<Array<MessageSubscriptionFilter>>;
  or?: Maybe<Array<MessageSubscriptionFilter>>;
  id?: Maybe<IdFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  content?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  messageThreadId?: Maybe<StringFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
};

export type MessageThread = {
  __typename?: 'MessageThread';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  messagesAggregate: Array<MessageThreadMessagesAggregateResponse>;
  threadParticipantsAggregate: Array<MessageThreadThreadParticipantsAggregateResponse>;
  messages?: Maybe<MessageThreadMessagesConnection>;
  threadParticipants?: Maybe<MessageThreadThreadParticipantsConnection>;
};

export type MessageThreadMessagesAggregateArgs = {
  filter?: Maybe<MessageAggregateFilter>;
};

export type MessageThreadThreadParticipantsAggregateArgs = {
  filter?: Maybe<ThreadParticipantsAggregateFilter>;
};

export type MessageThreadMessagesArgs = {
  paging?: CursorPaging;
  filter?: MessageFilter;
  sorting?: Array<MessageSort>;
};

export type MessageThreadThreadParticipantsArgs = {
  paging?: CursorPaging;
  filter?: ThreadParticipantsFilter;
  sorting?: Array<ThreadParticipantsSort>;
};

export type MessageThreadAggregateFilter = {
  and?: Maybe<Array<MessageThreadAggregateFilter>>;
  or?: Maybe<Array<MessageThreadAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  lastMessageAt?: Maybe<DateFieldComparison>;
};

export type MessageThreadAggregateGroupBy = {
  __typename?: 'MessageThreadAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
};

export type MessageThreadAggregateResponse = {
  __typename?: 'MessageThreadAggregateResponse';
  groupBy?: Maybe<MessageThreadAggregateGroupBy>;
  count?: Maybe<MessageThreadCountAggregate>;
  min?: Maybe<MessageThreadMinAggregate>;
  max?: Maybe<MessageThreadMaxAggregate>;
};

export type MessageThreadConnection = {
  __typename?: 'MessageThreadConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<MessageThreadEdge>;
};

export type MessageThreadCountAggregate = {
  __typename?: 'MessageThreadCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  lastMessageAt?: Maybe<Scalars['Int']>;
};

export type MessageThreadDeleteFilter = {
  and?: Maybe<Array<MessageThreadDeleteFilter>>;
  or?: Maybe<Array<MessageThreadDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  lastMessageAt?: Maybe<DateFieldComparison>;
};

export type MessageThreadDeleteResponse = {
  __typename?: 'MessageThreadDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
};

export type MessageThreadEdge = {
  __typename?: 'MessageThreadEdge';
  /** The node containing the MessageThread */
  node: MessageThread;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type MessageThreadFilter = {
  and?: Maybe<Array<MessageThreadFilter>>;
  or?: Maybe<Array<MessageThreadFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  lastMessageAt?: Maybe<DateFieldComparison>;
  threadParticipants?: Maybe<MessageThreadFilterThreadParticipantsFilter>;
  messages?: Maybe<MessageThreadFilterMessageFilter>;
};

export type MessageThreadFilterMessageFilter = {
  and?: Maybe<Array<MessageThreadFilterMessageFilter>>;
  or?: Maybe<Array<MessageThreadFilterMessageFilter>>;
  id?: Maybe<IdFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  content?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  messageThreadId?: Maybe<StringFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
};

export type MessageThreadFilterThreadParticipantsFilter = {
  and?: Maybe<Array<MessageThreadFilterThreadParticipantsFilter>>;
  or?: Maybe<Array<MessageThreadFilterThreadParticipantsFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  unread?: Maybe<BooleanFieldComparison>;
  messageThreadId?: Maybe<StringFieldComparison>;
  lastMessageAt?: Maybe<DateFieldComparison>;
};

export type MessageThreadMaxAggregate = {
  __typename?: 'MessageThreadMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
};

export type MessageThreadMessagesAggregateGroupBy = {
  __typename?: 'MessageThreadMessagesAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  messageThreadId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type MessageThreadMessagesAggregateResponse = {
  __typename?: 'MessageThreadMessagesAggregateResponse';
  groupBy?: Maybe<MessageThreadMessagesAggregateGroupBy>;
  count?: Maybe<MessageThreadMessagesCountAggregate>;
  min?: Maybe<MessageThreadMessagesMinAggregate>;
  max?: Maybe<MessageThreadMessagesMaxAggregate>;
};

export type MessageThreadMessagesConnection = {
  __typename?: 'MessageThreadMessagesConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<MessageEdge>;
};

export type MessageThreadMessagesCountAggregate = {
  __typename?: 'MessageThreadMessagesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  messageThreadId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

export type MessageThreadMessagesMaxAggregate = {
  __typename?: 'MessageThreadMessagesMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  messageThreadId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type MessageThreadMessagesMinAggregate = {
  __typename?: 'MessageThreadMessagesMinAggregate';
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  messageThreadId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type MessageThreadMinAggregate = {
  __typename?: 'MessageThreadMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
};

export type MessageThreadSort = {
  field: MessageThreadSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum MessageThreadSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Name = 'name',
  LastMessageAt = 'lastMessageAt',
}

export type MessageThreadSubscriptionFilter = {
  and?: Maybe<Array<MessageThreadSubscriptionFilter>>;
  or?: Maybe<Array<MessageThreadSubscriptionFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  lastMessageAt?: Maybe<DateFieldComparison>;
};

export type MessageThreadThreadParticipantsAggregateGroupBy = {
  __typename?: 'MessageThreadThreadParticipantsAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  unread?: Maybe<Scalars['Boolean']>;
  messageThreadId?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
};

export type MessageThreadThreadParticipantsAggregateResponse = {
  __typename?: 'MessageThreadThreadParticipantsAggregateResponse';
  groupBy?: Maybe<MessageThreadThreadParticipantsAggregateGroupBy>;
  count?: Maybe<MessageThreadThreadParticipantsCountAggregate>;
  min?: Maybe<MessageThreadThreadParticipantsMinAggregate>;
  max?: Maybe<MessageThreadThreadParticipantsMaxAggregate>;
};

export type MessageThreadThreadParticipantsConnection = {
  __typename?: 'MessageThreadThreadParticipantsConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ThreadParticipantsEdge>;
};

export type MessageThreadThreadParticipantsCountAggregate = {
  __typename?: 'MessageThreadThreadParticipantsCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  unread?: Maybe<Scalars['Int']>;
  messageThreadId?: Maybe<Scalars['Int']>;
  lastMessageAt?: Maybe<Scalars['Int']>;
};

export type MessageThreadThreadParticipantsMaxAggregate = {
  __typename?: 'MessageThreadThreadParticipantsMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  messageThreadId?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
};

export type MessageThreadThreadParticipantsMinAggregate = {
  __typename?: 'MessageThreadThreadParticipantsMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  messageThreadId?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
};

export type MessageThreadUpdateFilter = {
  and?: Maybe<Array<MessageThreadUpdateFilter>>;
  or?: Maybe<Array<MessageThreadUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  lastMessageAt?: Maybe<DateFieldComparison>;
};

export type MessageUpdateFilter = {
  and?: Maybe<Array<MessageUpdateFilter>>;
  or?: Maybe<Array<MessageUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  content?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  messageThreadId?: Maybe<StringFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOneFile: File;
  createManyFiles: Array<File>;
  updateOneFile: File;
  updateManyFiles: UpdateManyResponse;
  deleteOneFile: FileDeleteResponse;
  deleteManyFiles: DeleteManyResponse;
  restoreOneFile: File;
  restoreManyFile: UpdateManyResponse;
  upsertAuth: Auth;
  setUserOnAuth: Auth;
  removeUserFromAuth: Auth;
  createOneAuth: Auth;
  createManyAuths: Array<Auth>;
  updateOneAuth: Auth;
  updateManyAuths: UpdateManyResponse;
  deleteOneAuth: AuthDeleteResponse;
  deleteManyAuths: DeleteManyResponse;
  restoreOneAuth: Auth;
  restoreManyAuths: UpdateManyResponse;
  setProfileOnUser: User;
  setZoomUserOnUser: User;
  addFeedbacksToUser: User;
  setFeedbacksOnUser: User;
  addExperiencesToUser: User;
  setExperiencesOnUser: User;
  removeProfileFromUser: User;
  removeZoomUserFromUser: User;
  removeFeedbacksFromUser: User;
  removeExperiencesFromUser: User;
  createOneUser: User;
  createManyUsers: Array<User>;
  updateOneUser: User;
  updateManyUsers: UpdateManyResponse;
  deleteOneUser: UserDeleteResponse;
  deleteManyUsers: DeleteManyResponse;
  restoreOneUser: User;
  restoreManyUsers: UpdateManyResponse;
  requestBooking: Event;
  rescheduleBooking: Event;
  cancelBooking: Event;
  confirmBooking: Event;
  setCalendarOnEvent: Event;
  addAttendeesToEvent: Event;
  setAttendeesOnEvent: Event;
  removeCalendarFromEvent: Event;
  removeAttendeesFromEvent: Event;
  createOneEvent: Event;
  createManyEvents: Array<Event>;
  updateOneEvent: Event;
  updateManyEvents: UpdateManyResponse;
  deleteOneEvent: EventDeleteResponse;
  deleteManyEvents: DeleteManyResponse;
  restoreOneEvent: Event;
  restoreManyEvents: UpdateManyResponse;
  setUserOnZoomUser: ZoomUser;
  addMeetingsToZoomUser: ZoomUser;
  setMeetingsOnZoomUser: ZoomUser;
  removeUserFromZoomUser: ZoomUser;
  removeMeetingsFromZoomUser: ZoomUser;
  createOneZoomUser: ZoomUser;
  createManyZoomUsers: Array<ZoomUser>;
  updateOneZoomUser: ZoomUser;
  updateManyZoomUsers: UpdateManyResponse;
  deleteOneZoomUser: ZoomUserDeleteResponse;
  deleteManyZoomUsers: DeleteManyResponse;
  setZoomUserOnZoomMeeting: ZoomMeeting;
  removeZoomUserFromZoomMeeting: ZoomMeeting;
  createOneZoomMeeting: ZoomMeeting;
  createManyZoomMeetings: Array<ZoomMeeting>;
  updateOneZoomMeeting: ZoomMeeting;
  updateManyZoomMeetings: UpdateManyResponse;
  deleteOneZoomMeeting: ZoomMeetingDeleteResponse;
  deleteManyZoomMeetings: DeleteManyResponse;
  setUserOnAttendee: Attendee;
  setEventOnAttendee: Attendee;
  removeUserFromAttendee: Attendee;
  removeEventFromAttendee: Attendee;
  createOneAttendee: Attendee;
  createManyAttendees: Array<Attendee>;
  updateOneAttendee: Attendee;
  updateManyAttendees: UpdateManyResponse;
  deleteOneAttendee: AttendeeDeleteResponse;
  deleteManyAttendees: DeleteManyResponse;
  restoreOneAttendee: Attendee;
  restoreManyAttendees: UpdateManyResponse;
  setScheduleOnCalendar: Calendar;
  setExperienceOnCalendar: Calendar;
  addEventsToCalendar: Calendar;
  setEventsOnCalendar: Calendar;
  removeScheduleFromCalendar: Calendar;
  removeExperienceFromCalendar: Calendar;
  removeEventsFromCalendar: Calendar;
  createOneCalendar: Calendar;
  createManyCalendars: Array<Calendar>;
  updateOneCalendar: Calendar;
  updateManyCalendars: UpdateManyResponse;
  deleteOneCalendar: CalendarDeleteResponse;
  deleteManyCalendars: DeleteManyResponse;
  restoreOneCalendar: Calendar;
  restoreManyCalendars: UpdateManyResponse;
  setUserOnProfile: Profile;
  addImagesToProfile: Profile;
  setImagesOnProfile: Profile;
  addInterestsToProfile: Profile;
  setInterestsOnProfile: Profile;
  addStrengthsToProfile: Profile;
  setStrengthsOnProfile: Profile;
  removeUserFromProfile: Profile;
  removeImagesFromProfile: Profile;
  removeInterestsFromProfile: Profile;
  removeStrengthsFromProfile: Profile;
  createOneProfile: Profile;
  createManyProfiles: Array<Profile>;
  updateOneProfile: Profile;
  updateManyProfiles: UpdateManyResponse;
  deleteOneProfile: ProfileDeleteResponse;
  deleteManyProfiles: DeleteManyResponse;
  restoreOneProfile: Profile;
  restoreManyProfiles: UpdateManyResponse;
  updateAvatar?: Maybe<File>;
  uploadImage: File;
  updateAvatarWithSignedUrl?: Maybe<File>;
  updateOwnProfileInterests?: Maybe<Profile>;
  updateOwnProfileStrengths?: Maybe<Profile>;
  createOneProfileInterest: ProfileInterest;
  createManyProfileInterests: Array<ProfileInterest>;
  updateOneProfileInterest: ProfileInterest;
  updateManyProfileInterests: UpdateManyResponse;
  deleteOneProfileInterest: ProfileInterestDeleteResponse;
  deleteManyProfileInterests: DeleteManyResponse;
  restoreOneProfileInterest: ProfileInterest;
  restoreManyProfileInterests: UpdateManyResponse;
  createOneProfileStrength: ProfileStrength;
  createManyProfileStrengths: Array<ProfileStrength>;
  updateOneProfileStrength: ProfileStrength;
  updateManyProfileStrengths: UpdateManyResponse;
  deleteOneProfileStrength: ProfileStrengthDeleteResponse;
  deleteManyProfileStrengths: DeleteManyResponse;
  restoreOneProfileStrength: ProfileStrength;
  restoreManyProfileStrengths: UpdateManyResponse;
  sortProfileImages: UpdateManyResponse;
  setFileOnProfileImage: ProfileImage;
  setProfileOnProfileImage: ProfileImage;
  removeFileFromProfileImage: ProfileImage;
  removeProfileFromProfileImage: ProfileImage;
  createOneProfileImage: ProfileImage;
  createManyProfileImages: Array<ProfileImage>;
  updateOneProfileImage: ProfileImage;
  updateManyProfileImages: UpdateManyResponse;
  deleteOneProfileImage: ProfileImageDeleteResponse;
  deleteManyProfileImages: DeleteManyResponse;
  restoreOneProfileImage: ProfileImage;
  restoreManyProfileImages: UpdateManyResponse;
  createOneOEmbed: OEmbed;
  createManyOEmbeds: Array<OEmbed>;
  updateOneOEmbed: OEmbed;
  updateManyOEmbeds: UpdateManyResponse;
  deleteOneOEmbed: OEmbedDeleteResponse;
  deleteManyOEmbeds: DeleteManyResponse;
  restoreOneOEmbed: OEmbed;
  restoreManyOEmbeds: UpdateManyResponse;
  setCalendarOnExperience: Experience;
  addImagesToExperience: Experience;
  setImagesOnExperience: Experience;
  addExperienceTagsToExperience: Experience;
  setExperienceTagsOnExperience: Experience;
  removeCalendarFromExperience: Experience;
  removeImagesFromExperience: Experience;
  removeExperienceTagsFromExperience: Experience;
  createOneExperience: Experience;
  createManyExperiences: Array<Experience>;
  updateOneExperience: Experience;
  updateManyExperiences: UpdateManyResponse;
  deleteOneExperience: ExperienceDeleteResponse;
  deleteManyExperiences: DeleteManyResponse;
  restoreOneExperience: Experience;
  restoreManyExperiences: UpdateManyResponse;
  uploadOneExperienceImage: Image;
  updateCoverImage: Image;
  deleteOneExperienceImage: Image;
  setFileOnImage: Image;
  setExperienceOnImage: Image;
  removeFileFromImage: Image;
  removeExperienceFromImage: Image;
  createOneImage: Image;
  createManyImages: Array<Image>;
  updateOneImage: Image;
  updateManyImages: UpdateManyResponse;
  deleteOneImage: ImageDeleteResponse;
  deleteManyImages: DeleteManyResponse;
  restoreOneImage: Image;
  restoreManyImages: UpdateManyResponse;
  addTimeslotsToSchedule: Schedule;
  setTimeslotsOnSchedule: Schedule;
  removeTimeslotsFromSchedule: Schedule;
  createOneSchedule: Schedule;
  createManySchedules: Array<Schedule>;
  updateOneSchedule: Schedule;
  updateManySchedules: UpdateManyResponse;
  deleteOneSchedule: ScheduleDeleteResponse;
  deleteManySchedules: DeleteManyResponse;
  restoreOneSchedule: Schedule;
  restoreManySchedules: UpdateManyResponse;
  createOneTimeslot: Timeslot;
  createManyTimeslots: Array<Timeslot>;
  updateOneTimeslot: Timeslot;
  updateManyTimeslots: UpdateManyResponse;
  deleteOneTimeslot: TimeslotDeleteResponse;
  deleteManyTimeslots: DeleteManyResponse;
  restoreOneTimeslot: Timeslot;
  restoreManyTimeslots: UpdateManyResponse;
  setUserOnOrder: Order;
  setExperienceOnOrder: Order;
  removeUserFromOrder: Order;
  removeExperienceFromOrder: Order;
  createOneOrder: Order;
  createManyOrders: Array<Order>;
  updateOneOrder: Order;
  updateManyOrders: UpdateManyResponse;
  deleteOneOrder: OrderDeleteResponse;
  deleteManyOrders: DeleteManyResponse;
  restoreOneOrder: Order;
  restoreManyOrders: UpdateManyResponse;
  createStripeCheckoutSession: StripeCheckoutSession;
  setUserOnStripeCustomer: StripeCustomer;
  removeUserFromStripeCustomer: StripeCustomer;
  createOneStripeCustomer: StripeCustomer;
  createManyStripeCustomers: Array<StripeCustomer>;
  updateOneStripeCustomer: StripeCustomer;
  updateManyStripeCustomers: UpdateManyResponse;
  deleteOneStripeCustomer: StripeCustomerDeleteResponse;
  deleteManyStripeCustomers: DeleteManyResponse;
  setExperienceOnStripeProduct: StripeProduct;
  removeExperienceFromStripeProduct: StripeProduct;
  createOneStripeProduct: StripeProduct;
  createManyStripeProducts: Array<StripeProduct>;
  updateOneStripeProduct: StripeProduct;
  updateManyStripeProducts: UpdateManyResponse;
  deleteOneStripeProduct: StripeProductDeleteResponse;
  deleteManyStripeProducts: DeleteManyResponse;
  createOneNotification: Notification;
  createManyNotifications: Array<Notification>;
  updateOneNotification: Notification;
  updateManyNotifications: UpdateManyResponse;
  deleteOneNotification: NotificationDeleteResponse;
  deleteManyNotifications: DeleteManyResponse;
  restoreOneNotification: Notification;
  restoreManyNotifications: UpdateManyResponse;
  setCreatedByOnFeedback: Feedback;
  setUserOnFeedback: Feedback;
  setExperienceOnFeedback: Feedback;
  setEventOnFeedback: Feedback;
  removeCreatedByFromFeedback: Feedback;
  removeUserFromFeedback: Feedback;
  removeExperienceFromFeedback: Feedback;
  removeEventFromFeedback: Feedback;
  createOneFeedback: Feedback;
  createManyFeedbacks: Array<Feedback>;
  updateOneFeedback: Feedback;
  updateManyFeedbacks: UpdateManyResponse;
  deleteOneFeedback: FeedbackDeleteResponse;
  deleteManyFeedbacks: DeleteManyResponse;
  restoreOneFeedback: Feedback;
  restoreManyFeedbacks: UpdateManyResponse;
  setUserOnMessage: Message;
  setMessageThreadOnMessage: Message;
  setMessageFlaggingOnMessage: Message;
  addMessageFlaggingsToMessage: Message;
  setMessageFlaggingsOnMessage: Message;
  addMessageFilesToMessage: Message;
  setMessageFilesOnMessage: Message;
  removeUserFromMessage: Message;
  removeMessageThreadFromMessage: Message;
  removeMessageFlaggingFromMessage: Message;
  removeMessageFlaggingsFromMessage: Message;
  removeMessageFilesFromMessage: Message;
  createOneMessage: Message;
  updateOneMessage: Message;
  updateManyMessages: UpdateManyResponse;
  deleteOneMessage: MessageDeleteResponse;
  deleteManyMessages: DeleteManyResponse;
  uploadMessageFile: File;
  createMessageForFiles: Message;
  createMessageThread: MessageThread;
  checkIfThreadExists?: Maybe<ThreadParticipants>;
  addMessagesToMessageThread: MessageThread;
  setMessagesOnMessageThread: MessageThread;
  addThreadParticipantsToMessageThread: MessageThread;
  setThreadParticipantsOnMessageThread: MessageThread;
  removeMessagesFromMessageThread: MessageThread;
  removeThreadParticipantsFromMessageThread: MessageThread;
  createOneMessageThread: MessageThread;
  createManyMessageThreads: Array<MessageThread>;
  updateOneMessageThread: MessageThread;
  updateManyMessageThreads: UpdateManyResponse;
  deleteOneMessageThread: MessageThreadDeleteResponse;
  deleteManyMessageThreads: DeleteManyResponse;
  setMessageThreadOnThreadParticipants: ThreadParticipants;
  setUserOnThreadParticipants: ThreadParticipants;
  addUsersToThreadParticipants: ThreadParticipants;
  setUsersOnThreadParticipants: ThreadParticipants;
  removeMessageThreadFromThreadParticipants: ThreadParticipants;
  removeUserFromThreadParticipants: ThreadParticipants;
  removeUsersFromThreadParticipants: ThreadParticipants;
  createOneThreadParticipants: ThreadParticipants;
  createManyThreadParticipants: Array<ThreadParticipants>;
  updateOneThreadParticipants: ThreadParticipants;
  updateManyThreadParticipants: UpdateManyResponse;
  deleteOneThreadParticipants: ThreadParticipantsDeleteResponse;
  deleteManyThreadParticipants: DeleteManyResponse;
  setFileOnMessageFile: MessageFile;
  setMessageOnMessageFile: MessageFile;
  removeFileFromMessageFile: MessageFile;
  removeMessageFromMessageFile: MessageFile;
  createOneMessageFile: MessageFile;
  createManyMessageFiles: Array<MessageFile>;
  updateOneMessageFile: MessageFile;
  updateManyMessageFiles: UpdateManyResponse;
  deleteOneMessageFile: MessageFileDeleteResponse;
  deleteManyMessageFiles: DeleteManyResponse;
  addMessagesToMessageFlagging: MessageFlagging;
  setMessagesOnMessageFlagging: MessageFlagging;
  removeMessagesFromMessageFlagging: MessageFlagging;
  createOneMessageFlagging: MessageFlagging;
  createManyMessageFlaggings: Array<MessageFlagging>;
  updateOneMessageFlagging: MessageFlagging;
  updateManyMessageFlaggings: UpdateManyResponse;
  deleteOneMessageFlagging: MessageFlaggingDeleteResponse;
  deleteManyMessageFlaggings: DeleteManyResponse;
  uploadIconFile: File;
  setIconOnTag: Tag;
  addExperienceTagsToTag: Tag;
  setExperienceTagsOnTag: Tag;
  removeIconFromTag: Tag;
  removeExperienceTagsFromTag: Tag;
  createOneTag: Tag;
  updateOneTag: Tag;
  updateManyTags: UpdateManyResponse;
  deleteOneTag: TagDeleteResponse;
  deleteManyTags: DeleteManyResponse;
  setExperienceOnExperienceTag: ExperienceTag;
  setTagOnExperienceTag: ExperienceTag;
  removeExperienceFromExperienceTag: ExperienceTag;
  removeTagFromExperienceTag: ExperienceTag;
  createOneExperienceTag: ExperienceTag;
  createManyExperienceTags: Array<ExperienceTag>;
  updateOneExperienceTag: ExperienceTag;
  updateManyExperienceTags: UpdateManyResponse;
  deleteOneExperienceTag: ExperienceTagDeleteResponse;
  deleteManyExperienceTags: DeleteManyResponse;
};

export type MutationCreateOneFileArgs = {
  input: CreateOneFileInput;
};

export type MutationCreateManyFilesArgs = {
  input: CreateManyFilesInput;
};

export type MutationUpdateOneFileArgs = {
  input: UpdateOneFileInput;
};

export type MutationUpdateManyFilesArgs = {
  input: UpdateManyFilesInput;
};

export type MutationDeleteOneFileArgs = {
  input: DeleteOneFileInput;
};

export type MutationDeleteManyFilesArgs = {
  input: DeleteManyFilesInput;
};

export type MutationRestoreOneFileArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyFileArgs = {
  input: FileFilter;
};

export type MutationUpsertAuthArgs = {
  input: CreateAuthInput;
};

export type MutationSetUserOnAuthArgs = {
  input: SetUserOnAuthInput;
};

export type MutationRemoveUserFromAuthArgs = {
  input: RemoveUserFromAuthInput;
};

export type MutationCreateOneAuthArgs = {
  input: CreateOneAuthInput;
};

export type MutationCreateManyAuthsArgs = {
  input: CreateManyAuthsInput;
};

export type MutationUpdateOneAuthArgs = {
  input: UpdateOneAuthInput;
};

export type MutationUpdateManyAuthsArgs = {
  input: UpdateManyAuthsInput;
};

export type MutationDeleteOneAuthArgs = {
  input: DeleteOneAuthInput;
};

export type MutationDeleteManyAuthsArgs = {
  input: DeleteManyAuthsInput;
};

export type MutationRestoreOneAuthArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyAuthsArgs = {
  input: AuthFilter;
};

export type MutationSetProfileOnUserArgs = {
  input: SetProfileOnUserInput;
};

export type MutationSetZoomUserOnUserArgs = {
  input: SetZoomUserOnUserInput;
};

export type MutationAddFeedbacksToUserArgs = {
  input: AddFeedbacksToUserInput;
};

export type MutationSetFeedbacksOnUserArgs = {
  input: SetFeedbacksOnUserInput;
};

export type MutationAddExperiencesToUserArgs = {
  input: AddExperiencesToUserInput;
};

export type MutationSetExperiencesOnUserArgs = {
  input: SetExperiencesOnUserInput;
};

export type MutationRemoveProfileFromUserArgs = {
  input: RemoveProfileFromUserInput;
};

export type MutationRemoveZoomUserFromUserArgs = {
  input: RemoveZoomUserFromUserInput;
};

export type MutationRemoveFeedbacksFromUserArgs = {
  input: RemoveFeedbacksFromUserInput;
};

export type MutationRemoveExperiencesFromUserArgs = {
  input: RemoveExperiencesFromUserInput;
};

export type MutationCreateOneUserArgs = {
  input: CreateOneUserInput;
};

export type MutationCreateManyUsersArgs = {
  input: CreateManyUsersInput;
};

export type MutationUpdateOneUserArgs = {
  input: UpdateOneUserInput;
};

export type MutationUpdateManyUsersArgs = {
  input: UpdateManyUsersInput;
};

export type MutationDeleteOneUserArgs = {
  input: DeleteOneUserInput;
};

export type MutationDeleteManyUsersArgs = {
  input: DeleteManyUsersInput;
};

export type MutationRestoreOneUserArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyUsersArgs = {
  input: UserFilter;
};

export type MutationRequestBookingArgs = {
  input: RequestBookingInput;
};

export type MutationRescheduleBookingArgs = {
  input: RescheduleBookingInput;
};

export type MutationCancelBookingArgs = {
  input: CancelBookingInput;
};

export type MutationConfirmBookingArgs = {
  input: ConfirmBookingInput;
};

export type MutationSetCalendarOnEventArgs = {
  input: SetCalendarOnEventInput;
};

export type MutationAddAttendeesToEventArgs = {
  input: AddAttendeesToEventInput;
};

export type MutationSetAttendeesOnEventArgs = {
  input: SetAttendeesOnEventInput;
};

export type MutationRemoveCalendarFromEventArgs = {
  input: RemoveCalendarFromEventInput;
};

export type MutationRemoveAttendeesFromEventArgs = {
  input: RemoveAttendeesFromEventInput;
};

export type MutationCreateOneEventArgs = {
  input: CreateOneEventInput;
};

export type MutationCreateManyEventsArgs = {
  input: CreateManyEventsInput;
};

export type MutationUpdateOneEventArgs = {
  input: UpdateOneEventInput;
};

export type MutationUpdateManyEventsArgs = {
  input: UpdateManyEventsInput;
};

export type MutationDeleteOneEventArgs = {
  input: DeleteOneEventInput;
};

export type MutationDeleteManyEventsArgs = {
  input: DeleteManyEventsInput;
};

export type MutationRestoreOneEventArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyEventsArgs = {
  input: EventFilter;
};

export type MutationSetUserOnZoomUserArgs = {
  input: SetUserOnZoomUserInput;
};

export type MutationAddMeetingsToZoomUserArgs = {
  input: AddMeetingsToZoomUserInput;
};

export type MutationSetMeetingsOnZoomUserArgs = {
  input: SetMeetingsOnZoomUserInput;
};

export type MutationRemoveUserFromZoomUserArgs = {
  input: RemoveUserFromZoomUserInput;
};

export type MutationRemoveMeetingsFromZoomUserArgs = {
  input: RemoveMeetingsFromZoomUserInput;
};

export type MutationCreateOneZoomUserArgs = {
  input: CreateOneZoomUserInput;
};

export type MutationCreateManyZoomUsersArgs = {
  input: CreateManyZoomUsersInput;
};

export type MutationUpdateOneZoomUserArgs = {
  input: UpdateOneZoomUserInput;
};

export type MutationUpdateManyZoomUsersArgs = {
  input: UpdateManyZoomUsersInput;
};

export type MutationDeleteOneZoomUserArgs = {
  input: DeleteOneZoomUserInput;
};

export type MutationDeleteManyZoomUsersArgs = {
  input: DeleteManyZoomUsersInput;
};

export type MutationSetZoomUserOnZoomMeetingArgs = {
  input: SetZoomUserOnZoomMeetingInput;
};

export type MutationRemoveZoomUserFromZoomMeetingArgs = {
  input: RemoveZoomUserFromZoomMeetingInput;
};

export type MutationCreateOneZoomMeetingArgs = {
  input: CreateOneZoomMeetingInput;
};

export type MutationCreateManyZoomMeetingsArgs = {
  input: CreateManyZoomMeetingsInput;
};

export type MutationUpdateOneZoomMeetingArgs = {
  input: UpdateOneZoomMeetingInput;
};

export type MutationUpdateManyZoomMeetingsArgs = {
  input: UpdateManyZoomMeetingsInput;
};

export type MutationDeleteOneZoomMeetingArgs = {
  input: DeleteOneZoomMeetingInput;
};

export type MutationDeleteManyZoomMeetingsArgs = {
  input: DeleteManyZoomMeetingsInput;
};

export type MutationSetUserOnAttendeeArgs = {
  input: SetUserOnAttendeeInput;
};

export type MutationSetEventOnAttendeeArgs = {
  input: SetEventOnAttendeeInput;
};

export type MutationRemoveUserFromAttendeeArgs = {
  input: RemoveUserFromAttendeeInput;
};

export type MutationRemoveEventFromAttendeeArgs = {
  input: RemoveEventFromAttendeeInput;
};

export type MutationCreateOneAttendeeArgs = {
  input: CreateOneAttendeeInput;
};

export type MutationCreateManyAttendeesArgs = {
  input: CreateManyAttendeesInput;
};

export type MutationUpdateOneAttendeeArgs = {
  input: UpdateOneAttendeeInput;
};

export type MutationUpdateManyAttendeesArgs = {
  input: UpdateManyAttendeesInput;
};

export type MutationDeleteOneAttendeeArgs = {
  input: DeleteOneAttendeeInput;
};

export type MutationDeleteManyAttendeesArgs = {
  input: DeleteManyAttendeesInput;
};

export type MutationRestoreOneAttendeeArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyAttendeesArgs = {
  input: AttendeeFilter;
};

export type MutationSetScheduleOnCalendarArgs = {
  input: SetScheduleOnCalendarInput;
};

export type MutationSetExperienceOnCalendarArgs = {
  input: SetExperienceOnCalendarInput;
};

export type MutationAddEventsToCalendarArgs = {
  input: AddEventsToCalendarInput;
};

export type MutationSetEventsOnCalendarArgs = {
  input: SetEventsOnCalendarInput;
};

export type MutationRemoveScheduleFromCalendarArgs = {
  input: RemoveScheduleFromCalendarInput;
};

export type MutationRemoveExperienceFromCalendarArgs = {
  input: RemoveExperienceFromCalendarInput;
};

export type MutationRemoveEventsFromCalendarArgs = {
  input: RemoveEventsFromCalendarInput;
};

export type MutationCreateOneCalendarArgs = {
  input: CreateOneCalendarInput;
};

export type MutationCreateManyCalendarsArgs = {
  input: CreateManyCalendarsInput;
};

export type MutationUpdateOneCalendarArgs = {
  input: UpdateOneCalendarInput;
};

export type MutationUpdateManyCalendarsArgs = {
  input: UpdateManyCalendarsInput;
};

export type MutationDeleteOneCalendarArgs = {
  input: DeleteOneCalendarInput;
};

export type MutationDeleteManyCalendarsArgs = {
  input: DeleteManyCalendarsInput;
};

export type MutationRestoreOneCalendarArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyCalendarsArgs = {
  input: CalendarFilter;
};

export type MutationSetUserOnProfileArgs = {
  input: SetUserOnProfileInput;
};

export type MutationAddImagesToProfileArgs = {
  input: AddImagesToProfileInput;
};

export type MutationSetImagesOnProfileArgs = {
  input: SetImagesOnProfileInput;
};

export type MutationAddInterestsToProfileArgs = {
  input: AddInterestsToProfileInput;
};

export type MutationSetInterestsOnProfileArgs = {
  input: SetInterestsOnProfileInput;
};

export type MutationAddStrengthsToProfileArgs = {
  input: AddStrengthsToProfileInput;
};

export type MutationSetStrengthsOnProfileArgs = {
  input: SetStrengthsOnProfileInput;
};

export type MutationRemoveUserFromProfileArgs = {
  input: RemoveUserFromProfileInput;
};

export type MutationRemoveImagesFromProfileArgs = {
  input: RemoveImagesFromProfileInput;
};

export type MutationRemoveInterestsFromProfileArgs = {
  input: RemoveInterestsFromProfileInput;
};

export type MutationRemoveStrengthsFromProfileArgs = {
  input: RemoveStrengthsFromProfileInput;
};

export type MutationCreateOneProfileArgs = {
  input: CreateOneProfileInput;
};

export type MutationCreateManyProfilesArgs = {
  input: CreateManyProfilesInput;
};

export type MutationUpdateOneProfileArgs = {
  input: UpdateOneProfileInput;
};

export type MutationUpdateManyProfilesArgs = {
  input: UpdateManyProfilesInput;
};

export type MutationDeleteOneProfileArgs = {
  input: DeleteOneProfileInput;
};

export type MutationDeleteManyProfilesArgs = {
  input: DeleteManyProfilesInput;
};

export type MutationRestoreOneProfileArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyProfilesArgs = {
  input: ProfileFilter;
};

export type MutationUpdateAvatarArgs = {
  upload?: Maybe<UploadFileInput>;
};

export type MutationUploadImageArgs = {
  upload?: Maybe<UploadFileInput>;
};

export type MutationUpdateAvatarWithSignedUrlArgs = {
  upload?: Maybe<UploadFileWithSignedUrlInput>;
};

export type MutationUpdateOwnProfileInterestsArgs = {
  interests?: Maybe<Array<Scalars['String']>>;
};

export type MutationUpdateOwnProfileStrengthsArgs = {
  strengths?: Maybe<Array<Scalars['String']>>;
};

export type MutationCreateOneProfileInterestArgs = {
  input: CreateOneProfileInterestInput;
};

export type MutationCreateManyProfileInterestsArgs = {
  input: CreateManyProfileInterestsInput;
};

export type MutationUpdateOneProfileInterestArgs = {
  input: UpdateOneProfileInterestInput;
};

export type MutationUpdateManyProfileInterestsArgs = {
  input: UpdateManyProfileInterestsInput;
};

export type MutationDeleteOneProfileInterestArgs = {
  input: DeleteOneProfileInterestInput;
};

export type MutationDeleteManyProfileInterestsArgs = {
  input: DeleteManyProfileInterestsInput;
};

export type MutationRestoreOneProfileInterestArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyProfileInterestsArgs = {
  input: ProfileInterestFilter;
};

export type MutationCreateOneProfileStrengthArgs = {
  input: CreateOneProfileStrengthInput;
};

export type MutationCreateManyProfileStrengthsArgs = {
  input: CreateManyProfileStrengthsInput;
};

export type MutationUpdateOneProfileStrengthArgs = {
  input: UpdateOneProfileStrengthInput;
};

export type MutationUpdateManyProfileStrengthsArgs = {
  input: UpdateManyProfileStrengthsInput;
};

export type MutationDeleteOneProfileStrengthArgs = {
  input: DeleteOneProfileStrengthInput;
};

export type MutationDeleteManyProfileStrengthsArgs = {
  input: DeleteManyProfileStrengthsInput;
};

export type MutationRestoreOneProfileStrengthArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyProfileStrengthsArgs = {
  input: ProfileStrengthFilter;
};

export type MutationSortProfileImagesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type MutationSetFileOnProfileImageArgs = {
  input: SetFileOnProfileImageInput;
};

export type MutationSetProfileOnProfileImageArgs = {
  input: SetProfileOnProfileImageInput;
};

export type MutationRemoveFileFromProfileImageArgs = {
  input: RemoveFileFromProfileImageInput;
};

export type MutationRemoveProfileFromProfileImageArgs = {
  input: RemoveProfileFromProfileImageInput;
};

export type MutationCreateOneProfileImageArgs = {
  input: CreateOneProfileImageInput;
};

export type MutationCreateManyProfileImagesArgs = {
  input: CreateManyProfileImagesInput;
};

export type MutationUpdateOneProfileImageArgs = {
  input: UpdateOneProfileImageInput;
};

export type MutationUpdateManyProfileImagesArgs = {
  input: UpdateManyProfileImagesInput;
};

export type MutationDeleteOneProfileImageArgs = {
  input: DeleteOneProfileImageInput;
};

export type MutationDeleteManyProfileImagesArgs = {
  input: DeleteManyProfileImagesInput;
};

export type MutationRestoreOneProfileImageArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyProfileImagesArgs = {
  input: ProfileImageFilter;
};

export type MutationCreateOneOEmbedArgs = {
  input: CreateOneOEmbedInput;
};

export type MutationCreateManyOEmbedsArgs = {
  input: CreateManyOEmbedsInput;
};

export type MutationUpdateOneOEmbedArgs = {
  input: UpdateOneOEmbedInput;
};

export type MutationUpdateManyOEmbedsArgs = {
  input: UpdateManyOEmbedsInput;
};

export type MutationDeleteOneOEmbedArgs = {
  input: DeleteOneOEmbedInput;
};

export type MutationDeleteManyOEmbedsArgs = {
  input: DeleteManyOEmbedsInput;
};

export type MutationRestoreOneOEmbedArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyOEmbedsArgs = {
  input: OEmbedFilter;
};

export type MutationSetCalendarOnExperienceArgs = {
  input: SetCalendarOnExperienceInput;
};

export type MutationAddImagesToExperienceArgs = {
  input: AddImagesToExperienceInput;
};

export type MutationSetImagesOnExperienceArgs = {
  input: SetImagesOnExperienceInput;
};

export type MutationAddExperienceTagsToExperienceArgs = {
  input: AddExperienceTagsToExperienceInput;
};

export type MutationSetExperienceTagsOnExperienceArgs = {
  input: SetExperienceTagsOnExperienceInput;
};

export type MutationRemoveCalendarFromExperienceArgs = {
  input: RemoveCalendarFromExperienceInput;
};

export type MutationRemoveImagesFromExperienceArgs = {
  input: RemoveImagesFromExperienceInput;
};

export type MutationRemoveExperienceTagsFromExperienceArgs = {
  input: RemoveExperienceTagsFromExperienceInput;
};

export type MutationCreateOneExperienceArgs = {
  input: CreateOneExperienceInput;
};

export type MutationCreateManyExperiencesArgs = {
  input: CreateManyExperiencesInput;
};

export type MutationUpdateOneExperienceArgs = {
  input: UpdateOneExperienceInput;
};

export type MutationUpdateManyExperiencesArgs = {
  input: UpdateManyExperiencesInput;
};

export type MutationDeleteOneExperienceArgs = {
  input: DeleteOneExperienceInput;
};

export type MutationDeleteManyExperiencesArgs = {
  input: DeleteManyExperiencesInput;
};

export type MutationRestoreOneExperienceArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyExperiencesArgs = {
  input: ExperienceFilter;
};

export type MutationUploadOneExperienceImageArgs = {
  file?: Maybe<UploadFileInput>;
  experienceId: Scalars['ID'];
  order?: Maybe<Scalars['Float']>;
};

export type MutationUpdateCoverImageArgs = {
  file?: Maybe<UploadFileInput>;
  imageId: Scalars['ID'];
};

export type MutationDeleteOneExperienceImageArgs = {
  imageId: Scalars['ID'];
};

export type MutationSetFileOnImageArgs = {
  input: SetFileOnImageInput;
};

export type MutationSetExperienceOnImageArgs = {
  input: SetExperienceOnImageInput;
};

export type MutationRemoveFileFromImageArgs = {
  input: RemoveFileFromImageInput;
};

export type MutationRemoveExperienceFromImageArgs = {
  input: RemoveExperienceFromImageInput;
};

export type MutationCreateOneImageArgs = {
  input: CreateOneImageInput;
};

export type MutationCreateManyImagesArgs = {
  input: CreateManyImagesInput;
};

export type MutationUpdateOneImageArgs = {
  input: UpdateOneImageInput;
};

export type MutationUpdateManyImagesArgs = {
  input: UpdateManyImagesInput;
};

export type MutationDeleteOneImageArgs = {
  input: DeleteOneImageInput;
};

export type MutationDeleteManyImagesArgs = {
  input: DeleteManyImagesInput;
};

export type MutationRestoreOneImageArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyImagesArgs = {
  input: ImageFilter;
};

export type MutationAddTimeslotsToScheduleArgs = {
  input: AddTimeslotsToScheduleInput;
};

export type MutationSetTimeslotsOnScheduleArgs = {
  input: SetTimeslotsOnScheduleInput;
};

export type MutationRemoveTimeslotsFromScheduleArgs = {
  input: RemoveTimeslotsFromScheduleInput;
};

export type MutationCreateOneScheduleArgs = {
  input: CreateOneScheduleInput;
};

export type MutationCreateManySchedulesArgs = {
  input: CreateManySchedulesInput;
};

export type MutationUpdateOneScheduleArgs = {
  input: UpdateOneScheduleInput;
};

export type MutationUpdateManySchedulesArgs = {
  input: UpdateManySchedulesInput;
};

export type MutationDeleteOneScheduleArgs = {
  input: DeleteOneScheduleInput;
};

export type MutationDeleteManySchedulesArgs = {
  input: DeleteManySchedulesInput;
};

export type MutationRestoreOneScheduleArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManySchedulesArgs = {
  input: ScheduleFilter;
};

export type MutationCreateOneTimeslotArgs = {
  input: CreateOneTimeslotInput;
};

export type MutationCreateManyTimeslotsArgs = {
  input: CreateManyTimeslotsInput;
};

export type MutationUpdateOneTimeslotArgs = {
  input: UpdateOneTimeslotInput;
};

export type MutationUpdateManyTimeslotsArgs = {
  input: UpdateManyTimeslotsInput;
};

export type MutationDeleteOneTimeslotArgs = {
  input: DeleteOneTimeslotInput;
};

export type MutationDeleteManyTimeslotsArgs = {
  input: DeleteManyTimeslotsInput;
};

export type MutationRestoreOneTimeslotArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyTimeslotsArgs = {
  input: TimeslotFilter;
};

export type MutationSetUserOnOrderArgs = {
  input: SetUserOnOrderInput;
};

export type MutationSetExperienceOnOrderArgs = {
  input: SetExperienceOnOrderInput;
};

export type MutationRemoveUserFromOrderArgs = {
  input: RemoveUserFromOrderInput;
};

export type MutationRemoveExperienceFromOrderArgs = {
  input: RemoveExperienceFromOrderInput;
};

export type MutationCreateOneOrderArgs = {
  input: CreateOneOrderInput;
};

export type MutationCreateManyOrdersArgs = {
  input: CreateManyOrdersInput;
};

export type MutationUpdateOneOrderArgs = {
  input: UpdateOneOrderInput;
};

export type MutationUpdateManyOrdersArgs = {
  input: UpdateManyOrdersInput;
};

export type MutationDeleteOneOrderArgs = {
  input: DeleteOneOrderInput;
};

export type MutationDeleteManyOrdersArgs = {
  input: DeleteManyOrdersInput;
};

export type MutationRestoreOneOrderArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyOrdersArgs = {
  input: OrderFilter;
};

export type MutationCreateStripeCheckoutSessionArgs = {
  input: CreateStripeCheckoutSessionInput;
};

export type MutationSetUserOnStripeCustomerArgs = {
  input: SetUserOnStripeCustomerInput;
};

export type MutationRemoveUserFromStripeCustomerArgs = {
  input: RemoveUserFromStripeCustomerInput;
};

export type MutationCreateOneStripeCustomerArgs = {
  input: CreateOneStripeCustomerInput;
};

export type MutationCreateManyStripeCustomersArgs = {
  input: CreateManyStripeCustomersInput;
};

export type MutationUpdateOneStripeCustomerArgs = {
  input: UpdateOneStripeCustomerInput;
};

export type MutationUpdateManyStripeCustomersArgs = {
  input: UpdateManyStripeCustomersInput;
};

export type MutationDeleteOneStripeCustomerArgs = {
  input: DeleteOneStripeCustomerInput;
};

export type MutationDeleteManyStripeCustomersArgs = {
  input: DeleteManyStripeCustomersInput;
};

export type MutationSetExperienceOnStripeProductArgs = {
  input: SetExperienceOnStripeProductInput;
};

export type MutationRemoveExperienceFromStripeProductArgs = {
  input: RemoveExperienceFromStripeProductInput;
};

export type MutationCreateOneStripeProductArgs = {
  input: CreateOneStripeProductInput;
};

export type MutationCreateManyStripeProductsArgs = {
  input: CreateManyStripeProductsInput;
};

export type MutationUpdateOneStripeProductArgs = {
  input: UpdateOneStripeProductInput;
};

export type MutationUpdateManyStripeProductsArgs = {
  input: UpdateManyStripeProductsInput;
};

export type MutationDeleteOneStripeProductArgs = {
  input: DeleteOneStripeProductInput;
};

export type MutationDeleteManyStripeProductsArgs = {
  input: DeleteManyStripeProductsInput;
};

export type MutationCreateOneNotificationArgs = {
  input: CreateOneNotificationInput;
};

export type MutationCreateManyNotificationsArgs = {
  input: CreateManyNotificationsInput;
};

export type MutationUpdateOneNotificationArgs = {
  input: UpdateOneNotificationInput;
};

export type MutationUpdateManyNotificationsArgs = {
  input: UpdateManyNotificationsInput;
};

export type MutationDeleteOneNotificationArgs = {
  input: DeleteOneNotificationInput;
};

export type MutationDeleteManyNotificationsArgs = {
  input: DeleteManyNotificationsInput;
};

export type MutationRestoreOneNotificationArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyNotificationsArgs = {
  input: NotificationFilter;
};

export type MutationSetCreatedByOnFeedbackArgs = {
  input: SetCreatedByOnFeedbackInput;
};

export type MutationSetUserOnFeedbackArgs = {
  input: SetUserOnFeedbackInput;
};

export type MutationSetExperienceOnFeedbackArgs = {
  input: SetExperienceOnFeedbackInput;
};

export type MutationSetEventOnFeedbackArgs = {
  input: SetEventOnFeedbackInput;
};

export type MutationRemoveCreatedByFromFeedbackArgs = {
  input: RemoveCreatedByFromFeedbackInput;
};

export type MutationRemoveUserFromFeedbackArgs = {
  input: RemoveUserFromFeedbackInput;
};

export type MutationRemoveExperienceFromFeedbackArgs = {
  input: RemoveExperienceFromFeedbackInput;
};

export type MutationRemoveEventFromFeedbackArgs = {
  input: RemoveEventFromFeedbackInput;
};

export type MutationCreateOneFeedbackArgs = {
  input: CreateOneFeedbackInput;
};

export type MutationCreateManyFeedbacksArgs = {
  input: CreateManyFeedbacksInput;
};

export type MutationUpdateOneFeedbackArgs = {
  input: UpdateOneFeedbackInput;
};

export type MutationUpdateManyFeedbacksArgs = {
  input: UpdateManyFeedbacksInput;
};

export type MutationDeleteOneFeedbackArgs = {
  input: DeleteOneFeedbackInput;
};

export type MutationDeleteManyFeedbacksArgs = {
  input: DeleteManyFeedbacksInput;
};

export type MutationRestoreOneFeedbackArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreManyFeedbacksArgs = {
  input: FeedbackFilter;
};

export type MutationSetUserOnMessageArgs = {
  input: SetUserOnMessageInput;
};

export type MutationSetMessageThreadOnMessageArgs = {
  input: SetMessageThreadOnMessageInput;
};

export type MutationSetMessageFlaggingOnMessageArgs = {
  input: SetMessageFlaggingOnMessageInput;
};

export type MutationAddMessageFlaggingsToMessageArgs = {
  input: AddMessageFlaggingsToMessageInput;
};

export type MutationSetMessageFlaggingsOnMessageArgs = {
  input: SetMessageFlaggingsOnMessageInput;
};

export type MutationAddMessageFilesToMessageArgs = {
  input: AddMessageFilesToMessageInput;
};

export type MutationSetMessageFilesOnMessageArgs = {
  input: SetMessageFilesOnMessageInput;
};

export type MutationRemoveUserFromMessageArgs = {
  input: RemoveUserFromMessageInput;
};

export type MutationRemoveMessageThreadFromMessageArgs = {
  input: RemoveMessageThreadFromMessageInput;
};

export type MutationRemoveMessageFlaggingFromMessageArgs = {
  input: RemoveMessageFlaggingFromMessageInput;
};

export type MutationRemoveMessageFlaggingsFromMessageArgs = {
  input: RemoveMessageFlaggingsFromMessageInput;
};

export type MutationRemoveMessageFilesFromMessageArgs = {
  input: RemoveMessageFilesFromMessageInput;
};

export type MutationCreateOneMessageArgs = {
  input: CreateOneMessageInput;
};

export type MutationUpdateOneMessageArgs = {
  input: UpdateOneMessageInput;
};

export type MutationUpdateManyMessagesArgs = {
  input: UpdateManyMessagesInput;
};

export type MutationDeleteOneMessageArgs = {
  input: DeleteOneMessageInput;
};

export type MutationDeleteManyMessagesArgs = {
  input: DeleteManyMessagesInput;
};

export type MutationUploadMessageFileArgs = {
  uploadFile?: Maybe<UploadFileInput>;
  messageId: Scalars['ID'];
};

export type MutationCreateMessageForFilesArgs = {
  input: CreateMessageInput;
};

export type MutationCreateMessageThreadArgs = {
  input: CreateMessageThreadInput;
  otherUserId: Scalars['String'];
};

export type MutationCheckIfThreadExistsArgs = {
  userId: Scalars['String'];
};

export type MutationAddMessagesToMessageThreadArgs = {
  input: AddMessagesToMessageThreadInput;
};

export type MutationSetMessagesOnMessageThreadArgs = {
  input: SetMessagesOnMessageThreadInput;
};

export type MutationAddThreadParticipantsToMessageThreadArgs = {
  input: AddThreadParticipantsToMessageThreadInput;
};

export type MutationSetThreadParticipantsOnMessageThreadArgs = {
  input: SetThreadParticipantsOnMessageThreadInput;
};

export type MutationRemoveMessagesFromMessageThreadArgs = {
  input: RemoveMessagesFromMessageThreadInput;
};

export type MutationRemoveThreadParticipantsFromMessageThreadArgs = {
  input: RemoveThreadParticipantsFromMessageThreadInput;
};

export type MutationCreateOneMessageThreadArgs = {
  input: CreateOneMessageThreadInput;
};

export type MutationCreateManyMessageThreadsArgs = {
  input: CreateManyMessageThreadsInput;
};

export type MutationUpdateOneMessageThreadArgs = {
  input: UpdateOneMessageThreadInput;
};

export type MutationUpdateManyMessageThreadsArgs = {
  input: UpdateManyMessageThreadsInput;
};

export type MutationDeleteOneMessageThreadArgs = {
  input: DeleteOneMessageThreadInput;
};

export type MutationDeleteManyMessageThreadsArgs = {
  input: DeleteManyMessageThreadsInput;
};

export type MutationSetMessageThreadOnThreadParticipantsArgs = {
  input: SetMessageThreadOnThreadParticipantsInput;
};

export type MutationSetUserOnThreadParticipantsArgs = {
  input: SetUserOnThreadParticipantsInput;
};

export type MutationAddUsersToThreadParticipantsArgs = {
  input: AddUsersToThreadParticipantsInput;
};

export type MutationSetUsersOnThreadParticipantsArgs = {
  input: SetUsersOnThreadParticipantsInput;
};

export type MutationRemoveMessageThreadFromThreadParticipantsArgs = {
  input: RemoveMessageThreadFromThreadParticipantsInput;
};

export type MutationRemoveUserFromThreadParticipantsArgs = {
  input: RemoveUserFromThreadParticipantsInput;
};

export type MutationRemoveUsersFromThreadParticipantsArgs = {
  input: RemoveUsersFromThreadParticipantsInput;
};

export type MutationCreateOneThreadParticipantsArgs = {
  input: CreateOneThreadParticipantsInput;
};

export type MutationCreateManyThreadParticipantsArgs = {
  input: CreateManyThreadParticipantsInput;
};

export type MutationUpdateOneThreadParticipantsArgs = {
  input: UpdateOneThreadParticipantsInput;
};

export type MutationUpdateManyThreadParticipantsArgs = {
  input: UpdateManyThreadParticipantsInput;
};

export type MutationDeleteOneThreadParticipantsArgs = {
  input: DeleteOneThreadParticipantsInput;
};

export type MutationDeleteManyThreadParticipantsArgs = {
  input: DeleteManyThreadParticipantsInput;
};

export type MutationSetFileOnMessageFileArgs = {
  input: SetFileOnMessageFileInput;
};

export type MutationSetMessageOnMessageFileArgs = {
  input: SetMessageOnMessageFileInput;
};

export type MutationRemoveFileFromMessageFileArgs = {
  input: RemoveFileFromMessageFileInput;
};

export type MutationRemoveMessageFromMessageFileArgs = {
  input: RemoveMessageFromMessageFileInput;
};

export type MutationCreateOneMessageFileArgs = {
  input: CreateOneMessageFileInput;
};

export type MutationCreateManyMessageFilesArgs = {
  input: CreateManyMessageFilesInput;
};

export type MutationUpdateOneMessageFileArgs = {
  input: UpdateOneMessageFileInput;
};

export type MutationUpdateManyMessageFilesArgs = {
  input: UpdateManyMessageFilesInput;
};

export type MutationDeleteOneMessageFileArgs = {
  input: DeleteOneMessageFileInput;
};

export type MutationDeleteManyMessageFilesArgs = {
  input: DeleteManyMessageFilesInput;
};

export type MutationAddMessagesToMessageFlaggingArgs = {
  input: AddMessagesToMessageFlaggingInput;
};

export type MutationSetMessagesOnMessageFlaggingArgs = {
  input: SetMessagesOnMessageFlaggingInput;
};

export type MutationRemoveMessagesFromMessageFlaggingArgs = {
  input: RemoveMessagesFromMessageFlaggingInput;
};

export type MutationCreateOneMessageFlaggingArgs = {
  input: CreateOneMessageFlaggingInput;
};

export type MutationCreateManyMessageFlaggingsArgs = {
  input: CreateManyMessageFlaggingsInput;
};

export type MutationUpdateOneMessageFlaggingArgs = {
  input: UpdateOneMessageFlaggingInput;
};

export type MutationUpdateManyMessageFlaggingsArgs = {
  input: UpdateManyMessageFlaggingsInput;
};

export type MutationDeleteOneMessageFlaggingArgs = {
  input: DeleteOneMessageFlaggingInput;
};

export type MutationDeleteManyMessageFlaggingsArgs = {
  input: DeleteManyMessageFlaggingsInput;
};

export type MutationUploadIconFileArgs = {
  file?: Maybe<UploadFileInput>;
  tagId: Scalars['ID'];
};

export type MutationSetIconOnTagArgs = {
  input: SetIconOnTagInput;
};

export type MutationAddExperienceTagsToTagArgs = {
  input: AddExperienceTagsToTagInput;
};

export type MutationSetExperienceTagsOnTagArgs = {
  input: SetExperienceTagsOnTagInput;
};

export type MutationRemoveIconFromTagArgs = {
  input: RemoveIconFromTagInput;
};

export type MutationRemoveExperienceTagsFromTagArgs = {
  input: RemoveExperienceTagsFromTagInput;
};

export type MutationCreateOneTagArgs = {
  input: CreateOneTagInput;
};

export type MutationUpdateOneTagArgs = {
  input: UpdateOneTagInput;
};

export type MutationUpdateManyTagsArgs = {
  input: UpdateManyTagsInput;
};

export type MutationDeleteOneTagArgs = {
  input: DeleteOneTagInput;
};

export type MutationDeleteManyTagsArgs = {
  input: DeleteManyTagsInput;
};

export type MutationSetExperienceOnExperienceTagArgs = {
  input: SetExperienceOnExperienceTagInput;
};

export type MutationSetTagOnExperienceTagArgs = {
  input: SetTagOnExperienceTagInput;
};

export type MutationRemoveExperienceFromExperienceTagArgs = {
  input: RemoveExperienceFromExperienceTagInput;
};

export type MutationRemoveTagFromExperienceTagArgs = {
  input: RemoveTagFromExperienceTagInput;
};

export type MutationCreateOneExperienceTagArgs = {
  input: CreateOneExperienceTagInput;
};

export type MutationCreateManyExperienceTagsArgs = {
  input: CreateManyExperienceTagsInput;
};

export type MutationUpdateOneExperienceTagArgs = {
  input: UpdateOneExperienceTagInput;
};

export type MutationUpdateManyExperienceTagsArgs = {
  input: UpdateManyExperienceTagsInput;
};

export type MutationDeleteOneExperienceTagArgs = {
  input: DeleteOneExperienceTagInput;
};

export type MutationDeleteManyExperienceTagsArgs = {
  input: DeleteManyExperienceTagsInput;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status: NotificationStatus;
  userId: Scalars['ID'];
  user: User;
};

export type NotificationAggregateFilter = {
  and?: Maybe<Array<NotificationAggregateFilter>>;
  or?: Maybe<Array<NotificationAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  text?: Maybe<StringFieldComparison>;
  link?: Maybe<StringFieldComparison>;
  status?: Maybe<NotificationStatusFilterComparison>;
  userId?: Maybe<IdFilterComparison>;
};

export type NotificationAggregateGroupBy = {
  __typename?: 'NotificationAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<NotificationStatus>;
  userId?: Maybe<Scalars['ID']>;
};

export type NotificationAggregateResponse = {
  __typename?: 'NotificationAggregateResponse';
  groupBy?: Maybe<NotificationAggregateGroupBy>;
  count?: Maybe<NotificationCountAggregate>;
  min?: Maybe<NotificationMinAggregate>;
  max?: Maybe<NotificationMaxAggregate>;
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<NotificationEdge>;
};

export type NotificationCountAggregate = {
  __typename?: 'NotificationCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

export type NotificationDeleteFilter = {
  and?: Maybe<Array<NotificationDeleteFilter>>;
  or?: Maybe<Array<NotificationDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  text?: Maybe<StringFieldComparison>;
  link?: Maybe<StringFieldComparison>;
  status?: Maybe<NotificationStatusFilterComparison>;
  userId?: Maybe<IdFilterComparison>;
};

export type NotificationDeleteResponse = {
  __typename?: 'NotificationDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<NotificationStatus>;
  userId?: Maybe<Scalars['ID']>;
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** The node containing the Notification */
  node: Notification;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type NotificationFilter = {
  and?: Maybe<Array<NotificationFilter>>;
  or?: Maybe<Array<NotificationFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  text?: Maybe<StringFieldComparison>;
  link?: Maybe<StringFieldComparison>;
  status?: Maybe<NotificationStatusFilterComparison>;
  userId?: Maybe<IdFilterComparison>;
  user?: Maybe<NotificationFilterUserFilter>;
};

export type NotificationFilterUserFilter = {
  and?: Maybe<Array<NotificationFilterUserFilter>>;
  or?: Maybe<Array<NotificationFilterUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  emailVerified?: Maybe<BooleanFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  phoneVerified?: Maybe<BooleanFieldComparison>;
  username?: Maybe<StringFieldComparison>;
  firstName?: Maybe<StringFieldComparison>;
  lastName?: Maybe<StringFieldComparison>;
  nickname?: Maybe<StringFieldComparison>;
  avatar?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  continent?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  welcomeComplete?: Maybe<DateFieldComparison>;
  welcomeVetted?: Maybe<BooleanFieldComparison>;
  criminalRecordCheckDate?: Maybe<DateFieldComparison>;
  criminalRecordCheckValid?: Maybe<BooleanFieldComparison>;
  criminalRecordCheck?: Maybe<StringFieldComparison>;
  location?: Maybe<PointObjectFilterComparison>;
  dob?: Maybe<StringFieldComparison>;
  address?: Maybe<AddressObjectFilterComparison>;
};

export type NotificationMaxAggregate = {
  __typename?: 'NotificationMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<NotificationStatus>;
  userId?: Maybe<Scalars['ID']>;
};

export type NotificationMinAggregate = {
  __typename?: 'NotificationMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<NotificationStatus>;
  userId?: Maybe<Scalars['ID']>;
};

export type NotificationSort = {
  field: NotificationSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum NotificationSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Title = 'title',
  Text = 'text',
  Link = 'link',
  Status = 'status',
  UserId = 'userId',
}

export enum NotificationStatus {
  Unread = 'unread',
  Read = 'read',
}

export type NotificationStatusFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<NotificationStatus>;
  neq?: Maybe<NotificationStatus>;
  gt?: Maybe<NotificationStatus>;
  gte?: Maybe<NotificationStatus>;
  lt?: Maybe<NotificationStatus>;
  lte?: Maybe<NotificationStatus>;
  like?: Maybe<NotificationStatus>;
  notLike?: Maybe<NotificationStatus>;
  iLike?: Maybe<NotificationStatus>;
  notILike?: Maybe<NotificationStatus>;
  in?: Maybe<Array<NotificationStatus>>;
  notIn?: Maybe<Array<NotificationStatus>>;
};

export type NotificationSubscriptionFilter = {
  and?: Maybe<Array<NotificationSubscriptionFilter>>;
  or?: Maybe<Array<NotificationSubscriptionFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  text?: Maybe<StringFieldComparison>;
  link?: Maybe<StringFieldComparison>;
  status?: Maybe<NotificationStatusFilterComparison>;
  userId?: Maybe<IdFilterComparison>;
};

export type NotificationUpdateFilter = {
  and?: Maybe<Array<NotificationUpdateFilter>>;
  or?: Maybe<Array<NotificationUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  text?: Maybe<StringFieldComparison>;
  link?: Maybe<StringFieldComparison>;
  status?: Maybe<NotificationStatusFilterComparison>;
  userId?: Maybe<IdFilterComparison>;
};

export type NumberFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  between?: Maybe<NumberFieldComparisonBetween>;
  notBetween?: Maybe<NumberFieldComparisonBetween>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export type OEmbed = {
  __typename?: 'OEmbed';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  data?: Maybe<IoEmbedData>;
};

export type OEmbedAggregateGroupBy = {
  __typename?: 'OEmbedAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type OEmbedConnection = {
  __typename?: 'OEmbedConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<OEmbedEdge>;
};

export type OEmbedCountAggregate = {
  __typename?: 'OEmbedCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['Int']>;
};

export type OEmbedDeleteFilter = {
  and?: Maybe<Array<OEmbedDeleteFilter>>;
  or?: Maybe<Array<OEmbedDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  url?: Maybe<StringFieldComparison>;
};

export type OEmbedDeleteResponse = {
  __typename?: 'OEmbedDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type OEmbedEdge = {
  __typename?: 'OEmbedEdge';
  /** The node containing the OEmbed */
  node: OEmbed;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type OEmbedFilter = {
  and?: Maybe<Array<OEmbedFilter>>;
  or?: Maybe<Array<OEmbedFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  url?: Maybe<StringFieldComparison>;
};

export type OEmbedLinkData = IoEmbedData & {
  __typename?: 'OEmbedLinkData';
  title?: Maybe<Scalars['String']>;
  author_name?: Maybe<Scalars['String']>;
  author_url?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  provider_url?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  thumbnail_width?: Maybe<Scalars['Int']>;
  thumbnail_height?: Maybe<Scalars['Int']>;
};

export type OEmbedMaxAggregate = {
  __typename?: 'OEmbedMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type OEmbedMinAggregate = {
  __typename?: 'OEmbedMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type OEmbedPhotoData = IoEmbedData & {
  __typename?: 'OEmbedPhotoData';
  title?: Maybe<Scalars['String']>;
  author_name?: Maybe<Scalars['String']>;
  author_url?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  provider_url?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  thumbnail_width?: Maybe<Scalars['Int']>;
  thumbnail_height?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type OEmbedRichData = IoEmbedData & {
  __typename?: 'OEmbedRichData';
  title?: Maybe<Scalars['String']>;
  author_name?: Maybe<Scalars['String']>;
  author_url?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  provider_url?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  thumbnail_width?: Maybe<Scalars['Int']>;
  thumbnail_height?: Maybe<Scalars['Int']>;
  html?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type OEmbedSort = {
  field: OEmbedSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum OEmbedSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Url = 'url',
}

export type OEmbedUpdateFilter = {
  and?: Maybe<Array<OEmbedUpdateFilter>>;
  or?: Maybe<Array<OEmbedUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  url?: Maybe<StringFieldComparison>;
};

export type OEmbedVideoData = IoEmbedData & {
  __typename?: 'OEmbedVideoData';
  title?: Maybe<Scalars['String']>;
  author_name?: Maybe<Scalars['String']>;
  author_url?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  provider_url?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  thumbnail_width?: Maybe<Scalars['Int']>;
  thumbnail_height?: Maybe<Scalars['Int']>;
  html?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  stripeId: Scalars['String'];
  stripeLivemode: Scalars['Boolean'];
  stripeUrl?: Maybe<Scalars['String']>;
  shippedAt?: Maybe<Scalars['DateTime']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  user: User;
  experience: Experience;
};

export type OrderAggregateGroupBy = {
  __typename?: 'OrderAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeLivemode?: Maybe<Scalars['Boolean']>;
  shippedAt?: Maybe<Scalars['DateTime']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<OrderEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type OrderCountAggregate = {
  __typename?: 'OrderCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['Int']>;
  stripeLivemode?: Maybe<Scalars['Int']>;
  shippedAt?: Maybe<Scalars['Int']>;
  deliveredAt?: Maybe<Scalars['Int']>;
};

export type OrderDeleteFilter = {
  and?: Maybe<Array<OrderDeleteFilter>>;
  or?: Maybe<Array<OrderDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<StringFieldComparison>;
  stripeId?: Maybe<StringFieldComparison>;
  stripeLivemode?: Maybe<BooleanFieldComparison>;
  shippedAt?: Maybe<DateFieldComparison>;
  deliveredAt?: Maybe<DateFieldComparison>;
};

export type OrderDeleteResponse = {
  __typename?: 'OrderDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeLivemode?: Maybe<Scalars['Boolean']>;
  stripeUrl?: Maybe<Scalars['String']>;
  shippedAt?: Maybe<Scalars['DateTime']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** The node containing the Order */
  node: Order;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type OrderFilter = {
  and?: Maybe<Array<OrderFilter>>;
  or?: Maybe<Array<OrderFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<StringFieldComparison>;
  stripeId?: Maybe<StringFieldComparison>;
  stripeLivemode?: Maybe<BooleanFieldComparison>;
  shippedAt?: Maybe<DateFieldComparison>;
  deliveredAt?: Maybe<DateFieldComparison>;
  experience?: Maybe<OrderFilterExperienceFilter>;
  user?: Maybe<OrderFilterUserFilter>;
};

export type OrderFilterExperienceFilter = {
  and?: Maybe<Array<OrderFilterExperienceFilter>>;
  or?: Maybe<Array<OrderFilterExperienceFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  meetingScheduled?: Maybe<BooleanFieldComparison>;
  progressStep?: Maybe<ExperienceProgressStepFilterComparison>;
  hostId?: Maybe<StringFieldComparison>;
  video?: Maybe<StringFieldComparison>;
  featured?: Maybe<NumberFieldComparison>;
  category?: Maybe<ExperienceCategoryFilterComparison>;
  publishedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<ExperienceStatusFilterComparison>;
  nextAvailability?: Maybe<DateFieldComparison>;
  nextEvent?: Maybe<DateFieldComparison>;
  next?: Maybe<DateFieldComparison>;
  type?: Maybe<ExperienceTypeFilterComparison>;
  mode?: Maybe<ExperienceModeFilterComparison>;
  locationType?: Maybe<ExperienceLocationTypeFilterComparison>;
  approximateLocation?: Maybe<PointObjectFilterComparison>;
  coordinates?: Maybe<PointObjectFilterComparison>;
  streetNumber?: Maybe<StringFieldComparison>;
  route?: Maybe<StringFieldComparison>;
  locality?: Maybe<StringFieldComparison>;
  administrativeAreaLevel2?: Maybe<StringFieldComparison>;
  administrativeAreaLevel1?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  postalCode?: Maybe<StringFieldComparison>;
};

export type OrderFilterUserFilter = {
  and?: Maybe<Array<OrderFilterUserFilter>>;
  or?: Maybe<Array<OrderFilterUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  emailVerified?: Maybe<BooleanFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  phoneVerified?: Maybe<BooleanFieldComparison>;
  username?: Maybe<StringFieldComparison>;
  firstName?: Maybe<StringFieldComparison>;
  lastName?: Maybe<StringFieldComparison>;
  nickname?: Maybe<StringFieldComparison>;
  avatar?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  continent?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  welcomeComplete?: Maybe<DateFieldComparison>;
  welcomeVetted?: Maybe<BooleanFieldComparison>;
  criminalRecordCheckDate?: Maybe<DateFieldComparison>;
  criminalRecordCheckValid?: Maybe<BooleanFieldComparison>;
  criminalRecordCheck?: Maybe<StringFieldComparison>;
  location?: Maybe<PointObjectFilterComparison>;
  dob?: Maybe<StringFieldComparison>;
  address?: Maybe<AddressObjectFilterComparison>;
};

export type OrderMaxAggregate = {
  __typename?: 'OrderMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  shippedAt?: Maybe<Scalars['DateTime']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
};

export type OrderMinAggregate = {
  __typename?: 'OrderMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  shippedAt?: Maybe<Scalars['DateTime']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
};

export type OrderSort = {
  field: OrderSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum OrderSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Status = 'status',
  StripeId = 'stripeId',
  StripeLivemode = 'stripeLivemode',
  ShippedAt = 'shippedAt',
  DeliveredAt = 'deliveredAt',
}

export enum OrderStatus {
  Processing = 'processing',
  Confirmed = 'confirmed',
  Shipped = 'shipped',
  Delivered = 'delivered',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export type OrderUpdateFilter = {
  and?: Maybe<Array<OrderUpdateFilter>>;
  or?: Maybe<Array<OrderUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<StringFieldComparison>;
  stripeId?: Maybe<StringFieldComparison>;
  stripeLivemode?: Maybe<BooleanFieldComparison>;
  shippedAt?: Maybe<DateFieldComparison>;
  deliveredAt?: Maybe<DateFieldComparison>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']>;
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']>;
};

export type Point = {
  __typename?: 'Point';
  type: Scalars['String'];
  coordinates: Array<Scalars['Float']>;
};

export type PointInput = {
  type?: Scalars['String'];
  coordinates: Array<Scalars['Float']>;
};

export type PointObjectFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<PointInput>;
  neq?: Maybe<PointInput>;
  gt?: Maybe<PointInput>;
  gte?: Maybe<PointInput>;
  lt?: Maybe<PointInput>;
  lte?: Maybe<PointInput>;
  like?: Maybe<PointInput>;
  notLike?: Maybe<PointInput>;
  iLike?: Maybe<PointInput>;
  notILike?: Maybe<PointInput>;
  in?: Maybe<Array<PointInput>>;
  notIn?: Maybe<Array<PointInput>>;
};

export type Profile = {
  __typename?: 'Profile';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Public name, visible to everyone */
  nickname?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  images?: Maybe<ProfileImagesConnection>;
  interests?: Maybe<ProfileInterestsConnection>;
  strengths?: Maybe<ProfileStrengthsConnection>;
  avatar?: Maybe<File>;
};

export type ProfileImagesArgs = {
  paging?: CursorPaging;
  filter?: ProfileImageFilter;
  sorting?: Array<ProfileImageSort>;
};

export type ProfileInterestsArgs = {
  paging?: CursorPaging;
  filter?: ProfileInterestFilter;
  sorting?: Array<ProfileInterestSort>;
};

export type ProfileStrengthsArgs = {
  paging?: CursorPaging;
  filter?: ProfileStrengthFilter;
  sorting?: Array<ProfileStrengthSort>;
};

export type ProfileAggregateGroupBy = {
  __typename?: 'ProfileAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProfileConnection = {
  __typename?: 'ProfileConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ProfileEdge>;
};

export type ProfileCountAggregate = {
  __typename?: 'ProfileCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type ProfileDeleteFilter = {
  and?: Maybe<Array<ProfileDeleteFilter>>;
  or?: Maybe<Array<ProfileDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ProfileDeleteResponse = {
  __typename?: 'ProfileDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Public name, visible to everyone */
  nickname?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
};

export type ProfileEdge = {
  __typename?: 'ProfileEdge';
  /** The node containing the Profile */
  node: Profile;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ProfileFilter = {
  and?: Maybe<Array<ProfileFilter>>;
  or?: Maybe<Array<ProfileFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  user?: Maybe<ProfileFilterUserFilter>;
  strengths?: Maybe<ProfileFilterProfileStrengthFilter>;
  interests?: Maybe<ProfileFilterProfileInterestFilter>;
  images?: Maybe<ProfileFilterProfileImageFilter>;
};

export type ProfileFilterProfileImageFilter = {
  and?: Maybe<Array<ProfileFilterProfileImageFilter>>;
  or?: Maybe<Array<ProfileFilterProfileImageFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  order?: Maybe<NumberFieldComparison>;
};

export type ProfileFilterProfileInterestFilter = {
  and?: Maybe<Array<ProfileFilterProfileInterestFilter>>;
  or?: Maybe<Array<ProfileFilterProfileInterestFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
};

export type ProfileFilterProfileStrengthFilter = {
  and?: Maybe<Array<ProfileFilterProfileStrengthFilter>>;
  or?: Maybe<Array<ProfileFilterProfileStrengthFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
};

export type ProfileFilterUserFilter = {
  and?: Maybe<Array<ProfileFilterUserFilter>>;
  or?: Maybe<Array<ProfileFilterUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  emailVerified?: Maybe<BooleanFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  phoneVerified?: Maybe<BooleanFieldComparison>;
  username?: Maybe<StringFieldComparison>;
  firstName?: Maybe<StringFieldComparison>;
  lastName?: Maybe<StringFieldComparison>;
  nickname?: Maybe<StringFieldComparison>;
  avatar?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  continent?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  welcomeComplete?: Maybe<DateFieldComparison>;
  welcomeVetted?: Maybe<BooleanFieldComparison>;
  criminalRecordCheckDate?: Maybe<DateFieldComparison>;
  criminalRecordCheckValid?: Maybe<BooleanFieldComparison>;
  criminalRecordCheck?: Maybe<StringFieldComparison>;
  location?: Maybe<PointObjectFilterComparison>;
  dob?: Maybe<StringFieldComparison>;
  address?: Maybe<AddressObjectFilterComparison>;
};

export type ProfileImage = {
  __typename?: 'ProfileImage';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  order: Scalars['Float'];
  file?: Maybe<File>;
  profile?: Maybe<Profile>;
};

export type ProfileImageAggregateGroupBy = {
  __typename?: 'ProfileImageAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Float']>;
};

export type ProfileImageAvgAggregate = {
  __typename?: 'ProfileImageAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type ProfileImageConnection = {
  __typename?: 'ProfileImageConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ProfileImageEdge>;
};

export type ProfileImageCountAggregate = {
  __typename?: 'ProfileImageCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

export type ProfileImageDeleteFilter = {
  and?: Maybe<Array<ProfileImageDeleteFilter>>;
  or?: Maybe<Array<ProfileImageDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  order?: Maybe<NumberFieldComparison>;
};

export type ProfileImageDeleteResponse = {
  __typename?: 'ProfileImageDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Float']>;
};

export type ProfileImageEdge = {
  __typename?: 'ProfileImageEdge';
  /** The node containing the ProfileImage */
  node: ProfileImage;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ProfileImageFilter = {
  and?: Maybe<Array<ProfileImageFilter>>;
  or?: Maybe<Array<ProfileImageFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  order?: Maybe<NumberFieldComparison>;
  profile?: Maybe<ProfileImageFilterProfileFilter>;
  file?: Maybe<ProfileImageFilterFileFilter>;
};

export type ProfileImageFilterFileFilter = {
  and?: Maybe<Array<ProfileImageFilterFileFilter>>;
  or?: Maybe<Array<ProfileImageFilterFileFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  key?: Maybe<StringFieldComparison>;
};

export type ProfileImageFilterProfileFilter = {
  and?: Maybe<Array<ProfileImageFilterProfileFilter>>;
  or?: Maybe<Array<ProfileImageFilterProfileFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type ProfileImageMaxAggregate = {
  __typename?: 'ProfileImageMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Float']>;
};

export type ProfileImageMinAggregate = {
  __typename?: 'ProfileImageMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Float']>;
};

export type ProfileImageSort = {
  field: ProfileImageSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ProfileImageSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Order = 'order',
}

export type ProfileImageSumAggregate = {
  __typename?: 'ProfileImageSumAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type ProfileImageUpdateFilter = {
  and?: Maybe<Array<ProfileImageUpdateFilter>>;
  or?: Maybe<Array<ProfileImageUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  order?: Maybe<NumberFieldComparison>;
};

export type ProfileImagesConnection = {
  __typename?: 'ProfileImagesConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ProfileImageEdge>;
};

export type ProfileInterest = {
  __typename?: 'ProfileInterest';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};

export type ProfileInterestAggregateGroupBy = {
  __typename?: 'ProfileInterestAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type ProfileInterestConnection = {
  __typename?: 'ProfileInterestConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ProfileInterestEdge>;
};

export type ProfileInterestCountAggregate = {
  __typename?: 'ProfileInterestCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['Int']>;
};

export type ProfileInterestDeleteFilter = {
  and?: Maybe<Array<ProfileInterestDeleteFilter>>;
  or?: Maybe<Array<ProfileInterestDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
};

export type ProfileInterestDeleteResponse = {
  __typename?: 'ProfileInterestDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type ProfileInterestEdge = {
  __typename?: 'ProfileInterestEdge';
  /** The node containing the ProfileInterest */
  node: ProfileInterest;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ProfileInterestFilter = {
  and?: Maybe<Array<ProfileInterestFilter>>;
  or?: Maybe<Array<ProfileInterestFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
};

export type ProfileInterestMaxAggregate = {
  __typename?: 'ProfileInterestMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type ProfileInterestMinAggregate = {
  __typename?: 'ProfileInterestMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type ProfileInterestSort = {
  field: ProfileInterestSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ProfileInterestSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Name = 'name',
  Slug = 'slug',
}

export type ProfileInterestUpdateFilter = {
  and?: Maybe<Array<ProfileInterestUpdateFilter>>;
  or?: Maybe<Array<ProfileInterestUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
};

export type ProfileInterestsConnection = {
  __typename?: 'ProfileInterestsConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ProfileInterestEdge>;
};

export type ProfileMaxAggregate = {
  __typename?: 'ProfileMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProfileMinAggregate = {
  __typename?: 'ProfileMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProfileSort = {
  field: ProfileSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ProfileSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
}

export type ProfileStrength = {
  __typename?: 'ProfileStrength';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};

export type ProfileStrengthAggregateGroupBy = {
  __typename?: 'ProfileStrengthAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type ProfileStrengthConnection = {
  __typename?: 'ProfileStrengthConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ProfileStrengthEdge>;
};

export type ProfileStrengthCountAggregate = {
  __typename?: 'ProfileStrengthCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['Int']>;
};

export type ProfileStrengthDeleteFilter = {
  and?: Maybe<Array<ProfileStrengthDeleteFilter>>;
  or?: Maybe<Array<ProfileStrengthDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
};

export type ProfileStrengthDeleteResponse = {
  __typename?: 'ProfileStrengthDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type ProfileStrengthEdge = {
  __typename?: 'ProfileStrengthEdge';
  /** The node containing the ProfileStrength */
  node: ProfileStrength;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ProfileStrengthFilter = {
  and?: Maybe<Array<ProfileStrengthFilter>>;
  or?: Maybe<Array<ProfileStrengthFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
};

export type ProfileStrengthMaxAggregate = {
  __typename?: 'ProfileStrengthMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type ProfileStrengthMinAggregate = {
  __typename?: 'ProfileStrengthMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type ProfileStrengthSort = {
  field: ProfileStrengthSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ProfileStrengthSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Name = 'name',
  Slug = 'slug',
}

export type ProfileStrengthUpdateFilter = {
  and?: Maybe<Array<ProfileStrengthUpdateFilter>>;
  or?: Maybe<Array<ProfileStrengthUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
};

export type ProfileStrengthsConnection = {
  __typename?: 'ProfileStrengthsConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ProfileStrengthEdge>;
};

export type ProfileUpdateFilter = {
  and?: Maybe<Array<ProfileUpdateFilter>>;
  or?: Maybe<Array<ProfileUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type Query = {
  __typename?: 'Query';
  file?: Maybe<File>;
  files: FileConnection;
  auth?: Maybe<Auth>;
  auths: AuthConnection;
  userAggregate: Array<UserAggregateResponse>;
  user?: Maybe<User>;
  users: UserConnection;
  me: User;
  groupExperienceEvents: EventConnection;
  bookings: EventConnection;
  event?: Maybe<Event>;
  events: EventConnection;
  zoomUser?: Maybe<ZoomUser>;
  zoomUsers: ZoomUserConnection;
  zoomMeeting?: Maybe<ZoomMeeting>;
  zoomMeetings: ZoomMeetingConnection;
  attendee?: Maybe<Attendee>;
  attendees: AttendeeConnection;
  calendar?: Maybe<Calendar>;
  calendars: CalendarConnection;
  profile?: Maybe<Profile>;
  profiles: ProfileConnection;
  profileInterest?: Maybe<ProfileInterest>;
  profileInterests: ProfileInterestConnection;
  profileStrength?: Maybe<ProfileStrength>;
  profileStrengths: ProfileStrengthConnection;
  profileImage?: Maybe<ProfileImage>;
  profileImages: ProfileImageConnection;
  oEmbed?: Maybe<OEmbed>;
  oEmbeds: OEmbedConnection;
  experience?: Maybe<Experience>;
  experiences: ExperienceConnection;
  experienceAvailability: Array<AvailableTimeslot>;
  image?: Maybe<Image>;
  images: ImageConnection;
  schedule?: Maybe<Schedule>;
  schedules: ScheduleConnection;
  timeslot?: Maybe<Timeslot>;
  timeslots: TimeslotConnection;
  order?: Maybe<Order>;
  orders: OrderConnection;
  stripeCheckoutSession: StripeCheckoutSession;
  stripeCustomer?: Maybe<StripeCustomer>;
  stripeCustomers: StripeCustomerConnection;
  stripeProduct?: Maybe<StripeProduct>;
  stripeProducts: StripeProductConnection;
  notificationAggregate: Array<NotificationAggregateResponse>;
  notification?: Maybe<Notification>;
  notifications: NotificationConnection;
  feedback?: Maybe<Feedback>;
  feedbacks: FeedbackConnection;
  message?: Maybe<Message>;
  messages: MessageConnection;
  messageThreadAggregate: Array<MessageThreadAggregateResponse>;
  messageThread?: Maybe<MessageThread>;
  messageThreads: MessageThreadConnection;
  threadParticipantsAggregate: Array<ThreadParticipantsAggregateResponse>;
  threadParticipants: ThreadParticipantsConnection;
  messageFile?: Maybe<MessageFile>;
  messageFiles: MessageFileConnection;
  messageFlagging?: Maybe<MessageFlagging>;
  messageFlaggings: MessageFlaggingConnection;
  experienceLocationReport: ExperienceLocationReportObject;
  generalReport: GeneralReportObject;
  tag?: Maybe<Tag>;
  tags: TagConnection;
  experienceTag?: Maybe<ExperienceTag>;
  experienceTags: ExperienceTagConnection;
};

export type QueryFileArgs = {
  id: Scalars['ID'];
};

export type QueryFilesArgs = {
  paging?: CursorPaging;
  filter?: FileFilter;
  sorting?: Array<FileSort>;
};

export type QueryAuthArgs = {
  id: Scalars['ID'];
};

export type QueryAuthsArgs = {
  paging?: CursorPaging;
  filter?: AuthFilter;
  sorting?: Array<AuthSort>;
};

export type QueryUserAggregateArgs = {
  filter?: Maybe<UserAggregateFilter>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUsersArgs = {
  paging?: CursorPaging;
  filter?: UserFilter;
  sorting?: Array<UserSort>;
};

export type QueryGroupExperienceEventsArgs = {
  paging?: CursorPaging;
  filter?: EventFilter;
  sorting?: Array<EventSort>;
};

export type QueryBookingsArgs = {
  paging?: CursorPaging;
  filter?: EventFilter;
  sorting?: Array<EventSort>;
};

export type QueryEventArgs = {
  id: Scalars['ID'];
};

export type QueryEventsArgs = {
  paging?: CursorPaging;
  filter?: EventFilter;
  sorting?: Array<EventSort>;
};

export type QueryZoomUserArgs = {
  id: Scalars['ID'];
};

export type QueryZoomUsersArgs = {
  paging?: CursorPaging;
  filter?: ZoomUserFilter;
  sorting?: Array<ZoomUserSort>;
};

export type QueryZoomMeetingArgs = {
  id: Scalars['ID'];
};

export type QueryZoomMeetingsArgs = {
  paging?: CursorPaging;
  filter?: ZoomMeetingFilter;
  sorting?: Array<ZoomMeetingSort>;
};

export type QueryAttendeeArgs = {
  id: Scalars['ID'];
};

export type QueryAttendeesArgs = {
  paging?: CursorPaging;
  filter?: AttendeeFilter;
  sorting?: Array<AttendeeSort>;
};

export type QueryCalendarArgs = {
  id: Scalars['ID'];
};

export type QueryCalendarsArgs = {
  paging?: CursorPaging;
  filter?: CalendarFilter;
  sorting?: Array<CalendarSort>;
};

export type QueryProfileArgs = {
  id: Scalars['ID'];
};

export type QueryProfilesArgs = {
  paging?: CursorPaging;
  filter?: ProfileFilter;
  sorting?: Array<ProfileSort>;
};

export type QueryProfileInterestArgs = {
  id: Scalars['ID'];
};

export type QueryProfileInterestsArgs = {
  paging?: CursorPaging;
  filter?: ProfileInterestFilter;
  sorting?: Array<ProfileInterestSort>;
};

export type QueryProfileStrengthArgs = {
  id: Scalars['ID'];
};

export type QueryProfileStrengthsArgs = {
  paging?: CursorPaging;
  filter?: ProfileStrengthFilter;
  sorting?: Array<ProfileStrengthSort>;
};

export type QueryProfileImageArgs = {
  id: Scalars['ID'];
};

export type QueryProfileImagesArgs = {
  paging?: CursorPaging;
  filter?: ProfileImageFilter;
  sorting?: Array<ProfileImageSort>;
};

export type QueryOEmbedArgs = {
  id: Scalars['ID'];
};

export type QueryOEmbedsArgs = {
  paging?: CursorPaging;
  filter?: OEmbedFilter;
  sorting?: Array<OEmbedSort>;
};

export type QueryExperienceArgs = {
  id: Scalars['ID'];
};

export type QueryExperiencesArgs = {
  paging?: CursorPaging;
  filter?: ExperienceFilter;
  sorting?: Array<ExperienceSort>;
};

export type QueryExperienceAvailabilityArgs = {
  input: ExperienceAvailabilityInput;
};

export type QueryImageArgs = {
  id: Scalars['ID'];
};

export type QueryImagesArgs = {
  paging?: CursorPaging;
  filter?: ImageFilter;
  sorting?: Array<ImageSort>;
};

export type QueryScheduleArgs = {
  id: Scalars['ID'];
};

export type QuerySchedulesArgs = {
  paging?: CursorPaging;
  filter?: ScheduleFilter;
  sorting?: Array<ScheduleSort>;
};

export type QueryTimeslotArgs = {
  id: Scalars['ID'];
};

export type QueryTimeslotsArgs = {
  paging?: CursorPaging;
  filter?: TimeslotFilter;
  sorting?: Array<TimeslotSort>;
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
};

export type QueryOrdersArgs = {
  paging?: CursorPaging;
  filter?: OrderFilter;
  sorting?: Array<OrderSort>;
};

export type QueryStripeCheckoutSessionArgs = {
  id: Scalars['String'];
};

export type QueryStripeCustomerArgs = {
  id: Scalars['ID'];
};

export type QueryStripeCustomersArgs = {
  paging?: CursorPaging;
  filter?: StripeCustomerFilter;
  sorting?: Array<StripeCustomerSort>;
};

export type QueryStripeProductArgs = {
  id: Scalars['ID'];
};

export type QueryStripeProductsArgs = {
  paging?: CursorPaging;
  filter?: StripeProductFilter;
  sorting?: Array<StripeProductSort>;
};

export type QueryNotificationAggregateArgs = {
  filter?: Maybe<NotificationAggregateFilter>;
};

export type QueryNotificationArgs = {
  id: Scalars['ID'];
};

export type QueryNotificationsArgs = {
  paging?: CursorPaging;
  filter?: NotificationFilter;
  sorting?: Array<NotificationSort>;
};

export type QueryFeedbackArgs = {
  id: Scalars['ID'];
};

export type QueryFeedbacksArgs = {
  paging?: CursorPaging;
  filter?: FeedbackFilter;
  sorting?: Array<FeedbackSort>;
};

export type QueryMessageArgs = {
  id: Scalars['ID'];
};

export type QueryMessagesArgs = {
  paging?: CursorPaging;
  filter?: MessageFilter;
  sorting?: Array<MessageSort>;
};

export type QueryMessageThreadAggregateArgs = {
  filter?: Maybe<MessageThreadAggregateFilter>;
};

export type QueryMessageThreadArgs = {
  id: Scalars['ID'];
};

export type QueryMessageThreadsArgs = {
  paging?: CursorPaging;
  filter?: MessageThreadFilter;
  sorting?: Array<MessageThreadSort>;
};

export type QueryThreadParticipantsAggregateArgs = {
  filter?: Maybe<ThreadParticipantsAggregateFilter>;
};

export type QueryThreadParticipantsArgs = {
  paging?: CursorPaging;
  filter?: ThreadParticipantsFilter;
  sorting?: Array<ThreadParticipantsSort>;
};

export type QueryMessageFileArgs = {
  id: Scalars['ID'];
};

export type QueryMessageFilesArgs = {
  paging?: CursorPaging;
  filter?: MessageFileFilter;
  sorting?: Array<MessageFileSort>;
};

export type QueryMessageFlaggingArgs = {
  id: Scalars['ID'];
};

export type QueryMessageFlaggingsArgs = {
  paging?: CursorPaging;
  filter?: MessageFlaggingFilter;
  sorting?: Array<MessageFlaggingSort>;
};

export type QueryExperienceLocationReportArgs = {
  input?: Maybe<ExperienceLocationReportInput>;
};

export type QueryGeneralReportArgs = {
  input?: Maybe<GeneralReportInput>;
};

export type QueryTagArgs = {
  id: Scalars['ID'];
};

export type QueryTagsArgs = {
  paging?: CursorPaging;
  filter?: TagFilter;
  sorting?: Array<TagSort>;
};

export type QueryExperienceTagArgs = {
  id: Scalars['ID'];
};

export type QueryExperienceTagsArgs = {
  paging?: CursorPaging;
  filter?: ExperienceTagFilter;
  sorting?: Array<ExperienceTagSort>;
};

export type RelationInput = {
  id: Scalars['ID'];
};

export type RemoveAttendeesFromEventInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveCalendarFromEventInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveCalendarFromExperienceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveCreatedByFromFeedbackInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveEventFromAttendeeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveEventFromFeedbackInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveEventsFromCalendarInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveExperienceFromCalendarInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveExperienceFromExperienceTagInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveExperienceFromFeedbackInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveExperienceFromImageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveExperienceFromOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveExperienceFromStripeProductInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveExperienceTagsFromExperienceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveExperienceTagsFromTagInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveExperiencesFromUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveFeedbacksFromUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveFileFromImageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveFileFromMessageFileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveFileFromProfileImageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveIconFromTagInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveImagesFromExperienceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveImagesFromProfileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveInterestsFromProfileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveMeetingsFromZoomUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveMessageFilesFromMessageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveMessageFlaggingFromMessageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMessageFlaggingsFromMessageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveMessageFromMessageFileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMessageThreadFromMessageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMessageThreadFromThreadParticipantsInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMessagesFromMessageFlaggingInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveMessagesFromMessageThreadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveProfileFromProfileImageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveProfileFromUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveScheduleFromCalendarInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveStrengthsFromProfileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveTagFromExperienceTagInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveThreadParticipantsFromMessageThreadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveTimeslotsFromScheduleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveUserFromAttendeeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromAuthInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromFeedbackInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromMessageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromProfileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromStripeCustomerInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromThreadParticipantsInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromZoomUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUsersFromThreadParticipantsInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveZoomUserFromUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveZoomUserFromZoomMeetingInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type ReportLocationObject = {
  __typename?: 'ReportLocationObject';
  locality?: Maybe<Scalars['String']>;
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  numExperiences?: Maybe<Scalars['Float']>;
  numAttendees?: Maybe<Scalars['Float']>;
  numUniqueAttendees?: Maybe<Scalars['Float']>;
  numEvents?: Maybe<Scalars['Float']>;
  numHosts?: Maybe<Scalars['Float']>;
  experiences?: Maybe<Array<Experience>>;
};

export type RequestBookingInput = {
  experienceId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type RescheduleBookingInput = {
  eventId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type Schedule = {
  __typename?: 'Schedule';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  duration: Scalars['Float'];
  durationMetric: TimeMetric;
  frequency: Scalars['Float'];
  frequencyMetric: TimeMetric;
  range: Scalars['Float'];
  rangeMetric: TimeMetric;
  delay: Scalars['Float'];
  delayMetric: TimeMetric;
  cap: Scalars['Float'];
  capRange: Scalars['Float'];
  capRangeMetric: TimeMetric;
  timeslots: ScheduleTimeslotsConnection;
};

export type ScheduleTimeslotsArgs = {
  paging?: CursorPaging;
  filter?: TimeslotFilter;
  sorting?: Array<TimeslotSort>;
};

export type ScheduleAggregateGroupBy = {
  __typename?: 'ScheduleAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Float']>;
  durationMetric?: Maybe<TimeMetric>;
  frequency?: Maybe<Scalars['Float']>;
  frequencyMetric?: Maybe<TimeMetric>;
  range?: Maybe<Scalars['Float']>;
  rangeMetric?: Maybe<TimeMetric>;
  delay?: Maybe<Scalars['Float']>;
  delayMetric?: Maybe<TimeMetric>;
  cap?: Maybe<Scalars['Float']>;
  capRange?: Maybe<Scalars['Float']>;
  capRangeMetric?: Maybe<TimeMetric>;
};

export type ScheduleAvgAggregate = {
  __typename?: 'ScheduleAvgAggregate';
  duration?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  delay?: Maybe<Scalars['Float']>;
  cap?: Maybe<Scalars['Float']>;
  capRange?: Maybe<Scalars['Float']>;
};

export type ScheduleConnection = {
  __typename?: 'ScheduleConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ScheduleEdge>;
};

export type ScheduleCountAggregate = {
  __typename?: 'ScheduleCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  durationMetric?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['Int']>;
  frequencyMetric?: Maybe<Scalars['Int']>;
  range?: Maybe<Scalars['Int']>;
  rangeMetric?: Maybe<Scalars['Int']>;
  delay?: Maybe<Scalars['Int']>;
  delayMetric?: Maybe<Scalars['Int']>;
  cap?: Maybe<Scalars['Int']>;
  capRange?: Maybe<Scalars['Int']>;
  capRangeMetric?: Maybe<Scalars['Int']>;
};

export type ScheduleDeleteFilter = {
  and?: Maybe<Array<ScheduleDeleteFilter>>;
  or?: Maybe<Array<ScheduleDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  duration?: Maybe<NumberFieldComparison>;
  durationMetric?: Maybe<TimeMetricFilterComparison>;
  frequency?: Maybe<NumberFieldComparison>;
  frequencyMetric?: Maybe<TimeMetricFilterComparison>;
  range?: Maybe<NumberFieldComparison>;
  rangeMetric?: Maybe<TimeMetricFilterComparison>;
  delay?: Maybe<NumberFieldComparison>;
  delayMetric?: Maybe<TimeMetricFilterComparison>;
  cap?: Maybe<NumberFieldComparison>;
  capRange?: Maybe<NumberFieldComparison>;
  capRangeMetric?: Maybe<TimeMetricFilterComparison>;
};

export type ScheduleDeleteResponse = {
  __typename?: 'ScheduleDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Float']>;
  durationMetric?: Maybe<TimeMetric>;
  frequency?: Maybe<Scalars['Float']>;
  frequencyMetric?: Maybe<TimeMetric>;
  range?: Maybe<Scalars['Float']>;
  rangeMetric?: Maybe<TimeMetric>;
  delay?: Maybe<Scalars['Float']>;
  delayMetric?: Maybe<TimeMetric>;
  cap?: Maybe<Scalars['Float']>;
  capRange?: Maybe<Scalars['Float']>;
  capRangeMetric?: Maybe<TimeMetric>;
  timeslots?: Maybe<Array<Timeslot>>;
};

export type ScheduleEdge = {
  __typename?: 'ScheduleEdge';
  /** The node containing the Schedule */
  node: Schedule;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ScheduleFilter = {
  and?: Maybe<Array<ScheduleFilter>>;
  or?: Maybe<Array<ScheduleFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  duration?: Maybe<NumberFieldComparison>;
  durationMetric?: Maybe<TimeMetricFilterComparison>;
  frequency?: Maybe<NumberFieldComparison>;
  frequencyMetric?: Maybe<TimeMetricFilterComparison>;
  range?: Maybe<NumberFieldComparison>;
  rangeMetric?: Maybe<TimeMetricFilterComparison>;
  delay?: Maybe<NumberFieldComparison>;
  delayMetric?: Maybe<TimeMetricFilterComparison>;
  cap?: Maybe<NumberFieldComparison>;
  capRange?: Maybe<NumberFieldComparison>;
  capRangeMetric?: Maybe<TimeMetricFilterComparison>;
  timeslots?: Maybe<ScheduleFilterTimeslotFilter>;
};

export type ScheduleFilterTimeslotFilter = {
  and?: Maybe<Array<ScheduleFilterTimeslotFilter>>;
  or?: Maybe<Array<ScheduleFilterTimeslotFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  scheduleId?: Maybe<IdFilterComparison>;
  start?: Maybe<DateFieldComparison>;
  end?: Maybe<DateFieldComparison>;
  free?: Maybe<BooleanFieldComparison>;
  weekly?: Maybe<BooleanFieldComparison>;
};

export type ScheduleMaxAggregate = {
  __typename?: 'ScheduleMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Float']>;
  durationMetric?: Maybe<TimeMetric>;
  frequency?: Maybe<Scalars['Float']>;
  frequencyMetric?: Maybe<TimeMetric>;
  range?: Maybe<Scalars['Float']>;
  rangeMetric?: Maybe<TimeMetric>;
  delay?: Maybe<Scalars['Float']>;
  delayMetric?: Maybe<TimeMetric>;
  cap?: Maybe<Scalars['Float']>;
  capRange?: Maybe<Scalars['Float']>;
  capRangeMetric?: Maybe<TimeMetric>;
};

export type ScheduleMinAggregate = {
  __typename?: 'ScheduleMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Float']>;
  durationMetric?: Maybe<TimeMetric>;
  frequency?: Maybe<Scalars['Float']>;
  frequencyMetric?: Maybe<TimeMetric>;
  range?: Maybe<Scalars['Float']>;
  rangeMetric?: Maybe<TimeMetric>;
  delay?: Maybe<Scalars['Float']>;
  delayMetric?: Maybe<TimeMetric>;
  cap?: Maybe<Scalars['Float']>;
  capRange?: Maybe<Scalars['Float']>;
  capRangeMetric?: Maybe<TimeMetric>;
};

export type ScheduleSort = {
  field: ScheduleSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ScheduleSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Duration = 'duration',
  DurationMetric = 'durationMetric',
  Frequency = 'frequency',
  FrequencyMetric = 'frequencyMetric',
  Range = 'range',
  RangeMetric = 'rangeMetric',
  Delay = 'delay',
  DelayMetric = 'delayMetric',
  Cap = 'cap',
  CapRange = 'capRange',
  CapRangeMetric = 'capRangeMetric',
}

export type ScheduleSumAggregate = {
  __typename?: 'ScheduleSumAggregate';
  duration?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  delay?: Maybe<Scalars['Float']>;
  cap?: Maybe<Scalars['Float']>;
  capRange?: Maybe<Scalars['Float']>;
};

export type ScheduleTimeslotsConnection = {
  __typename?: 'ScheduleTimeslotsConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<TimeslotEdge>;
};

export type ScheduleUpdateFilter = {
  and?: Maybe<Array<ScheduleUpdateFilter>>;
  or?: Maybe<Array<ScheduleUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  duration?: Maybe<NumberFieldComparison>;
  durationMetric?: Maybe<TimeMetricFilterComparison>;
  frequency?: Maybe<NumberFieldComparison>;
  frequencyMetric?: Maybe<TimeMetricFilterComparison>;
  range?: Maybe<NumberFieldComparison>;
  rangeMetric?: Maybe<TimeMetricFilterComparison>;
  delay?: Maybe<NumberFieldComparison>;
  delayMetric?: Maybe<TimeMetricFilterComparison>;
  cap?: Maybe<NumberFieldComparison>;
  capRange?: Maybe<NumberFieldComparison>;
  capRangeMetric?: Maybe<TimeMetricFilterComparison>;
};

export type SetAttendeesOnEventInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetCalendarOnEventInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCalendarOnExperienceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCreatedByOnFeedbackInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetEventOnAttendeeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetEventOnFeedbackInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetEventsOnCalendarInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetExperienceOnCalendarInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetExperienceOnExperienceTagInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetExperienceOnFeedbackInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetExperienceOnImageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetExperienceOnOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetExperienceOnStripeProductInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetExperienceTagsOnExperienceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetExperienceTagsOnTagInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetExperiencesOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetFeedbacksOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetFileOnImageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetFileOnMessageFileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetFileOnProfileImageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetIconOnTagInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetImagesOnExperienceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetImagesOnProfileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetInterestsOnProfileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMeetingsOnZoomUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMessageFilesOnMessageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMessageFlaggingOnMessageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMessageFlaggingsOnMessageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMessageOnMessageFileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMessageThreadOnMessageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMessageThreadOnThreadParticipantsInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMessagesOnMessageFlaggingInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMessagesOnMessageThreadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetProfileOnProfileImageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetProfileOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetScheduleOnCalendarInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetStrengthsOnProfileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetTagOnExperienceTagInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetThreadParticipantsOnMessageThreadInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetTimeslotsOnScheduleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetUserOnAttendeeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnAuthInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnFeedbackInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnMessageInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnProfileInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnStripeCustomerInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnThreadParticipantsInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnZoomUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUsersOnThreadParticipantsInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetZoomUserOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetZoomUserOnZoomMeetingInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST',
}

export type Source = {
  __typename?: 'Source';
  src: Scalars['String'];
  srcSet?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  mimetype?: Maybe<Scalars['String']>;
};

export type SourceSizeInput = {
  width: Scalars['Float'];
  height: Scalars['Float'];
};

export type StringFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  like?: Maybe<Scalars['String']>;
  notLike?: Maybe<Scalars['String']>;
  iLike?: Maybe<Scalars['String']>;
  notILike?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
};

export type StripeCheckoutSession = {
  __typename?: 'StripeCheckoutSession';
  id: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
};

export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  stripeLivemode: Scalars['Boolean'];
  stripeUrl: Scalars['String'];
  user: User;
};

export type StripeCustomerAggregateGroupBy = {
  __typename?: 'StripeCustomerAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeLivemode?: Maybe<Scalars['Boolean']>;
};

export type StripeCustomerConnection = {
  __typename?: 'StripeCustomerConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<StripeCustomerEdge>;
};

export type StripeCustomerCountAggregate = {
  __typename?: 'StripeCustomerCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['Int']>;
  stripeLivemode?: Maybe<Scalars['Int']>;
};

export type StripeCustomerDeleteFilter = {
  and?: Maybe<Array<StripeCustomerDeleteFilter>>;
  or?: Maybe<Array<StripeCustomerDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  stripeId?: Maybe<StringFieldComparison>;
  stripeLivemode?: Maybe<BooleanFieldComparison>;
};

export type StripeCustomerDeleteResponse = {
  __typename?: 'StripeCustomerDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeLivemode?: Maybe<Scalars['Boolean']>;
  stripeUrl?: Maybe<Scalars['String']>;
};

export type StripeCustomerEdge = {
  __typename?: 'StripeCustomerEdge';
  /** The node containing the StripeCustomer */
  node: StripeCustomer;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type StripeCustomerFilter = {
  and?: Maybe<Array<StripeCustomerFilter>>;
  or?: Maybe<Array<StripeCustomerFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  stripeId?: Maybe<StringFieldComparison>;
  stripeLivemode?: Maybe<BooleanFieldComparison>;
  user?: Maybe<StripeCustomerFilterUserFilter>;
};

export type StripeCustomerFilterUserFilter = {
  and?: Maybe<Array<StripeCustomerFilterUserFilter>>;
  or?: Maybe<Array<StripeCustomerFilterUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  emailVerified?: Maybe<BooleanFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  phoneVerified?: Maybe<BooleanFieldComparison>;
  username?: Maybe<StringFieldComparison>;
  firstName?: Maybe<StringFieldComparison>;
  lastName?: Maybe<StringFieldComparison>;
  nickname?: Maybe<StringFieldComparison>;
  avatar?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  continent?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  welcomeComplete?: Maybe<DateFieldComparison>;
  welcomeVetted?: Maybe<BooleanFieldComparison>;
  criminalRecordCheckDate?: Maybe<DateFieldComparison>;
  criminalRecordCheckValid?: Maybe<BooleanFieldComparison>;
  criminalRecordCheck?: Maybe<StringFieldComparison>;
  location?: Maybe<PointObjectFilterComparison>;
  dob?: Maybe<StringFieldComparison>;
  address?: Maybe<AddressObjectFilterComparison>;
};

export type StripeCustomerMaxAggregate = {
  __typename?: 'StripeCustomerMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerMinAggregate = {
  __typename?: 'StripeCustomerMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerSort = {
  field: StripeCustomerSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum StripeCustomerSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeId = 'stripeId',
  StripeLivemode = 'stripeLivemode',
}

export type StripeCustomerUpdateFilter = {
  and?: Maybe<Array<StripeCustomerUpdateFilter>>;
  or?: Maybe<Array<StripeCustomerUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  stripeId?: Maybe<StringFieldComparison>;
  stripeLivemode?: Maybe<BooleanFieldComparison>;
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  stripeLivemode: Scalars['Boolean'];
  stripeUrl: Scalars['String'];
  priceId: Scalars['String'];
  experience: Experience;
};

export type StripeProductAggregateGroupBy = {
  __typename?: 'StripeProductAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeLivemode?: Maybe<Scalars['Boolean']>;
  priceId?: Maybe<Scalars['String']>;
};

export type StripeProductConnection = {
  __typename?: 'StripeProductConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<StripeProductEdge>;
};

export type StripeProductCountAggregate = {
  __typename?: 'StripeProductCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['Int']>;
  stripeLivemode?: Maybe<Scalars['Int']>;
  priceId?: Maybe<Scalars['Int']>;
};

export type StripeProductDeleteFilter = {
  and?: Maybe<Array<StripeProductDeleteFilter>>;
  or?: Maybe<Array<StripeProductDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  stripeId?: Maybe<StringFieldComparison>;
  stripeLivemode?: Maybe<BooleanFieldComparison>;
  priceId?: Maybe<StringFieldComparison>;
};

export type StripeProductDeleteResponse = {
  __typename?: 'StripeProductDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeLivemode?: Maybe<Scalars['Boolean']>;
  stripeUrl?: Maybe<Scalars['String']>;
  priceId?: Maybe<Scalars['String']>;
};

export type StripeProductEdge = {
  __typename?: 'StripeProductEdge';
  /** The node containing the StripeProduct */
  node: StripeProduct;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type StripeProductFilter = {
  and?: Maybe<Array<StripeProductFilter>>;
  or?: Maybe<Array<StripeProductFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  stripeId?: Maybe<StringFieldComparison>;
  stripeLivemode?: Maybe<BooleanFieldComparison>;
  priceId?: Maybe<StringFieldComparison>;
  experience?: Maybe<StripeProductFilterExperienceFilter>;
};

export type StripeProductFilterExperienceFilter = {
  and?: Maybe<Array<StripeProductFilterExperienceFilter>>;
  or?: Maybe<Array<StripeProductFilterExperienceFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  meetingScheduled?: Maybe<BooleanFieldComparison>;
  progressStep?: Maybe<ExperienceProgressStepFilterComparison>;
  hostId?: Maybe<StringFieldComparison>;
  video?: Maybe<StringFieldComparison>;
  featured?: Maybe<NumberFieldComparison>;
  category?: Maybe<ExperienceCategoryFilterComparison>;
  publishedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<ExperienceStatusFilterComparison>;
  nextAvailability?: Maybe<DateFieldComparison>;
  nextEvent?: Maybe<DateFieldComparison>;
  next?: Maybe<DateFieldComparison>;
  type?: Maybe<ExperienceTypeFilterComparison>;
  mode?: Maybe<ExperienceModeFilterComparison>;
  locationType?: Maybe<ExperienceLocationTypeFilterComparison>;
  approximateLocation?: Maybe<PointObjectFilterComparison>;
  coordinates?: Maybe<PointObjectFilterComparison>;
  streetNumber?: Maybe<StringFieldComparison>;
  route?: Maybe<StringFieldComparison>;
  locality?: Maybe<StringFieldComparison>;
  administrativeAreaLevel2?: Maybe<StringFieldComparison>;
  administrativeAreaLevel1?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  postalCode?: Maybe<StringFieldComparison>;
};

export type StripeProductMaxAggregate = {
  __typename?: 'StripeProductMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  priceId?: Maybe<Scalars['String']>;
};

export type StripeProductMinAggregate = {
  __typename?: 'StripeProductMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  priceId?: Maybe<Scalars['String']>;
};

export type StripeProductSort = {
  field: StripeProductSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum StripeProductSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeId = 'stripeId',
  StripeLivemode = 'stripeLivemode',
  PriceId = 'priceId',
}

export type StripeProductUpdateFilter = {
  and?: Maybe<Array<StripeProductUpdateFilter>>;
  or?: Maybe<Array<StripeProductUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  stripeId?: Maybe<StringFieldComparison>;
  stripeLivemode?: Maybe<BooleanFieldComparison>;
  priceId?: Maybe<StringFieldComparison>;
};

export type Subscription = {
  __typename?: 'Subscription';
  createdNotification: Notification;
  updatedOneNotification: Notification;
  updatedManyNotifications: UpdateManyResponse;
  deletedOneNotification: NotificationDeleteResponse;
  deletedManyNotifications: DeleteManyResponse;
  createdMessage: Message;
  updatedOneMessage: Message;
  updatedManyMessages: UpdateManyResponse;
  deletedOneMessage: MessageDeleteResponse;
  deletedManyMessages: DeleteManyResponse;
  createdMessageThread: MessageThread;
  updatedOneMessageThread: MessageThread;
  updatedManyMessageThreads: UpdateManyResponse;
  deletedOneMessageThread: MessageThreadDeleteResponse;
  deletedManyMessageThreads: DeleteManyResponse;
  createdThreadParticipants: ThreadParticipants;
  updatedOneThreadParticipants: ThreadParticipants;
  updatedManyThreadParticipants: UpdateManyResponse;
  deletedOneThreadParticipants: ThreadParticipantsDeleteResponse;
  deletedManyThreadParticipants: DeleteManyResponse;
};

export type SubscriptionCreatedNotificationArgs = {
  input?: Maybe<CreateNotificationSubscriptionFilterInput>;
};

export type SubscriptionUpdatedOneNotificationArgs = {
  input?: Maybe<UpdateOneNotificationSubscriptionFilterInput>;
};

export type SubscriptionDeletedOneNotificationArgs = {
  input?: Maybe<DeleteOneNotificationSubscriptionFilterInput>;
};

export type SubscriptionCreatedMessageArgs = {
  input?: Maybe<CreateMessageSubscriptionFilterInput>;
};

export type SubscriptionUpdatedOneMessageArgs = {
  input?: Maybe<UpdateOneMessageSubscriptionFilterInput>;
};

export type SubscriptionDeletedOneMessageArgs = {
  input?: Maybe<DeleteOneMessageSubscriptionFilterInput>;
};

export type SubscriptionCreatedMessageThreadArgs = {
  input?: Maybe<CreateMessageThreadSubscriptionFilterInput>;
};

export type SubscriptionUpdatedOneMessageThreadArgs = {
  input?: Maybe<UpdateOneMessageThreadSubscriptionFilterInput>;
};

export type SubscriptionDeletedOneMessageThreadArgs = {
  input?: Maybe<DeleteOneMessageThreadSubscriptionFilterInput>;
};

export type SubscriptionCreatedThreadParticipantsArgs = {
  input?: Maybe<CreateThreadParticipantsSubscriptionFilterInput>;
};

export type SubscriptionUpdatedOneThreadParticipantsArgs = {
  input?: Maybe<UpdateOneThreadParticipantsSubscriptionFilterInput>;
};

export type SubscriptionDeletedOneThreadParticipantsArgs = {
  input?: Maybe<DeleteOneThreadParticipantsSubscriptionFilterInput>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<TagCategory>;
  public?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<File>;
  experienceTags: TagExperienceTagsConnection;
};

export type TagExperienceTagsArgs = {
  paging?: CursorPaging;
  filter?: ExperienceTagFilter;
  sorting?: Array<ExperienceTagSort>;
};

export type TagAggregateGroupBy = {
  __typename?: 'TagAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<TagCategory>;
  public?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export enum TagCategory {
  WhatToBring = 'WhatToBring',
  Setting = 'Setting',
  Accessibility = 'Accessibility',
}

export type TagCategoryFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<TagCategory>;
  neq?: Maybe<TagCategory>;
  gt?: Maybe<TagCategory>;
  gte?: Maybe<TagCategory>;
  lt?: Maybe<TagCategory>;
  lte?: Maybe<TagCategory>;
  like?: Maybe<TagCategory>;
  notLike?: Maybe<TagCategory>;
  iLike?: Maybe<TagCategory>;
  notILike?: Maybe<TagCategory>;
  in?: Maybe<Array<TagCategory>>;
  notIn?: Maybe<Array<TagCategory>>;
};

export type TagConnection = {
  __typename?: 'TagConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<TagEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type TagCountAggregate = {
  __typename?: 'TagCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['Int']>;
  public?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
};

export type TagDeleteFilter = {
  and?: Maybe<Array<TagDeleteFilter>>;
  or?: Maybe<Array<TagDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  category?: Maybe<TagCategoryFilterComparison>;
  public?: Maybe<BooleanFieldComparison>;
  description?: Maybe<StringFieldComparison>;
};

export type TagDeleteResponse = {
  __typename?: 'TagDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<TagCategory>;
  public?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type TagEdge = {
  __typename?: 'TagEdge';
  /** The node containing the Tag */
  node: Tag;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type TagExperienceTagsConnection = {
  __typename?: 'TagExperienceTagsConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ExperienceTagEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type TagFilter = {
  and?: Maybe<Array<TagFilter>>;
  or?: Maybe<Array<TagFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  category?: Maybe<TagCategoryFilterComparison>;
  public?: Maybe<BooleanFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  icon?: Maybe<TagFilterFileFilter>;
};

export type TagFilterFileFilter = {
  and?: Maybe<Array<TagFilterFileFilter>>;
  or?: Maybe<Array<TagFilterFileFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  key?: Maybe<StringFieldComparison>;
};

export type TagMaxAggregate = {
  __typename?: 'TagMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<TagCategory>;
  description?: Maybe<Scalars['String']>;
};

export type TagMinAggregate = {
  __typename?: 'TagMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<TagCategory>;
  description?: Maybe<Scalars['String']>;
};

export type TagSort = {
  field: TagSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TagSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Slug = 'slug',
  Title = 'title',
  Category = 'category',
  Public = 'public',
  Description = 'description',
}

export type TagUpdateFilter = {
  and?: Maybe<Array<TagUpdateFilter>>;
  or?: Maybe<Array<TagUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  category?: Maybe<TagCategoryFilterComparison>;
  public?: Maybe<BooleanFieldComparison>;
  description?: Maybe<StringFieldComparison>;
};

export type ThreadParticipants = {
  __typename?: 'ThreadParticipants';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  unread: Scalars['Boolean'];
  messageThreadId: Scalars['String'];
  lastMessageAt: Scalars['DateTime'];
  usersAggregate: Array<ThreadParticipantsUsersAggregateResponse>;
  messageThread?: Maybe<MessageThread>;
  user: User;
  users?: Maybe<ThreadParticipantsUsersConnection>;
};

export type ThreadParticipantsUsersAggregateArgs = {
  filter?: Maybe<UserAggregateFilter>;
};

export type ThreadParticipantsUsersArgs = {
  paging?: CursorPaging;
  filter?: UserFilter;
  sorting?: Array<UserSort>;
};

export type ThreadParticipantsAggregateFilter = {
  and?: Maybe<Array<ThreadParticipantsAggregateFilter>>;
  or?: Maybe<Array<ThreadParticipantsAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  unread?: Maybe<BooleanFieldComparison>;
  messageThreadId?: Maybe<StringFieldComparison>;
  lastMessageAt?: Maybe<DateFieldComparison>;
};

export type ThreadParticipantsAggregateGroupBy = {
  __typename?: 'ThreadParticipantsAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  unread?: Maybe<Scalars['Boolean']>;
  messageThreadId?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
};

export type ThreadParticipantsAggregateResponse = {
  __typename?: 'ThreadParticipantsAggregateResponse';
  groupBy?: Maybe<ThreadParticipantsAggregateGroupBy>;
  count?: Maybe<ThreadParticipantsCountAggregate>;
  min?: Maybe<ThreadParticipantsMinAggregate>;
  max?: Maybe<ThreadParticipantsMaxAggregate>;
};

export type ThreadParticipantsConnection = {
  __typename?: 'ThreadParticipantsConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ThreadParticipantsEdge>;
};

export type ThreadParticipantsCountAggregate = {
  __typename?: 'ThreadParticipantsCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  unread?: Maybe<Scalars['Int']>;
  messageThreadId?: Maybe<Scalars['Int']>;
  lastMessageAt?: Maybe<Scalars['Int']>;
};

export type ThreadParticipantsDeleteFilter = {
  and?: Maybe<Array<ThreadParticipantsDeleteFilter>>;
  or?: Maybe<Array<ThreadParticipantsDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  unread?: Maybe<BooleanFieldComparison>;
  messageThreadId?: Maybe<StringFieldComparison>;
  lastMessageAt?: Maybe<DateFieldComparison>;
};

export type ThreadParticipantsDeleteResponse = {
  __typename?: 'ThreadParticipantsDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  unread?: Maybe<Scalars['Boolean']>;
  messageThreadId?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
};

export type ThreadParticipantsEdge = {
  __typename?: 'ThreadParticipantsEdge';
  /** The node containing the ThreadParticipants */
  node: ThreadParticipants;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ThreadParticipantsFilter = {
  and?: Maybe<Array<ThreadParticipantsFilter>>;
  or?: Maybe<Array<ThreadParticipantsFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  unread?: Maybe<BooleanFieldComparison>;
  messageThreadId?: Maybe<StringFieldComparison>;
  lastMessageAt?: Maybe<DateFieldComparison>;
  user?: Maybe<ThreadParticipantsFilterUserFilter>;
  messageThread?: Maybe<ThreadParticipantsFilterMessageThreadFilter>;
};

export type ThreadParticipantsFilterMessageThreadFilter = {
  and?: Maybe<Array<ThreadParticipantsFilterMessageThreadFilter>>;
  or?: Maybe<Array<ThreadParticipantsFilterMessageThreadFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  lastMessageAt?: Maybe<DateFieldComparison>;
};

export type ThreadParticipantsFilterUserFilter = {
  and?: Maybe<Array<ThreadParticipantsFilterUserFilter>>;
  or?: Maybe<Array<ThreadParticipantsFilterUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  emailVerified?: Maybe<BooleanFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  phoneVerified?: Maybe<BooleanFieldComparison>;
  username?: Maybe<StringFieldComparison>;
  firstName?: Maybe<StringFieldComparison>;
  lastName?: Maybe<StringFieldComparison>;
  nickname?: Maybe<StringFieldComparison>;
  avatar?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  continent?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  welcomeComplete?: Maybe<DateFieldComparison>;
  welcomeVetted?: Maybe<BooleanFieldComparison>;
  criminalRecordCheckDate?: Maybe<DateFieldComparison>;
  criminalRecordCheckValid?: Maybe<BooleanFieldComparison>;
  criminalRecordCheck?: Maybe<StringFieldComparison>;
  location?: Maybe<PointObjectFilterComparison>;
  dob?: Maybe<StringFieldComparison>;
  address?: Maybe<AddressObjectFilterComparison>;
};

export type ThreadParticipantsMaxAggregate = {
  __typename?: 'ThreadParticipantsMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  messageThreadId?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
};

export type ThreadParticipantsMinAggregate = {
  __typename?: 'ThreadParticipantsMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  messageThreadId?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
};

export type ThreadParticipantsSort = {
  field: ThreadParticipantsSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ThreadParticipantsSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  Unread = 'unread',
  MessageThreadId = 'messageThreadId',
  LastMessageAt = 'lastMessageAt',
}

export type ThreadParticipantsSubscriptionFilter = {
  and?: Maybe<Array<ThreadParticipantsSubscriptionFilter>>;
  or?: Maybe<Array<ThreadParticipantsSubscriptionFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  unread?: Maybe<BooleanFieldComparison>;
  messageThreadId?: Maybe<StringFieldComparison>;
  lastMessageAt?: Maybe<DateFieldComparison>;
};

export type ThreadParticipantsUpdateFilter = {
  and?: Maybe<Array<ThreadParticipantsUpdateFilter>>;
  or?: Maybe<Array<ThreadParticipantsUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  userId?: Maybe<StringFieldComparison>;
  unread?: Maybe<BooleanFieldComparison>;
  messageThreadId?: Maybe<StringFieldComparison>;
  lastMessageAt?: Maybe<DateFieldComparison>;
};

export type ThreadParticipantsUsersAggregateGroupBy = {
  __typename?: 'ThreadParticipantsUsersAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  welcomeComplete?: Maybe<Scalars['DateTime']>;
  welcomeVetted?: Maybe<Scalars['Boolean']>;
  criminalRecordCheckDate?: Maybe<Scalars['DateTime']>;
  criminalRecordCheckValid?: Maybe<Scalars['Boolean']>;
  criminalRecordCheck?: Maybe<Scalars['String']>;
  location?: Maybe<Point>;
  dob?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
};

export type ThreadParticipantsUsersAggregateResponse = {
  __typename?: 'ThreadParticipantsUsersAggregateResponse';
  groupBy?: Maybe<ThreadParticipantsUsersAggregateGroupBy>;
  count?: Maybe<ThreadParticipantsUsersCountAggregate>;
  min?: Maybe<ThreadParticipantsUsersMinAggregate>;
  max?: Maybe<ThreadParticipantsUsersMaxAggregate>;
};

export type ThreadParticipantsUsersConnection = {
  __typename?: 'ThreadParticipantsUsersConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<UserEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ThreadParticipantsUsersCountAggregate = {
  __typename?: 'ThreadParticipantsUsersCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
  emailVerified?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['Int']>;
  phoneVerified?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['Int']>;
  nickname?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['Int']>;
  continent?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['Int']>;
  welcomeComplete?: Maybe<Scalars['Int']>;
  welcomeVetted?: Maybe<Scalars['Int']>;
  criminalRecordCheckDate?: Maybe<Scalars['Int']>;
  criminalRecordCheckValid?: Maybe<Scalars['Int']>;
  criminalRecordCheck?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['Int']>;
  dob?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['Int']>;
};

export type ThreadParticipantsUsersMaxAggregate = {
  __typename?: 'ThreadParticipantsUsersMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  welcomeComplete?: Maybe<Scalars['DateTime']>;
  criminalRecordCheckDate?: Maybe<Scalars['DateTime']>;
  criminalRecordCheck?: Maybe<Scalars['String']>;
  location?: Maybe<Point>;
  dob?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
};

export type ThreadParticipantsUsersMinAggregate = {
  __typename?: 'ThreadParticipantsUsersMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  welcomeComplete?: Maybe<Scalars['DateTime']>;
  criminalRecordCheckDate?: Maybe<Scalars['DateTime']>;
  criminalRecordCheck?: Maybe<Scalars['String']>;
  location?: Maybe<Point>;
  dob?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
};

export enum TimeMetric {
  Mins = 'mins',
  Hours = 'hours',
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
  Years = 'years',
}

export type TimeMetricFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<TimeMetric>;
  neq?: Maybe<TimeMetric>;
  gt?: Maybe<TimeMetric>;
  gte?: Maybe<TimeMetric>;
  lt?: Maybe<TimeMetric>;
  lte?: Maybe<TimeMetric>;
  like?: Maybe<TimeMetric>;
  notLike?: Maybe<TimeMetric>;
  iLike?: Maybe<TimeMetric>;
  notILike?: Maybe<TimeMetric>;
  in?: Maybe<Array<TimeMetric>>;
  notIn?: Maybe<Array<TimeMetric>>;
};

export type Timeslot = {
  __typename?: 'Timeslot';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  scheduleId?: Maybe<Scalars['ID']>;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  free: Scalars['Boolean'];
  weekly: Scalars['Boolean'];
};

export type TimeslotAggregateGroupBy = {
  __typename?: 'TimeslotAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  scheduleId?: Maybe<Scalars['ID']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  free?: Maybe<Scalars['Boolean']>;
  weekly?: Maybe<Scalars['Boolean']>;
};

export type TimeslotConnection = {
  __typename?: 'TimeslotConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<TimeslotEdge>;
};

export type TimeslotCountAggregate = {
  __typename?: 'TimeslotCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  scheduleId?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  free?: Maybe<Scalars['Int']>;
  weekly?: Maybe<Scalars['Int']>;
};

export type TimeslotDeleteFilter = {
  and?: Maybe<Array<TimeslotDeleteFilter>>;
  or?: Maybe<Array<TimeslotDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  scheduleId?: Maybe<IdFilterComparison>;
  start?: Maybe<DateFieldComparison>;
  end?: Maybe<DateFieldComparison>;
  free?: Maybe<BooleanFieldComparison>;
  weekly?: Maybe<BooleanFieldComparison>;
};

export type TimeslotDeleteResponse = {
  __typename?: 'TimeslotDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  scheduleId?: Maybe<Scalars['ID']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  free?: Maybe<Scalars['Boolean']>;
  weekly?: Maybe<Scalars['Boolean']>;
};

export type TimeslotEdge = {
  __typename?: 'TimeslotEdge';
  /** The node containing the Timeslot */
  node: Timeslot;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type TimeslotFilter = {
  and?: Maybe<Array<TimeslotFilter>>;
  or?: Maybe<Array<TimeslotFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  scheduleId?: Maybe<IdFilterComparison>;
  start?: Maybe<DateFieldComparison>;
  end?: Maybe<DateFieldComparison>;
  free?: Maybe<BooleanFieldComparison>;
  weekly?: Maybe<BooleanFieldComparison>;
};

export type TimeslotMaxAggregate = {
  __typename?: 'TimeslotMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  scheduleId?: Maybe<Scalars['ID']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
};

export type TimeslotMinAggregate = {
  __typename?: 'TimeslotMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  scheduleId?: Maybe<Scalars['ID']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
};

export type TimeslotSort = {
  field: TimeslotSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TimeslotSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  ScheduleId = 'scheduleId',
  Start = 'start',
  End = 'end',
  Free = 'free',
  Weekly = 'weekly',
}

export type TimeslotUpdateFilter = {
  and?: Maybe<Array<TimeslotUpdateFilter>>;
  or?: Maybe<Array<TimeslotUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  scheduleId?: Maybe<IdFilterComparison>;
  start?: Maybe<DateFieldComparison>;
  end?: Maybe<DateFieldComparison>;
  free?: Maybe<BooleanFieldComparison>;
  weekly?: Maybe<BooleanFieldComparison>;
};

export enum Timezone {
  AfricaAbidjan = 'AfricaAbidjan',
  AfricaAccra = 'AfricaAccra',
  AfricaAddisAbaba = 'AfricaAddisAbaba',
  AfricaAlgiers = 'AfricaAlgiers',
  AfricaAsmara = 'AfricaAsmara',
  AfricaAsmera = 'AfricaAsmera',
  AfricaBamako = 'AfricaBamako',
  AfricaBangui = 'AfricaBangui',
  AfricaBanjul = 'AfricaBanjul',
  AfricaBissau = 'AfricaBissau',
  AfricaBlantyre = 'AfricaBlantyre',
  AfricaBrazzaville = 'AfricaBrazzaville',
  AfricaBujumbura = 'AfricaBujumbura',
  AfricaCairo = 'AfricaCairo',
  AfricaCasablanca = 'AfricaCasablanca',
  AfricaCeuta = 'AfricaCeuta',
  AfricaConakry = 'AfricaConakry',
  AfricaDakar = 'AfricaDakar',
  AfricaDaresSalaam = 'AfricaDaresSalaam',
  AfricaDjibouti = 'AfricaDjibouti',
  AfricaDouala = 'AfricaDouala',
  AfricaElAaiun = 'AfricaElAaiun',
  AfricaFreetown = 'AfricaFreetown',
  AfricaGaborone = 'AfricaGaborone',
  AfricaHarare = 'AfricaHarare',
  AfricaJohannesburg = 'AfricaJohannesburg',
  AfricaJuba = 'AfricaJuba',
  AfricaKampala = 'AfricaKampala',
  AfricaKhartoum = 'AfricaKhartoum',
  AfricaKigali = 'AfricaKigali',
  AfricaKinshasa = 'AfricaKinshasa',
  AfricaLagos = 'AfricaLagos',
  AfricaLibreville = 'AfricaLibreville',
  AfricaLome = 'AfricaLome',
  AfricaLuanda = 'AfricaLuanda',
  AfricaLubumbashi = 'AfricaLubumbashi',
  AfricaLusaka = 'AfricaLusaka',
  AfricaMalabo = 'AfricaMalabo',
  AfricaMaputo = 'AfricaMaputo',
  AfricaMaseru = 'AfricaMaseru',
  AfricaMbabane = 'AfricaMbabane',
  AfricaMogadishu = 'AfricaMogadishu',
  AfricaMonrovia = 'AfricaMonrovia',
  AfricaNairobi = 'AfricaNairobi',
  AfricaNdjamena = 'AfricaNdjamena',
  AfricaNiamey = 'AfricaNiamey',
  AfricaNouakchott = 'AfricaNouakchott',
  AfricaOuagadougou = 'AfricaOuagadougou',
  AfricaPortoNovo = 'AfricaPortoNovo',
  AfricaSaoTome = 'AfricaSaoTome',
  AfricaTimbuktu = 'AfricaTimbuktu',
  AfricaTripoli = 'AfricaTripoli',
  AfricaTunis = 'AfricaTunis',
  AfricaWindhoek = 'AfricaWindhoek',
  AmericaAdak = 'AmericaAdak',
  AmericaAnchorage = 'AmericaAnchorage',
  AmericaAnguilla = 'AmericaAnguilla',
  AmericaAntigua = 'AmericaAntigua',
  AmericaAraguaina = 'AmericaAraguaina',
  AmericaArgentinaBuenosAires = 'AmericaArgentinaBuenosAires',
  AmericaArgentinaCatamarca = 'AmericaArgentinaCatamarca',
  AmericaArgentinaComodRivadavia = 'AmericaArgentinaComodRivadavia',
  AmericaArgentinaCordoba = 'AmericaArgentinaCordoba',
  AmericaArgentinaJujuy = 'AmericaArgentinaJujuy',
  AmericaArgentinaLaRioja = 'AmericaArgentinaLaRioja',
  AmericaArgentinaMendoza = 'AmericaArgentinaMendoza',
  AmericaArgentinaRioGallegos = 'AmericaArgentinaRioGallegos',
  AmericaArgentinaSalta = 'AmericaArgentinaSalta',
  AmericaArgentinaSanJuan = 'AmericaArgentinaSanJuan',
  AmericaArgentinaSanLuis = 'AmericaArgentinaSanLuis',
  AmericaArgentinaTucuman = 'AmericaArgentinaTucuman',
  AmericaArgentinaUshuaia = 'AmericaArgentinaUshuaia',
  AmericaAruba = 'AmericaAruba',
  AmericaAsuncion = 'AmericaAsuncion',
  AmericaAtikokan = 'AmericaAtikokan',
  AmericaAtka = 'AmericaAtka',
  AmericaBahia = 'AmericaBahia',
  AmericaBahiaBanderas = 'AmericaBahiaBanderas',
  AmericaBarbados = 'AmericaBarbados',
  AmericaBelem = 'AmericaBelem',
  AmericaBelize = 'AmericaBelize',
  AmericaBlancSablon = 'AmericaBlancSablon',
  AmericaBoaVista = 'AmericaBoaVista',
  AmericaBogota = 'AmericaBogota',
  AmericaBoise = 'AmericaBoise',
  AmericaBuenosAires = 'AmericaBuenosAires',
  AmericaCambridgeBay = 'AmericaCambridgeBay',
  AmericaCampoGrande = 'AmericaCampoGrande',
  AmericaCancun = 'AmericaCancun',
  AmericaCaracas = 'AmericaCaracas',
  AmericaCatamarca = 'AmericaCatamarca',
  AmericaCayenne = 'AmericaCayenne',
  AmericaCayman = 'AmericaCayman',
  AmericaChicago = 'AmericaChicago',
  AmericaChihuahua = 'AmericaChihuahua',
  AmericaCoralHarbour = 'AmericaCoralHarbour',
  AmericaCordoba = 'AmericaCordoba',
  AmericaCostaRica = 'AmericaCostaRica',
  AmericaCreston = 'AmericaCreston',
  AmericaCuiaba = 'AmericaCuiaba',
  AmericaCuracao = 'AmericaCuracao',
  AmericaDanmarkshavn = 'AmericaDanmarkshavn',
  AmericaDawson = 'AmericaDawson',
  AmericaDawsonCreek = 'AmericaDawsonCreek',
  AmericaDenver = 'AmericaDenver',
  AmericaDetroit = 'AmericaDetroit',
  AmericaDominica = 'AmericaDominica',
  AmericaEdmonton = 'AmericaEdmonton',
  AmericaEirunepe = 'AmericaEirunepe',
  AmericaElSalvador = 'AmericaElSalvador',
  AmericaEnsenada = 'AmericaEnsenada',
  AmericaFortNelson = 'AmericaFortNelson',
  AmericaFortWayne = 'AmericaFortWayne',
  AmericaFortaleza = 'AmericaFortaleza',
  AmericaGlaceBay = 'AmericaGlaceBay',
  AmericaGodthab = 'AmericaGodthab',
  AmericaGooseBay = 'AmericaGooseBay',
  AmericaGrandTurk = 'AmericaGrandTurk',
  AmericaGrenada = 'AmericaGrenada',
  AmericaGuadeloupe = 'AmericaGuadeloupe',
  AmericaGuatemala = 'AmericaGuatemala',
  AmericaGuayaquil = 'AmericaGuayaquil',
  AmericaGuyana = 'AmericaGuyana',
  AmericaHalifax = 'AmericaHalifax',
  AmericaHavana = 'AmericaHavana',
  AmericaHermosillo = 'AmericaHermosillo',
  AmericaIndianaIndianapolis = 'AmericaIndianaIndianapolis',
  AmericaIndianaKnox = 'AmericaIndianaKnox',
  AmericaIndianaMarengo = 'AmericaIndianaMarengo',
  AmericaIndianaPetersburg = 'AmericaIndianaPetersburg',
  AmericaIndianaTellCity = 'AmericaIndianaTellCity',
  AmericaIndianaVevay = 'AmericaIndianaVevay',
  AmericaIndianaVincennes = 'AmericaIndianaVincennes',
  AmericaIndianaWinamac = 'AmericaIndianaWinamac',
  AmericaIndianapolis = 'AmericaIndianapolis',
  AmericaInuvik = 'AmericaInuvik',
  AmericaIqaluit = 'AmericaIqaluit',
  AmericaJamaica = 'AmericaJamaica',
  AmericaJujuy = 'AmericaJujuy',
  AmericaJuneau = 'AmericaJuneau',
  AmericaKentuckyLouisville = 'AmericaKentuckyLouisville',
  AmericaKentuckyMonticello = 'AmericaKentuckyMonticello',
  AmericaKnoxIn = 'AmericaKnoxIN',
  AmericaKralendijk = 'AmericaKralendijk',
  AmericaLaPaz = 'AmericaLaPaz',
  AmericaLima = 'AmericaLima',
  AmericaLosAngeles = 'AmericaLosAngeles',
  AmericaLouisville = 'AmericaLouisville',
  AmericaLowerPrinces = 'AmericaLowerPrinces',
  AmericaMaceio = 'AmericaMaceio',
  AmericaManagua = 'AmericaManagua',
  AmericaManaus = 'AmericaManaus',
  AmericaMarigot = 'AmericaMarigot',
  AmericaMartinique = 'AmericaMartinique',
  AmericaMatamoros = 'AmericaMatamoros',
  AmericaMazatlan = 'AmericaMazatlan',
  AmericaMendoza = 'AmericaMendoza',
  AmericaMenominee = 'AmericaMenominee',
  AmericaMerida = 'AmericaMerida',
  AmericaMetlakatla = 'AmericaMetlakatla',
  AmericaMexicoCity = 'AmericaMexicoCity',
  AmericaMiquelon = 'AmericaMiquelon',
  AmericaMoncton = 'AmericaMoncton',
  AmericaMonterrey = 'AmericaMonterrey',
  AmericaMontevideo = 'AmericaMontevideo',
  AmericaMontreal = 'AmericaMontreal',
  AmericaMontserrat = 'AmericaMontserrat',
  AmericaNassau = 'AmericaNassau',
  AmericaNewYork = 'AmericaNewYork',
  AmericaNipigon = 'AmericaNipigon',
  AmericaNome = 'AmericaNome',
  AmericaNoronha = 'AmericaNoronha',
  AmericaNorthDakotaBeulah = 'AmericaNorthDakotaBeulah',
  AmericaNorthDakotaCenter = 'AmericaNorthDakotaCenter',
  AmericaNorthDakotaNewSalem = 'AmericaNorthDakotaNewSalem',
  AmericaNuuk = 'AmericaNuuk',
  AmericaOjinaga = 'AmericaOjinaga',
  AmericaPanama = 'AmericaPanama',
  AmericaPangnirtung = 'AmericaPangnirtung',
  AmericaParamaribo = 'AmericaParamaribo',
  AmericaPhoenix = 'AmericaPhoenix',
  AmericaPortauPrince = 'AmericaPortauPrince',
  AmericaPortofSpain = 'AmericaPortofSpain',
  AmericaPortoAcre = 'AmericaPortoAcre',
  AmericaPortoVelho = 'AmericaPortoVelho',
  AmericaPuertoRico = 'AmericaPuertoRico',
  AmericaPuntaArenas = 'AmericaPuntaArenas',
  AmericaRainyRiver = 'AmericaRainyRiver',
  AmericaRankinInlet = 'AmericaRankinInlet',
  AmericaRecife = 'AmericaRecife',
  AmericaRegina = 'AmericaRegina',
  AmericaResolute = 'AmericaResolute',
  AmericaRioBranco = 'AmericaRioBranco',
  AmericaRosario = 'AmericaRosario',
  AmericaSantaIsabel = 'AmericaSantaIsabel',
  AmericaSantarem = 'AmericaSantarem',
  AmericaSantiago = 'AmericaSantiago',
  AmericaSantoDomingo = 'AmericaSantoDomingo',
  AmericaSaoPaulo = 'AmericaSaoPaulo',
  AmericaScoresbysund = 'AmericaScoresbysund',
  AmericaShiprock = 'AmericaShiprock',
  AmericaSitka = 'AmericaSitka',
  AmericaStBarthelemy = 'AmericaStBarthelemy',
  AmericaStJohns = 'AmericaStJohns',
  AmericaStKitts = 'AmericaStKitts',
  AmericaStLucia = 'AmericaStLucia',
  AmericaStThomas = 'AmericaStThomas',
  AmericaStVincent = 'AmericaStVincent',
  AmericaSwiftCurrent = 'AmericaSwiftCurrent',
  AmericaTegucigalpa = 'AmericaTegucigalpa',
  AmericaThule = 'AmericaThule',
  AmericaThunderBay = 'AmericaThunderBay',
  AmericaTijuana = 'AmericaTijuana',
  AmericaToronto = 'AmericaToronto',
  AmericaTortola = 'AmericaTortola',
  AmericaVancouver = 'AmericaVancouver',
  AmericaVirgin = 'AmericaVirgin',
  AmericaWhitehorse = 'AmericaWhitehorse',
  AmericaWinnipeg = 'AmericaWinnipeg',
  AmericaYakutat = 'AmericaYakutat',
  AmericaYellowknife = 'AmericaYellowknife',
  AntarcticaCasey = 'AntarcticaCasey',
  AntarcticaDavis = 'AntarcticaDavis',
  AntarcticaDumontDUrville = 'AntarcticaDumontDUrville',
  AntarcticaMacquarie = 'AntarcticaMacquarie',
  AntarcticaMawson = 'AntarcticaMawson',
  AntarcticaMcMurdo = 'AntarcticaMcMurdo',
  AntarcticaPalmer = 'AntarcticaPalmer',
  AntarcticaRothera = 'AntarcticaRothera',
  AntarcticaSouthPole = 'AntarcticaSouthPole',
  AntarcticaSyowa = 'AntarcticaSyowa',
  AntarcticaTroll = 'AntarcticaTroll',
  AntarcticaVostok = 'AntarcticaVostok',
  ArcticLongyearbyen = 'ArcticLongyearbyen',
  AsiaAden = 'AsiaAden',
  AsiaAlmaty = 'AsiaAlmaty',
  AsiaAmman = 'AsiaAmman',
  AsiaAnadyr = 'AsiaAnadyr',
  AsiaAqtau = 'AsiaAqtau',
  AsiaAqtobe = 'AsiaAqtobe',
  AsiaAshgabat = 'AsiaAshgabat',
  AsiaAshkhabad = 'AsiaAshkhabad',
  AsiaAtyrau = 'AsiaAtyrau',
  AsiaBaghdad = 'AsiaBaghdad',
  AsiaBahrain = 'AsiaBahrain',
  AsiaBaku = 'AsiaBaku',
  AsiaBangkok = 'AsiaBangkok',
  AsiaBarnaul = 'AsiaBarnaul',
  AsiaBeirut = 'AsiaBeirut',
  AsiaBishkek = 'AsiaBishkek',
  AsiaBrunei = 'AsiaBrunei',
  AsiaCalcutta = 'AsiaCalcutta',
  AsiaChita = 'AsiaChita',
  AsiaChoibalsan = 'AsiaChoibalsan',
  AsiaChongqing = 'AsiaChongqing',
  AsiaChungking = 'AsiaChungking',
  AsiaColombo = 'AsiaColombo',
  AsiaDacca = 'AsiaDacca',
  AsiaDamascus = 'AsiaDamascus',
  AsiaDhaka = 'AsiaDhaka',
  AsiaDili = 'AsiaDili',
  AsiaDubai = 'AsiaDubai',
  AsiaDushanbe = 'AsiaDushanbe',
  AsiaFamagusta = 'AsiaFamagusta',
  AsiaGaza = 'AsiaGaza',
  AsiaHarbin = 'AsiaHarbin',
  AsiaHebron = 'AsiaHebron',
  AsiaHoChiMinh = 'AsiaHoChiMinh',
  AsiaHongKong = 'AsiaHongKong',
  AsiaHovd = 'AsiaHovd',
  AsiaIrkutsk = 'AsiaIrkutsk',
  AsiaIstanbul = 'AsiaIstanbul',
  AsiaJakarta = 'AsiaJakarta',
  AsiaJayapura = 'AsiaJayapura',
  AsiaJerusalem = 'AsiaJerusalem',
  AsiaKabul = 'AsiaKabul',
  AsiaKamchatka = 'AsiaKamchatka',
  AsiaKarachi = 'AsiaKarachi',
  AsiaKashgar = 'AsiaKashgar',
  AsiaKathmandu = 'AsiaKathmandu',
  AsiaKatmandu = 'AsiaKatmandu',
  AsiaKhandyga = 'AsiaKhandyga',
  AsiaKolkata = 'AsiaKolkata',
  AsiaKrasnoyarsk = 'AsiaKrasnoyarsk',
  AsiaKualaLumpur = 'AsiaKualaLumpur',
  AsiaKuching = 'AsiaKuching',
  AsiaKuwait = 'AsiaKuwait',
  AsiaMacao = 'AsiaMacao',
  AsiaMacau = 'AsiaMacau',
  AsiaMagadan = 'AsiaMagadan',
  AsiaMakassar = 'AsiaMakassar',
  AsiaManila = 'AsiaManila',
  AsiaMuscat = 'AsiaMuscat',
  AsiaNicosia = 'AsiaNicosia',
  AsiaNovokuznetsk = 'AsiaNovokuznetsk',
  AsiaNovosibirsk = 'AsiaNovosibirsk',
  AsiaOmsk = 'AsiaOmsk',
  AsiaOral = 'AsiaOral',
  AsiaPhnomPenh = 'AsiaPhnomPenh',
  AsiaPontianak = 'AsiaPontianak',
  AsiaPyongyang = 'AsiaPyongyang',
  AsiaQatar = 'AsiaQatar',
  AsiaQostanay = 'AsiaQostanay',
  AsiaQyzylorda = 'AsiaQyzylorda',
  AsiaRangoon = 'AsiaRangoon',
  AsiaRiyadh = 'AsiaRiyadh',
  AsiaSaigon = 'AsiaSaigon',
  AsiaSakhalin = 'AsiaSakhalin',
  AsiaSamarkand = 'AsiaSamarkand',
  AsiaSeoul = 'AsiaSeoul',
  AsiaShanghai = 'AsiaShanghai',
  AsiaSingapore = 'AsiaSingapore',
  AsiaSrednekolymsk = 'AsiaSrednekolymsk',
  AsiaTaipei = 'AsiaTaipei',
  AsiaTashkent = 'AsiaTashkent',
  AsiaTbilisi = 'AsiaTbilisi',
  AsiaTehran = 'AsiaTehran',
  AsiaTelAviv = 'AsiaTelAviv',
  AsiaThimbu = 'AsiaThimbu',
  AsiaThimphu = 'AsiaThimphu',
  AsiaTokyo = 'AsiaTokyo',
  AsiaTomsk = 'AsiaTomsk',
  AsiaUjungPandang = 'AsiaUjungPandang',
  AsiaUlaanbaatar = 'AsiaUlaanbaatar',
  AsiaUlanBator = 'AsiaUlanBator',
  AsiaUrumqi = 'AsiaUrumqi',
  AsiaUstNera = 'AsiaUstNera',
  AsiaVientiane = 'AsiaVientiane',
  AsiaVladivostok = 'AsiaVladivostok',
  AsiaYakutsk = 'AsiaYakutsk',
  AsiaYangon = 'AsiaYangon',
  AsiaYekaterinburg = 'AsiaYekaterinburg',
  AsiaYerevan = 'AsiaYerevan',
  AtlanticAzores = 'AtlanticAzores',
  AtlanticBermuda = 'AtlanticBermuda',
  AtlanticCanary = 'AtlanticCanary',
  AtlanticCapeVerde = 'AtlanticCapeVerde',
  AtlanticFaeroe = 'AtlanticFaeroe',
  AtlanticFaroe = 'AtlanticFaroe',
  AtlanticJanMayen = 'AtlanticJanMayen',
  AtlanticMadeira = 'AtlanticMadeira',
  AtlanticReykjavik = 'AtlanticReykjavik',
  AtlanticSouthGeorgia = 'AtlanticSouthGeorgia',
  AtlanticStHelena = 'AtlanticStHelena',
  AtlanticStanley = 'AtlanticStanley',
  AustraliaAct = 'AustraliaACT',
  AustraliaAdelaide = 'AustraliaAdelaide',
  AustraliaBrisbane = 'AustraliaBrisbane',
  AustraliaBrokenHill = 'AustraliaBrokenHill',
  AustraliaCanberra = 'AustraliaCanberra',
  AustraliaCurrie = 'AustraliaCurrie',
  AustraliaDarwin = 'AustraliaDarwin',
  AustraliaEucla = 'AustraliaEucla',
  AustraliaHobart = 'AustraliaHobart',
  AustraliaLhi = 'AustraliaLHI',
  AustraliaLindeman = 'AustraliaLindeman',
  AustraliaLordHowe = 'AustraliaLordHowe',
  AustraliaMelbourne = 'AustraliaMelbourne',
  AustraliaNsw = 'AustraliaNSW',
  AustraliaNorth = 'AustraliaNorth',
  AustraliaPerth = 'AustraliaPerth',
  AustraliaQueensland = 'AustraliaQueensland',
  AustraliaSouth = 'AustraliaSouth',
  AustraliaSydney = 'AustraliaSydney',
  AustraliaTasmania = 'AustraliaTasmania',
  AustraliaVictoria = 'AustraliaVictoria',
  AustraliaWest = 'AustraliaWest',
  AustraliaYancowinna = 'AustraliaYancowinna',
  BrazilAcre = 'BrazilAcre',
  BrazilDeNoronha = 'BrazilDeNoronha',
  BrazilEast = 'BrazilEast',
  BrazilWest = 'BrazilWest',
  Cet = 'CET',
  Cst6Cdt = 'CST6CDT',
  CanadaAtlantic = 'CanadaAtlantic',
  CanadaCentral = 'CanadaCentral',
  CanadaEastern = 'CanadaEastern',
  CanadaMountain = 'CanadaMountain',
  CanadaNewfoundland = 'CanadaNewfoundland',
  CanadaPacific = 'CanadaPacific',
  CanadaSaskatchewan = 'CanadaSaskatchewan',
  CanadaYukon = 'CanadaYukon',
  ChileContinental = 'ChileContinental',
  ChileEasterIsland = 'ChileEasterIsland',
  Cuba = 'Cuba',
  Eet = 'EET',
  Est = 'EST',
  Est5Edt = 'EST5EDT',
  Egypt = 'Egypt',
  Eire = 'Eire',
  EtcGmt = 'EtcGMT',
  EtcGmtPlus0 = 'EtcGMTPlus0',
  EtcGmtPlus1 = 'EtcGMTPlus1',
  EtcGmtPlus10 = 'EtcGMTPlus10',
  EtcGmtPlus11 = 'EtcGMTPlus11',
  EtcGmtPlus12 = 'EtcGMTPlus12',
  EtcGmtPlus2 = 'EtcGMTPlus2',
  EtcGmtPlus3 = 'EtcGMTPlus3',
  EtcGmtPlus4 = 'EtcGMTPlus4',
  EtcGmtPlus5 = 'EtcGMTPlus5',
  EtcGmtPlus6 = 'EtcGMTPlus6',
  EtcGmtPlus7 = 'EtcGMTPlus7',
  EtcGmtPlus8 = 'EtcGMTPlus8',
  EtcGmtPlus9 = 'EtcGMTPlus9',
  EtcGmtMinus0 = 'EtcGMTMinus0',
  EtcGmtMinus1 = 'EtcGMTMinus1',
  EtcGmtMinus10 = 'EtcGMTMinus10',
  EtcGmtMinus11 = 'EtcGMTMinus11',
  EtcGmtMinus12 = 'EtcGMTMinus12',
  EtcGmtMinus13 = 'EtcGMTMinus13',
  EtcGmtMinus14 = 'EtcGMTMinus14',
  EtcGmtMinus2 = 'EtcGMTMinus2',
  EtcGmtMinus3 = 'EtcGMTMinus3',
  EtcGmtMinus4 = 'EtcGMTMinus4',
  EtcGmtMinus5 = 'EtcGMTMinus5',
  EtcGmtMinus6 = 'EtcGMTMinus6',
  EtcGmtMinus7 = 'EtcGMTMinus7',
  EtcGmtMinus8 = 'EtcGMTMinus8',
  EtcGmtMinus9 = 'EtcGMTMinus9',
  EtcGmt0 = 'EtcGMT0',
  EtcGreenwich = 'EtcGreenwich',
  EtcUct = 'EtcUCT',
  EtcUtc = 'EtcUTC',
  EtcUniversal = 'EtcUniversal',
  EtcZulu = 'EtcZulu',
  EuropeAmsterdam = 'EuropeAmsterdam',
  EuropeAndorra = 'EuropeAndorra',
  EuropeAstrakhan = 'EuropeAstrakhan',
  EuropeAthens = 'EuropeAthens',
  EuropeBelfast = 'EuropeBelfast',
  EuropeBelgrade = 'EuropeBelgrade',
  EuropeBerlin = 'EuropeBerlin',
  EuropeBratislava = 'EuropeBratislava',
  EuropeBrussels = 'EuropeBrussels',
  EuropeBucharest = 'EuropeBucharest',
  EuropeBudapest = 'EuropeBudapest',
  EuropeBusingen = 'EuropeBusingen',
  EuropeChisinau = 'EuropeChisinau',
  EuropeCopenhagen = 'EuropeCopenhagen',
  EuropeDublin = 'EuropeDublin',
  EuropeGibraltar = 'EuropeGibraltar',
  EuropeGuernsey = 'EuropeGuernsey',
  EuropeHelsinki = 'EuropeHelsinki',
  EuropeIsleofMan = 'EuropeIsleofMan',
  EuropeIstanbul = 'EuropeIstanbul',
  EuropeJersey = 'EuropeJersey',
  EuropeKaliningrad = 'EuropeKaliningrad',
  EuropeKiev = 'EuropeKiev',
  EuropeKirov = 'EuropeKirov',
  EuropeLisbon = 'EuropeLisbon',
  EuropeLjubljana = 'EuropeLjubljana',
  EuropeLondon = 'EuropeLondon',
  EuropeLuxembourg = 'EuropeLuxembourg',
  EuropeMadrid = 'EuropeMadrid',
  EuropeMalta = 'EuropeMalta',
  EuropeMariehamn = 'EuropeMariehamn',
  EuropeMinsk = 'EuropeMinsk',
  EuropeMonaco = 'EuropeMonaco',
  EuropeMoscow = 'EuropeMoscow',
  EuropeNicosia = 'EuropeNicosia',
  EuropeOslo = 'EuropeOslo',
  EuropeParis = 'EuropeParis',
  EuropePodgorica = 'EuropePodgorica',
  EuropePrague = 'EuropePrague',
  EuropeRiga = 'EuropeRiga',
  EuropeRome = 'EuropeRome',
  EuropeSamara = 'EuropeSamara',
  EuropeSanMarino = 'EuropeSanMarino',
  EuropeSarajevo = 'EuropeSarajevo',
  EuropeSaratov = 'EuropeSaratov',
  EuropeSimferopol = 'EuropeSimferopol',
  EuropeSkopje = 'EuropeSkopje',
  EuropeSofia = 'EuropeSofia',
  EuropeStockholm = 'EuropeStockholm',
  EuropeTallinn = 'EuropeTallinn',
  EuropeTirane = 'EuropeTirane',
  EuropeTiraspol = 'EuropeTiraspol',
  EuropeUlyanovsk = 'EuropeUlyanovsk',
  EuropeUzhgorod = 'EuropeUzhgorod',
  EuropeVaduz = 'EuropeVaduz',
  EuropeVatican = 'EuropeVatican',
  EuropeVienna = 'EuropeVienna',
  EuropeVilnius = 'EuropeVilnius',
  EuropeVolgograd = 'EuropeVolgograd',
  EuropeWarsaw = 'EuropeWarsaw',
  EuropeZagreb = 'EuropeZagreb',
  EuropeZaporozhye = 'EuropeZaporozhye',
  EuropeZurich = 'EuropeZurich',
  Gb = 'GB',
  GbEire = 'GBEire',
  Gmt = 'GMT',
  GmtPlus0 = 'GMTPlus0',
  GmtMinus0 = 'GMTMinus0',
  Gmt0 = 'GMT0',
  Greenwich = 'Greenwich',
  Hst = 'HST',
  Hongkong = 'Hongkong',
  Iceland = 'Iceland',
  IndianAntananarivo = 'IndianAntananarivo',
  IndianChagos = 'IndianChagos',
  IndianChristmas = 'IndianChristmas',
  IndianCocos = 'IndianCocos',
  IndianComoro = 'IndianComoro',
  IndianKerguelen = 'IndianKerguelen',
  IndianMahe = 'IndianMahe',
  IndianMaldives = 'IndianMaldives',
  IndianMauritius = 'IndianMauritius',
  IndianMayotte = 'IndianMayotte',
  IndianReunion = 'IndianReunion',
  Iran = 'Iran',
  Israel = 'Israel',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Kwajalein = 'Kwajalein',
  Libya = 'Libya',
  Met = 'MET',
  Mst = 'MST',
  Mst7Mdt = 'MST7MDT',
  MexicoBajaNorte = 'MexicoBajaNorte',
  MexicoBajaSur = 'MexicoBajaSur',
  MexicoGeneral = 'MexicoGeneral',
  Nz = 'NZ',
  Nzchat = 'NZCHAT',
  Navajo = 'Navajo',
  Prc = 'PRC',
  Pst8Pdt = 'PST8PDT',
  PacificApia = 'PacificApia',
  PacificAuckland = 'PacificAuckland',
  PacificBougainville = 'PacificBougainville',
  PacificChatham = 'PacificChatham',
  PacificChuuk = 'PacificChuuk',
  PacificEaster = 'PacificEaster',
  PacificEfate = 'PacificEfate',
  PacificEnderbury = 'PacificEnderbury',
  PacificFakaofo = 'PacificFakaofo',
  PacificFiji = 'PacificFiji',
  PacificFunafuti = 'PacificFunafuti',
  PacificGalapagos = 'PacificGalapagos',
  PacificGambier = 'PacificGambier',
  PacificGuadalcanal = 'PacificGuadalcanal',
  PacificGuam = 'PacificGuam',
  PacificHonolulu = 'PacificHonolulu',
  PacificJohnston = 'PacificJohnston',
  PacificKiritimati = 'PacificKiritimati',
  PacificKosrae = 'PacificKosrae',
  PacificKwajalein = 'PacificKwajalein',
  PacificMajuro = 'PacificMajuro',
  PacificMarquesas = 'PacificMarquesas',
  PacificMidway = 'PacificMidway',
  PacificNauru = 'PacificNauru',
  PacificNiue = 'PacificNiue',
  PacificNorfolk = 'PacificNorfolk',
  PacificNoumea = 'PacificNoumea',
  PacificPagoPago = 'PacificPagoPago',
  PacificPalau = 'PacificPalau',
  PacificPitcairn = 'PacificPitcairn',
  PacificPohnpei = 'PacificPohnpei',
  PacificPonape = 'PacificPonape',
  PacificPortMoresby = 'PacificPortMoresby',
  PacificRarotonga = 'PacificRarotonga',
  PacificSaipan = 'PacificSaipan',
  PacificSamoa = 'PacificSamoa',
  PacificTahiti = 'PacificTahiti',
  PacificTarawa = 'PacificTarawa',
  PacificTongatapu = 'PacificTongatapu',
  PacificTruk = 'PacificTruk',
  PacificWake = 'PacificWake',
  PacificWallis = 'PacificWallis',
  PacificYap = 'PacificYap',
  Poland = 'Poland',
  Portugal = 'Portugal',
  Roc = 'ROC',
  Rok = 'ROK',
  Singapore = 'Singapore',
  Turkey = 'Turkey',
  Uct = 'UCT',
  UsAlaska = 'USAlaska',
  UsAleutian = 'USAleutian',
  UsArizona = 'USArizona',
  UsCentral = 'USCentral',
  UsEastIndiana = 'USEastIndiana',
  UsEastern = 'USEastern',
  UsHawaii = 'USHawaii',
  UsIndianaStarke = 'USIndianaStarke',
  UsMichigan = 'USMichigan',
  UsMountain = 'USMountain',
  UsPacific = 'USPacific',
  UsPacificNew = 'USPacificNew',
  UsSamoa = 'USSamoa',
  Utc = 'UTC',
  Universal = 'Universal',
  Wsu = 'WSU',
  Wet = 'WET',
  Zulu = 'Zulu',
}

export type UpdateAttendeeInput = {
  user?: Maybe<RelationInput>;
  event?: Maybe<RelationInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<AttendeeStatus>;
  role?: Maybe<AttendeeRole>;
  cancelReason?: Maybe<Scalars['String']>;
};

export type UpdateAuthInput = {
  issuer?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  user?: Maybe<CreateUserInput>;
};

export type UpdateCalendarInput = {
  name?: Maybe<Scalars['String']>;
};

export type UpdateEventInput = {
  calendar?: Maybe<RelationInput>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  timezone?: Maybe<Timezone>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  private?: Maybe<Scalars['Boolean']>;
  transparency?: Maybe<EventTransparency>;
  status?: Maybe<EventStatus>;
  overrideLink?: Maybe<Scalars['String']>;
};

export type UpdateExperienceInput = {
  title?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  content?: Maybe<ExperienceContentInput>;
  brainstorm?: Maybe<ExperienceBrainstormInput>;
  progressStep?: Maybe<ExperienceProgressStep>;
  meetingScheduled?: Maybe<Scalars['Boolean']>;
  category?: Maybe<ExperienceCategory>;
  status?: Maybe<ExperienceStatus>;
  type?: Maybe<ExperienceType>;
  host?: Maybe<RelationInput>;
  mode?: Maybe<ExperienceMode>;
  locationType?: Maybe<ExperienceLocationType>;
  coordinates?: Maybe<PointInput>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']>;
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type UpdateExperienceTagInput = {
  experience?: Maybe<RelationInput>;
  tag?: Maybe<RelationInput>;
};

export type UpdateFeedbackInput = {
  status?: Maybe<FeedbackStatus>;
  content?: Maybe<FeedbackContentInput>;
  createdBy?: Maybe<RelationInput>;
  user?: Maybe<RelationInput>;
  experience?: Maybe<RelationInput>;
  event?: Maybe<RelationInput>;
};

export type UpdateFileInput = {
  key?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
};

export type UpdateImageInput = {
  order?: Maybe<Scalars['Float']>;
  experience?: Maybe<RelationInput>;
  file?: Maybe<RelationInput>;
};

export type UpdateManyAttendeesInput = {
  /** Filter used to find fields to update */
  filter: AttendeeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateAttendeeInput;
};

export type UpdateManyAuthsInput = {
  /** Filter used to find fields to update */
  filter: AuthUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateAuthInput;
};

export type UpdateManyCalendarsInput = {
  /** Filter used to find fields to update */
  filter: CalendarUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateCalendarInput;
};

export type UpdateManyEventsInput = {
  /** Filter used to find fields to update */
  filter: EventUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateEventInput;
};

export type UpdateManyExperienceTagsInput = {
  /** Filter used to find fields to update */
  filter: ExperienceTagUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateExperienceTagInput;
};

export type UpdateManyExperiencesInput = {
  /** Filter used to find fields to update */
  filter: ExperienceUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateExperienceInput;
};

export type UpdateManyFeedbacksInput = {
  /** Filter used to find fields to update */
  filter: FeedbackUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateFeedbackInput;
};

export type UpdateManyFilesInput = {
  /** Filter used to find fields to update */
  filter: FileUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateFileInput;
};

export type UpdateManyImagesInput = {
  /** Filter used to find fields to update */
  filter: ImageUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateImageInput;
};

export type UpdateManyMessageFilesInput = {
  /** Filter used to find fields to update */
  filter: MessageFileUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMessageFileInput;
};

export type UpdateManyMessageFlaggingsInput = {
  /** Filter used to find fields to update */
  filter: MessageFlaggingUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMessageFlagInput;
};

export type UpdateManyMessageThreadsInput = {
  /** Filter used to find fields to update */
  filter: MessageThreadUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMessageThreadInput;
};

export type UpdateManyMessagesInput = {
  /** Filter used to find fields to update */
  filter: MessageUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMessageInput;
};

export type UpdateManyNotificationsInput = {
  /** Filter used to find fields to update */
  filter: NotificationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateNotificationInput;
};

export type UpdateManyOEmbedsInput = {
  /** Filter used to find fields to update */
  filter: OEmbedUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateOEmbedInput;
};

export type UpdateManyOrdersInput = {
  /** Filter used to find fields to update */
  filter: OrderUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateOrderInput;
};

export type UpdateManyProfileImagesInput = {
  /** Filter used to find fields to update */
  filter: ProfileImageUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateProfileImageInput;
};

export type UpdateManyProfileInterestsInput = {
  /** Filter used to find fields to update */
  filter: ProfileInterestUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateProfileInterestInput;
};

export type UpdateManyProfileStrengthsInput = {
  /** Filter used to find fields to update */
  filter: ProfileStrengthUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateProfileStrengthInput;
};

export type UpdateManyProfilesInput = {
  /** Filter used to find fields to update */
  filter: ProfileUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateProfileInput;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int'];
};

export type UpdateManySchedulesInput = {
  /** Filter used to find fields to update */
  filter: ScheduleUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateScheduleInput;
};

export type UpdateManyStripeCustomersInput = {
  /** Filter used to find fields to update */
  filter: StripeCustomerUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateStripeCustomerInput;
};

export type UpdateManyStripeProductsInput = {
  /** Filter used to find fields to update */
  filter: StripeProductUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateStripeProductInput;
};

export type UpdateManyTagsInput = {
  /** Filter used to find fields to update */
  filter: TagUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateTagInput;
};

export type UpdateManyThreadParticipantsInput = {
  /** Filter used to find fields to update */
  filter: ThreadParticipantsUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateThreadParticipantsInput;
};

export type UpdateManyTimeslotsInput = {
  /** Filter used to find fields to update */
  filter: TimeslotUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateTimeslotInput;
};

export type UpdateManyUsersInput = {
  /** Filter used to find fields to update */
  filter: UserUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateUserInput;
};

export type UpdateManyZoomMeetingsInput = {
  /** Filter used to find fields to update */
  filter: ZoomMeetingUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateZoomMeetingInput;
};

export type UpdateManyZoomUsersInput = {
  /** Filter used to find fields to update */
  filter: ZoomUserUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateZoomUserInput;
};

export type UpdateMessageFileInput = {
  file?: Maybe<RelationInput>;
  message?: Maybe<RelationInput>;
};

export type UpdateMessageFlagInput = {
  reason?: Maybe<Scalars['String']>;
  message?: Maybe<RelationInput>;
  user?: Maybe<RelationInput>;
  status?: Maybe<MessageFlaggingStatus>;
};

export type UpdateMessageInput = {
  messageThread?: Maybe<RelationInput>;
  user?: Maybe<RelationInput>;
  content?: Maybe<Scalars['String']>;
};

export type UpdateMessageThreadInput = {
  name?: Maybe<Scalars['String']>;
};

export type UpdateNotificationInput = {
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<NotificationStatus>;
  user?: Maybe<RelationInput>;
};

export type UpdateOEmbedInput = {
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type UpdateOneAttendeeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateAttendeeInput;
};

export type UpdateOneAuthInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateAuthInput;
};

export type UpdateOneCalendarInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateCalendarInput;
};

export type UpdateOneEventInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateEventInput;
};

export type UpdateOneExperienceInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateExperienceInput;
};

export type UpdateOneExperienceTagInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateExperienceTagInput;
};

export type UpdateOneFeedbackInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateFeedbackInput;
};

export type UpdateOneFileInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateFileInput;
};

export type UpdateOneImageInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateImageInput;
};

export type UpdateOneMessageFileInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMessageFileInput;
};

export type UpdateOneMessageFlaggingInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMessageFlagInput;
};

export type UpdateOneMessageInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMessageInput;
};

export type UpdateOneMessageSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: MessageSubscriptionFilter;
};

export type UpdateOneMessageThreadInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMessageThreadInput;
};

export type UpdateOneMessageThreadSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: MessageThreadSubscriptionFilter;
};

export type UpdateOneNotificationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateNotificationInput;
};

export type UpdateOneNotificationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: NotificationSubscriptionFilter;
};

export type UpdateOneOEmbedInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateOEmbedInput;
};

export type UpdateOneOrderInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateOrderInput;
};

export type UpdateOneProfileImageInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateProfileImageInput;
};

export type UpdateOneProfileInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateProfileInput;
};

export type UpdateOneProfileInterestInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateProfileInterestInput;
};

export type UpdateOneProfileStrengthInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateProfileStrengthInput;
};

export type UpdateOneScheduleInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateScheduleInput;
};

export type UpdateOneStripeCustomerInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateStripeCustomerInput;
};

export type UpdateOneStripeProductInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateStripeProductInput;
};

export type UpdateOneTagInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateTagInput;
};

export type UpdateOneThreadParticipantsInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateThreadParticipantsInput;
};

export type UpdateOneThreadParticipantsSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ThreadParticipantsSubscriptionFilter;
};

export type UpdateOneTimeslotInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateTimeslotInput;
};

export type UpdateOneUserInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateUserInput;
};

export type UpdateOneZoomMeetingInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateZoomMeetingInput;
};

export type UpdateOneZoomUserInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateZoomUserInput;
};

export type UpdateOrderInput = {
  experience?: Maybe<RelationInput>;
  user?: Maybe<RelationInput>;
  status?: Maybe<OrderStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripeLivemode?: Maybe<Scalars['Boolean']>;
  shippedAt?: Maybe<Scalars['DateTime']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateProfileImageInput = {
  order?: Maybe<Scalars['Float']>;
  profile?: Maybe<RelationInput>;
  file?: Maybe<RelationInput>;
};

export type UpdateProfileInput = {
  nickname?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
};

export type UpdateProfileInterestInput = {
  name?: Maybe<Scalars['String']>;
};

export type UpdateProfileStrengthInput = {
  name?: Maybe<Scalars['String']>;
};

export type UpdateScheduleInput = {
  duration?: Maybe<Scalars['Float']>;
  durationMetric?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['Float']>;
  frequencyMetric?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['Float']>;
  rangeMetric?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['Float']>;
  delayMetric?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['Float']>;
  capRange?: Maybe<Scalars['Float']>;
  capRangeMetric?: Maybe<Scalars['String']>;
};

export type UpdateStripeCustomerInput = {
  stripeId?: Maybe<Scalars['String']>;
  stripeLivemode?: Maybe<Scalars['Boolean']>;
  user?: Maybe<RelationInput>;
};

export type UpdateStripeProductInput = {
  stripeId?: Maybe<Scalars['String']>;
  stripeLivemode?: Maybe<Scalars['Boolean']>;
  priceId?: Maybe<Scalars['String']>;
  experience?: Maybe<RelationInput>;
};

export type UpdateTagInput = {
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<RelationInput>;
  category?: Maybe<TagCategory>;
  public?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateThreadParticipantsInput = {
  messageThread?: Maybe<RelationInput>;
  user?: Maybe<RelationInput>;
  unread?: Maybe<Scalars['Boolean']>;
};

export type UpdateTimeslotInput = {
  schedule?: Maybe<RelationInput>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  free?: Maybe<Scalars['Boolean']>;
  weekly?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  timezone?: Maybe<Timezone>;
  city?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  location?: Maybe<PointInput>;
  dob?: Maybe<Scalars['String']>;
  welcomeComplete?: Maybe<Scalars['DateTime']>;
  welcomeVetted?: Maybe<Scalars['Boolean']>;
  criminalRecordCheckDate?: Maybe<Scalars['DateTime']>;
  criminalRecordCheckValid?: Maybe<Scalars['Boolean']>;
  criminalRecordCheck?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
};

export type UpdateZoomMeetingInput = {
  zoomUser?: Maybe<RelationInput>;
  code?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  joinUrl?: Maybe<Scalars['String']>;
};

export type UpdateZoomUserInput = {
  zoomId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  user?: Maybe<RelationInput>;
};

export type UploadFileInput = {
  file: Scalars['Upload'];
};

export type UploadFileWithSignedUrlInput = {
  mimetype: Scalars['String'];
  filename: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  profile?: Maybe<Profile>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  timezone?: Maybe<Timezone>;
  city?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  welcomeComplete?: Maybe<Scalars['DateTime']>;
  welcomeVetted?: Maybe<Scalars['Boolean']>;
  criminalRecordCheckDate?: Maybe<Scalars['DateTime']>;
  criminalRecordCheckValid?: Maybe<Scalars['Boolean']>;
  criminalRecordCheck?: Maybe<Scalars['String']>;
  location?: Maybe<Point>;
  dob?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  eventsAggregate: Array<UserEventsAggregateResponse>;
  feedbacksAggregate: Array<UserFeedbacksAggregateResponse>;
  experiencesAggregate: Array<UserExperiencesAggregateResponse>;
  zoomUser?: Maybe<ZoomUser>;
  events?: Maybe<UserEventsConnection>;
  feedbacks?: Maybe<UserFeedbacksConnection>;
  experiences?: Maybe<UserExperiencesConnection>;
};

export type UserEventsAggregateArgs = {
  filter?: Maybe<EventAggregateFilter>;
};

export type UserFeedbacksAggregateArgs = {
  filter?: Maybe<FeedbackAggregateFilter>;
};

export type UserExperiencesAggregateArgs = {
  filter?: Maybe<ExperienceAggregateFilter>;
};

export type UserEventsArgs = {
  paging?: CursorPaging;
  filter?: EventFilter;
  sorting?: Array<EventSort>;
};

export type UserFeedbacksArgs = {
  paging?: CursorPaging;
  filter?: FeedbackFilter;
  sorting?: Array<FeedbackSort>;
};

export type UserExperiencesArgs = {
  paging?: CursorPaging;
  filter?: ExperienceFilter;
  sorting?: Array<ExperienceSort>;
};

export type UserAggregateFilter = {
  and?: Maybe<Array<UserAggregateFilter>>;
  or?: Maybe<Array<UserAggregateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  emailVerified?: Maybe<BooleanFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  phoneVerified?: Maybe<BooleanFieldComparison>;
  username?: Maybe<StringFieldComparison>;
  firstName?: Maybe<StringFieldComparison>;
  lastName?: Maybe<StringFieldComparison>;
  nickname?: Maybe<StringFieldComparison>;
  avatar?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  continent?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  welcomeComplete?: Maybe<DateFieldComparison>;
  welcomeVetted?: Maybe<BooleanFieldComparison>;
  criminalRecordCheckDate?: Maybe<DateFieldComparison>;
  criminalRecordCheckValid?: Maybe<BooleanFieldComparison>;
  criminalRecordCheck?: Maybe<StringFieldComparison>;
  location?: Maybe<PointObjectFilterComparison>;
  dob?: Maybe<StringFieldComparison>;
  address?: Maybe<AddressObjectFilterComparison>;
};

export type UserAggregateGroupBy = {
  __typename?: 'UserAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  welcomeComplete?: Maybe<Scalars['DateTime']>;
  welcomeVetted?: Maybe<Scalars['Boolean']>;
  criminalRecordCheckDate?: Maybe<Scalars['DateTime']>;
  criminalRecordCheckValid?: Maybe<Scalars['Boolean']>;
  criminalRecordCheck?: Maybe<Scalars['String']>;
  location?: Maybe<Point>;
  dob?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
};

export type UserAggregateResponse = {
  __typename?: 'UserAggregateResponse';
  groupBy?: Maybe<UserAggregateGroupBy>;
  count?: Maybe<UserCountAggregate>;
  min?: Maybe<UserMinAggregate>;
  max?: Maybe<UserMaxAggregate>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<UserEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
  emailVerified?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['Int']>;
  phoneVerified?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['Int']>;
  nickname?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['Int']>;
  continent?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['Int']>;
  welcomeComplete?: Maybe<Scalars['Int']>;
  welcomeVetted?: Maybe<Scalars['Int']>;
  criminalRecordCheckDate?: Maybe<Scalars['Int']>;
  criminalRecordCheckValid?: Maybe<Scalars['Int']>;
  criminalRecordCheck?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['Int']>;
  dob?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['Int']>;
};

export type UserDeleteFilter = {
  and?: Maybe<Array<UserDeleteFilter>>;
  or?: Maybe<Array<UserDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  emailVerified?: Maybe<BooleanFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  phoneVerified?: Maybe<BooleanFieldComparison>;
  username?: Maybe<StringFieldComparison>;
  firstName?: Maybe<StringFieldComparison>;
  lastName?: Maybe<StringFieldComparison>;
  nickname?: Maybe<StringFieldComparison>;
  avatar?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  continent?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  welcomeComplete?: Maybe<DateFieldComparison>;
  welcomeVetted?: Maybe<BooleanFieldComparison>;
  criminalRecordCheckDate?: Maybe<DateFieldComparison>;
  criminalRecordCheckValid?: Maybe<BooleanFieldComparison>;
  criminalRecordCheck?: Maybe<StringFieldComparison>;
  location?: Maybe<PointObjectFilterComparison>;
  dob?: Maybe<StringFieldComparison>;
  address?: Maybe<AddressObjectFilterComparison>;
};

export type UserDeleteResponse = {
  __typename?: 'UserDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  profile?: Maybe<Profile>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  timezone?: Maybe<Timezone>;
  city?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  welcomeComplete?: Maybe<Scalars['DateTime']>;
  welcomeVetted?: Maybe<Scalars['Boolean']>;
  criminalRecordCheckDate?: Maybe<Scalars['DateTime']>;
  criminalRecordCheckValid?: Maybe<Scalars['Boolean']>;
  criminalRecordCheck?: Maybe<Scalars['String']>;
  location?: Maybe<Point>;
  dob?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** The node containing the User */
  node: User;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type UserEventsAggregateGroupBy = {
  __typename?: 'UserEventsAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  calendarId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  private?: Maybe<Scalars['Boolean']>;
  transparency?: Maybe<EventTransparency>;
  status?: Maybe<EventStatus>;
  overrideLink?: Maybe<Scalars['String']>;
};

export type UserEventsAggregateResponse = {
  __typename?: 'UserEventsAggregateResponse';
  groupBy?: Maybe<UserEventsAggregateGroupBy>;
  count?: Maybe<UserEventsCountAggregate>;
  min?: Maybe<UserEventsMinAggregate>;
  max?: Maybe<UserEventsMaxAggregate>;
};

export type UserEventsConnection = {
  __typename?: 'UserEventsConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<EventEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type UserEventsCountAggregate = {
  __typename?: 'UserEventsCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  calendarId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  private?: Maybe<Scalars['Int']>;
  transparency?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  overrideLink?: Maybe<Scalars['Int']>;
};

export type UserEventsMaxAggregate = {
  __typename?: 'UserEventsMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  calendarId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  transparency?: Maybe<EventTransparency>;
  status?: Maybe<EventStatus>;
  overrideLink?: Maybe<Scalars['String']>;
};

export type UserEventsMinAggregate = {
  __typename?: 'UserEventsMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  calendarId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  transparency?: Maybe<EventTransparency>;
  status?: Maybe<EventStatus>;
  overrideLink?: Maybe<Scalars['String']>;
};

export type UserExperiencesAggregateGroupBy = {
  __typename?: 'UserExperiencesAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  meetingScheduled?: Maybe<Scalars['Boolean']>;
  progressStep?: Maybe<ExperienceProgressStep>;
  hostId?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Float']>;
  category?: Maybe<ExperienceCategory>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ExperienceStatus>;
  nextAvailability?: Maybe<Scalars['DateTime']>;
  nextEvent?: Maybe<Scalars['DateTime']>;
  next?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ExperienceType>;
  mode?: Maybe<ExperienceMode>;
  locationType?: Maybe<ExperienceLocationType>;
  approximateLocation?: Maybe<Point>;
  coordinates?: Maybe<Point>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']>;
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type UserExperiencesAggregateResponse = {
  __typename?: 'UserExperiencesAggregateResponse';
  groupBy?: Maybe<UserExperiencesAggregateGroupBy>;
  count?: Maybe<UserExperiencesCountAggregate>;
  sum?: Maybe<UserExperiencesSumAggregate>;
  avg?: Maybe<UserExperiencesAvgAggregate>;
  min?: Maybe<UserExperiencesMinAggregate>;
  max?: Maybe<UserExperiencesMaxAggregate>;
};

export type UserExperiencesAvgAggregate = {
  __typename?: 'UserExperiencesAvgAggregate';
  featured?: Maybe<Scalars['Float']>;
};

export type UserExperiencesConnection = {
  __typename?: 'UserExperiencesConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ExperienceEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type UserExperiencesCountAggregate = {
  __typename?: 'UserExperiencesCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['Int']>;
  meetingScheduled?: Maybe<Scalars['Int']>;
  progressStep?: Maybe<Scalars['Int']>;
  hostId?: Maybe<Scalars['Int']>;
  video?: Maybe<Scalars['Int']>;
  featured?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  nextAvailability?: Maybe<Scalars['Int']>;
  nextEvent?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['Int']>;
  locationType?: Maybe<Scalars['Int']>;
  approximateLocation?: Maybe<Scalars['Int']>;
  coordinates?: Maybe<Scalars['Int']>;
  streetNumber?: Maybe<Scalars['Int']>;
  route?: Maybe<Scalars['Int']>;
  locality?: Maybe<Scalars['Int']>;
  administrativeAreaLevel2?: Maybe<Scalars['Int']>;
  administrativeAreaLevel1?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['Int']>;
  postalCode?: Maybe<Scalars['Int']>;
};

export type UserExperiencesMaxAggregate = {
  __typename?: 'UserExperiencesMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  progressStep?: Maybe<ExperienceProgressStep>;
  hostId?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Float']>;
  category?: Maybe<ExperienceCategory>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ExperienceStatus>;
  nextAvailability?: Maybe<Scalars['DateTime']>;
  nextEvent?: Maybe<Scalars['DateTime']>;
  next?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ExperienceType>;
  mode?: Maybe<ExperienceMode>;
  locationType?: Maybe<ExperienceLocationType>;
  approximateLocation?: Maybe<Point>;
  coordinates?: Maybe<Point>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']>;
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type UserExperiencesMinAggregate = {
  __typename?: 'UserExperiencesMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  progressStep?: Maybe<ExperienceProgressStep>;
  hostId?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Float']>;
  category?: Maybe<ExperienceCategory>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ExperienceStatus>;
  nextAvailability?: Maybe<Scalars['DateTime']>;
  nextEvent?: Maybe<Scalars['DateTime']>;
  next?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ExperienceType>;
  mode?: Maybe<ExperienceMode>;
  locationType?: Maybe<ExperienceLocationType>;
  approximateLocation?: Maybe<Point>;
  coordinates?: Maybe<Point>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']>;
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type UserExperiencesSumAggregate = {
  __typename?: 'UserExperiencesSumAggregate';
  featured?: Maybe<Scalars['Float']>;
};

export type UserFeedbacksAggregateGroupBy = {
  __typename?: 'UserFeedbacksAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<FeedbackStatus>;
  content?: Maybe<FeedbackContent>;
};

export type UserFeedbacksAggregateResponse = {
  __typename?: 'UserFeedbacksAggregateResponse';
  groupBy?: Maybe<UserFeedbacksAggregateGroupBy>;
  count?: Maybe<UserFeedbacksCountAggregate>;
  min?: Maybe<UserFeedbacksMinAggregate>;
  max?: Maybe<UserFeedbacksMaxAggregate>;
};

export type UserFeedbacksConnection = {
  __typename?: 'UserFeedbacksConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<FeedbackEdge>;
};

export type UserFeedbacksCountAggregate = {
  __typename?: 'UserFeedbacksCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['Int']>;
};

export type UserFeedbacksMaxAggregate = {
  __typename?: 'UserFeedbacksMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<FeedbackStatus>;
  content?: Maybe<FeedbackContent>;
};

export type UserFeedbacksMinAggregate = {
  __typename?: 'UserFeedbacksMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<FeedbackStatus>;
  content?: Maybe<FeedbackContent>;
};

export type UserFilter = {
  and?: Maybe<Array<UserFilter>>;
  or?: Maybe<Array<UserFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  emailVerified?: Maybe<BooleanFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  phoneVerified?: Maybe<BooleanFieldComparison>;
  username?: Maybe<StringFieldComparison>;
  firstName?: Maybe<StringFieldComparison>;
  lastName?: Maybe<StringFieldComparison>;
  nickname?: Maybe<StringFieldComparison>;
  avatar?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  continent?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  welcomeComplete?: Maybe<DateFieldComparison>;
  welcomeVetted?: Maybe<BooleanFieldComparison>;
  criminalRecordCheckDate?: Maybe<DateFieldComparison>;
  criminalRecordCheckValid?: Maybe<BooleanFieldComparison>;
  criminalRecordCheck?: Maybe<StringFieldComparison>;
  location?: Maybe<PointObjectFilterComparison>;
  dob?: Maybe<StringFieldComparison>;
  address?: Maybe<AddressObjectFilterComparison>;
  zoomUser?: Maybe<UserFilterZoomUserFilter>;
  profile?: Maybe<UserFilterProfileFilter>;
  experiences?: Maybe<UserFilterExperienceFilter>;
  feedbacks?: Maybe<UserFilterFeedbackFilter>;
  events?: Maybe<UserFilterEventFilter>;
};

export type UserFilterEventFilter = {
  and?: Maybe<Array<UserFilterEventFilter>>;
  or?: Maybe<Array<UserFilterEventFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  calendarId?: Maybe<StringFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  start?: Maybe<DateFieldComparison>;
  end?: Maybe<DateFieldComparison>;
  private?: Maybe<BooleanFieldComparison>;
  transparency?: Maybe<EventTransparencyFilterComparison>;
  status?: Maybe<EventStatusFilterComparison>;
  overrideLink?: Maybe<StringFieldComparison>;
};

export type UserFilterExperienceFilter = {
  and?: Maybe<Array<UserFilterExperienceFilter>>;
  or?: Maybe<Array<UserFilterExperienceFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  title?: Maybe<StringFieldComparison>;
  slug?: Maybe<StringFieldComparison>;
  meetingScheduled?: Maybe<BooleanFieldComparison>;
  progressStep?: Maybe<ExperienceProgressStepFilterComparison>;
  hostId?: Maybe<StringFieldComparison>;
  video?: Maybe<StringFieldComparison>;
  featured?: Maybe<NumberFieldComparison>;
  category?: Maybe<ExperienceCategoryFilterComparison>;
  publishedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<ExperienceStatusFilterComparison>;
  nextAvailability?: Maybe<DateFieldComparison>;
  nextEvent?: Maybe<DateFieldComparison>;
  next?: Maybe<DateFieldComparison>;
  type?: Maybe<ExperienceTypeFilterComparison>;
  mode?: Maybe<ExperienceModeFilterComparison>;
  locationType?: Maybe<ExperienceLocationTypeFilterComparison>;
  approximateLocation?: Maybe<PointObjectFilterComparison>;
  coordinates?: Maybe<PointObjectFilterComparison>;
  streetNumber?: Maybe<StringFieldComparison>;
  route?: Maybe<StringFieldComparison>;
  locality?: Maybe<StringFieldComparison>;
  administrativeAreaLevel2?: Maybe<StringFieldComparison>;
  administrativeAreaLevel1?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  postalCode?: Maybe<StringFieldComparison>;
};

export type UserFilterFeedbackFilter = {
  and?: Maybe<Array<UserFilterFeedbackFilter>>;
  or?: Maybe<Array<UserFilterFeedbackFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  status?: Maybe<FeedbackStatusFilterComparison>;
  content?: Maybe<FeedbackContentObjectFilterComparison>;
};

export type UserFilterProfileFilter = {
  and?: Maybe<Array<UserFilterProfileFilter>>;
  or?: Maybe<Array<UserFilterProfileFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
};

export type UserFilterZoomUserFilter = {
  and?: Maybe<Array<UserFilterZoomUserFilter>>;
  or?: Maybe<Array<UserFilterZoomUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  zoomId?: Maybe<StringFieldComparison>;
  email?: Maybe<StringFieldComparison>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  welcomeComplete?: Maybe<Scalars['DateTime']>;
  criminalRecordCheckDate?: Maybe<Scalars['DateTime']>;
  criminalRecordCheck?: Maybe<Scalars['String']>;
  location?: Maybe<Point>;
  dob?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  welcomeComplete?: Maybe<Scalars['DateTime']>;
  criminalRecordCheckDate?: Maybe<Scalars['DateTime']>;
  criminalRecordCheck?: Maybe<Scalars['String']>;
  location?: Maybe<Point>;
  dob?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
};

export type UserSort = {
  field: UserSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum UserSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Email = 'email',
  EmailVerified = 'emailVerified',
  Phone = 'phone',
  PhoneVerified = 'phoneVerified',
  Username = 'username',
  FirstName = 'firstName',
  LastName = 'lastName',
  Nickname = 'nickname',
  Avatar = 'avatar',
  City = 'city',
  Continent = 'continent',
  Country = 'country',
  WelcomeComplete = 'welcomeComplete',
  WelcomeVetted = 'welcomeVetted',
  CriminalRecordCheckDate = 'criminalRecordCheckDate',
  CriminalRecordCheckValid = 'criminalRecordCheckValid',
  CriminalRecordCheck = 'criminalRecordCheck',
  Location = 'location',
  Dob = 'dob',
  Address = 'address',
}

export type UserUpdateFilter = {
  and?: Maybe<Array<UserUpdateFilter>>;
  or?: Maybe<Array<UserUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  emailVerified?: Maybe<BooleanFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  phoneVerified?: Maybe<BooleanFieldComparison>;
  username?: Maybe<StringFieldComparison>;
  firstName?: Maybe<StringFieldComparison>;
  lastName?: Maybe<StringFieldComparison>;
  nickname?: Maybe<StringFieldComparison>;
  avatar?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  continent?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  welcomeComplete?: Maybe<DateFieldComparison>;
  welcomeVetted?: Maybe<BooleanFieldComparison>;
  criminalRecordCheckDate?: Maybe<DateFieldComparison>;
  criminalRecordCheckValid?: Maybe<BooleanFieldComparison>;
  criminalRecordCheck?: Maybe<StringFieldComparison>;
  location?: Maybe<PointObjectFilterComparison>;
  dob?: Maybe<StringFieldComparison>;
  address?: Maybe<AddressObjectFilterComparison>;
};

export type ZoomMeeting = {
  __typename?: 'ZoomMeeting';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  joinUrl: Scalars['String'];
  zoomUserId: Scalars['String'];
  startUrl?: Maybe<Scalars['String']>;
  zoomUser: ZoomUser;
};

export type ZoomMeetingAggregateGroupBy = {
  __typename?: 'ZoomMeetingAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  joinUrl?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['String']>;
};

export type ZoomMeetingConnection = {
  __typename?: 'ZoomMeetingConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ZoomMeetingEdge>;
};

export type ZoomMeetingCountAggregate = {
  __typename?: 'ZoomMeetingCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['Int']>;
  joinUrl?: Maybe<Scalars['Int']>;
  zoomUserId?: Maybe<Scalars['Int']>;
};

export type ZoomMeetingDeleteFilter = {
  and?: Maybe<Array<ZoomMeetingDeleteFilter>>;
  or?: Maybe<Array<ZoomMeetingDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  joinUrl?: Maybe<StringFieldComparison>;
  zoomUserId?: Maybe<StringFieldComparison>;
};

export type ZoomMeetingDeleteResponse = {
  __typename?: 'ZoomMeetingDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  joinUrl?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['String']>;
};

export type ZoomMeetingEdge = {
  __typename?: 'ZoomMeetingEdge';
  /** The node containing the ZoomMeeting */
  node: ZoomMeeting;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ZoomMeetingFilter = {
  and?: Maybe<Array<ZoomMeetingFilter>>;
  or?: Maybe<Array<ZoomMeetingFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  joinUrl?: Maybe<StringFieldComparison>;
  zoomUserId?: Maybe<StringFieldComparison>;
  zoomUser?: Maybe<ZoomMeetingFilterZoomUserFilter>;
};

export type ZoomMeetingFilterZoomUserFilter = {
  and?: Maybe<Array<ZoomMeetingFilterZoomUserFilter>>;
  or?: Maybe<Array<ZoomMeetingFilterZoomUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  zoomId?: Maybe<StringFieldComparison>;
  email?: Maybe<StringFieldComparison>;
};

export type ZoomMeetingMaxAggregate = {
  __typename?: 'ZoomMeetingMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  joinUrl?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['String']>;
};

export type ZoomMeetingMinAggregate = {
  __typename?: 'ZoomMeetingMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  joinUrl?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['String']>;
};

export type ZoomMeetingSort = {
  field: ZoomMeetingSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ZoomMeetingSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Code = 'code',
  JoinUrl = 'joinUrl',
  ZoomUserId = 'zoomUserId',
}

export type ZoomMeetingUpdateFilter = {
  and?: Maybe<Array<ZoomMeetingUpdateFilter>>;
  or?: Maybe<Array<ZoomMeetingUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  joinUrl?: Maybe<StringFieldComparison>;
  zoomUserId?: Maybe<StringFieldComparison>;
};

export type ZoomUser = {
  __typename?: 'ZoomUser';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zoomId: Scalars['String'];
  email: Scalars['String'];
  user: User;
  meetings?: Maybe<ZoomUserMeetingsConnection>;
};

export type ZoomUserMeetingsArgs = {
  paging?: CursorPaging;
  filter?: ZoomMeetingFilter;
  sorting?: Array<ZoomMeetingSort>;
};

export type ZoomUserAggregateGroupBy = {
  __typename?: 'ZoomUserAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zoomId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ZoomUserConnection = {
  __typename?: 'ZoomUserConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ZoomUserEdge>;
};

export type ZoomUserCountAggregate = {
  __typename?: 'ZoomUserCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  zoomId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
};

export type ZoomUserDeleteFilter = {
  and?: Maybe<Array<ZoomUserDeleteFilter>>;
  or?: Maybe<Array<ZoomUserDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  zoomId?: Maybe<StringFieldComparison>;
  email?: Maybe<StringFieldComparison>;
};

export type ZoomUserDeleteResponse = {
  __typename?: 'ZoomUserDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zoomId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ZoomUserEdge = {
  __typename?: 'ZoomUserEdge';
  /** The node containing the ZoomUser */
  node: ZoomUser;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ZoomUserFilter = {
  and?: Maybe<Array<ZoomUserFilter>>;
  or?: Maybe<Array<ZoomUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  zoomId?: Maybe<StringFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  user?: Maybe<ZoomUserFilterUserFilter>;
  meetings?: Maybe<ZoomUserFilterZoomMeetingFilter>;
};

export type ZoomUserFilterUserFilter = {
  and?: Maybe<Array<ZoomUserFilterUserFilter>>;
  or?: Maybe<Array<ZoomUserFilterUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  deletedAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  emailVerified?: Maybe<BooleanFieldComparison>;
  phone?: Maybe<StringFieldComparison>;
  phoneVerified?: Maybe<BooleanFieldComparison>;
  username?: Maybe<StringFieldComparison>;
  firstName?: Maybe<StringFieldComparison>;
  lastName?: Maybe<StringFieldComparison>;
  nickname?: Maybe<StringFieldComparison>;
  avatar?: Maybe<StringFieldComparison>;
  city?: Maybe<StringFieldComparison>;
  continent?: Maybe<StringFieldComparison>;
  country?: Maybe<StringFieldComparison>;
  welcomeComplete?: Maybe<DateFieldComparison>;
  welcomeVetted?: Maybe<BooleanFieldComparison>;
  criminalRecordCheckDate?: Maybe<DateFieldComparison>;
  criminalRecordCheckValid?: Maybe<BooleanFieldComparison>;
  criminalRecordCheck?: Maybe<StringFieldComparison>;
  location?: Maybe<PointObjectFilterComparison>;
  dob?: Maybe<StringFieldComparison>;
  address?: Maybe<AddressObjectFilterComparison>;
};

export type ZoomUserFilterZoomMeetingFilter = {
  and?: Maybe<Array<ZoomUserFilterZoomMeetingFilter>>;
  or?: Maybe<Array<ZoomUserFilterZoomMeetingFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  code?: Maybe<StringFieldComparison>;
  joinUrl?: Maybe<StringFieldComparison>;
  zoomUserId?: Maybe<StringFieldComparison>;
};

export type ZoomUserMaxAggregate = {
  __typename?: 'ZoomUserMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zoomId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ZoomUserMeetingsConnection = {
  __typename?: 'ZoomUserMeetingsConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ZoomMeetingEdge>;
};

export type ZoomUserMinAggregate = {
  __typename?: 'ZoomUserMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zoomId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ZoomUserSort = {
  field: ZoomUserSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ZoomUserSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ZoomId = 'zoomId',
  Email = 'email',
}

export type ZoomUserUpdateFilter = {
  and?: Maybe<Array<ZoomUserUpdateFilter>>;
  or?: Maybe<Array<ZoomUserUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  zoomId?: Maybe<StringFieldComparison>;
  email?: Maybe<StringFieldComparison>;
};

export type NextGroupExperiencesQueryVariables = Exact<{
  now?: Maybe<Scalars['DateTime']>;
}>;

export type NextGroupExperiencesQuery = { __typename?: 'Query' } & {
  experiences: { __typename?: 'ExperienceConnection' } & {
    edges: Array<
      { __typename?: 'ExperienceEdge' } & {
        node: { __typename?: 'Experience' } & ExperienceCardFieldsFragment;
      }
    >;
  };
};

export type HomepageUpcomingQueryVariables = Exact<{
  now?: Maybe<Scalars['DateTime']>;
}>;

export type HomepageUpcomingQuery = { __typename?: 'Query' } & {
  experiences: { __typename?: 'ExperienceConnection' } & {
    edges: Array<
      { __typename?: 'ExperienceEdge' } & {
        node: { __typename?: 'Experience' } & ExperienceCardFieldsFragment;
      }
    >;
  };
};

export type ExperienceAvailabilityQueryVariables = Exact<{
  experienceId: Scalars['ID'];
  userId: Scalars['ID'];
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
}>;

export type ExperienceAvailabilityQuery = { __typename?: 'Query' } & {
  experienceAvailability: Array<
    { __typename?: 'AvailableTimeslot' } & AvailableTimeslotFieldsFragment
  >;
};

export type RequestBookingFieldsFragment = { __typename?: 'Event' } & Pick<Event, 'id'>;

export type RequestBookingMutationVariables = Exact<{
  experienceId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;

export type RequestBookingMutation = { __typename?: 'Mutation' } & {
  requestBooking: { __typename?: 'Event' } & RequestBookingFieldsFragment;
};

export type BookingExperienceFieldsFragment = { __typename?: 'Experience' } & Pick<
  Experience,
  | 'title'
  | 'createdAt'
  | 'id'
  | 'mode'
  | 'category'
  | 'type'
  | 'status'
  | 'nextEvent'
  | 'publishedAt'
> & {
    video?: Maybe<
      { __typename?: 'OEmbed' } & Pick<OEmbed, 'url'> & {
          data?: Maybe<
            | { __typename?: 'OEmbedLinkData' }
            | { __typename?: 'OEmbedPhotoData' }
            | { __typename?: 'OEmbedRichData' }
            | ({ __typename?: 'OEmbedVideoData' } & VideoFieldsFragment)
          >;
        }
    >;
    content?: Maybe<
      { __typename?: 'ExperienceContent' } & Pick<
        ExperienceContent,
        'description' | 'what' | 'bring' | 'accessibility' | 'environment'
      >
    >;
    images: { __typename?: 'ExperienceImagesConnection' } & {
      edges: Array<
        { __typename?: 'ImageEdge' } & {
          node: { __typename?: 'Image' } & Pick<Image, 'id' | 'order'> & {
              file?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment>;
            };
        }
      >;
    };
    host: { __typename?: 'User' } & Pick<User, 'id'> & {
        profile?: Maybe<
          { __typename?: 'Profile' } & Pick<Profile, 'id' | 'about' | 'nickname'> & {
              avatar?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment>;
            }
        >;
      };
  };

export type BookingFieldsFragment = { __typename?: 'Event' } & Pick<
  Event,
  'id' | 'start' | 'end' | 'status' | 'timezone' | 'private' | 'overrideLink'
> & {
    location?: Maybe<
      { __typename?: 'EventLocation' } & Pick<
        EventLocation,
        | 'streetNumber'
        | 'route'
        | 'locality'
        | 'administrativeAreaLevel1'
        | 'country'
        | 'postalCode'
      > & {
          approximateLocation?: Maybe<{ __typename?: 'Point' } & Pick<Point, 'coordinates'>>;
          coordinates?: Maybe<{ __typename?: 'Point' } & Pick<Point, 'coordinates'>>;
        }
    >;
    calendar: { __typename?: 'Calendar' } & {
      experience?: Maybe<{ __typename?: 'Experience' } & BookingExperienceFieldsFragment>;
    };
    attendees?: Maybe<
      { __typename?: 'EventAttendeesConnection' } & {
        edges: Array<
          { __typename?: 'AttendeeEdge' } & {
            node: { __typename?: 'Attendee' } & BookingAttendeeFieldsFragment;
          }
        >;
      }
    >;
    zoomMeeting?: Maybe<{ __typename?: 'ZoomMeeting' } & Pick<ZoomMeeting, 'id' | 'joinUrl'>>;
  };

export type BookingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BookingQuery = { __typename?: 'Query' } & {
  event?: Maybe<{ __typename?: 'Event' } & BookingFieldsFragment>;
};

export type ConfirmBookingMutationVariables = Exact<{
  eventId: Scalars['ID'];
}>;

export type ConfirmBookingMutation = { __typename?: 'Mutation' } & {
  confirmBooking: { __typename?: 'Event' } & Pick<Event, 'id'>;
};

export type CancelBookingMutationVariables = Exact<{
  eventId: Scalars['ID'];
  reason: Scalars['String'];
}>;

export type CancelBookingMutation = { __typename?: 'Mutation' } & {
  cancelBooking: { __typename?: 'Event' } & Pick<Event, 'id'>;
};

export type BookingAttendeeFieldsFragment = { __typename?: 'Attendee' } & Pick<
  Attendee,
  'id' | 'status' | 'role'
> & { user?: Maybe<{ __typename?: 'User' } & ExperienceCardHostFieldsFragment> };

export type BookingCardFieldsFragment = { __typename?: 'Event' } & Pick<
  Event,
  'id' | 'start' | 'end' | 'status' | 'timezone' | 'private'
> & {
    calendar: { __typename?: 'Calendar' } & {
      experience?: Maybe<{ __typename?: 'Experience' } & ExperienceCardFieldsFragment>;
    };
    attendees?: Maybe<
      { __typename?: 'EventAttendeesConnection' } & {
        edges: Array<
          { __typename?: 'AttendeeEdge' } & {
            node: { __typename?: 'Attendee' } & BookingAttendeeFieldsFragment;
          }
        >;
      }
    >;
  };

export type BookingsQueryVariables = Exact<{
  filter?: Maybe<EventFilter>;
  sorting?: Maybe<Array<EventSort> | EventSort>;
  paging?: Maybe<CursorPaging>;
}>;

export type BookingsQuery = { __typename?: 'Query' } & {
  bookings: { __typename?: 'EventConnection' } & Pick<EventConnection, 'totalCount'> & {
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFieldsFragment;
      edges: Array<
        { __typename?: 'EventEdge' } & Pick<EventEdge, 'cursor'> & {
            node: { __typename?: 'Event' } & BookingCardFieldsFragment;
          }
      >;
    };
};

export type OrdersQueryVariables = Exact<{
  filter?: Maybe<OrderFilter>;
  sorting?: Maybe<Array<OrderSort> | OrderSort>;
}>;

export type OrdersQuery = { __typename?: 'Query' } & {
  orders: { __typename?: 'OrderConnection' } & Pick<OrderConnection, 'totalCount'> & {
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFieldsFragment;
      edges: Array<
        { __typename?: 'OrderEdge' } & Pick<OrderEdge, 'cursor'> & {
            node: { __typename?: 'Order' } & OrderCardFieldsFragment;
          }
      >;
    };
};

export type OrderCardFieldsFragment = { __typename?: 'Order' } & Pick<
  Order,
  'id' | 'createdAt' | 'status' | 'shippedAt' | 'deliveredAt'
> & { experience: { __typename?: 'Experience' } & ExperienceCardFieldsFragment };

export type OrderFieldsFragment = { __typename?: 'Order' } & Pick<
  Order,
  'id' | 'createdAt' | 'status' | 'shippedAt' | 'deliveredAt'
> & { experience: { __typename?: 'Experience' } & ExperienceCardFieldsFragment };

export type OrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrderQuery = { __typename?: 'Query' } & {
  order?: Maybe<{ __typename?: 'Order' } & OrderFieldsFragment>;
};

export type RescheduleBookingMutationVariables = Exact<{
  eventId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;

export type RescheduleBookingMutation = { __typename?: 'Mutation' } & {
  rescheduleBooking: { __typename?: 'Event' } & Pick<Event, 'id'>;
};

export type CheckIfThreadExistsMutationVariables = Exact<{
  otherUserId: Scalars['String'];
}>;

export type CheckIfThreadExistsMutation = { __typename?: 'Mutation' } & {
  checkIfThreadExists?: Maybe<
    { __typename?: 'ThreadParticipants' } & Pick<ThreadParticipants, 'messageThreadId'>
  >;
};

export type CreateNewMessageFlagMutationVariables = Exact<{
  input: CreateOneMessageFlaggingInput;
}>;

export type CreateNewMessageFlagMutation = { __typename?: 'Mutation' } & {
  createOneMessageFlagging: { __typename?: 'MessageFlagging' } & Pick<
    MessageFlagging,
    'id' | 'userId' | 'reason' | 'status'
  >;
};

export type CreateMessageForFileMutationVariables = Exact<{
  input: CreateMessageInput;
}>;

export type CreateMessageForFileMutation = { __typename?: 'Mutation' } & {
  createMessageForFiles: { __typename?: 'Message' } & Pick<
    Message,
    'id' | 'createdAt' | 'content' | 'updatedAt' | 'userId' | 'messageThreadId'
  >;
};

export type CreateNewThreadMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  otherUserId: Scalars['String'];
}>;

export type CreateNewThreadMutation = { __typename?: 'Mutation' } & {
  createMessageThread: { __typename?: 'MessageThread' } & Pick<
    MessageThread,
    'id' | 'createdAt' | 'updatedAt'
  >;
};

export type SendMessageMutationVariables = Exact<{
  input: CreateOneMessageInput;
}>;

export type SendMessageMutation = { __typename?: 'Mutation' } & {
  createOneMessage: { __typename?: 'Message' } & Pick<
    Message,
    'id' | 'createdAt' | 'updatedAt' | 'content' | 'userId' | 'messageThreadId'
  >;
};

export type CreatedNewThreadSubscriptionVariables = Exact<{ [key: string]: never }>;

export type CreatedNewThreadSubscription = { __typename?: 'Subscription' } & {
  createdMessageThread: { __typename?: 'MessageThread' } & Pick<
    MessageThread,
    'id' | 'createdAt' | 'updatedAt' | 'lastMessageAt'
  > & {
      threadParticipants?: Maybe<
        { __typename?: 'MessageThreadThreadParticipantsConnection' } & {
          edges: Array<
            { __typename?: 'ThreadParticipantsEdge' } & {
              node: { __typename?: 'ThreadParticipants' } & Pick<
                ThreadParticipants,
                'id' | 'createdAt' | 'updatedAt' | 'userId' | 'unread'
              > & {
                  user: { __typename?: 'User' } & Pick<User, 'id'> & {
                      profile?: Maybe<
                        { __typename?: 'Profile' } & Pick<Profile, 'id' | 'nickname'> & {
                            avatar?: Maybe<{ __typename?: 'File' } & Pick<File, 'url'>>;
                          }
                      >;
                    };
                };
            }
          >;
        }
      >;
    };
};

export type CurrentParticipantQueryVariables = Exact<{
  userId: Scalars['String'];
  threadId: Scalars['String'];
}>;

export type CurrentParticipantQuery = { __typename?: 'Query' } & {
  threadParticipants: { __typename?: 'ThreadParticipantsConnection' } & {
    edges: Array<
      { __typename?: 'ThreadParticipantsEdge' } & {
        node: { __typename?: 'ThreadParticipants' } & Pick<ThreadParticipants, 'id' | 'unread'>;
      }
    >;
  };
};

export type GetLastMessageQueryVariables = Exact<{
  threadId: Scalars['String'];
}>;

export type GetLastMessageQuery = { __typename?: 'Query' } & {
  messages: { __typename?: 'MessageConnection' } & {
    edges: Array<
      { __typename?: 'MessageEdge' } & {
        node: { __typename?: 'Message' } & Pick<Message, 'content' | 'id' | 'updatedAt'>;
      }
    >;
  };
};

export type GetMessagesForAThreadQueryVariables = Exact<{
  threadId: Scalars['String'];
  cursor?: Maybe<Scalars['ConnectionCursor']>;
}>;

export type GetMessagesForAThreadQuery = { __typename?: 'Query' } & {
  messages: { __typename?: 'MessageConnection' } & {
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFieldsFragment;
    edges: Array<
      { __typename?: 'MessageEdge' } & Pick<MessageEdge, 'cursor'> & {
          node: { __typename?: 'Message' } & Pick<
            Message,
            'content' | 'userId' | 'id' | 'createdAt' | 'updatedAt' | 'messageThreadId'
          > & {
              messageThread?: Maybe<{ __typename?: 'MessageThread' } & Pick<MessageThread, 'id'>>;
              messageFlagging?: Maybe<
                { __typename?: 'MessageFlagging' } & Pick<MessageFlagging, 'id' | 'status'>
              >;
              messageFiles?: Maybe<
                { __typename?: 'MessageMessageFilesConnection' } & {
                  edges: Array<
                    { __typename?: 'MessageFileEdge' } & {
                      node: { __typename?: 'MessageFile' } & Pick<MessageFile, 'id'> & {
                          file?: Maybe<{ __typename?: 'File' } & Pick<File, 'url'>>;
                        };
                    }
                  >;
                }
              >;
            };
        }
    >;
  };
};

export type GetMessagesSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GetMessagesSubscription = { __typename?: 'Subscription' } & {
  createdMessage: { __typename?: 'Message' } & Pick<
    Message,
    'content' | 'userId' | 'id' | 'createdAt' | 'updatedAt' | 'messageThreadId'
  >;
};

export type GetOtherParticipantQueryVariables = Exact<{
  userId: Scalars['String'];
  threadId: Scalars['String'];
}>;

export type GetOtherParticipantQuery = { __typename?: 'Query' } & {
  threadParticipants: { __typename?: 'ThreadParticipantsConnection' } & {
    edges: Array<
      { __typename?: 'ThreadParticipantsEdge' } & {
        node: { __typename?: 'ThreadParticipants' } & Pick<
          ThreadParticipants,
          'id' | 'messageThreadId' | 'userId' | 'unread'
        >;
      }
    >;
  };
};

export type GetThreadsForCurrentUserQueryVariables = Exact<{
  cursor?: Maybe<Scalars['ConnectionCursor']>;
}>;

export type GetThreadsForCurrentUserQuery = { __typename?: 'Query' } & {
  messageThreads: { __typename?: 'MessageThreadConnection' } & {
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFieldsFragment;
    edges: Array<
      { __typename?: 'MessageThreadEdge' } & Pick<MessageThreadEdge, 'cursor'> & {
          node: { __typename?: 'MessageThread' } & Pick<
            MessageThread,
            'id' | 'createdAt' | 'lastMessageAt'
          > & {
              messages?: Maybe<
                { __typename?: 'MessageThreadMessagesConnection' } & {
                  edges: Array<
                    { __typename?: 'MessageEdge' } & {
                      node: { __typename?: 'Message' } & Pick<
                        Message,
                        'content' | 'createdAt' | 'updatedAt'
                      > & {
                          user: { __typename?: 'User' } & Pick<User, 'nickname' | 'id'>;
                          messageFiles?: Maybe<
                            { __typename?: 'MessageMessageFilesConnection' } & {
                              edges: Array<
                                { __typename?: 'MessageFileEdge' } & {
                                  node: { __typename?: 'MessageFile' } & Pick<MessageFile, 'id'>;
                                }
                              >;
                            }
                          >;
                        };
                    }
                  >;
                }
              >;
              threadParticipants?: Maybe<
                { __typename?: 'MessageThreadThreadParticipantsConnection' } & {
                  edges: Array<
                    { __typename?: 'ThreadParticipantsEdge' } & {
                      node: { __typename?: 'ThreadParticipants' } & Pick<
                        ThreadParticipants,
                        'id' | 'createdAt' | 'updatedAt' | 'userId' | 'unread'
                      > & {
                          user: { __typename?: 'User' } & Pick<User, 'id'> & {
                              profile?: Maybe<
                                { __typename?: 'Profile' } & Pick<Profile, 'id' | 'nickname'> & {
                                    avatar?: Maybe<{ __typename?: 'File' } & Pick<File, 'url'>>;
                                  }
                              >;
                            };
                        };
                    }
                  >;
                }
              >;
            };
        }
    >;
  };
};

export type GetUpdatedMessageSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GetUpdatedMessageSubscription = { __typename?: 'Subscription' } & {
  updatedOneMessage: { __typename?: 'Message' } & Pick<
    Message,
    'content' | 'userId' | 'id' | 'createdAt' | 'updatedAt' | 'messageThreadId'
  >;
};

export type UploadMessageFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  messageId: Scalars['ID'];
}>;

export type UploadMessageFileMutation = { __typename?: 'Mutation' } & {
  uploadMessageFile: { __typename?: 'File' } & Pick<File, 'id' | 'url'>;
};

export type EventFieldsFragment = { __typename?: 'Event' } & Pick<
  Event,
  'id' | 'start' | 'end' | 'timezone' | 'status' | 'overrideLink'
> & {
    calendar: { __typename?: 'Calendar' } & Pick<Calendar, 'id'> & {
        experience?: Maybe<{ __typename?: 'Experience' } & ExperienceCardFieldsFragment>;
      };
    zoomMeeting?: Maybe<
      { __typename?: 'ZoomMeeting' } & Pick<ZoomMeeting, 'id' | 'code' | 'password' | 'joinUrl'>
    >;
    attendees?: Maybe<
      { __typename?: 'EventAttendeesConnection' } & {
        edges: Array<
          { __typename?: 'AttendeeEdge' } & {
            node: { __typename?: 'Attendee' } & Pick<Attendee, 'id' | 'status'> & {
                user?: Maybe<
                  { __typename?: 'User' } & Pick<User, 'id'> & {
                      profile?: Maybe<{ __typename?: 'Profile' } & UserProfileFieldsFragment>;
                    }
                >;
              };
          }
        >;
      }
    >;
  };

export type EventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type EventQuery = { __typename?: 'Query' } & {
  event?: Maybe<{ __typename?: 'Event' } & EventFieldsFragment>;
};

export type ExperienceEventFieldsFragment = { __typename?: 'Event' } & Pick<
  Event,
  'id' | 'start' | 'end' | 'timezone' | 'status' | 'overrideLink'
> & { zoomMeeting?: Maybe<{ __typename?: 'ZoomMeeting' } & Pick<ZoomMeeting, 'id' | 'joinUrl'>> };

export type ExperienceEventsQueryVariables = Exact<{
  id: Scalars['ID'];
  now?: Maybe<Scalars['DateTime']>;
}>;

export type ExperienceEventsQuery = { __typename?: 'Query' } & {
  events: { __typename?: 'EventConnection' } & {
    edges: Array<
      { __typename?: 'EventEdge' } & {
        node: { __typename?: 'Event' } & ExperienceEventFieldsFragment;
      }
    >;
  };
};

export type ExperienceFieldsFragment = { __typename?: 'Experience' } & Pick<
  Experience,
  | 'title'
  | 'createdAt'
  | 'id'
  | 'category'
  | 'type'
  | 'status'
  | 'mode'
  | 'nextEvent'
  | 'locality'
  | 'administrativeAreaLevel1'
  | 'nextAvailability'
  | 'publishedAt'
> & {
    video?: Maybe<
      { __typename?: 'OEmbed' } & Pick<OEmbed, 'url'> & {
          data?: Maybe<
            | { __typename?: 'OEmbedLinkData' }
            | { __typename?: 'OEmbedPhotoData' }
            | { __typename?: 'OEmbedRichData' }
            | ({ __typename?: 'OEmbedVideoData' } & VideoFieldsFragment)
          >;
        }
    >;
    content?: Maybe<
      { __typename?: 'ExperienceContent' } & Pick<
        ExperienceContent,
        'description' | 'what' | 'bring' | 'accessibility' | 'environment'
      >
    >;
    images: { __typename?: 'ExperienceImagesConnection' } & {
      edges: Array<
        { __typename?: 'ImageEdge' } & {
          node: { __typename?: 'Image' } & Pick<Image, 'id' | 'order'> & {
              file?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment>;
            };
        }
      >;
    };
    host: { __typename?: 'User' } & Pick<User, 'id'> & {
        profile?: Maybe<
          { __typename?: 'Profile' } & Pick<Profile, 'id' | 'about' | 'nickname'> & {
              avatar?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment>;
            }
        >;
      };
    calendar?: Maybe<
      { __typename?: 'Calendar' } & {
        events: { __typename?: 'CalendarEventsConnection' } & {
          edges: Array<
            { __typename?: 'EventEdge' } & {
              node: { __typename?: 'Event' } & Pick<Event, 'id' | 'start' | 'end'>;
            }
          >;
        };
      }
    >;
    experienceTags: { __typename?: 'ExperienceExperienceTagsConnection' } & {
      edges: Array<
        { __typename?: 'ExperienceTagEdge' } & {
          node: { __typename?: 'ExperienceTag' } & Pick<ExperienceTag, 'id'> & {
              tag: { __typename?: 'Tag' } & Pick<Tag, 'id'>;
            };
        }
      >;
    };
  };

export type FullExperienceFieldsFragment = { __typename?: 'Experience' } & Pick<
  Experience,
  | 'title'
  | 'createdAt'
  | 'id'
  | 'category'
  | 'type'
  | 'status'
  | 'nextEvent'
  | 'mode'
  | 'nextAvailability'
  | 'locationType'
  | 'administrativeAreaLevel1'
  | 'administrativeAreaLevel2'
  | 'country'
  | 'postalCode'
  | 'streetNumber'
  | 'route'
  | 'locality'
  | 'publishedAt'
> & {
    coordinates?: Maybe<{ __typename?: 'Point' } & Pick<Point, 'type' | 'coordinates'>>;
    approximateLocation?: Maybe<{ __typename?: 'Point' } & Pick<Point, 'coordinates'>>;
    video?: Maybe<
      { __typename?: 'OEmbed' } & Pick<OEmbed, 'url'> & {
          data?: Maybe<
            | { __typename?: 'OEmbedLinkData' }
            | { __typename?: 'OEmbedPhotoData' }
            | { __typename?: 'OEmbedRichData' }
            | ({ __typename?: 'OEmbedVideoData' } & VideoFieldsFragment)
          >;
        }
    >;
    content?: Maybe<
      { __typename?: 'ExperienceContent' } & Pick<
        ExperienceContent,
        'description' | 'what' | 'bring' | 'accessibility' | 'environment'
      >
    >;
    images: { __typename?: 'ExperienceImagesConnection' } & {
      edges: Array<
        { __typename?: 'ImageEdge' } & {
          node: { __typename?: 'Image' } & Pick<Image, 'id' | 'order'> & {
              file?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment>;
            };
        }
      >;
    };
    host: { __typename?: 'User' } & Pick<User, 'id'> & {
        profile?: Maybe<
          { __typename?: 'Profile' } & Pick<Profile, 'id' | 'about' | 'nickname'> & {
              avatar?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment>;
            }
        >;
      };
  };

export type GetExperienceByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetExperienceByIdQuery = { __typename?: 'Query' } & {
  experience?: Maybe<{ __typename?: 'Experience' } & ExperienceFieldsFragment>;
};

export type GetExperienceTagsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetExperienceTagsQuery = { __typename?: 'Query' } & {
  experience?: Maybe<
    { __typename?: 'Experience' } & {
      experienceTags: { __typename?: 'ExperienceExperienceTagsConnection' } & {
        edges: Array<
          { __typename?: 'ExperienceTagEdge' } & {
            node: { __typename?: 'ExperienceTag' } & {
              tag: { __typename?: 'Tag' } & Pick<
                Tag,
                'id' | 'title' | 'category' | 'public' | 'description'
              > & { icon?: Maybe<{ __typename?: 'File' } & Pick<File, 'url' | 'key'>> };
            };
          }
        >;
      };
    }
  >;
};

export type GetFullExperienceByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetFullExperienceByIdQuery = { __typename?: 'Query' } & {
  experience?: Maybe<
    { __typename?: 'Experience' } & Pick<
      Experience,
      | 'title'
      | 'createdAt'
      | 'id'
      | 'category'
      | 'type'
      | 'status'
      | 'nextEvent'
      | 'mode'
      | 'nextAvailability'
      | 'locationType'
      | 'administrativeAreaLevel1'
      | 'administrativeAreaLevel2'
      | 'country'
      | 'locality'
      | 'publishedAt'
    > & {
        approximateLocation?: Maybe<{ __typename?: 'Point' } & Pick<Point, 'coordinates'>>;
        video?: Maybe<
          { __typename?: 'OEmbed' } & Pick<OEmbed, 'url'> & {
              data?: Maybe<
                | { __typename?: 'OEmbedLinkData' }
                | { __typename?: 'OEmbedPhotoData' }
                | { __typename?: 'OEmbedRichData' }
                | ({ __typename?: 'OEmbedVideoData' } & VideoFieldsFragment)
              >;
            }
        >;
        experienceTags: { __typename?: 'ExperienceExperienceTagsConnection' } & {
          edges: Array<
            { __typename?: 'ExperienceTagEdge' } & {
              node: { __typename?: 'ExperienceTag' } & Pick<ExperienceTag, 'id'> & {
                  tag: { __typename?: 'Tag' } & Pick<
                    Tag,
                    'id' | 'title' | 'category' | 'public' | 'description'
                  > & { icon?: Maybe<{ __typename?: 'File' } & Pick<File, 'url' | 'key'>> };
                };
            }
          >;
        };
        content?: Maybe<
          { __typename?: 'ExperienceContent' } & Pick<
            ExperienceContent,
            'description' | 'what' | 'bring' | 'accessibility' | 'environment'
          >
        >;
        images: { __typename?: 'ExperienceImagesConnection' } & {
          edges: Array<
            { __typename?: 'ImageEdge' } & {
              node: { __typename?: 'Image' } & Pick<Image, 'id' | 'order'> & {
                  file?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment>;
                };
            }
          >;
        };
        host: { __typename?: 'User' } & Pick<User, 'id'> & {
            profile?: Maybe<
              { __typename?: 'Profile' } & Pick<Profile, 'id' | 'about' | 'nickname'> & {
                  avatar?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment>;
                }
            >;
          };
      }
  >;
};

export type SignupForEventAgainMutationVariables = Exact<{
  attendeeId: Scalars['ID'];
}>;

export type SignupForEventAgainMutation = { __typename?: 'Mutation' } & {
  updateOneAttendee: { __typename?: 'Attendee' } & Pick<Attendee, 'id'>;
};

export type SignupForEventMutationVariables = Exact<{
  userId: Scalars['ID'];
  eventId: Scalars['ID'];
}>;

export type SignupForEventMutation = { __typename?: 'Mutation' } & {
  createOneAttendee: { __typename?: 'Attendee' } & Pick<Attendee, 'id'>;
};

export type ExperiencesQueryVariables = Exact<{
  filter?: Maybe<ExperienceFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<ExperienceSort> | ExperienceSort>;
}>;

export type ExperiencesQuery = { __typename?: 'Query' } & {
  experiences: { __typename?: 'ExperienceConnection' } & Pick<
    ExperienceConnection,
    'totalCount'
  > & {
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFieldsFragment;
      edges: Array<
        { __typename?: 'ExperienceEdge' } & Pick<ExperienceEdge, 'cursor'> & {
            node: { __typename?: 'Experience' } & ExperienceCardFieldsFragment;
          }
      >;
    };
};

export type GroupExperienceEventsQueryVariables = Exact<{
  filter?: Maybe<EventFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<EventSort> | EventSort>;
}>;

export type GroupExperienceEventsQuery = { __typename?: 'Query' } & {
  groupExperienceEvents: { __typename?: 'EventConnection' } & {
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFieldsFragment;
    edges: Array<
      { __typename?: 'EventEdge' } & Pick<EventEdge, 'cursor'> & {
          node: { __typename?: 'Event' } & EventCardFieldsFragment;
        }
    >;
  };
};

export type AccessibilityTagsQueryVariables = Exact<{ [key: string]: never }>;

export type AccessibilityTagsQuery = { __typename?: 'Query' } & {
  tags: { __typename?: 'TagConnection' } & {
    edges: Array<
      { __typename?: 'TagEdge' } & {
        node: { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'description' | 'category'> & {
            icon?: Maybe<{ __typename?: 'File' } & Pick<File, 'url' | 'key'>>;
          };
      }
    >;
  };
};

export type SettingTagsQueryVariables = Exact<{ [key: string]: never }>;

export type SettingTagsQuery = { __typename?: 'Query' } & {
  tags: { __typename?: 'TagConnection' } & {
    edges: Array<
      { __typename?: 'TagEdge' } & {
        node: { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'description' | 'category'> & {
            icon?: Maybe<{ __typename?: 'File' } & Pick<File, 'url' | 'key'>>;
          };
      }
    >;
  };
};

export type WhatToBringTagsQueryVariables = Exact<{ [key: string]: never }>;

export type WhatToBringTagsQuery = { __typename?: 'Query' } & {
  tags: { __typename?: 'TagConnection' } & {
    edges: Array<
      { __typename?: 'TagEdge' } & {
        node: { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'description' | 'category'> & {
            icon?: Maybe<{ __typename?: 'File' } & Pick<File, 'url' | 'key'>>;
          };
      }
    >;
  };
};

export type CompleteExperienceFieldsFragment = { __typename?: 'Experience' } & Pick<
  Experience,
  | 'title'
  | 'createdAt'
  | 'id'
  | 'category'
  | 'type'
  | 'status'
  | 'nextEvent'
  | 'nextAvailability'
  | 'mode'
  | 'locality'
  | 'administrativeAreaLevel1'
  | 'publishedAt'
  | 'progressStep'
  | 'meetingScheduled'
> & {
    video?: Maybe<
      { __typename?: 'OEmbed' } & Pick<OEmbed, 'url'> & {
          data?: Maybe<
            | { __typename?: 'OEmbedLinkData' }
            | { __typename?: 'OEmbedPhotoData' }
            | { __typename?: 'OEmbedRichData' }
            | ({ __typename?: 'OEmbedVideoData' } & VideoFieldsFragment)
          >;
        }
    >;
    content?: Maybe<
      { __typename?: 'ExperienceContent' } & Pick<
        ExperienceContent,
        'description' | 'what' | 'bring' | 'accessibility' | 'environment'
      >
    >;
    brainstorm?: Maybe<
      { __typename?: 'ExperienceBrainstorm' } & Pick<
        ExperienceBrainstorm,
        | 'inspiration'
        | 'hobby'
        | 'first'
        | 'second'
        | 'third'
        | 'interest'
        | 'secondInterest'
        | 'partOfDay'
      >
    >;
    images: { __typename?: 'ExperienceImagesConnection' } & {
      edges: Array<
        { __typename?: 'ImageEdge' } & {
          node: { __typename?: 'Image' } & Pick<Image, 'id' | 'order'> & {
              file?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment>;
            };
        }
      >;
    };
    host: { __typename?: 'User' } & Pick<User, 'id'> & {
        profile?: Maybe<
          { __typename?: 'Profile' } & Pick<Profile, 'id' | 'about' | 'nickname'> & {
              avatar?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment>;
            }
        >;
      };
  };

export type CreateEmptyExperienceMutationVariables = Exact<{
  hostId: Scalars['ID'];
}>;

export type CreateEmptyExperienceMutation = { __typename?: 'Mutation' } & {
  createOneExperience: { __typename?: 'Experience' } & Pick<Experience, 'id'>;
};

export type CreateEventForExperienceMutationVariables = Exact<{
  input: CreateEventInput;
}>;

export type CreateEventForExperienceMutation = { __typename?: 'Mutation' } & {
  createOneEvent: { __typename?: 'Event' } & Pick<Event, 'id'>;
};

export type CreateExperienceTagsMutationVariables = Exact<{
  input: CreateManyExperienceTagsInput;
}>;

export type CreateExperienceTagsMutation = { __typename?: 'Mutation' } & {
  createManyExperienceTags: Array<{ __typename?: 'ExperienceTag' } & Pick<ExperienceTag, 'id'>>;
};

export type CreateTimeSlotsMutationVariables = Exact<{
  timeslots: CreateManyTimeslotsInput;
}>;

export type CreateTimeSlotsMutation = { __typename?: 'Mutation' } & {
  createManyTimeslots: Array<{ __typename?: 'Timeslot' } & Pick<Timeslot, 'scheduleId'>>;
};

export type DeleteExperienceMutationVariables = Exact<{
  ID: Scalars['ID'];
}>;

export type DeleteExperienceMutation = { __typename?: 'Mutation' } & {
  deleteOneExperience: { __typename?: 'ExperienceDeleteResponse' } & Pick<
    ExperienceDeleteResponse,
    'id'
  >;
};

export type DeleteExperienceImageMutationVariables = Exact<{
  imageId: Scalars['ID'];
}>;

export type DeleteExperienceImageMutation = { __typename?: 'Mutation' } & {
  deleteOneImage: { __typename?: 'ImageDeleteResponse' } & Pick<ImageDeleteResponse, 'id'>;
};

export type DeleteManyExperienceTagsMutationVariables = Exact<{
  input: DeleteManyExperienceTagsInput;
}>;

export type DeleteManyExperienceTagsMutation = { __typename?: 'Mutation' } & {
  deleteManyExperienceTags: { __typename?: 'DeleteManyResponse' } & Pick<
    DeleteManyResponse,
    'deletedCount'
  >;
};

export type DeleteManyEventsMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type DeleteManyEventsMutation = { __typename?: 'Mutation' } & {
  deleteManyEvents: { __typename?: 'DeleteManyResponse' } & Pick<
    DeleteManyResponse,
    'deletedCount'
  >;
};

export type DeleteOneEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteOneEventMutation = { __typename?: 'Mutation' } & {
  deleteOneEvent: { __typename?: 'EventDeleteResponse' } & Pick<EventDeleteResponse, 'id'>;
};

export type DeleteOneExperienceTagMutationVariables = Exact<{
  input: DeleteOneExperienceTagInput;
}>;

export type DeleteOneExperienceTagMutation = { __typename?: 'Mutation' } & {
  deleteOneExperienceTag: { __typename?: 'ExperienceTagDeleteResponse' } & Pick<
    ExperienceTagDeleteResponse,
    'id'
  >;
};

export type DeleteTimeslotMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTimeslotMutation = { __typename?: 'Mutation' } & {
  deleteOneTimeslot: { __typename?: 'TimeslotDeleteResponse' } & Pick<TimeslotDeleteResponse, 'id'>;
};

export type EditExperienceFieldsFragment = { __typename?: 'Experience' } & Pick<
  Experience,
  | 'mode'
  | 'locationType'
  | 'streetNumber'
  | 'route'
  | 'locality'
  | 'administrativeAreaLevel2'
  | 'administrativeAreaLevel1'
  | 'country'
  | 'postalCode'
> & {
    calendar?: Maybe<{ __typename?: 'Calendar' } & Pick<Calendar, 'id'>>;
    coordinates?: Maybe<{ __typename?: 'Point' } & Pick<Point, 'type' | 'coordinates'>>;
  } & CompleteExperienceFieldsFragment;

export type GetCalendarQueryVariables = Exact<{
  experienceId: Scalars['ID'];
}>;

export type GetCalendarQuery = { __typename?: 'Query' } & {
  experience?: Maybe<
    { __typename?: 'Experience' } & Pick<Experience, 'id'> & {
        calendar?: Maybe<{ __typename?: 'Calendar' } & Pick<Calendar, 'id'>>;
      }
  >;
};

export type GetCoverImageQueryVariables = Exact<{
  experienceId: Scalars['ID'];
}>;

export type GetCoverImageQuery = { __typename?: 'Query' } & {
  images: { __typename?: 'ImageConnection' } & {
    edges: Array<
      { __typename?: 'ImageEdge' } & {
        node: { __typename?: 'Image' } & Pick<Image, 'id' | 'order' | 'createdAt'> & {
            file?: Maybe<{ __typename?: 'File' } & Pick<File, 'id' | 'url'>>;
          };
      }
    >;
  };
};

export type GetExperienceForEditByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetExperienceForEditByIdQuery = { __typename?: 'Query' } & {
  experience?: Maybe<{ __typename?: 'Experience' } & EditExperienceFieldsFragment>;
};

export type GetExperiencesByHostIdQueryVariables = Exact<{
  filter?: Maybe<ExperienceFilter>;
}>;

export type GetExperiencesByHostIdQuery = { __typename?: 'Query' } & {
  experiences: { __typename?: 'ExperienceConnection' } & {
    edges: Array<
      { __typename?: 'ExperienceEdge' } & {
        node: { __typename?: 'Experience' } & CompleteExperienceFieldsFragment;
      }
    >;
  };
};

export type GetScheduleForAExperienceQueryVariables = Exact<{
  experienceId: Scalars['ID'];
}>;

export type GetScheduleForAExperienceQuery = { __typename?: 'Query' } & {
  calendars: { __typename?: 'CalendarConnection' } & {
    edges: Array<
      { __typename?: 'CalendarEdge' } & {
        node: { __typename?: 'Calendar' } & Pick<Calendar, 'id'> & {
            schedule?: Maybe<
              { __typename?: 'Schedule' } & Pick<
                Schedule,
                | 'id'
                | 'createdAt'
                | 'updatedAt'
                | 'duration'
                | 'durationMetric'
                | 'range'
                | 'rangeMetric'
                | 'delay'
                | 'delayMetric'
                | 'frequency'
                | 'frequencyMetric'
                | 'cap'
                | 'capRange'
                | 'capRangeMetric'
              > & {
                  timeslots: { __typename?: 'ScheduleTimeslotsConnection' } & {
                    edges: Array<
                      { __typename?: 'TimeslotEdge' } & {
                        node: { __typename: 'Timeslot' } & Pick<
                          Timeslot,
                          'createdAt' | 'updatedAt' | 'id' | 'start' | 'end' | 'free' | 'weekly'
                        >;
                      }
                    >;
                  };
                }
            >;
            events: { __typename?: 'CalendarEventsConnection' } & {
              edges: Array<
                { __typename?: 'EventEdge' } & {
                  node: { __typename?: 'Event' } & Pick<
                    Event,
                    'id' | 'title' | 'start' | 'end' | 'calendarId'
                  >;
                }
              >;
            };
          };
      }
    >;
  };
};

export type UpdateCoverImageMutationVariables = Exact<{
  file: Scalars['Upload'];
  imageId: Scalars['ID'];
}>;

export type UpdateCoverImageMutation = { __typename?: 'Mutation' } & {
  updateCoverImage: { __typename?: 'Image' } & Pick<Image, 'id'> & {
      file?: Maybe<{ __typename?: 'File' } & Pick<File, 'id' | 'url'>>;
    };
};

export type UpdateExperienceMutationVariables = Exact<{
  input: UpdateOneExperienceInput;
}>;

export type UpdateExperienceMutation = { __typename?: 'Mutation' } & {
  updateOneExperience: { __typename?: 'Experience' } & Pick<
    Experience,
    'id' | 'category' | 'status' | 'type' | 'title'
  > & {
      content?: Maybe<
        { __typename?: 'ExperienceContent' } & Pick<
          ExperienceContent,
          'description' | 'what' | 'bring' | 'environment' | 'accessibility'
        >
      >;
    };
};

export type UpdateOneEventMutationVariables = Exact<{
  id: Scalars['ID'];
  update: UpdateEventInput;
}>;

export type UpdateOneEventMutation = { __typename?: 'Mutation' } & {
  updateOneEvent: { __typename?: 'Event' } & Pick<Event, 'id'>;
};

export type UpdateScheduleWithIdMutationVariables = Exact<{
  id: Scalars['ID'];
  update: UpdateScheduleInput;
}>;

export type UpdateScheduleWithIdMutation = { __typename?: 'Mutation' } & {
  updateOneSchedule: { __typename?: 'Schedule' } & Pick<Schedule, 'id'>;
};

export type AddImageToExperienceMutationVariables = Exact<{
  file: Scalars['Upload'];
  experienceId: Scalars['ID'];
  order?: Maybe<Scalars['Float']>;
}>;

export type AddImageToExperienceMutation = { __typename?: 'Mutation' } & {
  uploadOneExperienceImage: { __typename?: 'Image' } & Pick<Image, 'id'> & {
      file?: Maybe<{ __typename?: 'File' } & Pick<File, 'id' | 'url'>>;
    };
};

export type CreateStripeCheckoutSessionMutationVariables = Exact<{
  experienceId: Scalars['ID'];
  cancelUrl?: Maybe<Scalars['String']>;
  successUrl?: Maybe<Scalars['String']>;
}>;

export type CreateStripeCheckoutSessionMutation = { __typename?: 'Mutation' } & {
  createStripeCheckoutSession: {
    __typename?: 'StripeCheckoutSession';
  } & StripeCheckoutSessionFieldsFragment;
};

export type StripeCheckoutSessionQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type StripeCheckoutSessionQuery = { __typename?: 'Query' } & {
  stripeCheckoutSession: {
    __typename?: 'StripeCheckoutSession';
  } & StripeCheckoutSessionFieldsFragment;
};

export type StripeCheckoutSessionFieldsFragment = { __typename?: 'StripeCheckoutSession' } & Pick<
  StripeCheckoutSession,
  'id' | 'url' | 'orderId'
>;

export type DeleteOneProfileImageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteOneProfileImageMutation = { __typename?: 'Mutation' } & {
  deleteOneProfileImage: { __typename?: 'ProfileImageDeleteResponse' } & Pick<
    ProfileImageDeleteResponse,
    'id'
  >;
};

export type AttendeesQueryVariables = Exact<{
  filter?: Maybe<AttendeeFilter>;
}>;

export type AttendeesQuery = { __typename?: 'Query' } & {
  attendees: { __typename?: 'AttendeeConnection' } & Pick<AttendeeConnection, 'totalCount'>;
};

export type ProfileImageFieldsFragment = { __typename?: 'ProfileImage' } & Pick<
  ProfileImage,
  'id' | 'order' | 'createdAt'
> & { file?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment> };

export type ProfileInterestFieldsFragment = { __typename?: 'ProfileInterest' } & Pick<
  ProfileInterest,
  'id' | 'name' | 'slug'
>;

export type ProfileStrengthFieldsFragment = { __typename?: 'ProfileStrength' } & Pick<
  ProfileStrength,
  'id' | 'name' | 'slug'
>;

export type SortProfileImagesMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type SortProfileImagesMutation = { __typename?: 'Mutation' } & {
  sortProfileImages: { __typename?: 'UpdateManyResponse' } & Pick<
    UpdateManyResponse,
    'updatedCount'
  >;
};

export type UpdateOwnAvatarMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;

export type UpdateOwnAvatarMutation = { __typename?: 'Mutation' } & {
  updateAvatar?: Maybe<{ __typename?: 'File' } & Pick<File, 'id' | 'url'>>;
};

export type UpdateOwnAvatarWithSignedUrlMutationVariables = Exact<{
  filename: Scalars['String'];
  mimetype: Scalars['String'];
}>;

export type UpdateOwnAvatarWithSignedUrlMutation = { __typename?: 'Mutation' } & {
  updateAvatarWithSignedUrl?: Maybe<
    { __typename?: 'File' } & Pick<File, 'id' | 'url' | 'uploadUrl'>
  >;
};

export type UpdateOwnProfileInterestsMutationVariables = Exact<{
  interests?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type UpdateOwnProfileInterestsMutation = { __typename?: 'Mutation' } & {
  updateOwnProfileInterests?: Maybe<{ __typename?: 'Profile' } & Pick<Profile, 'id'>>;
};

export type UpdateOwnProfileStrengthsMutationVariables = Exact<{
  strengths?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type UpdateOwnProfileStrengthsMutation = { __typename?: 'Mutation' } & {
  updateOwnProfileStrengths?: Maybe<{ __typename?: 'Profile' } & Pick<Profile, 'id'>>;
};

export type UpdateUserProfileMutationVariables = Exact<{
  id: Scalars['ID'];
  profile: UpdateProfileInput;
}>;

export type UpdateUserProfileMutation = { __typename?: 'Mutation' } & {
  updateOneProfile: { __typename?: 'Profile' } & UpdateUserProfileFieldsFragment;
};

export type UpdateUserProfileFieldsFragment = { __typename?: 'Profile' } & Pick<
  Profile,
  'id' | 'nickname' | 'about'
>;

export type UploadImageMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;

export type UploadImageMutation = { __typename?: 'Mutation' } & {
  uploadImage: { __typename?: 'File' } & Pick<File, 'id' | 'url'>;
};

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserQuery = { __typename?: 'Query' } & {
  user?: Maybe<{ __typename?: 'User' } & UserFieldsFragment>;
};

export type UserFeedbackQueryVariables = Exact<{
  userId: Scalars['ID'];
  paging?: Maybe<CursorPaging>;
}>;

export type UserFeedbackQuery = { __typename?: 'Query' } & {
  feedbacks: { __typename?: 'FeedbackConnection' } & {
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFieldsFragment;
    edges: Array<
      { __typename?: 'FeedbackEdge' } & Pick<FeedbackEdge, 'cursor'> & {
          node: { __typename?: 'Feedback' } & FeedbackFieldsFragment;
        }
    >;
  };
};

export type UserFieldsFragment = { __typename?: 'User' } & Pick<User, 'id' | 'createdAt'> & {
    profile?: Maybe<{ __typename?: 'Profile' } & UserProfileFieldsFragment>;
    experiences?: Maybe<
      { __typename?: 'UserExperiencesConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & PageInfoFieldsFragment;
        edges: Array<
          { __typename?: 'ExperienceEdge' } & Pick<ExperienceEdge, 'cursor'> & {
              node: { __typename?: 'Experience' } & ExperienceCardFieldsFragment;
            }
        >;
      }
    >;
  };

export type UserProfileFieldsFragment = { __typename?: 'Profile' } & Pick<
  Profile,
  'id' | 'nickname' | 'about'
> & {
    avatar?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment>;
    interests?: Maybe<
      { __typename?: 'ProfileInterestsConnection' } & {
        edges: Array<
          { __typename?: 'ProfileInterestEdge' } & {
            node: { __typename?: 'ProfileInterest' } & ProfileInterestFieldsFragment;
          }
        >;
      }
    >;
    strengths?: Maybe<
      { __typename?: 'ProfileStrengthsConnection' } & {
        edges: Array<
          { __typename?: 'ProfileStrengthEdge' } & {
            node: { __typename?: 'ProfileStrength' } & ProfileStrengthFieldsFragment;
          }
        >;
      }
    >;
    images?: Maybe<
      { __typename?: 'ProfileImagesConnection' } & {
        edges: Array<
          { __typename?: 'ProfileImageEdge' } & {
            node: { __typename?: 'ProfileImage' } & ProfileImageFieldsFragment;
          }
        >;
      }
    >;
  };

export type UpdateUserSettingsMutationVariables = Exact<{
  id: Scalars['ID'];
  user: UpdateUserInput;
}>;

export type UpdateUserSettingsMutation = { __typename?: 'Mutation' } & {
  updateOneUser: { __typename?: 'User' } & UpdateUserSettingsFieldsFragment;
};

export type UpdateUserSettingsFieldsFragment = { __typename?: 'User' } & Pick<
  User,
  'id' | 'nickname' | 'firstName' | 'lastName' | 'country' | 'email' | 'phone' | 'dob' | 'timezone'
> & {
    address?: Maybe<
      { __typename?: 'Address' } & Pick<
        Address,
        'street' | 'street2' | 'city' | 'postcode' | 'state' | 'country'
      >
    >;
  };

export type UserSettingsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserSettingsQuery = { __typename?: 'Query' } & {
  user?: Maybe<{ __typename?: 'User' } & UserSettingsFieldsFragment>;
};

export type UserSettingsFieldsFragment = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'createdAt'
  | 'nickname'
  | 'firstName'
  | 'lastName'
  | 'country'
  | 'email'
  | 'phone'
  | 'dob'
  | 'timezone'
> & {
    address?: Maybe<
      { __typename?: 'Address' } & Pick<
        Address,
        'street' | 'street2' | 'city' | 'postcode' | 'state' | 'country'
      >
    >;
  };

export type AvailableTimeslotFieldsFragment = { __typename?: 'AvailableTimeslot' } & Pick<
  AvailableTimeslot,
  'start' | 'end'
>;

export type EventCardFieldsFragment = { __typename?: 'Event' } & Pick<
  Event,
  'id' | 'start' | 'end' | 'status' | 'timezone'
> & {
    calendar: { __typename?: 'Calendar' } & {
      experience?: Maybe<{ __typename?: 'Experience' } & ExperienceCardFieldsFragment>;
    };
  };

export type ExperienceCardFieldsFragment = { __typename?: 'Experience' } & Pick<
  Experience,
  | 'id'
  | 'title'
  | 'type'
  | 'category'
  | 'createdAt'
  | 'status'
  | 'nextAvailability'
  | 'mode'
  | 'nextEvent'
  | 'next'
  | 'locality'
  | 'administrativeAreaLevel1'
> & {
    content?: Maybe<{ __typename?: 'ExperienceContent' } & Pick<ExperienceContent, 'description'>>;
    approximateLocation?: Maybe<{ __typename?: 'Point' } & Pick<Point, 'coordinates'>>;
    host: { __typename?: 'User' } & ExperienceCardHostFieldsFragment;
    images: { __typename?: 'ExperienceImagesConnection' } & {
      edges: Array<
        { __typename?: 'ImageEdge' } & {
          node: { __typename?: 'Image' } & Pick<Image, 'id' | 'order'> & {
              file?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment>;
            };
        }
      >;
    };
    video?: Maybe<
      { __typename?: 'OEmbed' } & Pick<OEmbed, 'url'> & {
          data?: Maybe<
            | { __typename?: 'OEmbedLinkData' }
            | { __typename?: 'OEmbedPhotoData' }
            | { __typename?: 'OEmbedRichData' }
            | ({ __typename?: 'OEmbedVideoData' } & VideoFieldsFragment)
          >;
        }
    >;
  };

export type ExperienceCardHostFieldsFragment = { __typename?: 'User' } & Pick<User, 'id'> & {
    profile?: Maybe<
      { __typename?: 'Profile' } & Pick<Profile, 'id' | 'nickname' | 'about'> & {
          avatar?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment>;
        }
    >;
  };

export type CountUnreadThreadsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type CountUnreadThreadsQuery = { __typename?: 'Query' } & {
  threadParticipantsAggregate: Array<
    { __typename?: 'ThreadParticipantsAggregateResponse' } & {
      count?: Maybe<
        { __typename?: 'ThreadParticipantsCountAggregate' } & Pick<
          ThreadParticipantsCountAggregate,
          'id'
        >
      >;
    }
  >;
};

export type CreatedThreadSubscriptionVariables = Exact<{ [key: string]: never }>;

export type CreatedThreadSubscription = { __typename?: 'Subscription' } & {
  createdMessageThread: { __typename?: 'MessageThread' } & Pick<
    MessageThread,
    'id' | 'lastMessageAt'
  >;
};

export type LatestThreadsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type LatestThreadsQuery = { __typename?: 'Query' } & {
  messageThreads: { __typename?: 'MessageThreadConnection' } & {
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFieldsFragment;
    edges: Array<
      { __typename?: 'MessageThreadEdge' } & Pick<MessageThreadEdge, 'cursor'> & {
          node: { __typename?: 'MessageThread' } & Pick<
            MessageThread,
            'id' | 'createdAt' | 'lastMessageAt'
          > & {
              messages?: Maybe<
                { __typename?: 'MessageThreadMessagesConnection' } & {
                  edges: Array<
                    { __typename?: 'MessageEdge' } & {
                      node: { __typename?: 'Message' } & Pick<
                        Message,
                        'content' | 'createdAt' | 'updatedAt'
                      > & {
                          messageFiles?: Maybe<
                            { __typename?: 'MessageMessageFilesConnection' } & {
                              edges: Array<
                                { __typename?: 'MessageFileEdge' } & {
                                  node: { __typename?: 'MessageFile' } & Pick<MessageFile, 'id'>;
                                }
                              >;
                            }
                          >;
                        };
                    }
                  >;
                }
              >;
              threadParticipants?: Maybe<
                { __typename?: 'MessageThreadThreadParticipantsConnection' } & {
                  edges: Array<
                    { __typename?: 'ThreadParticipantsEdge' } & {
                      node: { __typename?: 'ThreadParticipants' } & Pick<
                        ThreadParticipants,
                        'id' | 'createdAt' | 'updatedAt' | 'userId' | 'unread'
                      > & {
                          user: { __typename?: 'User' } & Pick<User, 'id'> & {
                              profile?: Maybe<
                                { __typename?: 'Profile' } & Pick<Profile, 'id' | 'nickname'> & {
                                    avatar?: Maybe<{ __typename?: 'File' } & Pick<File, 'url'>>;
                                  }
                              >;
                            };
                        };
                    }
                  >;
                }
              >;
            };
        }
    >;
  };
};

export type MarkReadMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MarkReadMutation = { __typename?: 'Mutation' } & {
  updateOneThreadParticipants: { __typename?: 'ThreadParticipants' } & Pick<
    ThreadParticipants,
    'id' | 'unread'
  >;
};

export type UpdatedThreadSubscriptionVariables = Exact<{ [key: string]: never }>;

export type UpdatedThreadSubscription = { __typename?: 'Subscription' } & {
  updatedOneMessageThread: { __typename?: 'MessageThread' } & Pick<
    MessageThread,
    'id' | 'lastMessageAt'
  >;
};

export type CreatedNotificationSubscriptionVariables = Exact<{ [key: string]: never }>;

export type CreatedNotificationSubscription = { __typename?: 'Subscription' } & {
  createdNotification: { __typename?: 'Notification' } & NotificationFieldsFragment;
};

export type MarkAllNotificationsAsReadMutationVariables = Exact<{ [key: string]: never }>;

export type MarkAllNotificationsAsReadMutation = { __typename?: 'Mutation' } & {
  updateManyNotifications: { __typename?: 'UpdateManyResponse' } & Pick<
    UpdateManyResponse,
    'updatedCount'
  >;
};

export type MarkNotificationAsReadMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MarkNotificationAsReadMutation = { __typename?: 'Mutation' } & {
  updateOneNotification: { __typename?: 'Notification' } & Pick<Notification, 'id' | 'status'>;
};

export type NotificationFieldsFragment = { __typename?: 'Notification' } & Pick<
  Notification,
  'id' | 'title' | 'text' | 'link' | 'status' | 'createdAt'
>;

export type NotificationsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['ConnectionCursor']>;
}>;

export type NotificationsQuery = { __typename?: 'Query' } & {
  notifications: { __typename?: 'NotificationConnection' } & {
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFieldsFragment;
    edges: Array<
      { __typename?: 'NotificationEdge' } & Pick<NotificationEdge, 'cursor'> & {
          node: { __typename?: 'Notification' } & NotificationFieldsFragment;
        }
    >;
  };
};

export type UnreadNotificationsCountQueryVariables = Exact<{ [key: string]: never }>;

export type UnreadNotificationsCountQuery = { __typename?: 'Query' } & {
  notificationAggregate: Array<
    { __typename?: 'NotificationAggregateResponse' } & {
      count?: Maybe<
        { __typename?: 'NotificationCountAggregate' } & Pick<NotificationCountAggregate, 'id'>
      >;
    }
  >;
};

export type FeedbackFieldsFragment = { __typename?: 'Feedback' } & Pick<
  Feedback,
  'id' | 'createdAt'
> & {
    content: { __typename?: 'FeedbackContent' } & Pick<FeedbackContent, 'public'>;
    createdBy: { __typename?: 'User' } & {
      profile?: Maybe<
        { __typename?: 'Profile' } & Pick<Profile, 'nickname'> & {
            avatar?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment>;
          }
      >;
    };
  };

export type FileFieldsFragment = { __typename?: 'File' } & Pick<
  File,
  'id' | 'url' | 'key' | 'mimetype'
>;

export type ImageFieldsFragment = { __typename?: 'File' } & Pick<File, 'id' | 'url'> & {
    sources?: Maybe<
      Array<
        { __typename?: 'Source' } & Pick<Source, 'src' | 'srcSet' | 'width' | 'height' | 'mimetype'>
      >
    >;
  };

export type PageInfoFieldsFragment = { __typename?: 'PageInfo' } & Pick<
  PageInfo,
  'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
>;

export type VideoFieldsFragment = { __typename?: 'OEmbedVideoData' } & Pick<
  OEmbedVideoData,
  'title' | 'thumbnail_url' | 'thumbnail_width' | 'thumbnail_height'
>;

export type CurrentUserFieldsFragment = { __typename?: 'User' } & Pick<
  User,
  'id' | 'email' | 'firstName' | 'lastName' | 'phone' | 'welcomeVetted'
> & { profile?: Maybe<{ __typename?: 'Profile' } & CurrentUserProfileFieldsFragment> };

export type CurrentUserProfileFieldsFragment = { __typename?: 'Profile' } & Pick<
  Profile,
  'id' | 'nickname'
> & { avatar?: Maybe<{ __typename?: 'File' } & ImageFieldsFragment> };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & CurrentUserFieldsFragment;
};

export const RequestBookingFieldsFragmentDoc = gql`
  fragment RequestBookingFields on Event {
    id
  }
`;
export const VideoFieldsFragmentDoc = gql`
  fragment VideoFields on OEmbedVideoData {
    title
    thumbnail_url
    thumbnail_width
    thumbnail_height
  }
`;
export const ImageFieldsFragmentDoc = gql`
  fragment ImageFields on File {
    id
    url
    sources {
      src
      srcSet
      width
      height
      mimetype
    }
  }
`;
export const BookingExperienceFieldsFragmentDoc = gql`
  fragment BookingExperienceFields on Experience {
    title
    createdAt
    id
    mode
    category
    type
    status
    nextEvent
    video {
      url
      data {
        ...VideoFields
      }
    }
    publishedAt
    content {
      description
      what
      bring
      accessibility
      environment
    }
    images(sorting: [{ field: order, direction: ASC }, { field: createdAt, direction: ASC }]) {
      edges {
        node {
          id
          order
          file {
            ...ImageFields
          }
        }
      }
    }
    host {
      id
      profile {
        id
        about
        nickname
        avatar {
          ...ImageFields
        }
      }
    }
  }
  ${VideoFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
`;
export const ExperienceCardHostFieldsFragmentDoc = gql`
  fragment ExperienceCardHostFields on User {
    id
    profile {
      id
      nickname
      about
      avatar {
        ...ImageFields
      }
    }
  }
  ${ImageFieldsFragmentDoc}
`;
export const BookingAttendeeFieldsFragmentDoc = gql`
  fragment BookingAttendeeFields on Attendee {
    id
    status
    role
    user {
      ...ExperienceCardHostFields
    }
  }
  ${ExperienceCardHostFieldsFragmentDoc}
`;
export const BookingFieldsFragmentDoc = gql`
  fragment BookingFields on Event {
    id
    start
    end
    status
    timezone
    private
    overrideLink
    location {
      approximateLocation {
        coordinates
      }
      coordinates {
        coordinates
      }
      streetNumber
      route
      locality
      administrativeAreaLevel1
      country
      postalCode
    }
    calendar {
      experience {
        ...BookingExperienceFields
      }
    }
    attendees {
      edges {
        node {
          ...BookingAttendeeFields
        }
      }
    }
    zoomMeeting {
      id
      joinUrl
    }
  }
  ${BookingExperienceFieldsFragmentDoc}
  ${BookingAttendeeFieldsFragmentDoc}
`;
export const ExperienceCardFieldsFragmentDoc = gql`
  fragment ExperienceCardFields on Experience {
    id
    title
    type
    category
    createdAt
    status
    nextAvailability
    mode
    nextEvent
    next
    content {
      description
    }
    locality
    administrativeAreaLevel1
    approximateLocation {
      coordinates
    }
    host {
      ...ExperienceCardHostFields
    }
    images(paging: { first: 1 }) {
      edges {
        node {
          id
          order
          file {
            ...ImageFields
          }
        }
      }
    }
    video {
      url
      data {
        ...VideoFields
      }
    }
  }
  ${ExperienceCardHostFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
`;
export const BookingCardFieldsFragmentDoc = gql`
  fragment BookingCardFields on Event {
    id
    start
    end
    status
    timezone
    private
    calendar {
      experience {
        ...ExperienceCardFields
      }
    }
    attendees {
      edges {
        node {
          ...BookingAttendeeFields
        }
      }
    }
  }
  ${ExperienceCardFieldsFragmentDoc}
  ${BookingAttendeeFieldsFragmentDoc}
`;
export const OrderCardFieldsFragmentDoc = gql`
  fragment OrderCardFields on Order {
    id
    createdAt
    status
    shippedAt
    deliveredAt
    experience {
      ...ExperienceCardFields
    }
  }
  ${ExperienceCardFieldsFragmentDoc}
`;
export const OrderFieldsFragmentDoc = gql`
  fragment OrderFields on Order {
    id
    createdAt
    status
    shippedAt
    deliveredAt
    experience {
      ...ExperienceCardFields
    }
  }
  ${ExperienceCardFieldsFragmentDoc}
`;
export const ProfileInterestFieldsFragmentDoc = gql`
  fragment ProfileInterestFields on ProfileInterest {
    id
    name
    slug
  }
`;
export const ProfileStrengthFieldsFragmentDoc = gql`
  fragment ProfileStrengthFields on ProfileStrength {
    id
    name
    slug
  }
`;
export const ProfileImageFieldsFragmentDoc = gql`
  fragment ProfileImageFields on ProfileImage {
    id
    order
    createdAt
    file {
      ...ImageFields
    }
  }
  ${ImageFieldsFragmentDoc}
`;
export const UserProfileFieldsFragmentDoc = gql`
  fragment UserProfileFields on Profile {
    id
    nickname
    about
    avatar {
      ...ImageFields
    }
    interests(paging: { first: 50 }) {
      edges {
        node {
          ...ProfileInterestFields
        }
      }
    }
    strengths(paging: { first: 50 }) {
      edges {
        node {
          ...ProfileStrengthFields
        }
      }
    }
    images(sorting: [{ field: order, direction: ASC }, { field: createdAt, direction: ASC }]) {
      edges {
        node {
          ...ProfileImageFields
        }
      }
    }
  }
  ${ImageFieldsFragmentDoc}
  ${ProfileInterestFieldsFragmentDoc}
  ${ProfileStrengthFieldsFragmentDoc}
  ${ProfileImageFieldsFragmentDoc}
`;
export const EventFieldsFragmentDoc = gql`
  fragment EventFields on Event {
    id
    start
    end
    timezone
    status
    overrideLink
    calendar {
      id
      experience {
        ...ExperienceCardFields
      }
    }
    zoomMeeting {
      id
      code
      password
      joinUrl
    }
    attendees(paging: { first: 50 }) {
      edges {
        node {
          id
          status
          user {
            id
            profile {
              ...UserProfileFields
            }
          }
        }
      }
    }
  }
  ${ExperienceCardFieldsFragmentDoc}
  ${UserProfileFieldsFragmentDoc}
`;
export const ExperienceEventFieldsFragmentDoc = gql`
  fragment ExperienceEventFields on Event {
    id
    start
    end
    timezone
    status
    overrideLink
    zoomMeeting {
      id
      joinUrl
    }
  }
`;
export const ExperienceFieldsFragmentDoc = gql`
  fragment ExperienceFields on Experience {
    title
    createdAt
    id
    category
    type
    status
    mode
    nextEvent
    locality
    administrativeAreaLevel1
    nextAvailability
    video {
      url
      data {
        ...VideoFields
      }
    }
    publishedAt
    content {
      description
      what
      bring
      accessibility
      environment
    }
    images(
      sorting: [{ field: order, direction: ASC }, { field: createdAt, direction: ASC }]
      paging: { first: 11 }
    ) {
      edges {
        node {
          id
          order
          file {
            ...ImageFields
          }
        }
      }
    }
    host {
      id
      profile {
        id
        about
        nickname
        avatar {
          ...ImageFields
        }
      }
    }
    calendar {
      events {
        edges {
          node {
            id
            start
            end
          }
        }
      }
    }
    experienceTags {
      edges {
        node {
          id
          tag {
            id
          }
        }
      }
    }
  }
  ${VideoFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
`;
export const FullExperienceFieldsFragmentDoc = gql`
  fragment FullExperienceFields on Experience {
    title
    createdAt
    id
    category
    type
    status
    nextEvent
    mode
    nextAvailability
    locationType
    coordinates {
      type
      coordinates
    }
    approximateLocation {
      coordinates
    }
    administrativeAreaLevel1
    administrativeAreaLevel2
    country
    postalCode
    streetNumber
    route
    locality
    video {
      url
      data {
        ...VideoFields
      }
    }
    publishedAt
    content {
      description
      what
      bring
      accessibility
      environment
    }
    images(
      sorting: [{ field: order, direction: ASC }, { field: createdAt, direction: ASC }]
      paging: { first: 11 }
    ) {
      edges {
        node {
          id
          order
          file {
            ...ImageFields
          }
        }
      }
    }
    host {
      id
      profile {
        id
        about
        nickname
        avatar {
          ...ImageFields
        }
      }
    }
  }
  ${VideoFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
`;
export const CompleteExperienceFieldsFragmentDoc = gql`
  fragment CompleteExperienceFields on Experience {
    title
    createdAt
    id
    category
    type
    status
    nextEvent
    nextAvailability
    mode
    locality
    administrativeAreaLevel1
    video {
      url
      data {
        ...VideoFields
      }
    }
    publishedAt
    content {
      description
      what
      bring
      accessibility
      environment
    }
    brainstorm {
      inspiration
      hobby
      first
      second
      third
      interest
      secondInterest
      partOfDay
    }
    progressStep
    meetingScheduled
    images(
      sorting: [{ field: order, direction: ASC }, { field: createdAt, direction: ASC }]
      paging: { first: 11 }
    ) {
      edges {
        node {
          id
          order
          file {
            ...ImageFields
          }
        }
      }
    }
    host {
      id
      profile {
        id
        about
        nickname
        avatar {
          ...ImageFields
        }
      }
    }
  }
  ${VideoFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
`;
export const EditExperienceFieldsFragmentDoc = gql`
  fragment EditExperienceFields on Experience {
    ...CompleteExperienceFields
    calendar {
      id
    }
    mode
    locationType
    coordinates {
      type
      coordinates
    }
    streetNumber
    route
    locality
    administrativeAreaLevel2
    administrativeAreaLevel1
    country
    postalCode
  }
  ${CompleteExperienceFieldsFragmentDoc}
`;
export const StripeCheckoutSessionFieldsFragmentDoc = gql`
  fragment StripeCheckoutSessionFields on StripeCheckoutSession {
    id
    url
    orderId
  }
`;
export const UpdateUserProfileFieldsFragmentDoc = gql`
  fragment UpdateUserProfileFields on Profile {
    id
    nickname
    about
  }
`;
export const PageInfoFieldsFragmentDoc = gql`
  fragment PageInfoFields on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    createdAt
    profile {
      ...UserProfileFields
    }
    experiences(filter: { status: { eq: Publish } }, paging: { first: 8 }) {
      pageInfo {
        ...PageInfoFields
      }
      edges {
        cursor
        node {
          ...ExperienceCardFields
        }
      }
    }
  }
  ${UserProfileFieldsFragmentDoc}
  ${PageInfoFieldsFragmentDoc}
  ${ExperienceCardFieldsFragmentDoc}
`;
export const UpdateUserSettingsFieldsFragmentDoc = gql`
  fragment UpdateUserSettingsFields on User {
    id
    nickname
    firstName
    lastName
    country
    email
    phone
    dob
    timezone
    address {
      street
      street2
      city
      postcode
      state
      country
    }
  }
`;
export const UserSettingsFieldsFragmentDoc = gql`
  fragment UserSettingsFields on User {
    id
    createdAt
    nickname
    firstName
    lastName
    country
    email
    phone
    dob
    timezone
    address {
      street
      street2
      city
      postcode
      state
      country
    }
  }
`;
export const AvailableTimeslotFieldsFragmentDoc = gql`
  fragment AvailableTimeslotFields on AvailableTimeslot {
    start
    end
  }
`;
export const EventCardFieldsFragmentDoc = gql`
  fragment EventCardFields on Event {
    id
    start
    end
    status
    timezone
    calendar {
      experience {
        ...ExperienceCardFields
      }
    }
  }
  ${ExperienceCardFieldsFragmentDoc}
`;
export const NotificationFieldsFragmentDoc = gql`
  fragment NotificationFields on Notification {
    id
    title
    text
    link
    status
    createdAt
  }
`;
export const FeedbackFieldsFragmentDoc = gql`
  fragment FeedbackFields on Feedback {
    id
    createdAt
    content {
      public
    }
    createdBy {
      profile {
        nickname
        avatar {
          ...ImageFields
        }
      }
    }
  }
  ${ImageFieldsFragmentDoc}
`;
export const FileFieldsFragmentDoc = gql`
  fragment FileFields on File {
    id
    url
    key
    mimetype
  }
`;
export const CurrentUserProfileFieldsFragmentDoc = gql`
  fragment CurrentUserProfileFields on Profile {
    id
    nickname
    avatar {
      ...ImageFields
    }
  }
  ${ImageFieldsFragmentDoc}
`;
export const CurrentUserFieldsFragmentDoc = gql`
  fragment CurrentUserFields on User {
    id
    email
    firstName
    lastName
    phone
    welcomeVetted
    profile {
      ...CurrentUserProfileFields
    }
  }
  ${CurrentUserProfileFieldsFragmentDoc}
`;
export const NextGroupExperiencesDocument = gql`
  query NextGroupExperiences($now: DateTime) {
    experiences(
      paging: { first: 20 }
      filter: {
        status: { eq: Publish }
        and: [{ nextEvent: { isNot: null } }, { nextEvent: { gte: $now } }]
      }
      sorting: [{ field: nextEvent, direction: ASC }]
    ) {
      edges {
        node {
          ...ExperienceCardFields
        }
      }
    }
  }
  ${ExperienceCardFieldsFragmentDoc}
`;

/**
 * __useNextGroupExperiencesQuery__
 *
 * To run a query within a React component, call `useNextGroupExperiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextGroupExperiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextGroupExperiencesQuery({
 *   variables: {
 *      now: // value for 'now'
 *   },
 * });
 */
export function useNextGroupExperiencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NextGroupExperiencesQuery,
    NextGroupExperiencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NextGroupExperiencesQuery, NextGroupExperiencesQueryVariables>(
    NextGroupExperiencesDocument,
    options,
  );
}
export function useNextGroupExperiencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NextGroupExperiencesQuery,
    NextGroupExperiencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NextGroupExperiencesQuery, NextGroupExperiencesQueryVariables>(
    NextGroupExperiencesDocument,
    options,
  );
}
export type NextGroupExperiencesQueryHookResult = ReturnType<typeof useNextGroupExperiencesQuery>;
export type NextGroupExperiencesLazyQueryHookResult = ReturnType<
  typeof useNextGroupExperiencesLazyQuery
>;
export type NextGroupExperiencesQueryResult = Apollo.QueryResult<
  NextGroupExperiencesQuery,
  NextGroupExperiencesQueryVariables
>;
export const HomepageUpcomingDocument = gql`
  query HomepageUpcoming($now: DateTime) {
    experiences(
      paging: { first: 20 }
      filter: {
        status: { eq: Publish }
        or: [
          { and: [{ nextEvent: { isNot: null } }, { nextEvent: { gte: $now } }] }
          { and: [{ nextAvailability: { isNot: null } }, { nextAvailability: { gte: $now } }] }
        ]
      }
      sorting: [{ field: nextEvent, direction: ASC }]
    ) {
      edges {
        node {
          ...ExperienceCardFields
        }
      }
    }
  }
  ${ExperienceCardFieldsFragmentDoc}
`;

/**
 * __useHomepageUpcomingQuery__
 *
 * To run a query within a React component, call `useHomepageUpcomingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomepageUpcomingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomepageUpcomingQuery({
 *   variables: {
 *      now: // value for 'now'
 *   },
 * });
 */
export function useHomepageUpcomingQuery(
  baseOptions?: Apollo.QueryHookOptions<HomepageUpcomingQuery, HomepageUpcomingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomepageUpcomingQuery, HomepageUpcomingQueryVariables>(
    HomepageUpcomingDocument,
    options,
  );
}
export function useHomepageUpcomingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HomepageUpcomingQuery, HomepageUpcomingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomepageUpcomingQuery, HomepageUpcomingQueryVariables>(
    HomepageUpcomingDocument,
    options,
  );
}
export type HomepageUpcomingQueryHookResult = ReturnType<typeof useHomepageUpcomingQuery>;
export type HomepageUpcomingLazyQueryHookResult = ReturnType<typeof useHomepageUpcomingLazyQuery>;
export type HomepageUpcomingQueryResult = Apollo.QueryResult<
  HomepageUpcomingQuery,
  HomepageUpcomingQueryVariables
>;
export const ExperienceAvailabilityDocument = gql`
  query ExperienceAvailability($experienceId: ID!, $userId: ID!, $start: DateTime, $end: DateTime) {
    experienceAvailability(
      input: { experience: $experienceId, users: [$userId], start: $start, end: $end }
    ) {
      ...AvailableTimeslotFields
    }
  }
  ${AvailableTimeslotFieldsFragmentDoc}
`;

/**
 * __useExperienceAvailabilityQuery__
 *
 * To run a query within a React component, call `useExperienceAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceAvailabilityQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      userId: // value for 'userId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useExperienceAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExperienceAvailabilityQuery,
    ExperienceAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExperienceAvailabilityQuery, ExperienceAvailabilityQueryVariables>(
    ExperienceAvailabilityDocument,
    options,
  );
}
export function useExperienceAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExperienceAvailabilityQuery,
    ExperienceAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExperienceAvailabilityQuery, ExperienceAvailabilityQueryVariables>(
    ExperienceAvailabilityDocument,
    options,
  );
}
export type ExperienceAvailabilityQueryHookResult = ReturnType<
  typeof useExperienceAvailabilityQuery
>;
export type ExperienceAvailabilityLazyQueryHookResult = ReturnType<
  typeof useExperienceAvailabilityLazyQuery
>;
export type ExperienceAvailabilityQueryResult = Apollo.QueryResult<
  ExperienceAvailabilityQuery,
  ExperienceAvailabilityQueryVariables
>;
export const RequestBookingDocument = gql`
  mutation RequestBooking($experienceId: ID!, $start: DateTime!, $end: DateTime!) {
    requestBooking(input: { experienceId: $experienceId, start: $start, end: $end }) {
      ...RequestBookingFields
    }
  }
  ${RequestBookingFieldsFragmentDoc}
`;
export type RequestBookingMutationFn = Apollo.MutationFunction<
  RequestBookingMutation,
  RequestBookingMutationVariables
>;

/**
 * __useRequestBookingMutation__
 *
 * To run a mutation, you first call `useRequestBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestBookingMutation, { data, loading, error }] = useRequestBookingMutation({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useRequestBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestBookingMutation, RequestBookingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestBookingMutation, RequestBookingMutationVariables>(
    RequestBookingDocument,
    options,
  );
}
export type RequestBookingMutationHookResult = ReturnType<typeof useRequestBookingMutation>;
export type RequestBookingMutationResult = Apollo.MutationResult<RequestBookingMutation>;
export type RequestBookingMutationOptions = Apollo.BaseMutationOptions<
  RequestBookingMutation,
  RequestBookingMutationVariables
>;
export const BookingDocument = gql`
  query Booking($id: ID!) {
    event(id: $id) {
      ...BookingFields
    }
  }
  ${BookingFieldsFragmentDoc}
`;

/**
 * __useBookingQuery__
 *
 * To run a query within a React component, call `useBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingQuery(
  baseOptions: Apollo.QueryHookOptions<BookingQuery, BookingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
}
export function useBookingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingQuery, BookingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
}
export type BookingQueryHookResult = ReturnType<typeof useBookingQuery>;
export type BookingLazyQueryHookResult = ReturnType<typeof useBookingLazyQuery>;
export type BookingQueryResult = Apollo.QueryResult<BookingQuery, BookingQueryVariables>;
export const ConfirmBookingDocument = gql`
  mutation ConfirmBooking($eventId: ID!) {
    confirmBooking(input: { eventId: $eventId }) {
      id
    }
  }
`;
export type ConfirmBookingMutationFn = Apollo.MutationFunction<
  ConfirmBookingMutation,
  ConfirmBookingMutationVariables
>;

/**
 * __useConfirmBookingMutation__
 *
 * To run a mutation, you first call `useConfirmBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmBookingMutation, { data, loading, error }] = useConfirmBookingMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useConfirmBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmBookingMutation, ConfirmBookingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmBookingMutation, ConfirmBookingMutationVariables>(
    ConfirmBookingDocument,
    options,
  );
}
export type ConfirmBookingMutationHookResult = ReturnType<typeof useConfirmBookingMutation>;
export type ConfirmBookingMutationResult = Apollo.MutationResult<ConfirmBookingMutation>;
export type ConfirmBookingMutationOptions = Apollo.BaseMutationOptions<
  ConfirmBookingMutation,
  ConfirmBookingMutationVariables
>;
export const CancelBookingDocument = gql`
  mutation CancelBooking($eventId: ID!, $reason: String!) {
    cancelBooking(input: { eventId: $eventId, reason: $reason }) {
      id
    }
  }
`;
export type CancelBookingMutationFn = Apollo.MutationFunction<
  CancelBookingMutation,
  CancelBookingMutationVariables
>;

/**
 * __useCancelBookingMutation__
 *
 * To run a mutation, you first call `useCancelBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBookingMutation, { data, loading, error }] = useCancelBookingMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCancelBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelBookingMutation, CancelBookingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelBookingMutation, CancelBookingMutationVariables>(
    CancelBookingDocument,
    options,
  );
}
export type CancelBookingMutationHookResult = ReturnType<typeof useCancelBookingMutation>;
export type CancelBookingMutationResult = Apollo.MutationResult<CancelBookingMutation>;
export type CancelBookingMutationOptions = Apollo.BaseMutationOptions<
  CancelBookingMutation,
  CancelBookingMutationVariables
>;
export const BookingsDocument = gql`
  query Bookings($filter: EventFilter, $sorting: [EventSort!], $paging: CursorPaging) {
    bookings(filter: $filter, sorting: $sorting, paging: $paging) {
      totalCount
      pageInfo {
        ...PageInfoFields
      }
      edges {
        cursor
        node {
          ...BookingCardFields
        }
      }
    }
  }
  ${PageInfoFieldsFragmentDoc}
  ${BookingCardFieldsFragmentDoc}
`;

/**
 * __useBookingsQuery__
 *
 * To run a query within a React component, call `useBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useBookingsQuery(
  baseOptions?: Apollo.QueryHookOptions<BookingsQuery, BookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
}
export function useBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingsQuery, BookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
}
export type BookingsQueryHookResult = ReturnType<typeof useBookingsQuery>;
export type BookingsLazyQueryHookResult = ReturnType<typeof useBookingsLazyQuery>;
export type BookingsQueryResult = Apollo.QueryResult<BookingsQuery, BookingsQueryVariables>;
export const OrdersDocument = gql`
  query Orders($filter: OrderFilter, $sorting: [OrderSort!]) {
    orders(filter: $filter, sorting: $sorting) {
      totalCount
      pageInfo {
        ...PageInfoFields
      }
      edges {
        cursor
        node {
          ...OrderCardFields
        }
      }
    }
  }
  ${PageInfoFieldsFragmentDoc}
  ${OrderCardFieldsFragmentDoc}
`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
}
export function useOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
}
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const OrderDocument = gql`
  query Order($id: ID!) {
    order(id: $id) {
      ...OrderFields
    }
  }
  ${OrderFieldsFragmentDoc}
`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(
  baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
}
export function useOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const RescheduleBookingDocument = gql`
  mutation RescheduleBooking($eventId: ID!, $start: DateTime!, $end: DateTime!) {
    rescheduleBooking(input: { eventId: $eventId, start: $start, end: $end }) {
      id
    }
  }
`;
export type RescheduleBookingMutationFn = Apollo.MutationFunction<
  RescheduleBookingMutation,
  RescheduleBookingMutationVariables
>;

/**
 * __useRescheduleBookingMutation__
 *
 * To run a mutation, you first call `useRescheduleBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleBookingMutation, { data, loading, error }] = useRescheduleBookingMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useRescheduleBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleBookingMutation,
    RescheduleBookingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RescheduleBookingMutation, RescheduleBookingMutationVariables>(
    RescheduleBookingDocument,
    options,
  );
}
export type RescheduleBookingMutationHookResult = ReturnType<typeof useRescheduleBookingMutation>;
export type RescheduleBookingMutationResult = Apollo.MutationResult<RescheduleBookingMutation>;
export type RescheduleBookingMutationOptions = Apollo.BaseMutationOptions<
  RescheduleBookingMutation,
  RescheduleBookingMutationVariables
>;
export const CheckIfThreadExistsDocument = gql`
  mutation checkIfThreadExists($otherUserId: String!) {
    checkIfThreadExists(userId: $otherUserId) {
      messageThreadId
    }
  }
`;
export type CheckIfThreadExistsMutationFn = Apollo.MutationFunction<
  CheckIfThreadExistsMutation,
  CheckIfThreadExistsMutationVariables
>;

/**
 * __useCheckIfThreadExistsMutation__
 *
 * To run a mutation, you first call `useCheckIfThreadExistsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckIfThreadExistsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkIfThreadExistsMutation, { data, loading, error }] = useCheckIfThreadExistsMutation({
 *   variables: {
 *      otherUserId: // value for 'otherUserId'
 *   },
 * });
 */
export function useCheckIfThreadExistsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckIfThreadExistsMutation,
    CheckIfThreadExistsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CheckIfThreadExistsMutation, CheckIfThreadExistsMutationVariables>(
    CheckIfThreadExistsDocument,
    options,
  );
}
export type CheckIfThreadExistsMutationHookResult = ReturnType<
  typeof useCheckIfThreadExistsMutation
>;
export type CheckIfThreadExistsMutationResult = Apollo.MutationResult<CheckIfThreadExistsMutation>;
export type CheckIfThreadExistsMutationOptions = Apollo.BaseMutationOptions<
  CheckIfThreadExistsMutation,
  CheckIfThreadExistsMutationVariables
>;
export const CreateNewMessageFlagDocument = gql`
  mutation CreateNewMessageFlag($input: CreateOneMessageFlaggingInput!) {
    createOneMessageFlagging(input: $input) {
      id
      userId
      reason
      status
    }
  }
`;
export type CreateNewMessageFlagMutationFn = Apollo.MutationFunction<
  CreateNewMessageFlagMutation,
  CreateNewMessageFlagMutationVariables
>;

/**
 * __useCreateNewMessageFlagMutation__
 *
 * To run a mutation, you first call `useCreateNewMessageFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewMessageFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewMessageFlagMutation, { data, loading, error }] = useCreateNewMessageFlagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewMessageFlagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewMessageFlagMutation,
    CreateNewMessageFlagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNewMessageFlagMutation, CreateNewMessageFlagMutationVariables>(
    CreateNewMessageFlagDocument,
    options,
  );
}
export type CreateNewMessageFlagMutationHookResult = ReturnType<
  typeof useCreateNewMessageFlagMutation
>;
export type CreateNewMessageFlagMutationResult =
  Apollo.MutationResult<CreateNewMessageFlagMutation>;
export type CreateNewMessageFlagMutationOptions = Apollo.BaseMutationOptions<
  CreateNewMessageFlagMutation,
  CreateNewMessageFlagMutationVariables
>;
export const CreateMessageForFileDocument = gql`
  mutation CreateMessageForFile($input: CreateMessageInput!) {
    createMessageForFiles(input: $input) {
      id
      createdAt
      content
      updatedAt
      userId
      messageThreadId
    }
  }
`;
export type CreateMessageForFileMutationFn = Apollo.MutationFunction<
  CreateMessageForFileMutation,
  CreateMessageForFileMutationVariables
>;

/**
 * __useCreateMessageForFileMutation__
 *
 * To run a mutation, you first call `useCreateMessageForFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageForFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageForFileMutation, { data, loading, error }] = useCreateMessageForFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageForFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMessageForFileMutation,
    CreateMessageForFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMessageForFileMutation, CreateMessageForFileMutationVariables>(
    CreateMessageForFileDocument,
    options,
  );
}
export type CreateMessageForFileMutationHookResult = ReturnType<
  typeof useCreateMessageForFileMutation
>;
export type CreateMessageForFileMutationResult =
  Apollo.MutationResult<CreateMessageForFileMutation>;
export type CreateMessageForFileMutationOptions = Apollo.BaseMutationOptions<
  CreateMessageForFileMutation,
  CreateMessageForFileMutationVariables
>;
export const CreateNewThreadDocument = gql`
  mutation createNewThread($name: String, $otherUserId: String!) {
    createMessageThread(input: { name: $name }, otherUserId: $otherUserId) {
      id
      createdAt
      updatedAt
    }
  }
`;
export type CreateNewThreadMutationFn = Apollo.MutationFunction<
  CreateNewThreadMutation,
  CreateNewThreadMutationVariables
>;

/**
 * __useCreateNewThreadMutation__
 *
 * To run a mutation, you first call `useCreateNewThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewThreadMutation, { data, loading, error }] = useCreateNewThreadMutation({
 *   variables: {
 *      name: // value for 'name'
 *      otherUserId: // value for 'otherUserId'
 *   },
 * });
 */
export function useCreateNewThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewThreadMutation,
    CreateNewThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNewThreadMutation, CreateNewThreadMutationVariables>(
    CreateNewThreadDocument,
    options,
  );
}
export type CreateNewThreadMutationHookResult = ReturnType<typeof useCreateNewThreadMutation>;
export type CreateNewThreadMutationResult = Apollo.MutationResult<CreateNewThreadMutation>;
export type CreateNewThreadMutationOptions = Apollo.BaseMutationOptions<
  CreateNewThreadMutation,
  CreateNewThreadMutationVariables
>;
export const SendMessageDocument = gql`
  mutation SendMessage($input: CreateOneMessageInput!) {
    createOneMessage(input: $input) {
      id
      createdAt
      updatedAt
      content
      userId
      messageThreadId
    }
  }
`;
export type SendMessageMutationFn = Apollo.MutationFunction<
  SendMessageMutation,
  SendMessageMutationVariables
>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(
    SendMessageDocument,
    options,
  );
}
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
  SendMessageMutation,
  SendMessageMutationVariables
>;
export const CreatedNewThreadDocument = gql`
  subscription CreatedNewThread {
    createdMessageThread {
      id
      createdAt
      updatedAt
      lastMessageAt
      threadParticipants {
        edges {
          node {
            id
            createdAt
            updatedAt
            userId
            unread
            user {
              id
              profile {
                id
                nickname
                avatar {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCreatedNewThreadSubscription__
 *
 * To run a query within a React component, call `useCreatedNewThreadSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedNewThreadSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedNewThreadSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCreatedNewThreadSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CreatedNewThreadSubscription,
    CreatedNewThreadSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CreatedNewThreadSubscription,
    CreatedNewThreadSubscriptionVariables
  >(CreatedNewThreadDocument, options);
}
export type CreatedNewThreadSubscriptionHookResult = ReturnType<
  typeof useCreatedNewThreadSubscription
>;
export type CreatedNewThreadSubscriptionResult =
  Apollo.SubscriptionResult<CreatedNewThreadSubscription>;
export const CurrentParticipantDocument = gql`
  query CurrentParticipant($userId: String!, $threadId: String!) {
    threadParticipants(
      filter: { and: [{ userId: { eq: $userId } }, { messageThreadId: { eq: $threadId } }] }
    ) {
      edges {
        node {
          id
          unread
        }
      }
    }
  }
`;

/**
 * __useCurrentParticipantQuery__
 *
 * To run a query within a React component, call `useCurrentParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentParticipantQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useCurrentParticipantQuery(
  baseOptions: Apollo.QueryHookOptions<CurrentParticipantQuery, CurrentParticipantQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentParticipantQuery, CurrentParticipantQueryVariables>(
    CurrentParticipantDocument,
    options,
  );
}
export function useCurrentParticipantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentParticipantQuery,
    CurrentParticipantQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentParticipantQuery, CurrentParticipantQueryVariables>(
    CurrentParticipantDocument,
    options,
  );
}
export type CurrentParticipantQueryHookResult = ReturnType<typeof useCurrentParticipantQuery>;
export type CurrentParticipantLazyQueryHookResult = ReturnType<
  typeof useCurrentParticipantLazyQuery
>;
export type CurrentParticipantQueryResult = Apollo.QueryResult<
  CurrentParticipantQuery,
  CurrentParticipantQueryVariables
>;
export const GetLastMessageDocument = gql`
  query GetLastMessage($threadId: String!) {
    messages(
      filter: { messageThreadId: { eq: $threadId } }
      paging: { first: 1 }
      sorting: { field: createdAt, direction: DESC }
    ) {
      edges {
        node {
          content
          id
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useGetLastMessageQuery__
 *
 * To run a query within a React component, call `useGetLastMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastMessageQuery({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useGetLastMessageQuery(
  baseOptions: Apollo.QueryHookOptions<GetLastMessageQuery, GetLastMessageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLastMessageQuery, GetLastMessageQueryVariables>(
    GetLastMessageDocument,
    options,
  );
}
export function useGetLastMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLastMessageQuery, GetLastMessageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLastMessageQuery, GetLastMessageQueryVariables>(
    GetLastMessageDocument,
    options,
  );
}
export type GetLastMessageQueryHookResult = ReturnType<typeof useGetLastMessageQuery>;
export type GetLastMessageLazyQueryHookResult = ReturnType<typeof useGetLastMessageLazyQuery>;
export type GetLastMessageQueryResult = Apollo.QueryResult<
  GetLastMessageQuery,
  GetLastMessageQueryVariables
>;
export const GetMessagesForAThreadDocument = gql`
  query GetMessagesForAThread($threadId: String!, $cursor: ConnectionCursor) {
    messages(
      filter: { messageThreadId: { eq: $threadId } }
      sorting: { field: updatedAt, direction: DESC }
      paging: { after: $cursor, first: 50 }
    ) {
      pageInfo {
        ...PageInfoFields
      }
      edges {
        cursor
        node {
          content
          userId
          id
          createdAt
          updatedAt
          messageThreadId
          messageThread {
            id
          }
          messageFlagging {
            id
            status
          }
          messageFiles {
            edges {
              node {
                id
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
  ${PageInfoFieldsFragmentDoc}
`;

/**
 * __useGetMessagesForAThreadQuery__
 *
 * To run a query within a React component, call `useGetMessagesForAThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesForAThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesForAThreadQuery({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetMessagesForAThreadQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMessagesForAThreadQuery,
    GetMessagesForAThreadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMessagesForAThreadQuery, GetMessagesForAThreadQueryVariables>(
    GetMessagesForAThreadDocument,
    options,
  );
}
export function useGetMessagesForAThreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMessagesForAThreadQuery,
    GetMessagesForAThreadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMessagesForAThreadQuery, GetMessagesForAThreadQueryVariables>(
    GetMessagesForAThreadDocument,
    options,
  );
}
export type GetMessagesForAThreadQueryHookResult = ReturnType<typeof useGetMessagesForAThreadQuery>;
export type GetMessagesForAThreadLazyQueryHookResult = ReturnType<
  typeof useGetMessagesForAThreadLazyQuery
>;
export type GetMessagesForAThreadQueryResult = Apollo.QueryResult<
  GetMessagesForAThreadQuery,
  GetMessagesForAThreadQueryVariables
>;
export const GetMessagesDocument = gql`
  subscription GetMessages {
    createdMessage {
      content
      userId
      id
      createdAt
      updatedAt
      messageThreadId
    }
  }
`;

/**
 * __useGetMessagesSubscription__
 *
 * To run a query within a React component, call `useGetMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetMessagesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetMessagesSubscription,
    GetMessagesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<GetMessagesSubscription, GetMessagesSubscriptionVariables>(
    GetMessagesDocument,
    options,
  );
}
export type GetMessagesSubscriptionHookResult = ReturnType<typeof useGetMessagesSubscription>;
export type GetMessagesSubscriptionResult = Apollo.SubscriptionResult<GetMessagesSubscription>;
export const GetOtherParticipantDocument = gql`
  query GetOtherParticipant($userId: String!, $threadId: String!) {
    threadParticipants(
      filter: { and: [{ userId: { neq: $userId } }, { messageThreadId: { eq: $threadId } }] }
    ) {
      edges {
        node {
          id
          messageThreadId
          userId
          unread
        }
      }
    }
  }
`;

/**
 * __useGetOtherParticipantQuery__
 *
 * To run a query within a React component, call `useGetOtherParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtherParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtherParticipantQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useGetOtherParticipantQuery(
  baseOptions: Apollo.QueryHookOptions<GetOtherParticipantQuery, GetOtherParticipantQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOtherParticipantQuery, GetOtherParticipantQueryVariables>(
    GetOtherParticipantDocument,
    options,
  );
}
export function useGetOtherParticipantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOtherParticipantQuery,
    GetOtherParticipantQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOtherParticipantQuery, GetOtherParticipantQueryVariables>(
    GetOtherParticipantDocument,
    options,
  );
}
export type GetOtherParticipantQueryHookResult = ReturnType<typeof useGetOtherParticipantQuery>;
export type GetOtherParticipantLazyQueryHookResult = ReturnType<
  typeof useGetOtherParticipantLazyQuery
>;
export type GetOtherParticipantQueryResult = Apollo.QueryResult<
  GetOtherParticipantQuery,
  GetOtherParticipantQueryVariables
>;
export const GetThreadsForCurrentUserDocument = gql`
  query GetThreadsForCurrentUser($cursor: ConnectionCursor) {
    messageThreads(paging: { first: 10, after: $cursor }) {
      pageInfo {
        ...PageInfoFields
      }
      edges {
        cursor
        node {
          id
          createdAt
          lastMessageAt
          messages(paging: { first: 1 }, sorting: { field: createdAt, direction: DESC }) {
            edges {
              node {
                content
                user {
                  nickname
                  id
                }
                createdAt
                updatedAt
                messageFiles {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
          threadParticipants {
            edges {
              node {
                id
                createdAt
                updatedAt
                userId
                unread
                user {
                  id
                  profile {
                    id
                    nickname
                    avatar {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${PageInfoFieldsFragmentDoc}
`;

/**
 * __useGetThreadsForCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetThreadsForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadsForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadsForCurrentUserQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetThreadsForCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetThreadsForCurrentUserQuery,
    GetThreadsForCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThreadsForCurrentUserQuery, GetThreadsForCurrentUserQueryVariables>(
    GetThreadsForCurrentUserDocument,
    options,
  );
}
export function useGetThreadsForCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetThreadsForCurrentUserQuery,
    GetThreadsForCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetThreadsForCurrentUserQuery, GetThreadsForCurrentUserQueryVariables>(
    GetThreadsForCurrentUserDocument,
    options,
  );
}
export type GetThreadsForCurrentUserQueryHookResult = ReturnType<
  typeof useGetThreadsForCurrentUserQuery
>;
export type GetThreadsForCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetThreadsForCurrentUserLazyQuery
>;
export type GetThreadsForCurrentUserQueryResult = Apollo.QueryResult<
  GetThreadsForCurrentUserQuery,
  GetThreadsForCurrentUserQueryVariables
>;
export const GetUpdatedMessageDocument = gql`
  subscription GetUpdatedMessage {
    updatedOneMessage {
      content
      userId
      id
      createdAt
      updatedAt
      messageThreadId
    }
  }
`;

/**
 * __useGetUpdatedMessageSubscription__
 *
 * To run a query within a React component, call `useGetUpdatedMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdatedMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdatedMessageSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetUpdatedMessageSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetUpdatedMessageSubscription,
    GetUpdatedMessageSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetUpdatedMessageSubscription,
    GetUpdatedMessageSubscriptionVariables
  >(GetUpdatedMessageDocument, options);
}
export type GetUpdatedMessageSubscriptionHookResult = ReturnType<
  typeof useGetUpdatedMessageSubscription
>;
export type GetUpdatedMessageSubscriptionResult =
  Apollo.SubscriptionResult<GetUpdatedMessageSubscription>;
export const UploadMessageFileDocument = gql`
  mutation UploadMessageFile($file: Upload!, $messageId: ID!) {
    uploadMessageFile(uploadFile: { file: $file }, messageId: $messageId) {
      id
      url
    }
  }
`;
export type UploadMessageFileMutationFn = Apollo.MutationFunction<
  UploadMessageFileMutation,
  UploadMessageFileMutationVariables
>;

/**
 * __useUploadMessageFileMutation__
 *
 * To run a mutation, you first call `useUploadMessageFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMessageFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMessageFileMutation, { data, loading, error }] = useUploadMessageFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useUploadMessageFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadMessageFileMutation,
    UploadMessageFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadMessageFileMutation, UploadMessageFileMutationVariables>(
    UploadMessageFileDocument,
    options,
  );
}
export type UploadMessageFileMutationHookResult = ReturnType<typeof useUploadMessageFileMutation>;
export type UploadMessageFileMutationResult = Apollo.MutationResult<UploadMessageFileMutation>;
export type UploadMessageFileMutationOptions = Apollo.BaseMutationOptions<
  UploadMessageFileMutation,
  UploadMessageFileMutationVariables
>;
export const EventDocument = gql`
  query Event($id: ID!) {
    event(id: $id) {
      ...EventFields
    }
  }
  ${EventFieldsFragmentDoc}
`;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventQuery(
  baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
}
export function useEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
}
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const ExperienceEventsDocument = gql`
  query ExperienceEvents($id: ID!, $now: DateTime) {
    events(
      filter: { experience: { id: { eq: $id } }, end: { gt: $now }, private: { isNot: true } }
    ) {
      edges {
        node {
          ...ExperienceEventFields
        }
      }
    }
  }
  ${ExperienceEventFieldsFragmentDoc}
`;

/**
 * __useExperienceEventsQuery__
 *
 * To run a query within a React component, call `useExperienceEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useExperienceEventsQuery(
  baseOptions: Apollo.QueryHookOptions<ExperienceEventsQuery, ExperienceEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExperienceEventsQuery, ExperienceEventsQueryVariables>(
    ExperienceEventsDocument,
    options,
  );
}
export function useExperienceEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExperienceEventsQuery, ExperienceEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExperienceEventsQuery, ExperienceEventsQueryVariables>(
    ExperienceEventsDocument,
    options,
  );
}
export type ExperienceEventsQueryHookResult = ReturnType<typeof useExperienceEventsQuery>;
export type ExperienceEventsLazyQueryHookResult = ReturnType<typeof useExperienceEventsLazyQuery>;
export type ExperienceEventsQueryResult = Apollo.QueryResult<
  ExperienceEventsQuery,
  ExperienceEventsQueryVariables
>;
export const GetExperienceByIdDocument = gql`
  query GetExperienceById($id: ID!) {
    experience(id: $id) {
      ...ExperienceFields
    }
  }
  ${ExperienceFieldsFragmentDoc}
`;

/**
 * __useGetExperienceByIdQuery__
 *
 * To run a query within a React component, call `useGetExperienceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperienceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperienceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExperienceByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetExperienceByIdQuery, GetExperienceByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExperienceByIdQuery, GetExperienceByIdQueryVariables>(
    GetExperienceByIdDocument,
    options,
  );
}
export function useGetExperienceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExperienceByIdQuery,
    GetExperienceByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExperienceByIdQuery, GetExperienceByIdQueryVariables>(
    GetExperienceByIdDocument,
    options,
  );
}
export type GetExperienceByIdQueryHookResult = ReturnType<typeof useGetExperienceByIdQuery>;
export type GetExperienceByIdLazyQueryHookResult = ReturnType<typeof useGetExperienceByIdLazyQuery>;
export type GetExperienceByIdQueryResult = Apollo.QueryResult<
  GetExperienceByIdQuery,
  GetExperienceByIdQueryVariables
>;
export const GetExperienceTagsDocument = gql`
  query GetExperienceTags($id: ID!) {
    experience(id: $id) {
      experienceTags {
        edges {
          node {
            tag {
              id
              icon {
                url
                key
              }
              title
              category
              public
              description
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetExperienceTagsQuery__
 *
 * To run a query within a React component, call `useGetExperienceTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperienceTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperienceTagsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExperienceTagsQuery(
  baseOptions: Apollo.QueryHookOptions<GetExperienceTagsQuery, GetExperienceTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExperienceTagsQuery, GetExperienceTagsQueryVariables>(
    GetExperienceTagsDocument,
    options,
  );
}
export function useGetExperienceTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExperienceTagsQuery,
    GetExperienceTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExperienceTagsQuery, GetExperienceTagsQueryVariables>(
    GetExperienceTagsDocument,
    options,
  );
}
export type GetExperienceTagsQueryHookResult = ReturnType<typeof useGetExperienceTagsQuery>;
export type GetExperienceTagsLazyQueryHookResult = ReturnType<typeof useGetExperienceTagsLazyQuery>;
export type GetExperienceTagsQueryResult = Apollo.QueryResult<
  GetExperienceTagsQuery,
  GetExperienceTagsQueryVariables
>;
export const GetFullExperienceByIdDocument = gql`
  query GetFullExperienceById($id: ID!) {
    experience(id: $id) {
      title
      createdAt
      id
      category
      type
      status
      nextEvent
      mode
      nextAvailability
      locationType
      approximateLocation {
        coordinates
      }
      administrativeAreaLevel1
      administrativeAreaLevel2
      country
      locality
      video {
        url
        data {
          ...VideoFields
        }
      }
      experienceTags {
        edges {
          node {
            id
            tag {
              id
              title
              category
              public
              description
              icon {
                url
                key
              }
            }
          }
        }
      }
      publishedAt
      content {
        description
        what
        bring
        accessibility
        environment
      }
      images(
        sorting: [{ field: order, direction: ASC }, { field: createdAt, direction: ASC }]
        paging: { first: 11 }
      ) {
        edges {
          node {
            id
            order
            file {
              ...ImageFields
            }
          }
        }
      }
      host {
        id
        profile {
          id
          about
          nickname
          avatar {
            ...ImageFields
          }
        }
      }
    }
  }
  ${VideoFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
`;

/**
 * __useGetFullExperienceByIdQuery__
 *
 * To run a query within a React component, call `useGetFullExperienceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullExperienceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullExperienceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFullExperienceByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFullExperienceByIdQuery,
    GetFullExperienceByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFullExperienceByIdQuery, GetFullExperienceByIdQueryVariables>(
    GetFullExperienceByIdDocument,
    options,
  );
}
export function useGetFullExperienceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFullExperienceByIdQuery,
    GetFullExperienceByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFullExperienceByIdQuery, GetFullExperienceByIdQueryVariables>(
    GetFullExperienceByIdDocument,
    options,
  );
}
export type GetFullExperienceByIdQueryHookResult = ReturnType<typeof useGetFullExperienceByIdQuery>;
export type GetFullExperienceByIdLazyQueryHookResult = ReturnType<
  typeof useGetFullExperienceByIdLazyQuery
>;
export type GetFullExperienceByIdQueryResult = Apollo.QueryResult<
  GetFullExperienceByIdQuery,
  GetFullExperienceByIdQueryVariables
>;
export const SignupForEventAgainDocument = gql`
  mutation SignupForEventAgain($attendeeId: ID!) {
    updateOneAttendee(
      input: { id: $attendeeId, update: { status: confirmed, role: participant } }
    ) {
      id
    }
  }
`;
export type SignupForEventAgainMutationFn = Apollo.MutationFunction<
  SignupForEventAgainMutation,
  SignupForEventAgainMutationVariables
>;

/**
 * __useSignupForEventAgainMutation__
 *
 * To run a mutation, you first call `useSignupForEventAgainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupForEventAgainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupForEventAgainMutation, { data, loading, error }] = useSignupForEventAgainMutation({
 *   variables: {
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useSignupForEventAgainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupForEventAgainMutation,
    SignupForEventAgainMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupForEventAgainMutation, SignupForEventAgainMutationVariables>(
    SignupForEventAgainDocument,
    options,
  );
}
export type SignupForEventAgainMutationHookResult = ReturnType<
  typeof useSignupForEventAgainMutation
>;
export type SignupForEventAgainMutationResult = Apollo.MutationResult<SignupForEventAgainMutation>;
export type SignupForEventAgainMutationOptions = Apollo.BaseMutationOptions<
  SignupForEventAgainMutation,
  SignupForEventAgainMutationVariables
>;
export const SignupForEventDocument = gql`
  mutation SignupForEvent($userId: ID!, $eventId: ID!) {
    createOneAttendee(
      input: {
        attendee: {
          user: { id: $userId }
          event: { id: $eventId }
          status: confirmed
          role: participant
        }
      }
    ) {
      id
    }
  }
`;
export type SignupForEventMutationFn = Apollo.MutationFunction<
  SignupForEventMutation,
  SignupForEventMutationVariables
>;

/**
 * __useSignupForEventMutation__
 *
 * To run a mutation, you first call `useSignupForEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupForEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupForEventMutation, { data, loading, error }] = useSignupForEventMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useSignupForEventMutation(
  baseOptions?: Apollo.MutationHookOptions<SignupForEventMutation, SignupForEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupForEventMutation, SignupForEventMutationVariables>(
    SignupForEventDocument,
    options,
  );
}
export type SignupForEventMutationHookResult = ReturnType<typeof useSignupForEventMutation>;
export type SignupForEventMutationResult = Apollo.MutationResult<SignupForEventMutation>;
export type SignupForEventMutationOptions = Apollo.BaseMutationOptions<
  SignupForEventMutation,
  SignupForEventMutationVariables
>;
export const ExperiencesDocument = gql`
  query Experiences($filter: ExperienceFilter, $paging: CursorPaging, $sorting: [ExperienceSort!]) {
    experiences(filter: $filter, paging: $paging, sorting: $sorting) {
      totalCount
      pageInfo {
        ...PageInfoFields
      }
      edges {
        cursor
        node {
          ...ExperienceCardFields
        }
      }
    }
  }
  ${PageInfoFieldsFragmentDoc}
  ${ExperienceCardFieldsFragmentDoc}
`;

/**
 * __useExperiencesQuery__
 *
 * To run a query within a React component, call `useExperiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperiencesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useExperiencesQuery(
  baseOptions?: Apollo.QueryHookOptions<ExperiencesQuery, ExperiencesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExperiencesQuery, ExperiencesQueryVariables>(ExperiencesDocument, options);
}
export function useExperiencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExperiencesQuery, ExperiencesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExperiencesQuery, ExperiencesQueryVariables>(
    ExperiencesDocument,
    options,
  );
}
export type ExperiencesQueryHookResult = ReturnType<typeof useExperiencesQuery>;
export type ExperiencesLazyQueryHookResult = ReturnType<typeof useExperiencesLazyQuery>;
export type ExperiencesQueryResult = Apollo.QueryResult<
  ExperiencesQuery,
  ExperiencesQueryVariables
>;
export const GroupExperienceEventsDocument = gql`
  query GroupExperienceEvents($filter: EventFilter, $paging: CursorPaging, $sorting: [EventSort!]) {
    groupExperienceEvents(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        ...PageInfoFields
      }
      edges {
        cursor
        node {
          ...EventCardFields
        }
      }
    }
  }
  ${PageInfoFieldsFragmentDoc}
  ${EventCardFieldsFragmentDoc}
`;

/**
 * __useGroupExperienceEventsQuery__
 *
 * To run a query within a React component, call `useGroupExperienceEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupExperienceEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupExperienceEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGroupExperienceEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GroupExperienceEventsQuery,
    GroupExperienceEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupExperienceEventsQuery, GroupExperienceEventsQueryVariables>(
    GroupExperienceEventsDocument,
    options,
  );
}
export function useGroupExperienceEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupExperienceEventsQuery,
    GroupExperienceEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupExperienceEventsQuery, GroupExperienceEventsQueryVariables>(
    GroupExperienceEventsDocument,
    options,
  );
}
export type GroupExperienceEventsQueryHookResult = ReturnType<typeof useGroupExperienceEventsQuery>;
export type GroupExperienceEventsLazyQueryHookResult = ReturnType<
  typeof useGroupExperienceEventsLazyQuery
>;
export type GroupExperienceEventsQueryResult = Apollo.QueryResult<
  GroupExperienceEventsQuery,
  GroupExperienceEventsQueryVariables
>;
export const AccessibilityTagsDocument = gql`
  query AccessibilityTags {
    tags(filter: { category: { eq: Accessibility } }, paging: { first: 50 }) {
      edges {
        node {
          id
          title
          icon {
            url
            key
          }
          description
          category
        }
      }
    }
  }
`;

/**
 * __useAccessibilityTagsQuery__
 *
 * To run a query within a React component, call `useAccessibilityTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessibilityTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessibilityTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccessibilityTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<AccessibilityTagsQuery, AccessibilityTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccessibilityTagsQuery, AccessibilityTagsQueryVariables>(
    AccessibilityTagsDocument,
    options,
  );
}
export function useAccessibilityTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessibilityTagsQuery,
    AccessibilityTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccessibilityTagsQuery, AccessibilityTagsQueryVariables>(
    AccessibilityTagsDocument,
    options,
  );
}
export type AccessibilityTagsQueryHookResult = ReturnType<typeof useAccessibilityTagsQuery>;
export type AccessibilityTagsLazyQueryHookResult = ReturnType<typeof useAccessibilityTagsLazyQuery>;
export type AccessibilityTagsQueryResult = Apollo.QueryResult<
  AccessibilityTagsQuery,
  AccessibilityTagsQueryVariables
>;
export const SettingTagsDocument = gql`
  query SettingTags {
    tags(filter: { category: { eq: Setting } }, paging: { first: 50 }) {
      edges {
        node {
          id
          title
          icon {
            url
            key
          }
          description
          category
        }
      }
    }
  }
`;

/**
 * __useSettingTagsQuery__
 *
 * To run a query within a React component, call `useSettingTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<SettingTagsQuery, SettingTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SettingTagsQuery, SettingTagsQueryVariables>(SettingTagsDocument, options);
}
export function useSettingTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SettingTagsQuery, SettingTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SettingTagsQuery, SettingTagsQueryVariables>(
    SettingTagsDocument,
    options,
  );
}
export type SettingTagsQueryHookResult = ReturnType<typeof useSettingTagsQuery>;
export type SettingTagsLazyQueryHookResult = ReturnType<typeof useSettingTagsLazyQuery>;
export type SettingTagsQueryResult = Apollo.QueryResult<
  SettingTagsQuery,
  SettingTagsQueryVariables
>;
export const WhatToBringTagsDocument = gql`
  query WhatToBringTags {
    tags(filter: { category: { eq: WhatToBring } }, paging: { first: 50 }) {
      edges {
        node {
          id
          title
          icon {
            url
            key
          }
          description
          category
        }
      }
    }
  }
`;

/**
 * __useWhatToBringTagsQuery__
 *
 * To run a query within a React component, call `useWhatToBringTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhatToBringTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhatToBringTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhatToBringTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<WhatToBringTagsQuery, WhatToBringTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WhatToBringTagsQuery, WhatToBringTagsQueryVariables>(
    WhatToBringTagsDocument,
    options,
  );
}
export function useWhatToBringTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WhatToBringTagsQuery, WhatToBringTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WhatToBringTagsQuery, WhatToBringTagsQueryVariables>(
    WhatToBringTagsDocument,
    options,
  );
}
export type WhatToBringTagsQueryHookResult = ReturnType<typeof useWhatToBringTagsQuery>;
export type WhatToBringTagsLazyQueryHookResult = ReturnType<typeof useWhatToBringTagsLazyQuery>;
export type WhatToBringTagsQueryResult = Apollo.QueryResult<
  WhatToBringTagsQuery,
  WhatToBringTagsQueryVariables
>;
export const CreateEmptyExperienceDocument = gql`
  mutation CreateEmptyExperience($hostId: ID!) {
    createOneExperience(
      input: {
        experience: { status: Draft, type: Undefined, category: Advocate, host: { id: $hostId } }
      }
    ) {
      id
    }
  }
`;
export type CreateEmptyExperienceMutationFn = Apollo.MutationFunction<
  CreateEmptyExperienceMutation,
  CreateEmptyExperienceMutationVariables
>;

/**
 * __useCreateEmptyExperienceMutation__
 *
 * To run a mutation, you first call `useCreateEmptyExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmptyExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmptyExperienceMutation, { data, loading, error }] = useCreateEmptyExperienceMutation({
 *   variables: {
 *      hostId: // value for 'hostId'
 *   },
 * });
 */
export function useCreateEmptyExperienceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEmptyExperienceMutation,
    CreateEmptyExperienceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEmptyExperienceMutation, CreateEmptyExperienceMutationVariables>(
    CreateEmptyExperienceDocument,
    options,
  );
}
export type CreateEmptyExperienceMutationHookResult = ReturnType<
  typeof useCreateEmptyExperienceMutation
>;
export type CreateEmptyExperienceMutationResult =
  Apollo.MutationResult<CreateEmptyExperienceMutation>;
export type CreateEmptyExperienceMutationOptions = Apollo.BaseMutationOptions<
  CreateEmptyExperienceMutation,
  CreateEmptyExperienceMutationVariables
>;
export const CreateEventForExperienceDocument = gql`
  mutation CreateEventForExperience($input: CreateEventInput!) {
    createOneEvent(input: { event: $input }) {
      id
    }
  }
`;
export type CreateEventForExperienceMutationFn = Apollo.MutationFunction<
  CreateEventForExperienceMutation,
  CreateEventForExperienceMutationVariables
>;

/**
 * __useCreateEventForExperienceMutation__
 *
 * To run a mutation, you first call `useCreateEventForExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventForExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventForExperienceMutation, { data, loading, error }] = useCreateEventForExperienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventForExperienceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventForExperienceMutation,
    CreateEventForExperienceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEventForExperienceMutation,
    CreateEventForExperienceMutationVariables
  >(CreateEventForExperienceDocument, options);
}
export type CreateEventForExperienceMutationHookResult = ReturnType<
  typeof useCreateEventForExperienceMutation
>;
export type CreateEventForExperienceMutationResult =
  Apollo.MutationResult<CreateEventForExperienceMutation>;
export type CreateEventForExperienceMutationOptions = Apollo.BaseMutationOptions<
  CreateEventForExperienceMutation,
  CreateEventForExperienceMutationVariables
>;
export const CreateExperienceTagsDocument = gql`
  mutation CreateExperienceTags($input: CreateManyExperienceTagsInput!) {
    createManyExperienceTags(input: $input) {
      id
    }
  }
`;
export type CreateExperienceTagsMutationFn = Apollo.MutationFunction<
  CreateExperienceTagsMutation,
  CreateExperienceTagsMutationVariables
>;

/**
 * __useCreateExperienceTagsMutation__
 *
 * To run a mutation, you first call `useCreateExperienceTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExperienceTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExperienceTagsMutation, { data, loading, error }] = useCreateExperienceTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExperienceTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExperienceTagsMutation,
    CreateExperienceTagsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateExperienceTagsMutation, CreateExperienceTagsMutationVariables>(
    CreateExperienceTagsDocument,
    options,
  );
}
export type CreateExperienceTagsMutationHookResult = ReturnType<
  typeof useCreateExperienceTagsMutation
>;
export type CreateExperienceTagsMutationResult =
  Apollo.MutationResult<CreateExperienceTagsMutation>;
export type CreateExperienceTagsMutationOptions = Apollo.BaseMutationOptions<
  CreateExperienceTagsMutation,
  CreateExperienceTagsMutationVariables
>;
export const CreateTimeSlotsDocument = gql`
  mutation CreateTimeSlots($timeslots: CreateManyTimeslotsInput!) {
    createManyTimeslots(input: $timeslots) {
      scheduleId
    }
  }
`;
export type CreateTimeSlotsMutationFn = Apollo.MutationFunction<
  CreateTimeSlotsMutation,
  CreateTimeSlotsMutationVariables
>;

/**
 * __useCreateTimeSlotsMutation__
 *
 * To run a mutation, you first call `useCreateTimeSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeSlotsMutation, { data, loading, error }] = useCreateTimeSlotsMutation({
 *   variables: {
 *      timeslots: // value for 'timeslots'
 *   },
 * });
 */
export function useCreateTimeSlotsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTimeSlotsMutation,
    CreateTimeSlotsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTimeSlotsMutation, CreateTimeSlotsMutationVariables>(
    CreateTimeSlotsDocument,
    options,
  );
}
export type CreateTimeSlotsMutationHookResult = ReturnType<typeof useCreateTimeSlotsMutation>;
export type CreateTimeSlotsMutationResult = Apollo.MutationResult<CreateTimeSlotsMutation>;
export type CreateTimeSlotsMutationOptions = Apollo.BaseMutationOptions<
  CreateTimeSlotsMutation,
  CreateTimeSlotsMutationVariables
>;
export const DeleteExperienceDocument = gql`
  mutation DeleteExperience($ID: ID!) {
    deleteOneExperience(input: { id: $ID }) {
      id
    }
  }
`;
export type DeleteExperienceMutationFn = Apollo.MutationFunction<
  DeleteExperienceMutation,
  DeleteExperienceMutationVariables
>;

/**
 * __useDeleteExperienceMutation__
 *
 * To run a mutation, you first call `useDeleteExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExperienceMutation, { data, loading, error }] = useDeleteExperienceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteExperienceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExperienceMutation,
    DeleteExperienceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteExperienceMutation, DeleteExperienceMutationVariables>(
    DeleteExperienceDocument,
    options,
  );
}
export type DeleteExperienceMutationHookResult = ReturnType<typeof useDeleteExperienceMutation>;
export type DeleteExperienceMutationResult = Apollo.MutationResult<DeleteExperienceMutation>;
export type DeleteExperienceMutationOptions = Apollo.BaseMutationOptions<
  DeleteExperienceMutation,
  DeleteExperienceMutationVariables
>;
export const DeleteExperienceImageDocument = gql`
  mutation DeleteExperienceImage($imageId: ID!) {
    deleteOneImage(input: { id: $imageId }) {
      id
    }
  }
`;
export type DeleteExperienceImageMutationFn = Apollo.MutationFunction<
  DeleteExperienceImageMutation,
  DeleteExperienceImageMutationVariables
>;

/**
 * __useDeleteExperienceImageMutation__
 *
 * To run a mutation, you first call `useDeleteExperienceImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExperienceImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExperienceImageMutation, { data, loading, error }] = useDeleteExperienceImageMutation({
 *   variables: {
 *      imageId: // value for 'imageId'
 *   },
 * });
 */
export function useDeleteExperienceImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExperienceImageMutation,
    DeleteExperienceImageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteExperienceImageMutation, DeleteExperienceImageMutationVariables>(
    DeleteExperienceImageDocument,
    options,
  );
}
export type DeleteExperienceImageMutationHookResult = ReturnType<
  typeof useDeleteExperienceImageMutation
>;
export type DeleteExperienceImageMutationResult =
  Apollo.MutationResult<DeleteExperienceImageMutation>;
export type DeleteExperienceImageMutationOptions = Apollo.BaseMutationOptions<
  DeleteExperienceImageMutation,
  DeleteExperienceImageMutationVariables
>;
export const DeleteManyExperienceTagsDocument = gql`
  mutation DeleteManyExperienceTags($input: DeleteManyExperienceTagsInput!) {
    deleteManyExperienceTags(input: $input) {
      deletedCount
    }
  }
`;
export type DeleteManyExperienceTagsMutationFn = Apollo.MutationFunction<
  DeleteManyExperienceTagsMutation,
  DeleteManyExperienceTagsMutationVariables
>;

/**
 * __useDeleteManyExperienceTagsMutation__
 *
 * To run a mutation, you first call `useDeleteManyExperienceTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyExperienceTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyExperienceTagsMutation, { data, loading, error }] = useDeleteManyExperienceTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyExperienceTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteManyExperienceTagsMutation,
    DeleteManyExperienceTagsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteManyExperienceTagsMutation,
    DeleteManyExperienceTagsMutationVariables
  >(DeleteManyExperienceTagsDocument, options);
}
export type DeleteManyExperienceTagsMutationHookResult = ReturnType<
  typeof useDeleteManyExperienceTagsMutation
>;
export type DeleteManyExperienceTagsMutationResult =
  Apollo.MutationResult<DeleteManyExperienceTagsMutation>;
export type DeleteManyExperienceTagsMutationOptions = Apollo.BaseMutationOptions<
  DeleteManyExperienceTagsMutation,
  DeleteManyExperienceTagsMutationVariables
>;
export const DeleteManyEventsDocument = gql`
  mutation DeleteManyEvents($ids: [ID!]) {
    deleteManyEvents(input: { filter: { id: { in: $ids } } }) {
      deletedCount
    }
  }
`;
export type DeleteManyEventsMutationFn = Apollo.MutationFunction<
  DeleteManyEventsMutation,
  DeleteManyEventsMutationVariables
>;

/**
 * __useDeleteManyEventsMutation__
 *
 * To run a mutation, you first call `useDeleteManyEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyEventsMutation, { data, loading, error }] = useDeleteManyEventsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteManyEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteManyEventsMutation,
    DeleteManyEventsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteManyEventsMutation, DeleteManyEventsMutationVariables>(
    DeleteManyEventsDocument,
    options,
  );
}
export type DeleteManyEventsMutationHookResult = ReturnType<typeof useDeleteManyEventsMutation>;
export type DeleteManyEventsMutationResult = Apollo.MutationResult<DeleteManyEventsMutation>;
export type DeleteManyEventsMutationOptions = Apollo.BaseMutationOptions<
  DeleteManyEventsMutation,
  DeleteManyEventsMutationVariables
>;
export const DeleteOneEventDocument = gql`
  mutation DeleteOneEvent($id: ID!) {
    deleteOneEvent(input: { id: $id }) {
      id
    }
  }
`;
export type DeleteOneEventMutationFn = Apollo.MutationFunction<
  DeleteOneEventMutation,
  DeleteOneEventMutationVariables
>;

/**
 * __useDeleteOneEventMutation__
 *
 * To run a mutation, you first call `useDeleteOneEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneEventMutation, { data, loading, error }] = useDeleteOneEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneEventMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOneEventMutation, DeleteOneEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOneEventMutation, DeleteOneEventMutationVariables>(
    DeleteOneEventDocument,
    options,
  );
}
export type DeleteOneEventMutationHookResult = ReturnType<typeof useDeleteOneEventMutation>;
export type DeleteOneEventMutationResult = Apollo.MutationResult<DeleteOneEventMutation>;
export type DeleteOneEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneEventMutation,
  DeleteOneEventMutationVariables
>;
export const DeleteOneExperienceTagDocument = gql`
  mutation DeleteOneExperienceTag($input: DeleteOneExperienceTagInput!) {
    deleteOneExperienceTag(input: $input) {
      id
    }
  }
`;
export type DeleteOneExperienceTagMutationFn = Apollo.MutationFunction<
  DeleteOneExperienceTagMutation,
  DeleteOneExperienceTagMutationVariables
>;

/**
 * __useDeleteOneExperienceTagMutation__
 *
 * To run a mutation, you first call `useDeleteOneExperienceTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneExperienceTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneExperienceTagMutation, { data, loading, error }] = useDeleteOneExperienceTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneExperienceTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneExperienceTagMutation,
    DeleteOneExperienceTagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOneExperienceTagMutation,
    DeleteOneExperienceTagMutationVariables
  >(DeleteOneExperienceTagDocument, options);
}
export type DeleteOneExperienceTagMutationHookResult = ReturnType<
  typeof useDeleteOneExperienceTagMutation
>;
export type DeleteOneExperienceTagMutationResult =
  Apollo.MutationResult<DeleteOneExperienceTagMutation>;
export type DeleteOneExperienceTagMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneExperienceTagMutation,
  DeleteOneExperienceTagMutationVariables
>;
export const DeleteTimeslotDocument = gql`
  mutation DeleteTimeslot($id: ID!) {
    deleteOneTimeslot(input: { id: $id }) {
      id
    }
  }
`;
export type DeleteTimeslotMutationFn = Apollo.MutationFunction<
  DeleteTimeslotMutation,
  DeleteTimeslotMutationVariables
>;

/**
 * __useDeleteTimeslotMutation__
 *
 * To run a mutation, you first call `useDeleteTimeslotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeslotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeslotMutation, { data, loading, error }] = useDeleteTimeslotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTimeslotMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTimeslotMutation, DeleteTimeslotMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTimeslotMutation, DeleteTimeslotMutationVariables>(
    DeleteTimeslotDocument,
    options,
  );
}
export type DeleteTimeslotMutationHookResult = ReturnType<typeof useDeleteTimeslotMutation>;
export type DeleteTimeslotMutationResult = Apollo.MutationResult<DeleteTimeslotMutation>;
export type DeleteTimeslotMutationOptions = Apollo.BaseMutationOptions<
  DeleteTimeslotMutation,
  DeleteTimeslotMutationVariables
>;
export const GetCalendarDocument = gql`
  query GetCalendar($experienceId: ID!) {
    experience(id: $experienceId) {
      id
      calendar {
        id
      }
    }
  }
`;

/**
 * __useGetCalendarQuery__
 *
 * To run a query within a React component, call `useGetCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useGetCalendarQuery(
  baseOptions: Apollo.QueryHookOptions<GetCalendarQuery, GetCalendarQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCalendarQuery, GetCalendarQueryVariables>(GetCalendarDocument, options);
}
export function useGetCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCalendarQuery, GetCalendarQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCalendarQuery, GetCalendarQueryVariables>(
    GetCalendarDocument,
    options,
  );
}
export type GetCalendarQueryHookResult = ReturnType<typeof useGetCalendarQuery>;
export type GetCalendarLazyQueryHookResult = ReturnType<typeof useGetCalendarLazyQuery>;
export type GetCalendarQueryResult = Apollo.QueryResult<
  GetCalendarQuery,
  GetCalendarQueryVariables
>;
export const GetCoverImageDocument = gql`
  query GetCoverImage($experienceId: ID!) {
    images(filter: { experience: { id: { eq: $experienceId } }, order: { eq: 0 } }) {
      edges {
        node {
          id
          order
          createdAt
          file {
            id
            url
          }
        }
      }
    }
  }
`;

/**
 * __useGetCoverImageQuery__
 *
 * To run a query within a React component, call `useGetCoverImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoverImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoverImageQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useGetCoverImageQuery(
  baseOptions: Apollo.QueryHookOptions<GetCoverImageQuery, GetCoverImageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoverImageQuery, GetCoverImageQueryVariables>(
    GetCoverImageDocument,
    options,
  );
}
export function useGetCoverImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoverImageQuery, GetCoverImageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoverImageQuery, GetCoverImageQueryVariables>(
    GetCoverImageDocument,
    options,
  );
}
export type GetCoverImageQueryHookResult = ReturnType<typeof useGetCoverImageQuery>;
export type GetCoverImageLazyQueryHookResult = ReturnType<typeof useGetCoverImageLazyQuery>;
export type GetCoverImageQueryResult = Apollo.QueryResult<
  GetCoverImageQuery,
  GetCoverImageQueryVariables
>;
export const GetExperienceForEditByIdDocument = gql`
  query GetExperienceForEditById($id: ID!) {
    experience(id: $id) {
      ...EditExperienceFields
    }
  }
  ${EditExperienceFieldsFragmentDoc}
`;

/**
 * __useGetExperienceForEditByIdQuery__
 *
 * To run a query within a React component, call `useGetExperienceForEditByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperienceForEditByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperienceForEditByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExperienceForEditByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExperienceForEditByIdQuery,
    GetExperienceForEditByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExperienceForEditByIdQuery, GetExperienceForEditByIdQueryVariables>(
    GetExperienceForEditByIdDocument,
    options,
  );
}
export function useGetExperienceForEditByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExperienceForEditByIdQuery,
    GetExperienceForEditByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExperienceForEditByIdQuery, GetExperienceForEditByIdQueryVariables>(
    GetExperienceForEditByIdDocument,
    options,
  );
}
export type GetExperienceForEditByIdQueryHookResult = ReturnType<
  typeof useGetExperienceForEditByIdQuery
>;
export type GetExperienceForEditByIdLazyQueryHookResult = ReturnType<
  typeof useGetExperienceForEditByIdLazyQuery
>;
export type GetExperienceForEditByIdQueryResult = Apollo.QueryResult<
  GetExperienceForEditByIdQuery,
  GetExperienceForEditByIdQueryVariables
>;
export const GetExperiencesByHostIdDocument = gql`
  query GetExperiencesByHostId($filter: ExperienceFilter) {
    experiences(filter: $filter, paging: { first: 50 }) {
      edges {
        node {
          ...CompleteExperienceFields
        }
      }
    }
  }
  ${CompleteExperienceFieldsFragmentDoc}
`;

/**
 * __useGetExperiencesByHostIdQuery__
 *
 * To run a query within a React component, call `useGetExperiencesByHostIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperiencesByHostIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperiencesByHostIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetExperiencesByHostIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExperiencesByHostIdQuery,
    GetExperiencesByHostIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExperiencesByHostIdQuery, GetExperiencesByHostIdQueryVariables>(
    GetExperiencesByHostIdDocument,
    options,
  );
}
export function useGetExperiencesByHostIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExperiencesByHostIdQuery,
    GetExperiencesByHostIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExperiencesByHostIdQuery, GetExperiencesByHostIdQueryVariables>(
    GetExperiencesByHostIdDocument,
    options,
  );
}
export type GetExperiencesByHostIdQueryHookResult = ReturnType<
  typeof useGetExperiencesByHostIdQuery
>;
export type GetExperiencesByHostIdLazyQueryHookResult = ReturnType<
  typeof useGetExperiencesByHostIdLazyQuery
>;
export type GetExperiencesByHostIdQueryResult = Apollo.QueryResult<
  GetExperiencesByHostIdQuery,
  GetExperiencesByHostIdQueryVariables
>;
export const GetScheduleForAExperienceDocument = gql`
  query GetScheduleForAExperience($experienceId: ID!) {
    calendars(filter: { experience: { id: { eq: $experienceId } } }) {
      edges {
        node {
          id
          schedule {
            id
            createdAt
            updatedAt
            duration
            durationMetric
            range
            rangeMetric
            delay
            delayMetric
            frequency
            frequencyMetric
            cap
            capRange
            capRangeMetric
            timeslots {
              edges {
                node {
                  createdAt
                  updatedAt
                  __typename
                  id
                  start
                  end
                  free
                  weekly
                }
              }
            }
          }
          events(paging: { first: 50 }) {
            edges {
              node {
                id
                title
                start
                end
                calendarId
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetScheduleForAExperienceQuery__
 *
 * To run a query within a React component, call `useGetScheduleForAExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleForAExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleForAExperienceQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useGetScheduleForAExperienceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScheduleForAExperienceQuery,
    GetScheduleForAExperienceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScheduleForAExperienceQuery, GetScheduleForAExperienceQueryVariables>(
    GetScheduleForAExperienceDocument,
    options,
  );
}
export function useGetScheduleForAExperienceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScheduleForAExperienceQuery,
    GetScheduleForAExperienceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetScheduleForAExperienceQuery,
    GetScheduleForAExperienceQueryVariables
  >(GetScheduleForAExperienceDocument, options);
}
export type GetScheduleForAExperienceQueryHookResult = ReturnType<
  typeof useGetScheduleForAExperienceQuery
>;
export type GetScheduleForAExperienceLazyQueryHookResult = ReturnType<
  typeof useGetScheduleForAExperienceLazyQuery
>;
export type GetScheduleForAExperienceQueryResult = Apollo.QueryResult<
  GetScheduleForAExperienceQuery,
  GetScheduleForAExperienceQueryVariables
>;
export const UpdateCoverImageDocument = gql`
  mutation UpdateCoverImage($file: Upload!, $imageId: ID!) {
    updateCoverImage(file: { file: $file }, imageId: $imageId) {
      id
      file {
        id
        url
      }
    }
  }
`;
export type UpdateCoverImageMutationFn = Apollo.MutationFunction<
  UpdateCoverImageMutation,
  UpdateCoverImageMutationVariables
>;

/**
 * __useUpdateCoverImageMutation__
 *
 * To run a mutation, you first call `useUpdateCoverImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoverImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoverImageMutation, { data, loading, error }] = useUpdateCoverImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *      imageId: // value for 'imageId'
 *   },
 * });
 */
export function useUpdateCoverImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCoverImageMutation,
    UpdateCoverImageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCoverImageMutation, UpdateCoverImageMutationVariables>(
    UpdateCoverImageDocument,
    options,
  );
}
export type UpdateCoverImageMutationHookResult = ReturnType<typeof useUpdateCoverImageMutation>;
export type UpdateCoverImageMutationResult = Apollo.MutationResult<UpdateCoverImageMutation>;
export type UpdateCoverImageMutationOptions = Apollo.BaseMutationOptions<
  UpdateCoverImageMutation,
  UpdateCoverImageMutationVariables
>;
export const UpdateExperienceDocument = gql`
  mutation UpdateExperience($input: UpdateOneExperienceInput!) {
    updateOneExperience(input: $input) {
      id
      category
      status
      type
      title
      content {
        description
        what
        bring
        environment
        accessibility
      }
    }
  }
`;
export type UpdateExperienceMutationFn = Apollo.MutationFunction<
  UpdateExperienceMutation,
  UpdateExperienceMutationVariables
>;

/**
 * __useUpdateExperienceMutation__
 *
 * To run a mutation, you first call `useUpdateExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExperienceMutation, { data, loading, error }] = useUpdateExperienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExperienceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExperienceMutation,
    UpdateExperienceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateExperienceMutation, UpdateExperienceMutationVariables>(
    UpdateExperienceDocument,
    options,
  );
}
export type UpdateExperienceMutationHookResult = ReturnType<typeof useUpdateExperienceMutation>;
export type UpdateExperienceMutationResult = Apollo.MutationResult<UpdateExperienceMutation>;
export type UpdateExperienceMutationOptions = Apollo.BaseMutationOptions<
  UpdateExperienceMutation,
  UpdateExperienceMutationVariables
>;
export const UpdateOneEventDocument = gql`
  mutation UpdateOneEvent($id: ID!, $update: UpdateEventInput!) {
    updateOneEvent(input: { id: $id, update: $update }) {
      id
    }
  }
`;
export type UpdateOneEventMutationFn = Apollo.MutationFunction<
  UpdateOneEventMutation,
  UpdateOneEventMutationVariables
>;

/**
 * __useUpdateOneEventMutation__
 *
 * To run a mutation, you first call `useUpdateOneEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneEventMutation, { data, loading, error }] = useUpdateOneEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateOneEventMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOneEventMutation, UpdateOneEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOneEventMutation, UpdateOneEventMutationVariables>(
    UpdateOneEventDocument,
    options,
  );
}
export type UpdateOneEventMutationHookResult = ReturnType<typeof useUpdateOneEventMutation>;
export type UpdateOneEventMutationResult = Apollo.MutationResult<UpdateOneEventMutation>;
export type UpdateOneEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneEventMutation,
  UpdateOneEventMutationVariables
>;
export const UpdateScheduleWithIdDocument = gql`
  mutation UpdateScheduleWithId($id: ID!, $update: UpdateScheduleInput!) {
    updateOneSchedule(input: { id: $id, update: $update }) {
      id
    }
  }
`;
export type UpdateScheduleWithIdMutationFn = Apollo.MutationFunction<
  UpdateScheduleWithIdMutation,
  UpdateScheduleWithIdMutationVariables
>;

/**
 * __useUpdateScheduleWithIdMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleWithIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleWithIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleWithIdMutation, { data, loading, error }] = useUpdateScheduleWithIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateScheduleWithIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScheduleWithIdMutation,
    UpdateScheduleWithIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateScheduleWithIdMutation, UpdateScheduleWithIdMutationVariables>(
    UpdateScheduleWithIdDocument,
    options,
  );
}
export type UpdateScheduleWithIdMutationHookResult = ReturnType<
  typeof useUpdateScheduleWithIdMutation
>;
export type UpdateScheduleWithIdMutationResult =
  Apollo.MutationResult<UpdateScheduleWithIdMutation>;
export type UpdateScheduleWithIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateScheduleWithIdMutation,
  UpdateScheduleWithIdMutationVariables
>;
export const AddImageToExperienceDocument = gql`
  mutation AddImageToExperience($file: Upload!, $experienceId: ID!, $order: Float) {
    uploadOneExperienceImage(file: { file: $file }, experienceId: $experienceId, order: $order) {
      id
      file {
        id
        url
      }
    }
  }
`;
export type AddImageToExperienceMutationFn = Apollo.MutationFunction<
  AddImageToExperienceMutation,
  AddImageToExperienceMutationVariables
>;

/**
 * __useAddImageToExperienceMutation__
 *
 * To run a mutation, you first call `useAddImageToExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddImageToExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addImageToExperienceMutation, { data, loading, error }] = useAddImageToExperienceMutation({
 *   variables: {
 *      file: // value for 'file'
 *      experienceId: // value for 'experienceId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddImageToExperienceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddImageToExperienceMutation,
    AddImageToExperienceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddImageToExperienceMutation, AddImageToExperienceMutationVariables>(
    AddImageToExperienceDocument,
    options,
  );
}
export type AddImageToExperienceMutationHookResult = ReturnType<
  typeof useAddImageToExperienceMutation
>;
export type AddImageToExperienceMutationResult =
  Apollo.MutationResult<AddImageToExperienceMutation>;
export type AddImageToExperienceMutationOptions = Apollo.BaseMutationOptions<
  AddImageToExperienceMutation,
  AddImageToExperienceMutationVariables
>;
export const CreateStripeCheckoutSessionDocument = gql`
  mutation CreateStripeCheckoutSession(
    $experienceId: ID!
    $cancelUrl: String
    $successUrl: String
  ) {
    createStripeCheckoutSession(
      input: { experienceId: $experienceId, cancelUrl: $cancelUrl, successUrl: $successUrl }
    ) {
      ...StripeCheckoutSessionFields
    }
  }
  ${StripeCheckoutSessionFieldsFragmentDoc}
`;
export type CreateStripeCheckoutSessionMutationFn = Apollo.MutationFunction<
  CreateStripeCheckoutSessionMutation,
  CreateStripeCheckoutSessionMutationVariables
>;

/**
 * __useCreateStripeCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCheckoutSessionMutation, { data, loading, error }] = useCreateStripeCheckoutSessionMutation({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      cancelUrl: // value for 'cancelUrl'
 *      successUrl: // value for 'successUrl'
 *   },
 * });
 */
export function useCreateStripeCheckoutSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStripeCheckoutSessionMutation,
    CreateStripeCheckoutSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStripeCheckoutSessionMutation,
    CreateStripeCheckoutSessionMutationVariables
  >(CreateStripeCheckoutSessionDocument, options);
}
export type CreateStripeCheckoutSessionMutationHookResult = ReturnType<
  typeof useCreateStripeCheckoutSessionMutation
>;
export type CreateStripeCheckoutSessionMutationResult =
  Apollo.MutationResult<CreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<
  CreateStripeCheckoutSessionMutation,
  CreateStripeCheckoutSessionMutationVariables
>;
export const StripeCheckoutSessionDocument = gql`
  query StripeCheckoutSession($id: String!) {
    stripeCheckoutSession(id: $id) {
      ...StripeCheckoutSessionFields
    }
  }
  ${StripeCheckoutSessionFieldsFragmentDoc}
`;

/**
 * __useStripeCheckoutSessionQuery__
 *
 * To run a query within a React component, call `useStripeCheckoutSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeCheckoutSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeCheckoutSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStripeCheckoutSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    StripeCheckoutSessionQuery,
    StripeCheckoutSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StripeCheckoutSessionQuery, StripeCheckoutSessionQueryVariables>(
    StripeCheckoutSessionDocument,
    options,
  );
}
export function useStripeCheckoutSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StripeCheckoutSessionQuery,
    StripeCheckoutSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StripeCheckoutSessionQuery, StripeCheckoutSessionQueryVariables>(
    StripeCheckoutSessionDocument,
    options,
  );
}
export type StripeCheckoutSessionQueryHookResult = ReturnType<typeof useStripeCheckoutSessionQuery>;
export type StripeCheckoutSessionLazyQueryHookResult = ReturnType<
  typeof useStripeCheckoutSessionLazyQuery
>;
export type StripeCheckoutSessionQueryResult = Apollo.QueryResult<
  StripeCheckoutSessionQuery,
  StripeCheckoutSessionQueryVariables
>;
export const DeleteOneProfileImageDocument = gql`
  mutation DeleteOneProfileImage($id: ID!) {
    deleteOneProfileImage(input: { id: $id }) {
      id
    }
  }
`;
export type DeleteOneProfileImageMutationFn = Apollo.MutationFunction<
  DeleteOneProfileImageMutation,
  DeleteOneProfileImageMutationVariables
>;

/**
 * __useDeleteOneProfileImageMutation__
 *
 * To run a mutation, you first call `useDeleteOneProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneProfileImageMutation, { data, loading, error }] = useDeleteOneProfileImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneProfileImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneProfileImageMutation,
    DeleteOneProfileImageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOneProfileImageMutation, DeleteOneProfileImageMutationVariables>(
    DeleteOneProfileImageDocument,
    options,
  );
}
export type DeleteOneProfileImageMutationHookResult = ReturnType<
  typeof useDeleteOneProfileImageMutation
>;
export type DeleteOneProfileImageMutationResult =
  Apollo.MutationResult<DeleteOneProfileImageMutation>;
export type DeleteOneProfileImageMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneProfileImageMutation,
  DeleteOneProfileImageMutationVariables
>;
export const AttendeesDocument = gql`
  query Attendees($filter: AttendeeFilter) {
    attendees(filter: $filter) {
      totalCount
    }
  }
`;

/**
 * __useAttendeesQuery__
 *
 * To run a query within a React component, call `useAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAttendeesQuery(
  baseOptions?: Apollo.QueryHookOptions<AttendeesQuery, AttendeesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttendeesQuery, AttendeesQueryVariables>(AttendeesDocument, options);
}
export function useAttendeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AttendeesQuery, AttendeesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttendeesQuery, AttendeesQueryVariables>(AttendeesDocument, options);
}
export type AttendeesQueryHookResult = ReturnType<typeof useAttendeesQuery>;
export type AttendeesLazyQueryHookResult = ReturnType<typeof useAttendeesLazyQuery>;
export type AttendeesQueryResult = Apollo.QueryResult<AttendeesQuery, AttendeesQueryVariables>;
export const SortProfileImagesDocument = gql`
  mutation SortProfileImages($ids: [ID!]) {
    sortProfileImages(ids: $ids) {
      updatedCount
    }
  }
`;
export type SortProfileImagesMutationFn = Apollo.MutationFunction<
  SortProfileImagesMutation,
  SortProfileImagesMutationVariables
>;

/**
 * __useSortProfileImagesMutation__
 *
 * To run a mutation, you first call `useSortProfileImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortProfileImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortProfileImagesMutation, { data, loading, error }] = useSortProfileImagesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSortProfileImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SortProfileImagesMutation,
    SortProfileImagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SortProfileImagesMutation, SortProfileImagesMutationVariables>(
    SortProfileImagesDocument,
    options,
  );
}
export type SortProfileImagesMutationHookResult = ReturnType<typeof useSortProfileImagesMutation>;
export type SortProfileImagesMutationResult = Apollo.MutationResult<SortProfileImagesMutation>;
export type SortProfileImagesMutationOptions = Apollo.BaseMutationOptions<
  SortProfileImagesMutation,
  SortProfileImagesMutationVariables
>;
export const UpdateOwnAvatarDocument = gql`
  mutation UpdateOwnAvatar($file: Upload!) {
    updateAvatar(upload: { file: $file }) {
      id
      url
    }
  }
`;
export type UpdateOwnAvatarMutationFn = Apollo.MutationFunction<
  UpdateOwnAvatarMutation,
  UpdateOwnAvatarMutationVariables
>;

/**
 * __useUpdateOwnAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateOwnAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnAvatarMutation, { data, loading, error }] = useUpdateOwnAvatarMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateOwnAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnAvatarMutation,
    UpdateOwnAvatarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOwnAvatarMutation, UpdateOwnAvatarMutationVariables>(
    UpdateOwnAvatarDocument,
    options,
  );
}
export type UpdateOwnAvatarMutationHookResult = ReturnType<typeof useUpdateOwnAvatarMutation>;
export type UpdateOwnAvatarMutationResult = Apollo.MutationResult<UpdateOwnAvatarMutation>;
export type UpdateOwnAvatarMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnAvatarMutation,
  UpdateOwnAvatarMutationVariables
>;
export const UpdateOwnAvatarWithSignedUrlDocument = gql`
  mutation UpdateOwnAvatarWithSignedUrl($filename: String!, $mimetype: String!) {
    updateAvatarWithSignedUrl(upload: { filename: $filename, mimetype: $mimetype }) {
      id
      url
      uploadUrl
    }
  }
`;
export type UpdateOwnAvatarWithSignedUrlMutationFn = Apollo.MutationFunction<
  UpdateOwnAvatarWithSignedUrlMutation,
  UpdateOwnAvatarWithSignedUrlMutationVariables
>;

/**
 * __useUpdateOwnAvatarWithSignedUrlMutation__
 *
 * To run a mutation, you first call `useUpdateOwnAvatarWithSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnAvatarWithSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnAvatarWithSignedUrlMutation, { data, loading, error }] = useUpdateOwnAvatarWithSignedUrlMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      mimetype: // value for 'mimetype'
 *   },
 * });
 */
export function useUpdateOwnAvatarWithSignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnAvatarWithSignedUrlMutation,
    UpdateOwnAvatarWithSignedUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOwnAvatarWithSignedUrlMutation,
    UpdateOwnAvatarWithSignedUrlMutationVariables
  >(UpdateOwnAvatarWithSignedUrlDocument, options);
}
export type UpdateOwnAvatarWithSignedUrlMutationHookResult = ReturnType<
  typeof useUpdateOwnAvatarWithSignedUrlMutation
>;
export type UpdateOwnAvatarWithSignedUrlMutationResult =
  Apollo.MutationResult<UpdateOwnAvatarWithSignedUrlMutation>;
export type UpdateOwnAvatarWithSignedUrlMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnAvatarWithSignedUrlMutation,
  UpdateOwnAvatarWithSignedUrlMutationVariables
>;
export const UpdateOwnProfileInterestsDocument = gql`
  mutation UpdateOwnProfileInterests($interests: [String!]) {
    updateOwnProfileInterests(interests: $interests) {
      id
    }
  }
`;
export type UpdateOwnProfileInterestsMutationFn = Apollo.MutationFunction<
  UpdateOwnProfileInterestsMutation,
  UpdateOwnProfileInterestsMutationVariables
>;

/**
 * __useUpdateOwnProfileInterestsMutation__
 *
 * To run a mutation, you first call `useUpdateOwnProfileInterestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnProfileInterestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnProfileInterestsMutation, { data, loading, error }] = useUpdateOwnProfileInterestsMutation({
 *   variables: {
 *      interests: // value for 'interests'
 *   },
 * });
 */
export function useUpdateOwnProfileInterestsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnProfileInterestsMutation,
    UpdateOwnProfileInterestsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOwnProfileInterestsMutation,
    UpdateOwnProfileInterestsMutationVariables
  >(UpdateOwnProfileInterestsDocument, options);
}
export type UpdateOwnProfileInterestsMutationHookResult = ReturnType<
  typeof useUpdateOwnProfileInterestsMutation
>;
export type UpdateOwnProfileInterestsMutationResult =
  Apollo.MutationResult<UpdateOwnProfileInterestsMutation>;
export type UpdateOwnProfileInterestsMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnProfileInterestsMutation,
  UpdateOwnProfileInterestsMutationVariables
>;
export const UpdateOwnProfileStrengthsDocument = gql`
  mutation UpdateOwnProfileStrengths($strengths: [String!]) {
    updateOwnProfileStrengths(strengths: $strengths) {
      id
    }
  }
`;
export type UpdateOwnProfileStrengthsMutationFn = Apollo.MutationFunction<
  UpdateOwnProfileStrengthsMutation,
  UpdateOwnProfileStrengthsMutationVariables
>;

/**
 * __useUpdateOwnProfileStrengthsMutation__
 *
 * To run a mutation, you first call `useUpdateOwnProfileStrengthsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnProfileStrengthsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnProfileStrengthsMutation, { data, loading, error }] = useUpdateOwnProfileStrengthsMutation({
 *   variables: {
 *      strengths: // value for 'strengths'
 *   },
 * });
 */
export function useUpdateOwnProfileStrengthsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnProfileStrengthsMutation,
    UpdateOwnProfileStrengthsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOwnProfileStrengthsMutation,
    UpdateOwnProfileStrengthsMutationVariables
  >(UpdateOwnProfileStrengthsDocument, options);
}
export type UpdateOwnProfileStrengthsMutationHookResult = ReturnType<
  typeof useUpdateOwnProfileStrengthsMutation
>;
export type UpdateOwnProfileStrengthsMutationResult =
  Apollo.MutationResult<UpdateOwnProfileStrengthsMutation>;
export type UpdateOwnProfileStrengthsMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnProfileStrengthsMutation,
  UpdateOwnProfileStrengthsMutationVariables
>;
export const UpdateUserProfileDocument = gql`
  mutation UpdateUserProfile($id: ID!, $profile: UpdateProfileInput!) {
    updateOneProfile(input: { id: $id, update: $profile }) {
      ...UpdateUserProfileFields
    }
  }
  ${UpdateUserProfileFieldsFragmentDoc}
`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(
    UpdateUserProfileDocument,
    options,
  );
}
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
export const UploadImageDocument = gql`
  mutation UploadImage($file: Upload!) {
    uploadImage(upload: { file: $file }) {
      id
      url
    }
  }
`;
export type UploadImageMutationFn = Apollo.MutationFunction<
  UploadImageMutation,
  UploadImageMutationVariables
>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadImageMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(
    UploadImageDocument,
    options,
  );
}
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<
  UploadImageMutation,
  UploadImageMutationVariables
>;
export const UserDocument = gql`
  query User($id: ID!) {
    user(id: $id) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserFeedbackDocument = gql`
  query UserFeedback($userId: ID!, $paging: CursorPaging) {
    feedbacks(paging: $paging, filter: { user: { id: { eq: $userId } } }) {
      pageInfo {
        ...PageInfoFields
      }
      edges {
        cursor
        node {
          ...FeedbackFields
        }
      }
    }
  }
  ${PageInfoFieldsFragmentDoc}
  ${FeedbackFieldsFragmentDoc}
`;

/**
 * __useUserFeedbackQuery__
 *
 * To run a query within a React component, call `useUserFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFeedbackQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useUserFeedbackQuery(
  baseOptions: Apollo.QueryHookOptions<UserFeedbackQuery, UserFeedbackQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserFeedbackQuery, UserFeedbackQueryVariables>(
    UserFeedbackDocument,
    options,
  );
}
export function useUserFeedbackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserFeedbackQuery, UserFeedbackQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserFeedbackQuery, UserFeedbackQueryVariables>(
    UserFeedbackDocument,
    options,
  );
}
export type UserFeedbackQueryHookResult = ReturnType<typeof useUserFeedbackQuery>;
export type UserFeedbackLazyQueryHookResult = ReturnType<typeof useUserFeedbackLazyQuery>;
export type UserFeedbackQueryResult = Apollo.QueryResult<
  UserFeedbackQuery,
  UserFeedbackQueryVariables
>;
export const UpdateUserSettingsDocument = gql`
  mutation UpdateUserSettings($id: ID!, $user: UpdateUserInput!) {
    updateOneUser(input: { id: $id, update: $user }) {
      ...UpdateUserSettingsFields
    }
  }
  ${UpdateUserSettingsFieldsFragmentDoc}
`;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(
    UpdateUserSettingsDocument,
    options,
  );
}
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;
export const UserSettingsDocument = gql`
  query UserSettings($id: ID!) {
    user(id: $id) {
      ...UserSettingsFields
    }
  }
  ${UserSettingsFieldsFragmentDoc}
`;

/**
 * __useUserSettingsQuery__
 *
 * To run a query within a React component, call `useUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<UserSettingsQuery, UserSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSettingsQuery, UserSettingsQueryVariables>(
    UserSettingsDocument,
    options,
  );
}
export function useUserSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserSettingsQuery, UserSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSettingsQuery, UserSettingsQueryVariables>(
    UserSettingsDocument,
    options,
  );
}
export type UserSettingsQueryHookResult = ReturnType<typeof useUserSettingsQuery>;
export type UserSettingsLazyQueryHookResult = ReturnType<typeof useUserSettingsLazyQuery>;
export type UserSettingsQueryResult = Apollo.QueryResult<
  UserSettingsQuery,
  UserSettingsQueryVariables
>;
export const CountUnreadThreadsDocument = gql`
  query CountUnreadThreads($userId: String!) {
    threadParticipantsAggregate(
      filter: { and: [{ unread: { is: true } }, { userId: { eq: $userId } }] }
    ) {
      count {
        id
      }
    }
  }
`;

/**
 * __useCountUnreadThreadsQuery__
 *
 * To run a query within a React component, call `useCountUnreadThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountUnreadThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountUnreadThreadsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCountUnreadThreadsQuery(
  baseOptions: Apollo.QueryHookOptions<CountUnreadThreadsQuery, CountUnreadThreadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountUnreadThreadsQuery, CountUnreadThreadsQueryVariables>(
    CountUnreadThreadsDocument,
    options,
  );
}
export function useCountUnreadThreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountUnreadThreadsQuery,
    CountUnreadThreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountUnreadThreadsQuery, CountUnreadThreadsQueryVariables>(
    CountUnreadThreadsDocument,
    options,
  );
}
export type CountUnreadThreadsQueryHookResult = ReturnType<typeof useCountUnreadThreadsQuery>;
export type CountUnreadThreadsLazyQueryHookResult = ReturnType<
  typeof useCountUnreadThreadsLazyQuery
>;
export type CountUnreadThreadsQueryResult = Apollo.QueryResult<
  CountUnreadThreadsQuery,
  CountUnreadThreadsQueryVariables
>;
export const CreatedThreadDocument = gql`
  subscription CreatedThread {
    createdMessageThread {
      id
      lastMessageAt
    }
  }
`;

/**
 * __useCreatedThreadSubscription__
 *
 * To run a query within a React component, call `useCreatedThreadSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedThreadSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedThreadSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCreatedThreadSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CreatedThreadSubscription,
    CreatedThreadSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<CreatedThreadSubscription, CreatedThreadSubscriptionVariables>(
    CreatedThreadDocument,
    options,
  );
}
export type CreatedThreadSubscriptionHookResult = ReturnType<typeof useCreatedThreadSubscription>;
export type CreatedThreadSubscriptionResult = Apollo.SubscriptionResult<CreatedThreadSubscription>;
export const LatestThreadsDocument = gql`
  query LatestThreads($userId: String!) {
    messageThreads(
      filter: { threadParticipants: { userId: { eq: $userId } } }
      paging: { first: 4 }
      sorting: { field: lastMessageAt, direction: DESC }
    ) {
      pageInfo {
        ...PageInfoFields
      }
      edges {
        cursor
        node {
          id
          createdAt
          lastMessageAt
          messages(paging: { first: 1 }, sorting: { field: createdAt, direction: DESC }) {
            edges {
              node {
                content
                createdAt
                updatedAt
                messageFiles {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
          threadParticipants {
            edges {
              node {
                id
                createdAt
                updatedAt
                userId
                unread
                user {
                  id
                  profile {
                    id
                    nickname
                    avatar {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${PageInfoFieldsFragmentDoc}
`;

/**
 * __useLatestThreadsQuery__
 *
 * To run a query within a React component, call `useLatestThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestThreadsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLatestThreadsQuery(
  baseOptions: Apollo.QueryHookOptions<LatestThreadsQuery, LatestThreadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LatestThreadsQuery, LatestThreadsQueryVariables>(
    LatestThreadsDocument,
    options,
  );
}
export function useLatestThreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LatestThreadsQuery, LatestThreadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LatestThreadsQuery, LatestThreadsQueryVariables>(
    LatestThreadsDocument,
    options,
  );
}
export type LatestThreadsQueryHookResult = ReturnType<typeof useLatestThreadsQuery>;
export type LatestThreadsLazyQueryHookResult = ReturnType<typeof useLatestThreadsLazyQuery>;
export type LatestThreadsQueryResult = Apollo.QueryResult<
  LatestThreadsQuery,
  LatestThreadsQueryVariables
>;
export const MarkReadDocument = gql`
  mutation MarkRead($id: ID!) {
    updateOneThreadParticipants(input: { id: $id, update: { unread: false } }) {
      id
      unread
    }
  }
`;
export type MarkReadMutationFn = Apollo.MutationFunction<
  MarkReadMutation,
  MarkReadMutationVariables
>;

/**
 * __useMarkReadMutation__
 *
 * To run a mutation, you first call `useMarkReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markReadMutation, { data, loading, error }] = useMarkReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkReadMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkReadMutation, MarkReadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkReadMutation, MarkReadMutationVariables>(MarkReadDocument, options);
}
export type MarkReadMutationHookResult = ReturnType<typeof useMarkReadMutation>;
export type MarkReadMutationResult = Apollo.MutationResult<MarkReadMutation>;
export type MarkReadMutationOptions = Apollo.BaseMutationOptions<
  MarkReadMutation,
  MarkReadMutationVariables
>;
export const UpdatedThreadDocument = gql`
  subscription UpdatedThread {
    updatedOneMessageThread {
      id
      lastMessageAt
    }
  }
`;

/**
 * __useUpdatedThreadSubscription__
 *
 * To run a query within a React component, call `useUpdatedThreadSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedThreadSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedThreadSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUpdatedThreadSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    UpdatedThreadSubscription,
    UpdatedThreadSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<UpdatedThreadSubscription, UpdatedThreadSubscriptionVariables>(
    UpdatedThreadDocument,
    options,
  );
}
export type UpdatedThreadSubscriptionHookResult = ReturnType<typeof useUpdatedThreadSubscription>;
export type UpdatedThreadSubscriptionResult = Apollo.SubscriptionResult<UpdatedThreadSubscription>;
export const CreatedNotificationDocument = gql`
  subscription CreatedNotification {
    createdNotification {
      ...NotificationFields
    }
  }
  ${NotificationFieldsFragmentDoc}
`;

/**
 * __useCreatedNotificationSubscription__
 *
 * To run a query within a React component, call `useCreatedNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCreatedNotificationSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CreatedNotificationSubscription,
    CreatedNotificationSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CreatedNotificationSubscription,
    CreatedNotificationSubscriptionVariables
  >(CreatedNotificationDocument, options);
}
export type CreatedNotificationSubscriptionHookResult = ReturnType<
  typeof useCreatedNotificationSubscription
>;
export type CreatedNotificationSubscriptionResult =
  Apollo.SubscriptionResult<CreatedNotificationSubscription>;
export const MarkAllNotificationsAsReadDocument = gql`
  mutation MarkAllNotificationsAsRead {
    updateManyNotifications(
      input: { update: { status: read }, filter: { status: { eq: unread } } }
    ) {
      updatedCount
    }
  }
`;
export type MarkAllNotificationsAsReadMutationFn = Apollo.MutationFunction<
  MarkAllNotificationsAsReadMutation,
  MarkAllNotificationsAsReadMutationVariables
>;

/**
 * __useMarkAllNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsAsReadMutation, { data, loading, error }] = useMarkAllNotificationsAsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllNotificationsAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkAllNotificationsAsReadMutation,
    MarkAllNotificationsAsReadMutationVariables
  >(MarkAllNotificationsAsReadDocument, options);
}
export type MarkAllNotificationsAsReadMutationHookResult = ReturnType<
  typeof useMarkAllNotificationsAsReadMutation
>;
export type MarkAllNotificationsAsReadMutationResult =
  Apollo.MutationResult<MarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkAllNotificationsAsReadMutation,
  MarkAllNotificationsAsReadMutationVariables
>;
export const MarkNotificationAsReadDocument = gql`
  mutation MarkNotificationAsRead($id: ID!) {
    updateOneNotification(input: { id: $id, update: { status: read } }) {
      id
      status
    }
  }
`;
export type MarkNotificationAsReadMutationFn = Apollo.MutationFunction<
  MarkNotificationAsReadMutation,
  MarkNotificationAsReadMutationVariables
>;

/**
 * __useMarkNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsReadMutation, { data, loading, error }] = useMarkNotificationAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkNotificationAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkNotificationAsReadMutation,
    MarkNotificationAsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkNotificationAsReadMutation,
    MarkNotificationAsReadMutationVariables
  >(MarkNotificationAsReadDocument, options);
}
export type MarkNotificationAsReadMutationHookResult = ReturnType<
  typeof useMarkNotificationAsReadMutation
>;
export type MarkNotificationAsReadMutationResult =
  Apollo.MutationResult<MarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkNotificationAsReadMutation,
  MarkNotificationAsReadMutationVariables
>;
export const NotificationsDocument = gql`
  query Notifications($cursor: ConnectionCursor) {
    notifications(paging: { after: $cursor, first: 10 }) {
      pageInfo {
        ...PageInfoFields
      }
      edges {
        cursor
        node {
          ...NotificationFields
        }
      }
    }
  }
  ${PageInfoFieldsFragmentDoc}
  ${NotificationFieldsFragmentDoc}
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options,
  );
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options,
  );
}
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const UnreadNotificationsCountDocument = gql`
  query UnreadNotificationsCount {
    notificationAggregate(filter: { status: { eq: unread } }) {
      count {
        id
      }
    }
  }
`;

/**
 * __useUnreadNotificationsCountQuery__
 *
 * To run a query within a React component, call `useUnreadNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadNotificationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnreadNotificationsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UnreadNotificationsCountQuery,
    UnreadNotificationsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnreadNotificationsCountQuery, UnreadNotificationsCountQueryVariables>(
    UnreadNotificationsCountDocument,
    options,
  );
}
export function useUnreadNotificationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnreadNotificationsCountQuery,
    UnreadNotificationsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnreadNotificationsCountQuery, UnreadNotificationsCountQueryVariables>(
    UnreadNotificationsCountDocument,
    options,
  );
}
export type UnreadNotificationsCountQueryHookResult = ReturnType<
  typeof useUnreadNotificationsCountQuery
>;
export type UnreadNotificationsCountLazyQueryHookResult = ReturnType<
  typeof useUnreadNotificationsCountLazyQuery
>;
export type UnreadNotificationsCountQueryResult = Apollo.QueryResult<
  UnreadNotificationsCountQuery,
  UnreadNotificationsCountQueryVariables
>;
export const CurrentUserDocument = gql`
  query CurrentUser {
    me {
      ...CurrentUserFields
    }
  }
  ${CurrentUserFieldsFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
